@if (shareLink()) {
  <axpo-dialog
    title="{{ getTitle() | transloco }}"
    cancelText="{{ getCancelText() | transloco }}"
    confirmText="{{ getConfirmText() | transloco }}"
    textAlign="left"
    (takenAction)="onShareClosed($event, mode() === 'edit' ? 'share' : 'confirmAndLoad')"
    [cancelBtnStyle]="mode() === 'create' ? 'primary' : 'secondary'"
    [cancelBtnType]="mode() === 'create' ? 'outlined' : 'ghost'"
  >
    @if (mode() === 'edit') {
      {{ 'incidentDetail.shareLinkDialog.text' | transloco }}
    } @else {
      <p class="pb-4">{{ 'incidentCreate.successP1' | transloco }}</p>
      <p class="pb-4">{{ 'incidentCreate.successP2' | transloco }}</p>
      <p class="pb-4">{{ 'incidentCreate.successP3' | transloco }}</p>
      <p class="pb-4">{{ 'incidentCreate.successP4' | transloco: { value: accessCode() } }}</p>
    }
    <div class="pt-4 pb-12 border-b-2 border-border-soft-300">
      <axpo-button
        [type]="getShareLinkBtnStyle()"
        [style]="mode() === 'create' ? 'primary' : 'secondary'"
        [autofocus]="true"
        (click)="copyLinkToClipboard()"
        class="float-right"
      >
        {{ getShareLinkBtnText() | transloco }}
      </axpo-button>
    </div>
    @if (isLoggedIn()) {
      <div class="pt-4">
        {{ 'incidentDetail.shareLinkDialog.textShareBy' | transloco }}
      </div>
      <div class="relative flex justify-between top-5">
        <div class="space-y-1.5">
          <div class="flex space-x-4">
            <ng-container [ngTemplateOutlet]="smsSvg"></ng-container>
            <axpo-typography>
              {{ 'incidentDetail.shareLinkDialog.sms' | transloco }}
            </axpo-typography>
          </div>
          <div class="flex space-x-4">
            <ng-container [ngTemplateOutlet]="mailSvg"></ng-container>
            <axpo-typography>
              {{ 'incidentDetail.shareLinkDialog.mail' | transloco }}
            </axpo-typography>
          </div>
          <div class="flex space-x-4">
            <ng-container [ngTemplateOutlet]="wakeUpCallSvg"></ng-container>
            <axpo-typography>
              {{ 'incidentDetail.shareLinkDialog.wakeUpCall' | transloco }}
            </axpo-typography>
          </div>
          <div class="flex space-x-4">
            <i class="text-2xl fas fa-info-circle text-primary"></i>
            <axpo-typography>
              {{ 'core.personToInform' | transloco }}
            </axpo-typography>
          </div>

          <div class="flex space-x-4">
            <svg class="h-6 fill-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                d="M482 363.58V316c0-49.388-15.925-96.331-46.053-135.753-26.743-34.993-63.719-61.83-105.003-76.443C329.795 80.007 310.077 61 286 61h-60c-24.077 0-43.795 19.007-44.944 42.804-41.284 14.613-78.26 41.45-105.003 76.443C45.925 219.669 30 266.612 30 316v47.58C12.541 369.772 0 386.445 0 406c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 0-19.555-12.541-36.228-30-42.42zM60 316c0-54.091 23.106-104.728 61-140.855V226c0 8.284 6.716 15 15 15s15-6.716 15-15v-74.383a195.128 195.128 0 0130-15.679V256c0 8.284 6.716 15 15 15s15-6.716 15-15V114.932 106c0-8.271 6.729-15 15-15h60c8.271 0 15 6.729 15 15v150c0 8.284 6.716 15 15 15s15-6.716 15-15V135.938a195.304 195.304 0 0130 15.679V226c0 8.284 6.716 15 15 15s15-6.716 15-15v-50.855c37.894 36.127 61 86.764 61 140.855v45H60v-45zm407 105H45c-8.271 0-15-6.729-15-15s6.729-15 15-15h422c8.271 0 15 6.729 15 15s-6.729 15-15 15z"
              ></path>
            </svg>
            <axpo-typography>
              {{ 'core.executingPerson' | transloco }}
            </axpo-typography>
          </div>
        </div>

        <div class="ml-auto w-[80px] flex justify-between pt-[93px] relative">
          <div class="w-[20px]">
            <ng-container [ngTemplateOutlet]="smsSvg"></ng-container>
            <axpo-form-element
              formId="linkShareSMS"
              formType="checkbox"
              [value]="linkShareSMS()"
              (valueChange)="updateSMSLinkCheck($event)"
              size="medium"
            ></axpo-form-element>
          </div>
          <div class="w-[20px]">
            <ng-container [ngTemplateOutlet]="mailSvg"></ng-container>
            <axpo-form-element
              formId="linkShareMail"
              formType="checkbox"
              [value]="linkShareMail()"
              (valueChange)="updateMailLinkCheck($event)"
              size="medium"
            ></axpo-form-element>
          </div>
          <div class="w-[20px]">
            <ng-container [ngTemplateOutlet]="wakeUpCallSvg"></ng-container>
            <axpo-form-element
              formId="linkShareCall"
              formType="checkbox"
              [value]="linkShareCall()"
              (valueChange)="updateCallLinkCheck($event)"
              size="medium"
            ></axpo-form-element>
          </div>
        </div>
      </div>
      <div class="pt-4">
        @for (contact of contacts(); track contact.id) {
          <div
            class="flex items-center justify-between pt-4 pb-4 border-b-2 border-border-soft-300"
          >
            <div>
              @if (contact.isExecutor === true) {
                <svg
                  class="h-6 fill-primary"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M482 363.58V316c0-49.388-15.925-96.331-46.053-135.753-26.743-34.993-63.719-61.83-105.003-76.443C329.795 80.007 310.077 61 286 61h-60c-24.077 0-43.795 19.007-44.944 42.804-41.284 14.613-78.26 41.45-105.003 76.443C45.925 219.669 30 266.612 30 316v47.58C12.541 369.772 0 386.445 0 406c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 0-19.555-12.541-36.228-30-42.42zM60 316c0-54.091 23.106-104.728 61-140.855V226c0 8.284 6.716 15 15 15s15-6.716 15-15v-74.383a195.128 195.128 0 0130-15.679V256c0 8.284 6.716 15 15 15s15-6.716 15-15V114.932 106c0-8.271 6.729-15 15-15h60c8.271 0 15 6.729 15 15v150c0 8.284 6.716 15 15 15s15-6.716 15-15V135.938a195.304 195.304 0 0130 15.679V226c0 8.284 6.716 15 15 15s15-6.716 15-15v-50.855c37.894 36.127 61 86.764 61 140.855v45H60v-45zm407 105H45c-8.271 0-15-6.729-15-15s6.729-15 15-15h422c8.271 0 15 6.729 15 15s-6.729 15-15 15z"
                  ></path>
                </svg>
              } @else {
                <i class="text-2xl fas fa-info-circle text-primary"></i>
              }
            </div>
            <div class="flex-1 ml-8">
              <axpo-typography variant="body-s">
                <span class="light-gray">{{ contact.designation }}</span>
              </axpo-typography>
              <axpo-typography variant="body-m">
                {{ contact.name }}
              </axpo-typography>
              <axpo-typography variant="body-s">
                <span class="light-gray">{{ contact.mail }}</span>
              </axpo-typography>
            </div>

            <div class="w-[80px] flex justify-between">
              @if (contact.phone) {
                <axpo-form-element
                  formId="contactNotifySignalPhone"
                  formType="checkbox"
                  [value]="getContactNotifySignal(contact.id, 'phone')()"
                  (valueChange)="updateContactNotifySignal(contact.id, 'phone')"
                  size="medium"
                ></axpo-form-element>
              } @else {
                <div class="w-[20px] h-[20px]"></div>
              }

              @if (contact.mail) {
                <axpo-form-element
                  formId="contactNotifySignalMail"
                  formType="checkbox"
                  [value]="getContactNotifySignal(contact.id, 'mail')()"
                  (valueChange)="updateContactNotifySignal(contact.id, 'mail')"
                  size="medium"
                ></axpo-form-element>
              } @else {
                <div class="w-[20px] h-[20px]"></div>
              }

              @if (contact.phone) {
                <axpo-form-element
                  formId="contactNotifySignalWakeUp"
                  formType="checkbox"
                  [value]="getContactNotifySignal(contact.id, 'wakeUp')()"
                  (valueChange)="updateContactNotifySignal(contact.id, 'wakeUp')"
                  size="medium"
                ></axpo-form-element>
              } @else {
                <div class="w-[20px] h-[20px]"></div>
              }
            </div>
          </div>
        }
        <div class="flex items-center justify-between pt-4">
          <i class="text-2xl fas fa-info-circle text-primary"></i>
          <div class="flex-1 ml-8">
            <axpo-typography variant="body-m" class="text-text-secondary">
              {{ 'admin.mailingList' | transloco }}
            </axpo-typography>
            <axpo-typography variant="body-m">
              {{ tenantContactName() }}
            </axpo-typography>
          </div>

          <div class="flex justify-between w-[80px]">
            <div class="w-[20px] h-[20px]"></div>
            <axpo-form-element
              formId="contactTenantInformed"
              formType="checkbox"
              [value]="tenantContactInformed()"
              (valueChange)="updateContactTenantInformedCheck($event)"
              size="medium"
              class="min-w-30"
            ></axpo-form-element>
            <div class="w-[20px] h-[20px]"></div>
          </div>
        </div>
        @if ((incidentService.incidentDetails()?.hasInterruptions ?? 0) > 0) {
          <div class="flex justify-between items-center pt-4">
            <i class="fas fa-info-circle text-primary text-2xl"></i>
            <div class="flex-1 ml-8">
              <axpo-typography variant="body-m" class="text-text-secondary">
                {{ 'admin.mailingListSupplyFailure' | transloco }}
              </axpo-typography>
              <axpo-typography variant="body-m">
                {{ tenantSupplyFailureContactName() }}
              </axpo-typography>
            </div>

            <div class="flex justify-between w-[80px]">
              <div class="w-[20px] h-[20px]"></div>
              <axpo-form-element
                formId="tenantSupplyFailureContactInformed"
                formType="checkbox"
                [value]="tenantSupplyFailureContactInformed()"
                (valueChange)="updateSupplyFailureContactTenantInformedCheck($event)"
                size="medium"
                class="min-w-30"
              ></axpo-form-element>
              <div class="w-[20px] h-[20px]"></div>
            </div>
          </div>
        }
      </div>
      @if (mode() === 'create') {
        <axpo-button
          [style]="'primary'"
          [type]="'outlined'"
          class="float-right"
          (click)="onShareClosed('confirm', 'share')"
        >
          {{ 'incidentDetail.share' | transloco }}
        </axpo-button>
      }
    }
  </axpo-dialog>
}
<!-- Templates -->
<ng-template #smsSvg>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <mask
      id="mask0_1190_1396"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="white" />
    </mask>
    <g mask="url(#mask0_1190_1396)">
      <path
        d="M3.90285 18.627C3.37113 18.627 2.92106 18.4447 2.55263 18.08C2.18421 17.7154 2 17.27 2 16.7437V4.88327C2 4.35702 2.18421 3.91158 2.55263 3.54695C2.92106 3.18232 3.37113 3 3.90285 3H20.0972C20.6289 3 21.0789 3.18232 21.4474 3.54695C21.8158 3.91158 22 4.35702 22 4.88327V20.0374C22 20.4595 21.8053 20.7516 21.416 20.9139C21.0266 21.0762 20.6822 21.0091 20.3826 20.7126L18.2753 18.627H3.90285ZM18.9474 17.0643L20.4211 18.5068V4.88327C20.4211 4.80312 20.3873 4.72964 20.3199 4.66285C20.2524 4.59607 20.1781 4.56268 20.0972 4.56268H3.90285C3.82186 4.56268 3.74763 4.59607 3.68014 4.66285C3.61266 4.72964 3.57893 4.80312 3.57893 4.88327V16.7437C3.57893 16.8239 3.61266 16.8973 3.68014 16.9641C3.74763 17.0309 3.82186 17.0643 3.90285 17.0643H18.9474Z"
        class="fill-primary"
      />
    </g>
  </svg>
</ng-template>

<ng-template #mailSvg>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <mask
      id="mask0_1682_475"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="white" />
    </mask>
    <g mask="url(#mask0_1682_475)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.1914 4.80005H2.80846C1.88931 4.80005 1.19995 5.50249 1.19995 6.43907V17.561C1.19995 18.4391 1.88931 19.2 2.80846 19.2H21.1914C22.0531 19.2 22.7999 18.4976 22.7999 17.561V6.43907C22.7425 5.50249 22.0531 4.80005 21.1914 4.80005ZM20.9617 5.6781L12 12.2927L2.92336 5.6781H20.9617ZM21.1914 18.2635C21.5361 18.2635 21.8808 17.9708 21.8808 17.561V6.43907C21.8808 6.322 21.8808 6.20493 21.8234 6.14639L12.2872 13.2293C12.1149 13.3464 11.9425 13.3464 11.7702 13.2293L2.17655 6.20493C2.1191 6.26346 2.1191 6.38054 2.1191 6.43907V17.561C2.1191 17.9122 2.40634 18.2635 2.80847 18.2635H21.1914Z"
        class="fill-primary"
      />
    </g>
  </svg>
</ng-template>

<ng-template #wakeUpCallSvg>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <mask
      id="mask0_1909_1837"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="white" />
    </mask>
    <g mask="url(#mask0_1909_1837)">
      <path
        d="M4.57143 19.022C4.40952 19.022 4.27381 18.9671 4.16429 18.8575C4.05476 18.7478 4 18.6119 4 18.4498C4 18.2877 4.05476 18.152 4.16429 18.0429C4.27381 17.9337 4.40952 17.8791 4.57143 17.8791H5.84614V8.82417C5.84614 7.33114 6.31868 6.01796 7.26374 4.88463C8.20879 3.75128 9.40659 3.04541 10.8571 2.76703V2.14286C10.8571 1.82539 10.968 1.55554 11.1897 1.33331C11.4114 1.11111 11.6807 1 11.9974 1C12.3142 1 12.5843 1.11111 12.8077 1.33331C13.0311 1.55554 13.1429 1.82539 13.1429 2.14286V2.76703C14.5934 3.04541 15.7912 3.75128 16.7363 4.88463C17.6813 6.01796 18.1539 7.33114 18.1539 8.82417V17.8791H19.4286C19.5905 17.8791 19.7262 17.934 19.8357 18.0436C19.9452 18.1533 20 18.2892 20 18.4513C20 18.6135 19.9452 18.7491 19.8357 18.8582C19.7262 18.9674 19.5905 19.022 19.4286 19.022H4.57143ZM11.9961 22.011C11.4874 22.011 11.0531 21.8302 10.6934 21.4687C10.3337 21.1071 10.1539 20.6725 10.1539 20.1648H13.8461C13.8461 20.6762 13.665 21.1117 13.3027 21.4714C12.9404 21.8311 12.5049 22.011 11.9961 22.011ZM6.989 17.8791H17.011V8.82417C17.011 7.43516 16.5231 6.25274 15.5473 5.27692C14.5714 4.30111 13.389 3.8132 12 3.8132C10.611 3.8132 9.42857 4.30111 8.45274 5.27692C7.47691 6.25274 6.989 7.43516 6.989 8.82417V17.8791Z"
        class="fill-primary"
      />
    </g>
  </svg>
</ng-template>
