export interface IKeyValueModel {
  key?: string | null;
  value?: string | null;
}

export interface IPositionModel {
  latitude?: number;
  longitude?: number;
}

export interface IAttachmentModel {
  id?: number;
  name: string;
  size?: number;
  internal?: boolean;
}

export interface IAttachmentLocalModel extends IAttachmentModel {
  isInPwa?: boolean;
  isDeleting: boolean;
  isUploading: boolean;
}

export interface FileResponse {
  data: Blob;
  status: number;
  fileName?: string;
  headers?: Record<string, any>;
}

export enum LocationModelType {
  Address = 'Address',
  Location = 'Location',
}

export interface ICustomLocationModel {
  coordinates?: IPositionModel | null;
  description?: string | null;
  id?: number;
  name: string;
}

export interface ILocationModel {
  additionalInformation?: string | null;
  axpoId?: string | null;
  coordinates?: IPositionModel | null;
  description?: string | null;
  id?: number;
  isActive?: boolean;
  keyName?: string | null;
  keyValue?: string | null;
  locationTypeId?: number;
  locationTypeName?: string | null;
  name?: string | null;
  query?: string | null;
  type?: LocationModelType;
}

export interface ICommentModel {
  author: string;
  id?: number;
  message: string;
  sendTimeStamp?: Date;
  isPrivate?: boolean;
  executor?: string | null;
}

export interface IContactModel {
  callNotificationStateId?: number | null;
  callNotificationStateName?: string | null;
  designation: string;
  id?: number;
  isExecutor?: boolean;
  members?: number;
  mail?: string | null;
  name: string;
  phone?: string | null;
}

export interface IContactListModel {
  id?: number;
  name?: string | null;
  mail?: string | null;
  phone?: string | null;
}

export interface IIncidentModel {
  accessCode?: string;
  alertErrorSource1?: string;
  alertErrorSource2?: string;
  alertErrorSource3?: string;
  alertErrorSource4?: string;
  alertMessageInfo?: string;
  alertName?: string;
  alertRelayData1?: string;
  alertRelayData2?: string;
  alertRelayData3?: string;
  alertRelayData4?: string;
  protectionReportRequired?: boolean;
  attachments?: IAttachmentModel[];
  attachmentsInternal?: IAttachmentModel[];
  comments?: ICommentModel[];
  contacts?: IContactModel[];
  customLocations?: ICustomLocationModel[];
  faultCauseId?: number;
  faultCauseName?: string;
  faultDamagedLocation?: string;
  faultDescription?: string;
  faultEffectId?: string;
  faultEffectName?: string;
  faultIsolators?: number;
  faultLineDamageId?: number;
  faultLineDamageName?: string;
  faultLocationId?: number;
  faultLocationName?: string;
  faultPlantDamageId?: number;
  faultPlantDamageName?: string;
  faultSubstation?: string;
  incidentCategoryId?: number;
  incidentCategoryName?: string;
  incidentCreatorName?: string;
  incidentDescription?: string;
  incidentFaultTimeStamp?: string;
  incidentFixedTimeStamp?: string;
  incidentImpactId?: number;
  incidentImpactName?: string;
  incidentLastModificationTimeStamp?: Date;
  incidentStateId?: number;
  incidentStateName?: string;
  incidentTitle?: string;
  hasInterruptions?: number;
  locations?: ILocationModel[];
  voltages?: IKeyValueModel[];
}

export interface IIncidentUpdateModel {
  alertErrorSource1?: string;
  alertErrorSource2?: string;
  alertErrorSource3?: string;
  alertErrorSource4?: string;
  alertMessageInfo?: string;
  alertName?: string;
  alertRelayData1?: string;
  alertRelayData2?: string;
  alertRelayData3?: string;
  alertRelayData4?: string;
  protectionReportRequired?: boolean;
  faultCauseId?: number;
  faultDamagedLocation?: string;
  faultDescription?: string;
  faultEffectId?: string;
  faultIsolators?: number;
  faultLineDamageId?: number;
  faultLocationId?: number;
  faultPlantDamageId?: number;
  faultSubstation?: string;
  incidentCategoryId?: number;
  incidentDescription?: string;
  incidentFaultTimeStamp?: string;
  incidentFixedTimeStamp?: string;
  incidentImpactId?: number;
  incidentStateId?: number;
  incidentTitle?: string;
  hasInterruptions?: number;
}

export interface IIncidentDetailsUpdateModel {
  incidentCategoryId?: number;
  incidentDescription?: string;
  incidentTitle?: string;
  faultEffectId?: string;
  incidentStateId?: number;
  incidentImpactId?: number;
  incidentFaultTimeStamp?: string;
  incidentFixedTimeStamp?: string;
  hasInterruptions?: number;
}

export interface IIncidentDetails {
  incidentCategoryId?: number | null;
  incidentCategoryName?: string | null;
  incidentCreatorName?: string | null;
  incidentDescription?: string | null;
  incidentFaultTimeStamp?: Date | null;
  incidentFixedTimeStamp?: Date | null;
  incidentImpactId?: number | null;
  incidentImpactName?: string | null;
  faultEffectId?: number | null;
  faultEffectName?: string | null;
  incidentLastModificationTimeStamp?: Date;
  incidentStateId?: number | null;
  incidentStateName?: string | null;
  incidentTitle?: string | null;
  hasInterruptions?: number | null;
}

export enum IncidentListFilterSorting {
  IncidentStateAsc = 'IncidentStateAsc',
  IncidentStateDesc = 'IncidentStateDesc',
  IncidentCategoryAsc = 'IncidentCategoryAsc',
  IncidentCategoryDesc = 'IncidentCategoryDesc',
  IncidentFaultTimeStampAsc = 'IncidentFaultTimeStampAsc',
  IncidentFaultTimeStampDesc = 'IncidentFaultTimeStampDesc',
  IncidentTitleAsc = 'IncidentTitleAsc',
  IncidentTitleDesc = 'IncidentTitleDesc',
  IncidentLastModificationTimeStampAsc = 'IncidentLastModificationTimeStampAsc',
  IncidentLastModificationTimeStampDesc = 'IncidentLastModificationTimeStampDesc',
}

export interface IIncidentListFilterModel {
  incidentCategoryId?: number | null;
  incidentFaultFrom?: string | null;
  incidentFaultTo?: string | null;
  incidentStateIds?: number[] | null;
  rowsToReturn?: number;
  rowsToSkip?: number;
  searchText?: string | null;
  sorting?: IncidentListFilterSorting;
  locale?: string;
}

export interface IIncidentListModel {
  faultCauseName?: string;
  accessCode?: string;
  alertName?: string | null;
  incidentCategoryId?: number | null;
  incidentCategoryName?: string | null;
  incidentFaultTimeStamp?: Date | string | null;
  incidentFixedTimeStamp?: Date | string | null;
  incidentLastModificationTimeStamp?: Date;
  incidentStateId?: number | null;
  incidentStateName?: string | null;
  incidentTitle?: string | null;
  hasInterruptions?: number | null;
  locations?: ILocationModel[] | null;
}

export interface IINotifyBody {
  contactId: number;
  sendMail: boolean;
  sendSms: boolean;
  makeCall: boolean;
  hasMail: boolean;
  hasPhone: boolean;
}

export interface IValidationErrorModel {
  errorMessage?: string | null;
  key?: string | null;
}
export interface ITenantContactModel {
  name?: string | null;
  email?: string | null;
}

export interface ITileModel {
  name?: string | null;
  originalName?: string | null;
  isVisible?: number;
}

export interface ITenantSettingsModel {
  tenantId?: string | null;
  tenantName?: string | null;
  applicationName?: string | null;
  footerBackgroundColor?: string | null;
  headerBackgroundColor?: string | null;
  primaryColor?: string | null;
  contact?: ITenantContactModel | null;
  tenantIncidentMailRecipientContact?: IContactModel | null;
  tenantSupplyFailureMailRecipientContact?: IContactModel | null;
  tileModels?: ITileModel[] | null;
}

export interface IIncidentIncidentResolutionUpdateModel {
  faultCauseId?: number;
  faultDamagedLocation?: string;
  faultDescription?: string;
  faultEffectId?: string;
  faultIsolators?: number;
  faultLineDamageId?: number;
  faultLocationId?: number;
  faultPlantDamageId?: number;
  faultSubstation?: string;
}

export interface ITenantSettingsUpdateModel {
  tenantId?: string | null;
  tenantName?: string | null;
  applicationName?: string | null;
  contact?: ITenantContactModel | null;
  tenantIncidentMailRecipientContact?: IContactModel | null;
  tenantSupplyFailureMailRecipientContact?: IContactModel | null;
  tileModels?: ITileModel[] | null;
  primaryColor?: string | null;
  headerBackgroundColor?: string | null;
  footerBackgroundColor?: string | null; 
}

export interface ITenantsModel {
  key: string;
  value: string;
}

export interface ILocationImportResultModel {
  activated?: ILocationImportModel[] | null;
  changed?: ILocationImportModel[] | null;
  deactivated?: ILocationImportModel[] | null;
  validationErrors?: string[] | null;
  validationWarnings?: string[] | null;
}

export interface ILocationImportModel {
  axpoId?: string | null;
  coordinates?: IPositionModel | null;
  description?: string | null;
  keyName?: string | null;
  keyValue?: string | null;
  latitude?: string | null;
  locationTypeId?: number | null;
  locationTypeName: string;
  longitude?: string | null;
  name: string;
  oldCoordinates?: IPositionModel | null;
  oldDescription?: string | null;
  oldLocationTypeName?: string | null;
  oldName?: string | null;
  query?: string | null;
}

export interface IContactTemplateImportResultModel {
  validationErrors?: string[] | null;
  validationWarnings?: string[] | null;
}

export interface IValueTupleOfIntegerAndValidationErrorModelOfAndString {
  item1?: number;
  item2?: IValidationErrorModel[];
  item3?: string;
}

export interface IStatisticsModel {
  averageResolvingMinutes: number;
  faultCauses: IKeyValueModel[];
  faultEffects: IKeyValueModel[];
  faultLineDamages: IKeyValueModel[];
  faultLocations: IKeyValueModel[];
  faultPlantDamages: IKeyValueModel[];
}

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  mobile?: number | string;
  active?: boolean;
  automaticallyAdded?: boolean;
  onCall?: boolean;
  isOnSite?: boolean;
  role?: string;
}

export interface AadGroup {
  id: number;
  name: string;
  createdAt: string;
}

export interface Subgroup {
  id?: number;
  name?: string;
  users?: User[];
  aad_groups?: AadGroup[];
}

export interface Organization {
  id: number;
  name: string;
  subgroups: Subgroup[];
  userOnCall: User | undefined;
}

export interface Circle {
  id?: number;
  name?: string;
  tenantId?: number;
  userMainNumber?: User | undefined | null;
  onSite?: Subgroup | undefined;
  mainNumber?: string;
  organizations?: Organization[];
}

export interface ICircleContact {
  subwork: number,
  name: string,
  id: number
}

export interface IOnCall {
  "user_id": number,
  "organization_id": number
}

export interface IUpdateUser {
  "user_id": number,
  "subgroup_id": number
}

export interface IUpdateCircle {
  main_number_user_id?: number | null,
  add_users?: IUpdateUser[],
  remove_users?: IUpdateUser[],
  on_call?: IOnCall[]
}

export interface IUserState {
  "subgroup_id": number,
  "users": [
    {
      "user_id": number,
      "active": boolean
    }
  ]
}
