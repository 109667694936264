<div
  class="flex-1 p-4 mb-8 space-y-4 rounded-lg shadow-lg"
  [ngClass]="{ 'bg-border-soft': type() === 'private' }"
>
  <div class="pb-4">
    @if (type() === 'public') {
      <axpo-typography [variant]="'title-m'">
        {{ title() }}
      </axpo-typography>
    } @else {
      <div class="flex space-x-4">
        <axpo-typography [variant]="'title-m'">
          {{ title() }}
        </axpo-typography>
        <axpo-typography class="relative top-[6px]" [variant]="'body-m'">
          ( {{ 'incidentDetail.onlyLoggedIn' | transloco }} )
        </axpo-typography>
      </div>
    }
    @if (commentsEmpty() && mode() !== 'edit') {
      <axpo-typography [variant]="'body-l'">
        {{ 'incidentDetail.noComments' | transloco }}
      </axpo-typography>
    }
  </div>
  @for (comment of comments(); track comment.id) {
    <div class="p-4 mb-4 bg-white border rounded-lg">
      <div class="flex items-center justify-between mb-2 space-y-4">
        <div class="flex-1">
          <div>
            <axpo-typography>{{ comment.author }}</axpo-typography>
            <axpo-typography>{{ comment.sendTimeStamp | date: 'medium' }}</axpo-typography>
          </div>

          @if (mode() === 'edit' && editingComment() && editingCommentId() === comment.id) {
            <div class="pt-4 pb-4 text-right">
              <axpo-form-element
                formId="editingCommentMessage"
                [(value)]="editingCommentMessage"
                formType="textarea"
              ></axpo-form-element>
              <div class="flex justify-between pt-4">
                @if (type() === 'private') {
                  <axpo-button
                    [type]="'outlined'"
                    [style]="'secondary'"
                    (click)="changeCommentVisibility('public')"
                  >
                    <div
                      class="w-5 h-5 text-text-secondary pr-1"
                      [innerHTML]="getSvg()['unlock']"
                    ></div>
                    {{ 'incidentDetail.makeCommentPublic' | transloco }}
                  </axpo-button>
                } @else {
                  <axpo-button
                    [type]="'outlined'"
                    [style]="'secondary'"
                    (click)="changeCommentVisibility('private')"
                  >
                    <div
                      class="w-5 h-5 text-text-secondary pr-1"
                      [innerHTML]="getSvg()['lock']"
                    ></div>
                    {{ 'incidentDetail.makeCommentPrivate' | transloco }}
                  </axpo-button>
                }
                <axpo-button [type]="'outlined'" [style]="'secondary'" (click)="cancelEdit()">
                  {{ 'core.cancel' | transloco }}
                </axpo-button>
                <axpo-button
                  [disabled]="!editingCommentMessage() || editingCommentMessage()?.length === 0"
                  (click)="updateComment()"
                >
                  {{ 'core.save' | transloco }}
                </axpo-button>
              </div>
            </div>
          } @else {
            <p>{{ comment.message }}</p>
          }
        </div>
        @if (
          mode() === 'edit' && !editingComment() && isLoggedIn() && comment.author === userName()
        ) {
          <div class="flex justify-between text-primary">
            <div
              class="relative top-[-4px] w-8"
              role="button"
              (click)="editComment(comment)"
              tabindex="0"
              aria-hidden="true"
            >
              <div class="w-6 h-6 text-text-secondary" [innerHTML]="getSvg()['pen']"></div>
            </div>
            <div
              role="button"
              (click)="deleteComment(comment)"
              tabindex="0"
              aria-hidden="true"
              class="w-8"
            >
              <div
                class="w-6 h-6 text-text-secondary relative top-[-3px]"
                [innerHTML]="getSvg()['delete']"
              ></div>
            </div>
          </div>
        }
      </div>
    </div>
  }
  @if (mode() === 'edit') {
    <div class="pt-4">
      <axpo-typography variant="body-s">{{ 'incidentDetail.creator' | transloco }}</axpo-typography>
      @if (isLoggedIn()) {
        <axpo-typography>{{ userName() }}</axpo-typography>
      } @else {
        <axpo-form-element
          formId="notLoggedInUserName"
          formType="text"
          [(value)]="notLoggedInUserName"
          [placeholder]="'incidentDetail.creator' | transloco"
        ></axpo-form-element>
      }
      <axpo-form-element
        formId="newCommentMessage"
        formType="textarea"
        [(value)]="newCommentMessage"
      ></axpo-form-element>

      <div class="flex justify-end pt-4">
        <axpo-button
          [disabled]="
            !newCommentMessage() ||
            newCommentMessage()?.length === 0 ||
            !userName() ||
            userName()?.length === 0
          "
          (click)="addComment()"
        >
          {{ 'incidentDetail.comment' | transloco }}
        </axpo-button>
      </div>
    </div>
  }
</div>
