<table class="w-full">
  @if (!showMobileLayout()) {
    <thead>
      <tr>
        @if (hasSelectAction()) {
          <th [class]="headerClass()" class="rounded-tr-xl"></th>
        }
        @for (column of columns(); track column.field; let first = $first; let last = $last) {
          <th
            scope="col"
            [class]="headerClass()"
            [ngClass]="{
              'cursor-pointer': column.sortable,
              'rounded-tl-xl': first,
              'rounded-tr-xl': last && actions().length === 0,
            }"
            (click)="column.sortable ? changeSort(column.field) : null"
          >
            @if (sortBy()?.field === column.field && sortBy()?.direction === 'desc') {
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="currentColor"
                viewBox="0 0 16 16"
                class="inline"
              >
                <path
                  d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                />
              </svg>
            } @else if (sortBy()?.field === column.field && sortBy()?.direction === 'asc') {
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="currentColor"
                class="inline"
                viewBox="0 0 16 16"
              >
                <path
                  d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                />
              </svg>
            } @else if (sortBy()?.field !== column.field && column.sortable) {
              <span class="text-text-secondary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="15"
                  viewBox="0 0 16 20"
                  fill="currentColor"
                  class="inline"
                >
                  <path
                    d="m 7.0453508,17.14 -4.796,-5.482 c -0.566,-0.645 -0.106,-1.658 0.753,-1.658 h 9.5920002 a 1,1 0 0 1 0.753,1.659 l -4.7960002,5.48 a 1,1 0 0 1 -1.506,0 z"
                  />
                  <path
                    d="m 7.0453508,1.3409795 -4.796,5.481 c -0.566,0.6469999 -0.106,1.6589999 0.753,1.6589999 h 9.5920002 a 1,1 0 0 0 0.753,-1.6589999 l -4.7960002,-5.48 a 1,1 0 0 0 -1.506,0 z"
                  />
                </svg>
              </span>
            }

            {{ column.title }}
          </th>
        }
        @if (hasNonSelectAction()) {
          <th [class]="headerClass()" class="rounded-tr-xl"></th>
        }
      </tr>
    </thead>
  }
  <tbody>
    @if (isLoading()) {
      <tr class="border-y border-spacing-y-8 border-gray">
        @if (showMobileLayout()) {
          <td><ngx-skeleton-loader [count]="skeletonLoadingLines()" appearance="line" /></td>
          <td><ngx-skeleton-loader [count]="skeletonLoadingLines()" appearance="line" /></td>
        } @else {
          @if (hasSelectAction()) {
            <td><ngx-skeleton-loader [count]="skeletonLoadingLines()" appearance="line" /></td>
          }
          @for (column of columns(); track column.field) {
            <td><ngx-skeleton-loader [count]="skeletonLoadingLines()" appearance="line" /></td>
          }
          @if (hasNonSelectAction()) {
            <td><ngx-skeleton-loader [count]="skeletonLoadingLines()" appearance="line" /></td>
          }
        }
      </tr>
    } @else {
      @for (item of data(); track item['id'].value; let index = $index) {
        <tr
          class="border-y border-spacing-y-8 border-gray hover:bg-background-1 hover:bg-opacity-30"
          (click)="rowClick.emit({ row: item, rowIndex: index })"
        >
          @if (showMobileLayout()) {
            <td>
              @for (column of columns(); track column.field) {
                <div class="flex my-2" [ngClass]="{ 'hover:cursor-pointer': rowsClickable() }">
                  <span class="inline-block w-1/3 pr-4 overflow-x-hidden font-bold text-left">
                    {{ column.title }}
                  </span>
                  <span class="inline-block w-2/3">
                    <ng-container
                      [ngTemplateOutlet]="cellValue"
                      [ngTemplateOutletContext]="{ item: item, column: column, index: index }"
                    />
                  </span>
                </div>
              }
            </td>
            @if (hasSelectAction()) {
              <td>
                <axpo-form-element
                  [formType]="'checkbox'"
                  [formId]="item['id'].value ?? ''"
                  [value]="''"
                  [noPadding]="true"
                  (click)="
                    actionClick.emit({ action: 'select', row: item, rowIndex: index });
                    $event.stopPropagation()
                  "
                ></axpo-form-element>
              </td>
            }
          } @else {
            @if (hasSelectAction()) {
              <td>
                <div class="flex items-center gap-2">
                  <axpo-form-element
                    [formType]="'checkbox'"
                    [formId]="item['id'].value ?? ''"
                    [value]="''"
                    [noPadding]="true"
                    (click)="
                      actionClick.emit({ action: 'select', row: item, rowIndex: index });
                      $event.stopPropagation()
                    "
                  ></axpo-form-element>
                </div>
              </td>
            }
            @for (column of columns(); track column.field) {
              <td class="px-4 py-4" [ngClass]="{ 'hover:cursor-pointer': rowsClickable() }">
                <ng-container
                  [ngTemplateOutlet]="cellValue"
                  [ngTemplateOutletContext]="{ item: item, column: column, index: index }"
                />
              </td>
            }
          }
          @if (hasNonSelectAction()) {
            <td>
              <div class="flex items-center gap-2">
                @for (action of actions(); track action) {
                  @if (action !== 'select') {
                    <button
                      (click)="
                        actionClick.emit({ action: action, row: item, rowIndex: index });
                        $event.stopPropagation()
                      "
                      class="text-text-primary hover:text-text-link hover:cursor-pointer"
                    >
                      <ng-container
                        [ngTemplateOutlet]="actionsDisplay"
                        [ngTemplateOutletContext]="{ action: action }"
                      />
                    </button>
                  }
                }
              </div>
            </td>
          }
        </tr>
      } @empty {
        <tr>
          <td
            [attr.colspan]="columns().length + actions().length"
            class="py-4 text-center text-text-secondary"
          >
            {{ textIfEmpty() }}
          </td>
        </tr>
      }
    }
  </tbody>
</table>

<ng-template #cellValue let-item="item" let-column="column" let-index="index">
  @if (item[column.field]) {
    @if (column.formatter?.includes('internal_link')) {
      <a [routerLink]="item[column.field].link" class="hover:text-text-link">
        <ng-container
          [ngTemplateOutlet]="displayText"
          [ngTemplateOutletContext]="{ item: item, column: column, index: index }"
        />
      </a>
    } @else if (column.formatter?.includes('external_link')) {
      <a [href]="item[column.field].link" class="hover:text-text-link" target="_blank">
        <ng-container
          [ngTemplateOutlet]="displayText"
          [ngTemplateOutletContext]="{ item: item, column: column, index: index }"
        />
      </a>
    } @else if (column.formatter?.includes('chart')) {
      <div>
        <ng-container
          [ngTemplateOutlet]="percentageBar"
          [ngTemplateOutletContext]="{
            percentage: calculateChartWidth(item),
            text: item.value.value,
            color: item.color?.chartColor
              ? getChartColor(item.color.chartColor)
              : getChartColor('default'),
          }"
        />
      </div>
    } @else {
      <ng-container
        [ngTemplateOutlet]="displayText"
        [ngTemplateOutletContext]="{ item: item, column: column, index: index }"
      />
    }
  }
</ng-template>

<ng-template #displayText let-item="item" let-column="column" let-index="index">
  @if (item[column.field].bold_value) {
    <span class="font-bold">
      {{ item[column.field].bold_value }}
    </span>
    <br />
  }
  @if (column.formatter?.includes('date')) {
    @if (item[column.field].value.split('/')[1]) {
      {{ item[column.field].value.split('/')[0].trim() | date: 'dd.MM.yyyy' }} -
      {{ item[column.field].value.split('/')[1].trim() | date: 'dd.MM.yyyy' }}
    } @else {
      {{ item[column.field].value | date: 'dd.MM.yyyy' }}
    }
  } @else if (column.formatter?.includes('dateTime')) {
    @if (item[column.field].value.split('/')[1]) {
      {{ item[column.field].value.split('/')[0].trim() | date: dateTimeFormat() }}
      <br />
      {{ item[column.field].value.split('/')[1].trim() | date: dateTimeFormat() }}
    } @else {
      {{ item[column.field].value | date: dateTimeFormat() }}
    }
  } @else if (column.formatter?.includes('image')) {
    @if (item[column.field].imageValue) {
      <img
        src="{{ item[column.field].imageValue }}"
        alt="Image"
        class="object-scale-down w-20 h-20"
        handleMissingImage
      />
    } @else {
      {{ item[column.field].value }}
    }
  } @else if (column.formatter?.includes('tag')) {
    <axpo-tag [variant]="'fullWidth'" [type]="item[column.field].value">
      {{ item[column.field].tagTranslation || item[column.field].value }}
    </axpo-tag>
  } @else if (column.formatter?.includes('roleTag') && item[column.field].valueArray) {
    <div class="flex flex-wrap gap-2">
      @for (role of item[column.field].valueArray; track role) {
        <axpo-tag [roles]="role" [variant]="'role'">
          {{ role | formatText }}
        </axpo-tag>
      }
    </div>
  } @else if (column.formatter?.includes('actions')) {
    <div class="flex items-center gap-2">
      @for (action of item[column.field].valueArray; track action) {
        <button
          (click)="
            actionClick.emit({ action: action, row: item, rowIndex: index });
            $event.stopPropagation()
          "
          class="text-text-primary hover:text-text-link hover:cursor-pointer"
        >
          <ng-container
            [ngTemplateOutlet]="actionsDisplay"
            [ngTemplateOutletContext]="{ action: action }"
          />
        </button>
      }
    </div>
  } @else {
    {{ item[column.field].value }}
  }
</ng-template>

<ng-template #actionsDisplay let-action="action">
  @switch (action) {
    @case ('edit') {
      <div class="w-6 h-6"><div [innerHTML]="getSvg()['edit']"></div></div>
    }
    @case ('delete') {
      <div class="w-6 h-6"><div [innerHTML]="getSvg()['delete']"></div></div>
    }
    @case ('view') {
      <div class="w-6 h-6"><div [innerHTML]="getSvg()['view']"></div></div>
    }
    @case ('pdf') {
      <div class="w-6 h-6"><div [innerHTML]="getSvg()['file-pdf']"></div></div>
    }
    @case ('download') {
      <div class="w-6 h-6"><div [innerHTML]="getSvg()['download']"></div></div>
    }
  }
</ng-template>

<ng-template #percentageBar let-percentage="percentage" let-color="color" let-text="text">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="20"
    viewBox="0 0 100 10"
    preserveAspectRatio="none"
  >
    <rect x="0" y="0" [attr.width]="percentage" height="10" [attr.fill]="color"></rect>

    <rect
      [attr.x]="percentage"
      y="0"
      [attr.width]="100 - percentage"
      height="10"
      fill="white"
    ></rect>
  </svg>
</ng-template>
