import { WritableSignal, signal } from "@angular/core";

export type ITileMode = 'create' | 'edit' | 'view' | 'locked';
export interface ISignalTilePair {
  visible: WritableSignal<boolean>;
  mode: WritableSignal<ITileMode>;
  action: WritableSignal<string | undefined>;
  disabled: WritableSignal<boolean>;
}
export type ITileSignalPair = Record<string, ISignalTilePair>;

export class TileStates {
  private tilesSignals = signal<ITileSignalPair>({});

  constructor(tiles?: string[]) {
    if (tiles) {
      for (const tile of tiles) {
        this.createTileSignals(tile, true, 'view');
      }
      
    }
  }

  createTileSignals(tileTitle: string, isVisible: boolean, mode: ITileMode) {
    this.tilesSignals.update(tileSignals => {
      const signals = { ...tileSignals };
      signals[tileTitle] = {
        visible: signal<boolean>(isVisible),
        mode: signal<ITileMode>(mode),
        action: signal<string | undefined>(undefined),
        disabled: signal<boolean>(false),
      };

      return signals;
    });
  }

  get all() {
    return this.tilesSignals()
  }
}