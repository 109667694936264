<div class="relative w-[38px] h-[38px] flex items-center justify-center">
  @if (timer()) {
    <button
      (click)="stopTimer()"
      (mouseenter)="toggleRunningTimer(true)"
      (mouseout)="toggleRunningTimer(false)"
      (blur)="toggleRunningTimer(false)"
      class="absolute inset-0 flex items-center justify-center text-primary z-10"
    >
      @if (runningTimerHover()) {
        <div class="w-6 h-6" [innerHTML]="getSvg()['pause']"></div>
      } @else {
        <div class="font-bold">
          {{ timeRemainingUntilRefresh() }}
        </div>
      }
    </button>
    <svg
      width="38"
      height="38"
      xmlns="http://www.w3.org/2000/svg"
      class="absolute inset-0 transform -rotate-90"
    >
      <circle
        id="circle"
        r="17.5"
        cy="19"
        cx="19"
        stroke-width="2"
        stroke="#cd2626"
        fill="none"
        style="stroke-dasharray: 110; transition: all 1s linear"
        [style.strokeDashoffset]="strokeDashoffset()"
      />
    </svg>
  }
  @if (!timer()) {
    <button
      (click)="startTimer()"
      class="absolute inset-0 flex items-center justify-center text-primary"
      (mouseenter)="setPlay()"
      (mouseout)="unsetPlay()"
      (blur)="unsetPlay()"
    >
      <div
        [ngClass]="{
          'w-6 h-6': startTimerClass() === 'timer',
          'w-9 h-9': startTimerClass() === 'play',
        }"
        [innerHTML]="getSvg()[startTimerClass()]"
      ></div>
    </button>
  }
</div>
