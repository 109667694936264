export type stateName =
  | 'Alle'
  | 'Pendent'
  | 'Eskaliert'
  | 'Rückmeldung Störungsbehebung'
  | 'Abgeschlossen';
export enum IncidentStatusTranslations {
  'Alle' = 'all',
  'Pendent' = 'pending',
  'Eskaliert' = 'escalated',
  'Rückmeldung Störungsbehebung' = 'incidentResolutionResponse',
  'Abgeschlossen' = 'completed'
};

export type impactName = 'Bleibend' | 'Nicht bleibend';
export enum IncidentImpactTranslations {
  'Bleibend' = 'permanent',
  'Nicht bleibend' = 'notPermanent'
};

export type tileName = 'Details' | 'Standort' | 'Alarm' | 'Kontakte' | 'Störungsbehebung' | 'Kommentare' | 'Anhänge';
export enum IncidentTilesTranslations {
  'Details' = 'details',
  'Standort' = 'location',
  'Alarm' = 'alarm',
  'Kontakte' = 'contacts',
  'Störungsbehebung' = 'incidentResolutionResponse',
  'Kommentare' = 'comments',
  'Anhänge' = 'attachments'
};
