<div class="relative inline-block text-left" (clickOutside)="isMenuOpen.set(false)">
  <div>
    <button
      type="button"
      [class]="computedClass()"
      (click)="isMenuOpen.set(!isMenuOpen()); $event.stopPropagation()"
      [disabled]="disabled()"
      tabindex="0"
    >
      @if (menuText(); as menuText) {
        {{ menuText }}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          stroke="currentColor"
          [ngClass]="{ 'rotate-180': isMenuOpen() }"
        >
          <path
            d="M11.1358 3.44783C11.1358 3.44783 6.23656 8.58726 6.02654 8.58726C5.81653 8.58726 0.864246 3.41284 0.864246 3.41284"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
      } @else {
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path
            d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3"
          />
        </svg>
      }
    </button>
  </div>

  @if (isMenuOpen()) {
    <div
      @transformOpacityScale
      class="absolute left-0 z-10 mt-2 overflow-y-auto bg-white rounded-md shadow-lg min-w-32 ring-1 ring-black ring-opacity-5 focus:outline-none max-h-80"
      [ngClass]="{ hideSrollbar: !showScrollbar() }"
      role="menu"
      tabindex="-1"
      dropdownPosition
    >
      <div class="py-1">
        @for (item of items(); track item.value) {
          <button
            class="block w-full px-4 py-2 text-sm text-left whitespace-nowrap hover:cursor-pointer hover:bg-background-1"
            [class]="item.value === selectedItemValue() ? 'bg-background-2 font-bold' : ''"
            role="menuitem"
            (click)="selectValue(item.value); $event.stopPropagation()"
          >
            {{ item.label }}
          </button>
        }
      </div>
    </div>
  }
</div>
