import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

import { AxpoButtonComponent } from '../../core/axpo-button/axpo-button.component';
import { AxpoSpinnerComponent } from '../../core/axpo-spinner/axpo-spinner.component';
import { AxpoStepperComponent } from '../../core/axpo-stepper/axpo-stepper.component';
import { AxpoTypographyComponent } from '../../core/axpo-typography/axpo-typography.component';
import { FileDropComponent } from '../../shared/controls/attachments/filedrop/filedrop.component';
import { ContactImportService } from '../../shared/services/contact-import.service';
import { LanguageService } from '../../shared/services/lang.service';
import { TenantService } from '../../shared/services/tenant.service';

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [
    TranslocoPipe,
    AxpoTypographyComponent,
    AxpoButtonComponent,
    FileDropComponent,
    AxpoStepperComponent,
    AxpoSpinnerComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './import-contact.component.html',
})
export class ImportContactComponent {
  translocoService = inject(TranslocoService);
  tenantService = inject(TenantService);
  importContactService = inject(ContactImportService);
  languageService = inject(LanguageService);

  activeStep = signal<number>(0);
  file = signal<File | undefined>(undefined);

  steps = signal<string[]>([]);
  showWarnings = signal<boolean>(false);
  showErrors = signal<boolean>(false);

  translatedWarnings = signal<string[]>([]);
  translatedErrors = signal<string[]>([]);

  maxUploadBytes: number = 1024 * 1024 * 1500;

  _translateMessages = effect(() => {
    const validationWarnings = this.importContactService.validationDetails()?.validationWarnings;
    const validationErrors = this.importContactService.validationDetails()?.validationErrors;
    untracked(() => {
      this.translatedWarnings.set(
        validationWarnings?.map(message => {
          return this.importContactService.TransformValidationMessagesToGenreicStrings(message);
        }) ?? [],
      );

      this.translatedErrors.set(
        validationErrors?.map(message => {
          return this.importContactService.TransformValidationMessagesToGenreicStrings(message);
        }) ?? [],
      );
    });
  });

  _lang = effect(() => {
    this.languageService.getLangSignal()();

    untracked(() => {
      const translatedSteps = [
        this.translocoService.translate('import-contact.step1'),
        this.translocoService.translate('import-contact.step2'),
      ];

      this.steps.set(translatedSteps);
    });
  });
  isFormValid = computed(() => {
    if (this.file()) {
      return true;
    }
    return false;
  });

  amountOfWarnings = computed(() => {
    return this.importContactService.validationDetails()?.validationWarnings?.length || 0;
  });

  amountOfErrors = computed(() => {
    return this.importContactService.validationDetails()?.validationErrors?.length || 0;
  });

  validationWarnings = computed(() => {
    return this.translatedWarnings();
  });

  validationErrors = computed(() => {
    return this.translatedErrors();
  });

  onBackClicked(): void {
    this.activeStep.update(currentStep => {
      currentStep--;
      return currentStep;
    });
  }

  onSubmitClicked(): void {
    if (this.activeStep() === 0 && this.file()) {
      this.importContactService.upload(this.file() as any, false);
    }

    if (this.activeStep() === 1) {
      if (this.file()) {
        this.importContactService.upload(this.file() as any, true);
      }
    }

    this.activeStep.update(currentStep => {
      currentStep++;
      return currentStep;
    });
  }

  remove(): void {
    this.file.set(undefined);
  }

  filesUploaded(files: any): void {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      this.file.set(file);
      i++;
    }
  }
}
