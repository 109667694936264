import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  WritableSignal,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

import { AxpoFooterComponent, FooterLinksProps } from './core/axpo-footer/axpo-footer.component';
import { AxpoHeaderComponent } from './core/axpo-header/axpo-header.component';
import { ICustomAction } from './core/axpo-profile-dropdown/axpo-profile-dropdown.component';
import { AuthnService } from './shared/services/authn.service';
import { BreadcrumbService } from './shared/services/breadcrumb.service';
import { version } from '../../public/version';
import { AxpoDialogComponent } from './core/axpo-dialog/axpo-dialog.component';
import { CheckForUpdateService } from './shared/services/check-for-update.service';
import { CircleService } from './shared/services/circle.service';
import { OfflineService } from './shared/services/offline.service';
import { TenantService } from './shared/services/tenant.service';
import { isSuperAdmin } from './shared/utils/authValidations';

export interface ILanguage {
  label: string;
  value: string;
}

@Component({
  selector: 'app-root',
  imports: [
    RouterOutlet,
    AxpoHeaderComponent,
    AxpoFooterComponent,
    TranslocoPipe,
    AxpoDialogComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit {
  private translocoService = inject(TranslocoService);
  private breadcrumbService = inject(BreadcrumbService);
  private tenantService = inject(TenantService);
  private circleService = inject(CircleService);
  public swService = inject(CheckForUpdateService);
  public offlineService = inject(OfflineService);
  public readonly authnService = inject(AuthnService);

  activeLanguage = signal<string | undefined>(undefined);
  footerLinks = signal<FooterLinksProps[]>([]);
  showLogout = signal<boolean>(false);
  customActions = signal<ICustomAction[]>([]);
  switchboardLinks = signal< ICustomAction[]>([]);
  isSwitchboardMaster = signal<boolean>(false);

  version = version;
  availableLanguages: ILanguage[] = [
    { label: 'English', value: 'en' },
    { label: 'Deutsch', value: 'de' },
  ];

  _activeLangueEffect = effect(() => {
    const activeLanguage = this.activeLanguage();
    untracked(() => {
      if (activeLanguage) {
        this.translocoService.setActiveLang(activeLanguage);
        localStorage.setItem('language', activeLanguage);
        this.getFooterLinks();
        this.getBreadcrumbs();
      }
    });
  });

  _tenantTriggerEffect = effect(() => {
    const user = this.authnService.user();
    untracked(() => {
      if (user) {
        this.circleService.getUserInfo();
        this.tenantService.getConfig();
        this.showLogout.set(true);
      }
    });
  });

  _userEffect = effect(() => {
    const user = this.authnService.user();
    const _ = this.activeLanguage();
    const switchboardLinks = this.switchboardLinks();
    let customActions: ICustomAction[] = [];
    untracked(() => {
      if (user) {
        if (isSuperAdmin(this.authnService.user()?.roles)) {
          customActions = [
            {
              label: this.translocoService.translate('core.tenantAdministration'),
              icon: 'company',
              link: '/tenant-administration',
            },
            {
              label: this.translocoService.translate('core.importLocation'),
              icon: 'transfer',
              link: '/import-locations',
            },
            {
              label: this.translocoService.translate('core.importContact'),
              icon: 'upload',
              link: '/import-contacts',
            },
            {
              label: this.translocoService.translate('core.deleteContacts'),
              icon: 'delete-hard',
              link: '/delete-contacts',
            },
            {
              label: this.translocoService.translate('core.statistics'),
              icon: 'chart-add',
              link: '/statistic/chart',
            }
          ];
        } else {
          customActions = [
            {
              label: this.translocoService.translate('core.statistics'),
              icon: 'chart-add',
              link: '/statistic/chart',
            }
          ];
        }

        if (switchboardLinks.length > 0) {
          let sbLinks: ICustomAction[];
          if (this.isSwitchboardMaster()) {
            sbLinks = [{
              label: this.translocoService.translate('switchboard.pikettOverview'),
              icon: 'phone',
              link: '/circles',
              },
              ...switchboardLinks
            ];
          } else {
            sbLinks = switchboardLinks;
          }

          customActions = customActions.concat(sbLinks);
        };

        this.customActions.set(customActions);
      }
    });
  });

  _triggerConfigLoad = effect(() => {
    const tenantId = this.tenantService.tenantId();
    if (tenantId) {
      this.tenantService.getSettings(tenantId);
    }
  });

  _setCustomColor = effect(() => {
    const tenantConfig = this.tenantService.tenantSettings();
    if (tenantConfig) {
      if (tenantConfig.primaryColor) {
        document.documentElement.style.setProperty('--color-primary', tenantConfig.primaryColor);
      }

      if (tenantConfig.headerBackgroundColor) {
        document.documentElement.style.setProperty(
          '--color-background-header',
          tenantConfig.headerBackgroundColor,
        );
      }

      if (tenantConfig.footerBackgroundColor) {
        document.documentElement.style.setProperty(
          '--color-background-footer',
          tenantConfig.footerBackgroundColor,
        );
      }
    }
  });

  _loadAddons = effect(() => {
    const userInfo = this.circleService.userInfo();
    if (userInfo) {
      if (userInfo.sb_roles.indexOf('master_admin') > -1) {
        this.isSwitchboardMaster.set(true);
      }
      this.switchboardLinks.set(userInfo.circles.map((userInfo: any) => {
        return {
          label: 'Pikett ' + userInfo.name as string,
          link: '/circle/' + userInfo.id,
          icon: 'notification'
        }
      }));
    }
  });

  ngOnInit() {
    this.loadLanguage();
    this.authnService.checkAuth();
    this.offlineService.checkOffline();
  }

  loadLanguage = () => {
    // clean language value for compatiblity reason. previous app stored it with double quotes
    const cachedLanguage = localStorage.getItem('language')?.replaceAll('"', '');
    if (cachedLanguage) {
      this.activeLanguage.set(cachedLanguage);
    } else {
      const defaultLanguage = this.translocoService.getActiveLang();
      this.activeLanguage.set(defaultLanguage);
    }
  };

  getFooterLinks = () => {
    const links: FooterLinksProps[] = [
      {
        text: this.translocoService.translate('core.imprint'),
        link: 'https://www.axpo.com/group/' + this.activeLanguage() + '/impressum.html',
        target: 'external_link',
      },
      {
        text: this.translocoService.translate('core.legalInformation'),
        link: 'https://www.axpo.com/group/' + this.activeLanguage() + '/legal-information.html',
        target: 'external_link',
      },
      {
        text: this.translocoService.translate('core.privacyPolicy'),
        link: 'https://www.axpo.com/group/' + this.activeLanguage() + '/privacy-policy.html',
        target: 'external_link',
      },
    ];
    this.footerLinks.set(links);
  };

  getBreadcrumbs = () => {
    const translatedBreadcrumb = {
      Home: this.translocoService.translate('breadcrumbs.home'),
      Unauthorized: this.translocoService.translate('breadcrumbs.unauthorized'),
      Login: this.translocoService.translate('breadcrumbs.login'),
    };
    this.breadcrumbService.replaceBreadcrumbs(translatedBreadcrumb);
  };

  logout = () => {
    this.authnService.logout();
  };

  isOffline = (): WritableSignal<boolean> => {
    return this.offlineService.offline;
  };

  reloadApp(action: string) {
    if (action === 'confirm') {
      document.location.reload();
    } else {
      this.swService.showNewSwVersionPrompt.set(false);
    }
  }
}
