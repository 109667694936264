@if (displayDialog()) {
  <div
    @background
    class="relative z-10"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity"></div>

    <div class="fixed inset-0 z-10 sm:w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
        <div
          @foreground
          class="relative transform overflow-hidden flex flex-col rounded-lg bg-white shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg p-4"
          [ngClass]="{ 'items-center': textAlign() === 'center' }"
        >
          <div class="flex justify-end w-full">
            <button (click)="takeAction('cancel')">
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="stroke-dark-gray"
              >
                <g id="x">
                  <path
                    d="M0.979974 16.9958L15.0294 3.0354"
                    stroke-width="1.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.0294 16.9958L0.97998 3.0354"
                    stroke-width="1.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </svg>
            </button>
          </div>

          @if (icon() === 'warning') {
            <svg
              width="46"
              height="46"
              viewBox="0 0 46 46"
              xmlns="http://www.w3.org/2000/svg"
              class="stroke-danger mb-4"
            >
              <g id="shape">
                <path
                  d="M44.9308 21.1717C44.4592 15.685 42.4154 11.2955 38.7996 7.84671C38.6424 7.68994 38.1708 7.53318 38.0136 7.84671C37.8563 8.00347 37.6991 8.47377 38.0136 8.63053C41.4722 11.9226 43.3587 15.9985 43.8303 21.3285C44.6164 32.7723 36.2843 42.6485 24.6507 43.7459C13.1744 44.6865 3.11292 36.2212 2.16966 24.7773C1.2264 13.3335 9.55854 3.45729 21.1921 2.35994C25.9084 1.88964 29.9958 2.83023 33.7689 5.33847C34.0833 5.49523 34.3977 5.49523 34.5549 5.1817C34.7122 4.86817 34.5549 4.55464 34.3977 4.39788C30.4675 1.73288 26.0656 0.635522 21.1921 1.10582C8.92969 2.35994 0.125932 12.7064 1.06919 24.9341C2.01245 36.3779 11.6023 45 23.0786 45H24.8079C37.0703 43.7459 45.8741 33.3994 44.9308 21.1717Z"
                />
                <path
                  d="M22.1067 30.9639C21.7182 31.3523 21.5 31.8792 21.5 32.4286C21.5 32.9779 21.7182 33.5048 22.1067 33.8933C22.4952 34.2818 23.0221 34.5 23.5714 34.5C24.1208 34.5 24.6477 34.2818 25.0362 33.8933C25.4246 33.5048 25.6429 32.9779 25.6429 32.4286C25.6429 31.8792 25.4246 31.3523 25.0362 30.9639C24.6477 30.5754 24.1208 30.3571 23.5714 30.3571C23.0221 30.3571 22.4952 30.5754 22.1067 30.9639ZM22.3571 11.5V25.9286H24.7857V11.5H22.3571Z"
                />
              </g>
            </svg>
          }
          @if (title()) {
            <axpo-typography variant="title-m" weight="bold">
              {{ title() }}
            </axpo-typography>
          }
          <div class="mt-4 mb-8 w-full" [ngClass]="{ 'text-center': textAlign() === 'center' }">
            <ng-content></ng-content>
          </div>
          <div [ngClass]="{ 'flex justify-end': textAlign() === 'left' }">
            @if (cancelText()) {
              <axpo-button (click)="takeAction('cancel')" [type]="'ghost'" [style]="'secondary'">
                {{ cancelText() }}
              </axpo-button>
            }
            @if (actionTaken() === 'confirm' && processBeforeClose() === true) {
              <axpo-button [disabled]="true">
                <axpo-spinner size="xs">{{ 'core.loading' | transloco }}</axpo-spinner>
              </axpo-button>
            }
            @if (actionTaken() === undefined && !saveDisabled()) {
              <axpo-button (click)="takeAction('confirm')">
                {{ confirmText() }}
              </axpo-button>
            }
            @if (saveDisabled()) {
              <axpo-button [disabled]="true">
                {{ confirmText() }}
              </axpo-button>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
}
