<ng-template #content>
  @switch (variant()) {
    @case ('H1') {
      <h1 [class]="computedClass()">
        <ng-container [ngTemplateOutlet]="ngContent"></ng-container>
      </h1>
    }
    @case ('H2') {
      <h2 [class]="computedClass()">
        <ng-container [ngTemplateOutlet]="ngContent"></ng-container>
      </h2>
    }
    @case ('H3') {
      <h3 [class]="computedClass()">
        <ng-container [ngTemplateOutlet]="ngContent"></ng-container>
      </h3>
    }
    @case ('H4') {
      <h4 [class]="computedClass()">
        <ng-container [ngTemplateOutlet]="ngContent"></ng-container>
      </h4>
    }
    @case ('title-xl') {
      <h3 [class]="computedClass()">
        <ng-container [ngTemplateOutlet]="ngContent"></ng-container>
      </h3>
    }
    @case ('title-l') {
      <h4 [class]="computedClass()">
        <ng-container [ngTemplateOutlet]="ngContent"></ng-container>
      </h4>
    }
    @case ('title-m') {
      <h5 [class]="computedClass()">
        <ng-container [ngTemplateOutlet]="ngContent"></ng-container>
      </h5>
    }
    @case ('title-s') {
      <h6 [class]="computedClass()">
        <ng-container [ngTemplateOutlet]="ngContent"></ng-container>
      </h6>
    }
    @case ('body-s') {
      <p [class]="computedClass()">
        <ng-container [ngTemplateOutlet]="ngContent"></ng-container>
      </p>
    }
    @case ('body-m') {
      <p [class]="computedClass()">
        <ng-container [ngTemplateOutlet]="ngContent"></ng-container>
      </p>
    }
    @case ('body-l') {
      <p [class]="computedClass()">
        <ng-container [ngTemplateOutlet]="ngContent"></ng-container>
      </p>
    }
    @case ('body-xl') {
      <p [class]="computedClass()">
        <ng-container [ngTemplateOutlet]="ngContent"></ng-container>
      </p>
    }
    @default {
      <span [class]="computedClass()">
        <ng-container [ngTemplateOutlet]="ngContent"></ng-container>
      </span>
    }
  }
</ng-template>

<ng-template #ngContent>
  <ng-content></ng-content>
</ng-template>

<ng-container [ngTemplateOutlet]="content"></ng-container>
