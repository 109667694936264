import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  WritableSignal,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { TranslocoService } from '@jsverse/transloco';

import { AxpoButtonComponent } from '../../core/axpo-button/axpo-button.component';
import { AxpoFormElementComponent } from '../../core/axpo-form-element/axpo-form-element.component';
import { AxpoSearchComponent } from '../../core/axpo-search/axpo-search.component';
import {
  AxpoSelectableButtonsComponent,
  IButton,
} from '../../core/axpo-selectable-buttons/axpo-selectable-buttons.component';
import { AxpoSpinnerComponent } from '../../core/axpo-spinner/axpo-spinner.component';
import { ISort, ITableColumn, ITableRow } from '../../core/axpo-table/axpo-table.component';
import { AxpoTableComponent } from '../../core/axpo-table/axpo-table.component';
import { AxpoTypographyComponent } from '../../core/axpo-typography/axpo-typography.component';
import { AxpoBreakpoints, axpoBreakpoints } from '../../core/services/axpo-resize.service';
import { AxpoResizeService } from '../../core/services/axpo-resize.service';
import { TimerComponent } from '../../shared/controls/timer/timer.component';
import { IKeyValueModel } from '../../shared/models/api_models';
import { IncidentService } from '../../shared/services/incident.service';
import { LanguageService } from '../../shared/services/lang.service';
import { addTimeToDate, dateLastWeek, dateToDay, getTimeDifferenceInMinutes } from '../../shared/utils/date';
import { downloadAsCSV } from '../../shared/utils/fileDownload';
import { getFilterStateButtons } from '../../shared/utils/incident';
import {
  sortTableFieldByDate,
  sortTableFieldByNumeric,
  sortTableFieldByTextual,
} from '../../shared/utils/sorter';

@Component({
  standalone: true,
  selector: 'app-admin',
  imports: [
    TranslocoPipe,
    AxpoTypographyComponent,
    AxpoFormElementComponent,
    AxpoSelectableButtonsComponent,
    AxpoButtonComponent,
    AxpoSpinnerComponent,
    AxpoTableComponent,
    AxpoSearchComponent,
    TimerComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './statistics.component.html',
})
export class StatisticsComponent implements OnInit {
  incidentService = inject(IncidentService);
  translocoService = inject(TranslocoService);
  langService = inject(LanguageService);
  resizeService = inject(AxpoResizeService);
  activatedRoute = inject(ActivatedRoute);

  filterFromDate = signal<string>(dateLastWeek());
  filterToDate = signal<string>(dateToDay());
  filterSearchText = signal<string | undefined>(undefined);
  filterStates = signal([]);
  stateButtons = signal<IButton[]>([]);
  solutionTimeMin = signal<number>(0);
  solutionTimeHour = signal<string>('0');
  solutionTimeDay = signal<string>('0');
  incidentSourceColumns = signal<ITableColumn[]>([]);
  incidentSourceData = signal<ITableRow[]>([]);
  incidentLocationColumns = signal<ITableColumn[]>([]);
  incidentLocationData = signal<ITableRow[]>([]);
  incidentDamageLineColumns = signal<ITableColumn[]>([]);
  incidentDamageLineData = signal<ITableRow[]>([]);
  incidentDamagePlantColumns = signal<ITableColumn[]>([]);
  incidentDamagePlantData = signal<ITableRow[]>([]);
  incidentEffectColumns = signal<ITableColumn[]>([]);
  incidentEffectData = signal<ITableRow[]>([]);
  chartMaxSource = signal<number | undefined>(undefined);
  chartMaxLocation = signal<number | undefined>(undefined);
  chartMaxDamageLine = signal<number | undefined>(undefined);
  chartMaxDamagePlant = signal<number | undefined>(undefined);
  chartMaxImpacts = signal<number | undefined>(undefined);
  allStatisticsBtn = signal<'primary' | 'secondary'>('primary');
  stationStatisticsBtn = signal<'primary' | 'secondary'>('secondary');
  incidentReviewData = signal<ITableRow[]>([]);
  incidentReviewColumns = signal<ITableColumn[]>([]);
  breakpoint: AxpoBreakpoints = axpoBreakpoints.LG;

  ngOnInit(): void {
    const activeTab = this.activatedRoute.snapshot.paramMap.get('tab');
    if (activeTab === 'table') {
      this.allStatisticsBtn.set('secondary');
    }
    this.searchFilter();
  }

  _toggleView = effect(() => {
    const allStatsBtn = this.allStatisticsBtn();
    let urlState = 'chart';
    untracked(() => {
      if (allStatsBtn === 'primary') {
        this.stationStatisticsBtn.set('secondary');
      } else {
        this.stationStatisticsBtn.set('primary');
        urlState = 'table';
        this.filterStates.set([]);
      }
      window.history.replaceState({}, '', `statistic/${urlState}`);
      this.searchFilter();
    });
  });

  _lang = effect(() => {
    const _activeLanguage = this.langService.getLangSignal()();
    const isDesktop = this.resizeService.width() >= this.breakpoint;
    untracked(() => {
      this.stateButtons.update((stateButtons: IButton[]) => {
        return getFilterStateButtons(this.translocoService, stateButtons, isDesktop);
      });
    });
  });

  _statistics = effect(() => {
    const _activeLanguage = this.langService.getLangSignal()();
    const stats = this.incidentService.incidentStatistics();

    if (stats) {
      untracked(() => {
        const hours = (stats.averageResolvingMinutes > 0)? Math.floor(stats.averageResolvingMinutes / 60): 0;
        const minutes = (stats.averageResolvingMinutes > 0)?stats.averageResolvingMinutes % 60: 0;
        const days = Math.floor(hours / 24);
        const dayHours = hours % 24;

        const minuteI18n = minutes === 1 ? 'core.minute' : 'core.minutes';
        const hourI18n = hours === 1 ? 'core.hour' : 'core.hours';
        const dayHourI18n = dayHours === 1 ? 'core.hour' : 'core.hours';
        const dayI18n = days === 1 ? 'core.day' : 'core.days';

        this.solutionTimeMin.set(minutes);
        this.solutionTimeHour.set(
          hours +
            ' ' +
            this.translocoService.translate(hourI18n) +
            ' ' +
            minutes +
            ' ' +
            this.translocoService.translate(minuteI18n),
        );
        this.solutionTimeDay.set(
          days +
            ' ' +
            this.translocoService.translate(dayI18n) +
            ' ' +
            dayHours +
            ' ' +
            this.translocoService.translate(dayHourI18n) +
            ' ' +
            minutes +
            ' ' +
            this.translocoService.translate(minuteI18n),
        );

        this.incidentSourceColumns.set(
          this.getTableHeader('incidentDetail.incidentResolution.incidentSource'),
        );
        this.incidentSourceData.set(
          this.transformKeyValueToTableRow(stats.faultCauses, 'causeEffect'),
        );
        this.chartMaxSource.set(this.getChartMax(stats.faultCauses));

        this.incidentLocationColumns.set(
          this.getTableHeader('incidentDetail.incidentResolution.incidentLocation'),
        );
        this.incidentLocationData.set(
          this.transformKeyValueToTableRow(stats.faultLocations, 'causeLocation'),
        );
        this.chartMaxLocation.set(this.getChartMax(stats.faultLocations));

        this.incidentDamageLineColumns.set(
          this.getTableHeader('incidentDetail.incidentResolution.damageLine'),
        );
        this.incidentDamageLineData.set(
          this.transformKeyValueToTableRow(stats.faultLineDamages, 'damageLine'),
        );
        this.chartMaxDamageLine.set(this.getChartMax(stats.faultLineDamages));

        this.incidentDamagePlantColumns.set(
          this.getTableHeader('incidentDetail.incidentResolution.damagePlant'),
        );
        this.incidentDamagePlantData.set(
          this.transformKeyValueToTableRow(stats.faultPlantDamages, 'damagePlant'),
        );
        this.chartMaxDamagePlant.set(this.getChartMax(stats.faultPlantDamages));

        this.incidentEffectColumns.set(this.getTableHeader('incidentDetail.impact'));
        this.incidentEffectData.set(
          this.transformKeyValueToTableRow(stats.faultEffects, 'effects'),
        );
        this.chartMaxImpacts.set(this.getChartMax(stats.faultEffects));
      });
    }
  });

  _review = effect(() => {
    const _activeLanguage = this.langService.getLangSignal()();
    const review = this.incidentService.incidentReview();

    if (review) {
      untracked(() => {
        this.incidentReviewColumns.set([
          {
            title: this.translocoService.translate('home.table.creationDate'),
            field: 'creation',
            sortable: true,
            formatter: ['dateTime'],
          },
          {
            title: this.translocoService.translate('home.table.title'),
            field: 'name',
            sortable: true,
          },
          {
            title: this.translocoService.translate('home.table.location'),
            field: 'location',
            sortable: false,
          },
          {
            title: this.translocoService.translate(
              'incidentDetail.incidentResolution.incidentSource',
            ),
            field: 'faultCauseName',
            sortable: true,
          },
          {
            title: this.translocoService.translate('core.deltaTime'),
            field: 'timeStamp',
            sortable: true,
          },
        ]);

        this.incidentReviewData.set(
          review.map(i => {
            return {
              id: { value: i.accessCode?.toString() ?? '' },
              name: { value: i.incidentTitle?.toString() ?? '' },
              creation: { value: i.incidentFaultTimeStamp?.toString() ?? '' },
              location: { value: i.locations?.map(l => ' ' + l.name) },
              faultCauseName: {
                value: i.faultCauseName?.toString()
                  ? this.translocoService.translate(
                      'options.causeEffect.' + i.faultCauseName?.toString(),
                    )
                  : '',
              },
              timeStamp: {
                value:
                  i.incidentFaultTimeStamp && i.incidentFixedTimeStamp
                    ? getTimeDifferenceInMinutes(
                        i.incidentFaultTimeStamp as string,
                        i.incidentFixedTimeStamp as string,
                      ).toString() +
                      ' ' +
                      this.translocoService.translate('core.minutes')
                    : '0 ' + this.translocoService.translate('core.minutes'),
              },
            } as ITableRow;
          }),
        );
      });
    }
  });

  _csvExport = effect(() => {
    const csvData = this.incidentService.reviewData();
    if (csvData) {
      untracked(() => {
        this.incidentService.reviewData.set(undefined);
        downloadAsCSV(csvData, 'statistics_insights.csv');
      });
    }
  });

  searchFilter = () => {
    const filter = {
      ...this.incidentService.statisticsFilter(),
      incidentFaultFrom: addTimeToDate(this.filterFromDate(), '00:00') + 'Z',
      incidentFaultTo: addTimeToDate(this.filterToDate(), '23:59') + 'Z',
      searchText: this.filterSearchText(),
    };

    if (this.filterStates().length > 0 && this.allStatisticsBtn() === 'primary') {
      filter.incidentStateIds = this.filterStates();
    }
    this.incidentService.filter.set(filter);
    if (this.allStatisticsBtn() === 'primary') {
      this.incidentService.getIncidentStatistics();
    } else {
      this.incidentService.getIncidentReview();
    }
  };

  stateFilter(value: any) {
    this.filterStates.set(value);
    this.searchFilter();
  }

  getTableHeader(title: string): ITableColumn[] {
    return [
      {
        title: this.translocoService.translate(title),
        field: 'key',
        sortable: true,
      },
      {
        title: this.translocoService.translate('core.amount'),
        field: 'value',
        sortable: true,
      },
      {
        title: '',
        field: 'value',
        sortable: false,
        formatter: ['chart'],
      },
    ];
  }

  transformKeyValueToTableRow(data: IKeyValueModel[], translationKey: string): ITableRow[] {
    const tableData: ITableRow[] = [];
    const i18nKey = `options.${translationKey}.`;
    for (const kv of data) {
      const key = kv.key !== undefined ? (kv.key as string) : 'core.notSpecified';
      const keyValue = this.translocoService.translate(
        kv.key !== undefined ? ((i18nKey + kv.key) as string) : key,
      );
      const row: ITableRow = {
        id: { value: key },
        key: {
          value: keyValue,
        },
        value: {
          value: kv.value as string,
        },
        color: { chartColor: 'red' },
      };

      tableData.push(row);
    }

    return tableData;
  }

  exportClicked() {
    this.incidentService.loadReviewFile();
  }

  getChartMax(statistics: IKeyValueModel[]): number {
    const max = statistics
      .map(item => ({
        ...item,
        value: parseInt(item.value || '0', 10),
      }))
      .reduce((max, item) => (item.value > max.value ? item : max), { key: '', value: -Infinity });

    if (max.value) {
      return max.value;
    }

    return -1;
  }

  onSort(sort: ISort, data: WritableSignal<any>) {
    const textual = ['name', 'faultCauseName', 'key'];
    const datual = ['creation'];
    const numeric = ['timeStamp', 'value'];

    if (textual.indexOf(sort.field) > -1) {
      data.set(sortTableFieldByTextual(data(), sort.field, sort.direction));
      return;
    }

    if (datual.indexOf(sort.field) > -1) {
      data.set(sortTableFieldByDate(data(), sort.field, sort.direction));
      return;
    }

    if (numeric.indexOf(sort.field) > -1) {
      data.set(sortTableFieldByNumeric(data(), sort.field, sort.direction));
    }
  }

  refresh() {
    this.searchFilter();
  }
}
