import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FeatureGuard implements CanActivate {
  private router = inject(Router);

  canActivate(next: ActivatedRouteSnapshot): boolean {
    if (environment.stage === 'PROD' && next.data['noProd']) {
      return false;
    }

    return true;
  }
}