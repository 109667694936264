import { AfterViewInit, Directive, ElementRef, Renderer2, inject } from '@angular/core';

@Directive({
  selector: '[dropdownPosition]',
  standalone: true,
})
export class DropdownPositionDirective implements AfterViewInit {
  el = inject(ElementRef);
  renderer = inject(Renderer2);

  ngAfterViewInit() {
    const observer = new MutationObserver(() => {
      this.adjustDropdownPosition();
    });

    observer.observe(this.el.nativeElement, {
      attributes: true,
      attributeFilter: ['style'],
    });

    this.adjustDropdownPosition();
  }

  private adjustDropdownPosition() {
    const dropdown = this.el.nativeElement;
    const trigger = dropdown.previousElementSibling;

    if (!trigger) {
      return;
    }

    const triggerRect = trigger.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const spaceBelow = viewportHeight - triggerRect.bottom;
    const dropdownHeight = dropdown.getBoundingClientRect().height + 12; // 12px is the space between dropdown and button

    if (spaceBelow < dropdownHeight) {
      this.renderer.addClass(dropdown, 'bottom-10');
    } else {
      this.renderer.removeClass(dropdown, 'bottom-10');
    }
  }
}
