import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

import { AxpoButtonComponent } from '../../core/axpo-button/axpo-button.component';
import { AxpoFormElementComponent } from '../../core/axpo-form-element/axpo-form-element.component';
import { AxpoSearchComponent } from '../../core/axpo-search/axpo-search.component';
import {
  AxpoSelectableButtonsComponent,
  IButton,
} from '../../core/axpo-selectable-buttons/axpo-selectable-buttons.component';
import {
  AxpoTableComponent,
  IRowClick,
  ISort,
  ITableColumn,
  ITableRow,
} from '../../core/axpo-table/axpo-table.component';
import { AxpoTypographyComponent } from '../../core/axpo-typography/axpo-typography.component';
import { IncidentListFilterSorting } from '../../shared/models/api_models';
import { IncidentService } from '../../shared/services/incident.service';
import { LanguageService } from '../../shared/services/lang.service';
import { TenantService } from '../../shared/services/tenant.service';
import { addTimeToDate, dateToDay } from '../../shared/utils/date';

@Component({
  selector: 'app-home',
  imports: [
    TranslocoPipe,
    AxpoTableComponent,
    AxpoFormElementComponent,
    AxpoSearchComponent,
    AxpoTypographyComponent,
    AxpoSelectableButtonsComponent,
    AxpoButtonComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './home.component.html',
})
export class HomeComponent {
  incidentService = inject(IncidentService);
  translocoService = inject(TranslocoService);
  langService = inject(LanguageService);
  router = inject(Router);
  tenantService = inject(TenantService);

  activeLanguage = signal<string | undefined>(undefined);
  columns = signal<ITableColumn[]>([]);
  filterFromDate = signal<string>(dateToDay(2, 'subtraction'));
  filterToDate = signal<string>(dateToDay());
  filterSearchText = signal<string>('');
  filterStates = signal([]);
  stateButtons = signal<IButton[]>([]);
  data = signal<ITableRow[]>([]);

  _lang = effect(() => {
    const _activeLanguage = this.langService.getLangSignal()();
    untracked(() => {
      this.columns.set(this.getTableColumns());
      this.stateButtons.update((stateButtons: IButton[]) => {
        return [
          {
            id: 'all',
            title: this.translocoService.translate('home.filterState.all'),
            value: undefined,
            selected: stateButtons.length > 0 ? stateButtons[0].selected : true,
            colors: undefined,
          },
          {
            id: 'pending',
            title: this.translocoService.translate('home.filterState.pending'),
            value: 4,
            selected: stateButtons.length > 0 ? stateButtons[1].selected : false,
            colors: undefined,
          },
          {
            id: 'escalated',
            title: this.translocoService.translate('home.filterState.escalated'),
            value: 5,
            selected: stateButtons.length > 0 ? stateButtons[2].selected : false,
            colors: undefined,
          },
          {
            id: 'feedback',
            title: this.translocoService.translate('home.filterState.incidentResolutionResponse'),
            value: 6,
            selected: stateButtons.length > 0 ? stateButtons[3].selected : false,
            colors: undefined,
          },
          {
            id: 'closed',
            title: this.translocoService.translate('home.filterState.completed'),
            value: 7,
            selected: stateButtons.length > 0 ? stateButtons[4].selected : false,
            colors: undefined,
          },
        ];
      });
    });
  });

  _data = effect(() => {
    this.langService.getLangSignal()();
    const incs = this.incidentService.incidents()?.map(i => {
      return {
        id: { value: i.accessCode?.toString() ?? '' },
        name: { value: i.incidentTitle?.toString() ?? '' },
        creation: { value: i.incidentFaultTimeStamp?.toString() ?? '' },
        updated: {
          value: i.incidentLastModificationTimeStamp?.toString() ?? '',
        },
        location: { value: i.locations?.map(l => ' ' + l.name) },
        state: {
          value: this.getStateType(i.incidentStateId ?? 0),
          tagTranslation: this.translocoService.translate(
            'home.filterState.' + i.incidentStateName,
          ),
        },
      } as ITableRow;
    });

    untracked(() => {
      this.data.set(incs ? incs : []);
    });
  });

  _startWizard = effect(() => {
    const accCode = this.incidentService.createdAccessCode();
    if (accCode) {
      untracked(() => {
        this.incidentService.createdAccessCode.set(undefined);
        this.router.navigate(['incident/' + accCode + '/edit/' + 0]);
      });
    }
  });

  tableDataIsLoading = computed(() => {
    return this.incidentService.isLoadingIncidents() || this.tenantService.isLoadingConfig();
  });

  onActionClick = ($event: IRowClick) => {
    this.router.navigate([
      '/incident/' + $event.row['id'].value + '/' + this.tenantService.tenantId(),
    ]);
  };

  getTableColumns = (): ITableColumn[] => {
    return [
      {
        title: this.translocoService.translate('home.table.creationDate'),
        field: 'creation',
        sortable: true,
        formatter: ['dateTime'],
      },
      {
        title: this.translocoService.translate('home.table.updated'),
        field: 'updated',
        sortable: true,
        formatter: ['dateTime'],
      },
      {
        title: this.translocoService.translate('home.table.title'),
        field: 'name',
        sortable: true,
      },
      {
        title: this.translocoService.translate('home.table.location'),
        field: 'location',
        sortable: false,
      },
      {
        title: this.translocoService.translate('home.table.state'),
        field: 'state',
        sortable: true,
        formatter: ['tag'],
      },
    ];
  };

  getStateType = (type: number) => {
    switch (type) {
      case 4: // pending
        return 'pending';
      case 6: // onHold
        return 'open';
      case 5: // escalated
        return 'cancelled';
      case 3: // creating step 3
        return 'open';
      case 2: // creating step 2
        return 'open';
      case 1: // creating step 1
        return 'open';
      case 7: // closed
        return 'paid';
      default:
        return 'paid';
    }
  };

  searchFilter = () => {
    this.incidentService.filter.set({
      ...this.incidentService.filter(),
      incidentFaultFrom: this.filterFromDate(),
      incidentFaultTo: addTimeToDate(this.filterToDate(), '23:59'),
      incidentStateIds: this.filterStates(),
      searchText: this.filterSearchText(),
    });
    this.incidentService.getIncidents();
  };

  stateFilter(value: any) {
    this.filterStates.set(value);
    this.searchFilter();
  }
  sortChanged(value: ISort) {
    let sortField: IncidentListFilterSorting = IncidentListFilterSorting.IncidentCategoryAsc;
    switch (value.field + value.direction) {
      case 'creationasc':
        sortField = IncidentListFilterSorting.IncidentFaultTimeStampAsc;
        break;
      case 'creationdesc':
        sortField = IncidentListFilterSorting.IncidentFaultTimeStampDesc;
        break;
      case 'updatedasc':
        sortField = IncidentListFilterSorting.IncidentLastModificationTimeStampAsc;
        break;
      case 'updateddesc':
        sortField = IncidentListFilterSorting.IncidentLastModificationTimeStampDesc;
        break;
      case 'nameasc':
        sortField = IncidentListFilterSorting.IncidentTitleAsc;
        break;
      case 'namedesc':
        sortField = IncidentListFilterSorting.IncidentTitleDesc;
        break;
      case 'locationasc':
        sortField = IncidentListFilterSorting.IncidentTitleDesc;
        break;
      case 'locationdesc':
        sortField = IncidentListFilterSorting.IncidentCategoryAsc;
        break;
      case 'stateasc':
        sortField = IncidentListFilterSorting.IncidentStateAsc;
        break;
      case 'statedesc':
        sortField = IncidentListFilterSorting.IncidentTitleDesc;
        break;
    }
    this.incidentService.filter.set({
      ...this.incidentService.filter(),
      incidentFaultFrom: this.filterFromDate(),
      incidentFaultTo: this.filterToDate(),
      incidentStateIds: this.filterStates(),
      searchText: this.filterSearchText(),
      sorting: IncidentListFilterSorting[sortField],
    });
    this.searchFilter();
  }
}
