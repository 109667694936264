<section class="bg-white dark:bg-gray-900">
  @if (incidentService.createInProgress()) {
    <div class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div class="p-8 bg-white rounded shadow-lg">
        <axpo-spinner size="normal">{{ 'core.loading' | transloco }}</axpo-spinner>
      </div>
    </div>
  }
  <div class="container px-4 py-8 mx-auto lg:py-8 lg:px-6">
    <div class="flex flex-col justify-between md:flex-row">
      <axpo-typography
        [variant]="'title-xl'"
        [weight]="'bold'"
        data-testid="home-heading"
        class="block mb-4"
      >
        {{ 'home.title' | transloco }}
      </axpo-typography>
      <axpo-button
        [disabled]="incidentService.createInProgress()"
        [autofocus]="true"
        (click)="incidentService.createIncident()"
      >
        <i class="text-xl fas fa-plus white" aria-hidden="true"></i>
        <span class="ml-4 whitespace-nowrap">{{ 'home.createIncident' | transloco }}</span>
      </axpo-button>
    </div>

    <!-- Grid -->
    <div class="container mx-auto mt-8 lg:mb-16 md:mt-0">
      <div class="flex flex-wrap">
        <div class="flex-initial w-36 sm:w-40 grow sm:grow-0 pr-4">
          <axpo-form-element
            formId="creationDateFrom"
            [label]="
              resizeService.width() < 375
                ? ('home.creationDateFromShort' | transloco)
                : ('home.creationDateFrom' | transloco)
            "
            formType="date"
            [(value)]="filterFromDate"
            (valueChange)="searchFilter()"
          />
        </div>
        <div class="flex-initial w-36 sm:w-40 grow sm:grow-0 sm:pr-4">
          <axpo-form-element
            formId="creationDateTo"
            [label]="'home.creationDateTo' | transloco"
            formType="date"
            [(value)]="filterToDate"
            (valueChange)="searchFilter()"
          />
        </div>
        <div class="flex-initial pb-4 grow xl:grow-0 xl:pr-4 min-w-72">
          <label
            for="filterSearchText"
            class="font-normal font-open-sans text-[14px] tracking-[-0.14px]"
          >
            {{ 'home.searchTitle' | transloco }}
          </label>
          <axpo-search
            id="filterSearchText"
            [(searchValue)]="filterSearchText"
            (debounceValue)="searchFilter()"
            [debounceTime]="500"
            [debounce]="true"
          ></axpo-search>
        </div>
        <div class="flex-initial lg:flex-1 grow md:min-w-[600px] w-[350px]">
          <p
            class="font-normal font-open-sans leading-[24px] text-[14px] tracking-[-0.14px] ng-star-inserted"
          >
            {{ 'home.state' | transloco }}
          </p>
          <axpo-selectable-buttons
            [buttons]="stateButtons()"
            [multiselect]="true"
            (buttonClicked)="stateFilter($event)"
          ></axpo-selectable-buttons>
        </div>
      </div>

      <div class="flex items-center justify-center">
        <div>
          <span class="align-middle">
            {{ data().length }} {{ 'home.incidentsFound' | transloco }}
          </span>
        </div>
        <div class="flex items-center justify-center my-4 lg:mb-0 lg:mt-0">
          <axpo-button (click)="refresh()" [type]="'ghost'">
            <i class="mr-3 text-xl fas fa-refresh text-primary" aria-hidden="true"></i>
            <span class="text-primary">{{ 'home.refresh' | transloco }}</span>
          </axpo-button>

          <app-timer [timerSeconds]="60" (timerElapsed)="refresh()"></app-timer>
        </div>
      </div>

      @if (!this.showMobileView()) {
        <axpo-table
          [columns]="columns()"
          [data]="data()"
          (sortClick)="sortChanged($event)"
          [actions]="['view']"
          [isLoading]="tableDataIsLoading()"
          (rowClick)="onActionClick($event)"
          (actionClick)="onActionClick($event)"
          [rowsClickable]="true"
          [mobileBreakpoint]="breakpoint"
          dateTimeFormat="dd.MM.yyyy - HH:mm"
        ></axpo-table>
      } @else {
        @for (incident of this.incidentService.incidents(); track incident.accessCode) {
          <div
            class="flex flex-wrap py-4 border-t-2"
            (click)="onIncidentClick(incident.accessCode ?? '')"
            (keydown)="onIncidentClick(incident.accessCode ?? '')"
            role="button"
            tabindex="0"
          >
            <div class="flex flex-wrap justify-between w-full mb-3">
              <div class="w-120">
                <axpo-typography variant="body-s">
                  {{ incident.incidentFaultTimeStamp | date: 'dd.MM.yyyy - HH:mm' }}
                </axpo-typography>
              </div>
              <div class="text-right w-1/3">
                <axpo-tag variant="fullWidth" [type]="getStateType(incident.incidentStateId ?? 0)">
                  {{ translocoService.translate('home.filterState.' + incident.incidentStateName) }}
                </axpo-tag>
              </div>
            </div>
            <div class="w-full">
              <axpo-typography variant="body-l">
                {{ incident.incidentTitle }}
              </axpo-typography>
              @for (location of incident.locations; track location.id) {
                <axpo-typography variant="body-s">
                  {{ location.name }}
                </axpo-typography>
              }
            </div>
          </div>
        }
      }
    </div>
  </div>
</section>
