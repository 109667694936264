export const ALL_ICONS = [
  'dark-mode',
  'double-arrow-vertical',
  'light-mode',
  'notification',
  'notification-off',
  'pause-outline',
  'pause',
  'play-outline',
  'play',
  'double-arrow-horizontal',
  'view-off',
  'view',
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'arrow-up',
  'caret-down',
  'caret-left',
  'caret-right',
  'caret-up',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'clear',
  'chart-add',
  'chart-column',
  'chart-show',
  'disconnected',
  'signal',
  'trending-down',
  'trending-up',
  'biomass',
  'drone',
  'electricity',
  'gas',
  'hydropower',
  'pump',
  'solar',
  'transformer',
  'turbine',
  'wind',
  'attach',
  'calendar',
  'contract',
  'copy',
  'delete-hard',
  'delete',
  'download',
  'file-excel',
  'file-pdf',
  'link-off',
  'link',
  'mail',
  'upload',
  'horizontal-points',
  'login',
  'logout',
  'menu',
  'refresh',
  'search',
  'user',
  'vertical-points',
  'zoom-in',
  'zoom-out',
  'check-circle',
  'check',
  'info-circle',
  'warning-circle',
  'warning-triangle',
  'warning',
  'add',
  'block',
  'comment',
  'edit',
  'feedback',
  'filter',
  'lock',
  'pen',
  'remove',
  'send',
  'settings',
  'share',
  'shortcut',
  'transfer-right',
  'transfer',
  'agenda',
  'alarm',
  'axpo',
  'branch',
  'company',
  'flow',
  'legal',
  'list',
  'placeholder',
  'site',
  'timer',
  'profile',
  'house',
  'gear',
  'translation',
  'feedback-pen',
  'helmet',
  'warning-triangle-filled',
  'warning-circle-filled',
  'info-circle-filled',
  'check-circle-filled',
  'phone',
  'phone-filled',
  'user-add',
  'user-add-filled',
  'user-filled',
  'warning-circle-2',
  'caret-double-vertical',
  'data',
  'unlock',
] as const;

export type SvgIconName = (typeof ALL_ICONS)[number];
