import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AxpoTypographyComponent } from '../axpo-typography/axpo-typography.component';

export interface FooterLinksProps {
  text: string;
  link?: string;
  target?: 'external_link' | 'internal_link';
}

@Component({
  selector: 'axpo-footer',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterModule, AxpoTypographyComponent],
  templateUrl: './axpo-footer.component.html',
  styleUrl: './axpo-footer.component.css',
})
export class AxpoFooterComponent {
  currentYear = new Date().getFullYear();
  version = input<string>('0.0.0');
  footerLinks = input<FooterLinksProps[]>([]);
}
