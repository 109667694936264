import { Injectable, inject, signal } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { ApiService } from './api.service';
import { ITenantSettingsModel } from '../models/api_models';
import { IncidentTilesTranslations, tileName } from '../models/api_data_translations';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  private apiService = inject(ApiService);
  private toastr = inject(ToastrService);
  private translocoService = inject(TranslocoService);
  tenantId = signal<string | undefined>(undefined);
  tenantSettings = signal<ITenantSettingsModel | undefined>(undefined);
  isLoadingConfig = signal<boolean>(false);
  isLoadingSettings = signal<boolean>(false);

  getConfig() {
    if (this.isLoadingConfig() === true) {
      return;
    }

    this.isLoadingConfig.set(true);
    this.apiService.get<any>(`/api/v1/Tenant/Config`).subscribe({
      next: tenantConfig => {
        this.tenantId.set(tenantConfig.tenantId);
      },
      error: () => {
        const msg = this.translocoService.translate('core.tenantFailed');
        this.toastr.error(msg);
        this.isLoadingConfig.set(false);
      },
      complete: () => {
        this.isLoadingConfig.set(false);
      },
    });
  }

  getSettings(tenantId: string) {
    if (this.isLoadingSettings() === true) {
      return;
    }

    this.isLoadingSettings.set(true);
    this.apiService
      .get<
        ITenantSettingsModel
      >(`/api/v1/tenant/settings/` + tenantId)
      .subscribe({
        next: tenantSetting => {
          const tenantSettingsI18n = {
            ...tenantSetting, 
            tileModels: tenantSetting.tileModels?.map((model) => ({ isVisible: model.isVisible, name: IncidentTilesTranslations[model.name as tileName] }))
          } 
          this.tenantSettings.set(tenantSettingsI18n);
        },
        error: () => {
          const msg = this.translocoService.translate('core.tenantFailed');
          this.toastr.error(msg);
        },
        complete: () => {
          this.isLoadingSettings.set(false);
        },
      });
  }
}
