import { DatePipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  signal,
  untracked,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';

import { AxpoButtonComponent } from '../../../core/axpo-button/axpo-button.component';
import { AxpoFormElementComponent } from '../../../core/axpo-form-element/axpo-form-element.component';
import { AxpoTypographyComponent } from '../../../core/axpo-typography/axpo-typography.component';
import { SvgService } from '../../../core/services/svg.service';
import { ICommentModel } from '../../models/api_models';
import { AuthnService } from '../../services/authn.service';
import { CommentService } from '../../services/comment.service';
import { IncidentService } from '../../services/incident.service';
import { TenantService } from '../../services/tenant.service';
import { ITileMode } from '../../utils/tileStates';

export type commentType = 'public' | 'private';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  imports: [
    AxpoButtonComponent,
    AxpoFormElementComponent,
    TranslocoPipe,
    AxpoTypographyComponent,
    DatePipe,
    NgClass
  ],
})
export class CommentsComponent {
  private incidentService = inject(IncidentService);
  private tenantService = inject(TenantService);
  private authnService = inject(AuthnService);
  private commentService = inject(CommentService);
  private activatedRoute = inject(ActivatedRoute);
  svgService = inject(SvgService);
  getSvg = this.svgService.svgMap;

  mode = input.required<ITileMode>();
  title = input.required<string>();
  type = input<commentType>('public');
  comments = signal<ICommentModel[] | undefined>([]);
  notLoggedInUserName = signal<string | undefined>(undefined);
  commentsEmpty = computed(() => {
    return !this.comments() || this.comments()?.length === 0;
  });
  userName = computed(() => {
    return this.authnService.user()?.name ?? this.notLoggedInUserName();
  });

  isLoggedIn = computed(() => {
    return !!this.authnService.user();
  });
  accessCode = computed(() => {
    return this.incidentService.incidentDetails()?.accessCode;
  });
  tenantId = computed(() => {
    if (this.tenantService.tenantId()) {
      return this.tenantService.tenantId();
    } else {
      return this.activatedRoute.snapshot.paramMap.get('tenantId');
    }
  });
  editingComment = signal<ICommentModel | undefined>(undefined);
  editingCommentId = signal<number | undefined>(undefined);
  editingCommentMessage = signal<string | undefined>(undefined);
  newCommentMessage = signal<string | undefined>(undefined);

  onInit = effect(() => {
    this.svgService.loadSvg(['pen', 'delete', 'lock', 'unlock']);
  });

  _updateComments = effect(() => {
    const comments = this.incidentService.incidentDetails()?.comments;
    if (comments) {
      untracked(() => {
        const commentsToShow = comments.filter(comment => {
          if (this.type() === 'private') {
            return comment.isPrivate === true;
          }

          return !comment.isPrivate;
        });
        this.comments.set(commentsToShow);
      });
    }
  });

  _commentCreated = effect(() => {
    const comment = this.commentService.commentCreateResult();
    if (comment) {
      untracked(() => {
        this.editingComment.set(undefined);
        this.newCommentMessage.set(undefined);
        this.reloadDetails();
      });
    }
  });

  _commentUpdated = effect(() => {
    const comment = this.commentService.commentUpdateResult();
    if (comment) {
      untracked(() => {
        this.editingComment.set(undefined);
        this.editingCommentId.set(undefined);
        this.editingCommentMessage.set(undefined);
        this.reloadDetails();
      });
    }
  });

  _commentDeleted = effect(() => {
    const comment = this.commentService.commentDeleteResult();
    if (comment) {
      untracked(() => {
        this.reloadDetails();
      });
    }
  });

  reloadDetails() {
    this.incidentService.getIncidentDetails(this.accessCode() as string, this.tenantId() as string);
  }

  editComment(comment: ICommentModel) {
    this.editingComment.set(comment);
    this.editingCommentId.set(comment.id);
    this.editingCommentMessage.set(comment.message);
  }

  updateComment() {
    this.commentService.addOrUpdate(
      this.accessCode() as string,
      this.tenantId() as string,
      { ...this.editingComment(), message: this.editingCommentMessage() } as ICommentModel,
    );
  }

  deleteComment(comment: ICommentModel) {
    this.commentService.delete(this.accessCode() as string, comment?.id?.toString() as string);
  }

  addComment() {
    this.commentService.addOrUpdate(
      this.accessCode() as string,
      this.tenantId() as string,
      {
        message: this.newCommentMessage(),
        author: this.userName(),
        isPrivate: this.type() === 'private',
      } as ICommentModel,
    );
  }

  cancelEdit() {
    this.editingComment.set(undefined);
    this.editingCommentId.set(undefined);
    this.editingCommentMessage.set(undefined);
  }

  changeCommentVisibility(visibility: commentType) {
    let isPrivate = false;
    if (visibility === 'private') {
      isPrivate = true;
    }

    this.commentService.addOrUpdate(
      this.accessCode() as string,
      this.tenantId() as string,
      { ...this.editingComment(), isPrivate: isPrivate} as ICommentModel
    );
  }
}
