import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OfflineService {
  private _offline = signal<boolean>(false);

  constructor() {
    window.addEventListener('offline', () => {
      this._offline.set(true);
    });
    window.addEventListener('online', () => {
      this._offline.set(false);
    });
  }

  public checkOffline = () => {
    this._offline.set(!window.navigator.onLine);
  };

  get offline() {
    return this._offline;
  }
}
