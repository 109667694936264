import { CommonModule, NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, effect, inject, input, model, output, signal } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";

import { RadioButtonComponent } from "./radioButton/radio-button.component";
import { AxpoDialogComponent } from "../../../core/axpo-dialog/axpo-dialog.component";
import { AxpoTypographyComponent } from "../../../core/axpo-typography/axpo-typography.component";
import { SvgService } from "../../../core/services/svg.service";
import { Subgroup, User } from "../../../shared/models/api_models";
import { AuthnService } from '../../../shared/services/authn.service';
import { CircleService } from "../../../shared/services/circle.service";
import { Roles } from "../../../shared/utils/constants";
import { BaseResizeComponent } from "../base.resize.component";

export type IToggleProperty = 'isOnSite' | 'onCall' | 'active';
export type ISubgroupMode = 'radio' | 'switch';
export interface ISelection {
  groupId: string;
  subgroupId: number;
  user: User;
  property: IToggleProperty;
};
export type UserSelection = Record<string, User>;
@Component({
  selector: 'app-subgroup-list-selection',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AxpoTypographyComponent, NgClass, CommonModule, RadioButtonComponent, AxpoDialogComponent, TranslocoPipe],
  templateUrl: './sub-group.component.html',
  styleUrl: './sub-group.component.scss'
})
export class SubgroupComponent extends BaseResizeComponent {
  private authnService = inject(AuthnService);
  private svgService = inject(SvgService);
  circleService = inject(CircleService);
  selectionGroupId = input.required<string>();
  subgroup = input<Subgroup | undefined>(undefined);
  circleId = input.required<number | undefined>();
  toggleProperty = input<IToggleProperty>('onCall');
  mode = input<ISubgroupMode>('radio');
  clearSelected = model<UserSelection | undefined>(undefined);
  userSelected = output<ISelection>();
  allDisabled = input<boolean>(false);
  isAdmin = signal<boolean>(false);
  getSvg = this.svgService.svgMap;
  showDeleteDialog = signal<boolean>(false);
  userToDelete = signal<User | undefined>(undefined);

  onInit = effect(() => {
    this.svgService.loadSvg(['delete']);
  });

  _isAdminContacts = effect(() => {
    const userRoles = this.authnService.user()?.roles || [];
    const isAdmin = [Roles.SUPER_ADMIN, Roles.CIRCLE_ADMIN].some(role => userRoles.includes(role));

    if (isAdmin) {
      this.isAdmin.set(true);
    }
  });

  _deletingDone = effect(() => {
    const deleted = this.circleService.userDeleted();

    if (deleted) {
      window.location.reload();
    }
  });

  toggleSelection(user: User) {
    const subgroup = this.subgroup();
    if (!subgroup){
      return;
    }

    if (this.mode() === 'radio' && subgroup.users) {
      for (const us of subgroup.users) {
        if (us.id !== user.id) {
          us[this.toggleProperty()] = false;
        }
      }
      user[this.toggleProperty()] = true;
    } else {
      user[this.toggleProperty()] = !user[this.toggleProperty()];
    }
    this.userSelected.emit({subgroupId: subgroup.id as number, groupId: this.selectionGroupId(), user: user, property: this.toggleProperty() });
  }

  deleteContact(user: User) {
    if (user.onCall || user.isOnSite || user.automaticallyAdded) {
      return;
    }
    this.userToDelete.set(user);
    this.showDeleteDialog.set(true);
  }

  afterUserDeleted(action: any) {
    if (action === 'confirm') {
      const user = this.userToDelete();
      if (user) {
        this.circleService.updateCircle(this.circleId() as number,{
          remove_users: [{
            user_id: user.id,
            subgroup_id: this.subgroup()?.id as number
          }]
        });
      }
    }

    this.showDeleteDialog.set(false);
    this.userToDelete.set(undefined);
  }
}