import { TranslocoService } from '@jsverse/transloco';
import atlas from 'azure-maps-control';

import geoIsLink from './geoIsLink';
import createNavigationLink from './navigationLink';
import { environment } from '../../../environments/environment';
import { ICustomLocationModel, ILocationModel, LocationModelType } from '../models/api_models';

export const addMarkers = (
  map: atlas.Map,
  translationService: TranslocoService,
  locations: ILocationModel[],
  onEditClick: (customLocation: ICustomLocationModel) => void,
  onDeleteClick: (customLocationId: number) => void,
) => {
  // Clear markers
  map.markers.clear();

  //Create an HTML marker and add it to the map.
  for (const loc of locations) {
    addMarker(map, translationService, loc, onEditClick, onDeleteClick);
  }

  map.setCamera({
    center:
      locations.length > 0
        ? [
            locations[locations.length - 1].coordinates?.longitude ?? 0,
            locations[locations.length - 1].coordinates?.latitude ?? 0,
          ]
        : [8.308, 47.4729], // default is Baden
  });
};

export const addMarker = (
  map: atlas.Map,
  translationService: TranslocoService,
  loc: ILocationModel | ICustomLocationModel,
  onEditClick: (customLocation: ICustomLocationModel) => void,
  onDeleteClick: (customLocationId: number) => void,
) => {
  //Create an HTML marker and add it to the map.
  const marker = new atlas.HtmlMarker({
    color:
      (loc as ILocationModel).locationTypeId ||
      (loc as ILocationModel).type == LocationModelType.Address
        ? '#ff5d64'
        : '#5d64ff',
    position: [loc.coordinates?.longitude ?? 0, loc.coordinates?.latitude ?? 0],
    anchor: 'top',
    text: "<b style='top:-55px;left:-25px;position:relative'>" + (loc.name ?? 'No Name') + '</b>',
  });
  // create pins html
  const div =
    (loc as ILocationModel).locationTypeId ||
    (loc as ILocationModel).type == LocationModelType.Address
      ? createPinHtmlElement(loc, translationService)
      : createPinHtmlElement4CustomLocation(
          loc as ICustomLocationModel,
          translationService,
          marker,
          onEditClick,
          onDeleteClick,
        );
  marker.setOptions({
    ...marker.getOptions(),
    popup: new atlas.Popup({
      content: div,
      pixelOffset: [0, -30],
    }),
  });
  marker.getElement().classList.add('cursor-pointer');

  marker._addEventListener(
    'click',
    (e: atlas.TargetedEvent) => {
      (e.target as atlas.HtmlMarker).togglePopup();
    },
    false,
  );

  map.markers.add(marker);
};

export const createPinHtmlElement = (
  loc: ILocationModel,
  translationService: TranslocoService,
): HTMLElement => {
  const div = document.createElement('div');
  div.classList.add('p-3');

  const addButtonClass = (button: HTMLButtonElement) => {
    button.classList.add('bg-primary');
    button.classList.add('items-center');
    button.classList.add('justify-center');
    button.classList.add('text-white');
    button.classList.add('rounded-full');
    button.classList.add('p-3');
    button.classList.add('mb-2');
    button.classList.add('block');
    button.classList.add('w-full');
  };
  const btnNavigationLink = document.createElement('button');
  btnNavigationLink.innerHTML = translationService.translate(
    'incidentDetail.panelLocation.map.routeplanning',
  );
  addButtonClass(btnNavigationLink);

  btnNavigationLink.onclick = function () {
    window.open(
      createNavigationLink(loc.coordinates?.latitude, loc.coordinates?.longitude),
      '_blank',
    );
  };
  if ((loc as ILocationModel).type == LocationModelType.Location) {
    const btnGoIs = document.createElement('button');
    btnGoIs.innerHTML = translationService.translate('incidentDetail.panelLocation.map.geoIs');
    addButtonClass(btnGoIs);
    btnGoIs.onclick = function () {
      window.open(geoIsLink(loc), '_blank');
    };

    div.appendChild(btnGoIs);
  }
  div.appendChild(btnNavigationLink);

  return div;
};

export const createPinHtmlElement4CustomLocation = (
  loc: ICustomLocationModel,
  translationService: TranslocoService,
  marker: atlas.HtmlMarker,
  onEditClick: (customLocation: ICustomLocationModel) => void,
  onDeleteClick: (customLocationId: number) => void,
): HTMLElement => {
  const div = document.createElement('div');
  div.classList.add('p-3', 'text-center');

  const addButtonClass = (button: HTMLButtonElement) => {
    button.classList.add(
      'bg-primary',
      'items-center',
      'justify-center',
      'text-white',
      'rounded-full',
      'p-3',
      'mb-2',
      'block',
      'w-full',
    );
  };

  const btnEditLocation = document.createElement('button');
  btnEditLocation.innerHTML = translationService.translate('incidentDetail.panelLocation.map.edit');
  addButtonClass(btnEditLocation);
  btnEditLocation.onclick = function () {
    marker.togglePopup();
    onEditClick(loc);
  };

  const btnDeleteLocation = document.createElement('button');
  btnDeleteLocation.innerHTML = translationService.translate(
    'incidentDetail.panelLocation.map.delete',
  );
  addButtonClass(btnDeleteLocation);
  btnDeleteLocation.onclick = function () {
    marker.togglePopup();
    onDeleteClick(loc.id ?? 0);
  };

  const btnNavigationLink = document.createElement('button');
  btnNavigationLink.innerHTML = translationService.translate(
    'incidentDetail.panelLocation.map.routeplanning',
  );
  addButtonClass(btnNavigationLink);

  btnNavigationLink.onclick = function () {
    window.open(
      createNavigationLink(loc.coordinates?.latitude, loc.coordinates?.longitude),
      '_blank',
    );
  };

  const nameElement = document.createElement('p');
  nameElement.classList.add(
    'font-normal',
    'leading-[32px]',
    'text-[24px]',
    'tracking-[-0.24]',
    'ng-star-inserted',
    'font-almarena',
  );
  nameElement.innerHTML = loc.name;

  const desciptionElement = document.createElement('p');
  desciptionElement.innerHTML = loc.description ?? '';

  div.appendChild(nameElement);
  div.appendChild(desciptionElement);
  div.appendChild(btnEditLocation);
  div.appendChild(btnDeleteLocation);
  div.appendChild(btnNavigationLink);

  return div;
};

export const initMap = (
  locations: ILocationModel[],
  mapId: string,
  translationService: TranslocoService,
  onEditClick: (customLocation: ICustomLocationModel) => void,
  onDeleteClick: (customLocationId: number) => void,
): atlas.Map => {
  const map = new atlas.Map(mapId, {
    view: 'Auto',
    // Add authentication details for connecting to Azure Maps.
    authOptions: {
      authType: atlas.AuthenticationType.subscriptionKey,
      subscriptionKey: environment.azureMapsKey,
    },
    showLogo: false,
    zoom: 11,
    center:
      locations.length > 0
        ? [locations[0].coordinates?.longitude ?? 0, locations[0].coordinates?.latitude ?? 0]
        : [8.308, 47.4729], // default is Baden (CH)
  });

  map.controls.add([new atlas.control.ZoomControl()], {
    position: atlas.ControlPosition.BottomRight,
  });

  addMarkers(map, translationService, locations, onEditClick, onDeleteClick);

  return map;
};
