import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { generateSimpleUID } from '../../shared/utils/uid';
import { AxpoTypographyComponent } from '../axpo-typography/axpo-typography.component';

export interface IButton {
  id: string | undefined | null;
  title: string;
  value: string | number | undefined;
  selected: boolean;
  colors: IButtonColor | undefined;
}

export interface IButtonColor {
  textColor: string;
  backgroundColor: string;
  textColorSelected: string;
  backgroundColorSelected: string;
}

@Component({
  standalone: true,
  imports: [FormsModule, NgTemplateOutlet, NgClass, AxpoTypographyComponent],
  selector: 'axpo-selectable-buttons',
  templateUrl: './axpo-selectable-buttons.component.html',
  styleUrl: './axpo-selectable-buttons.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AxpoSelectableButtonsComponent {
  buttons = input<IButton[]>([]);
  multiselect = input<boolean>(false);
  disabled = input<boolean>(false);
  label = input<string | undefined>(undefined);
  buttonClicked = output<(string | number)[]>();

  selectedValues = signal<(string | number)[]>([]);
  elementId = generateSimpleUID();

  buttonClick = (event: any, value: string | number | undefined): void => {
    event.preventDefault();
    if (this.multiselect()) this.handleMultiSelect(value);
    else this.handleSingleSelect(value);
  };

  getClasses = (button: IButton) => {
    const classes: string[] = [];

    if (!button.colors) {
      classes.push('peer-checked:text-white');
      if (this.disabled()) {
        classes.push('peer-checked:bg-disabled');
      } else {
        classes.push('peer-checked:bg-primary');
      }
    } else {
      classes.push('text-' + button.colors.textColor);

      classes.push('peer-checked:text-' + button.colors.textColorSelected);
      if (this.disabled()) {
        classes.push('peer-checked:bg-disabled');
      } else {
        classes.push(
          'bg-' +
            (button.selected
              ? button.colors.backgroundColorSelected
              : button.colors.backgroundColor),
        );
      }
    }
    return classes;
  };

  handleMultiSelect = (value: string | number | undefined): void => {
    let emitEvent = true;
    const selected = [...this.selectedValues()];
    if (value) {
      if (!selected.includes(value)) {
        selected.push(value);
      } else {
        selected.splice(selected.indexOf(value), 1);
      }
      this.setSelectedValues(selected);

      if (selected.length == 0) {
        this.uncheckAllCheckboxesExceptUndefined();
      }
    } else {
      // prevent event emit if value hasn't changed
      if (selected.length == 0) {
        emitEvent = false;
      }
      selected.length = 0;

      this.uncheckAllCheckboxesExceptUndefined();
    }

    this.selectedValues.set(selected);

    if (emitEvent) {
      this.buttonClicked.emit(selected);
    }
  };

  handleSingleSelect(value: string | number | undefined) {
    if (value) {
      this.selectedValues.set([value]);
    } else {
      this.selectedValues.set([]);
    }
    this.setSelectedValues([value]);
    this.buttonClicked.emit(this.selectedValues());
  }

  uncheckAllCheckboxesExceptUndefined = (): void => {
    this.buttons().forEach(element => {
      const checkboxValue = element.value;
      element.selected = checkboxValue == undefined || checkboxValue == 'undefined';
    });
  };

  getButtonId(buttonId: string | null | undefined) {
    return this.elementId + '_' + buttonId;
  }

  setSelectedValues = (selectedValues: (string | number | undefined)[]): void => {
    this.buttons().forEach(element => {
      element.selected = selectedValues.indexOf(element.value) > -1;
    });
  };
}
