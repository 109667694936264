import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import {
  FileResponse,
  IContactTemplateImportResultModel,
  IValidationErrorModel,
} from '../models/api_models';

@Injectable({
  providedIn: 'root',
})
export class ContactImportService {
  environment = environment;
  httpService = inject(HttpClient);
  private apiService = inject(ApiService);
  private translocoService = inject(TranslocoService);
  private toastr = inject(ToastrService);

  Files = signal<FileResponse[] | undefined>(undefined);
  isUploadingContactImportFile = signal<boolean>(false);

  validationDetails = signal<IContactTemplateImportResultModel | undefined>(undefined);

  upload(file: File, persist: boolean) {
    const formData = new FormData();
    if (file !== null && file !== undefined) formData.append('file', new Blob([file]), file.name); // todo: this causes
    const url = this.environment.apiUrl + `/api/v1/ContactTemplate/import-file/${persist}`;

    this.isUploadingContactImportFile.set(true);
    this.validationDetails.set(undefined);
    const req = new HttpRequest('POST', url, formData);

    this.httpService.request(req).subscribe({
      next: (details: any) => {
        this.validationDetails.set(details.body as IContactTemplateImportResultModel);
      },
      error: (errorResponse: any) => {
        this.handleErrorMessage(errorResponse);
        this.isUploadingContactImportFile.set(false);
      },
      complete: () => {
        this.isUploadingContactImportFile.set(false);
      },
    });
  }

  private handleErrorMessage(errorResponse: any) {
    const errors = Array.isArray(errorResponse.error)
      ? (errorResponse.error as IValidationErrorModel[])
      : [errorResponse.error];
    const msg = errors.map(error =>
      error.key
        ? this.translocoService.translate('incidentDetail.panelAttachments.invalid' + error.key)
        : error.errorMessage,
    );
    this.toastr.error(msg.length > 0 ? String(msg) : 'Error occured');
  }

  TransformValidationMessagesToGenreicStrings(message: string) {
    const invalidGroupnameKey = 'groupName';
    const invalidGroupnameMessage1 = 'Der Name der Gruppe ';
    const invalidGroupnameMessage2 = ' ist nicht eindeutig. Gruppen müssen eindeutige Namen haben.';

    const invalidMailKey = 'mail';
    const invalidMailMessage1 = 'Die Kontakt E-Mail-Adresse ';
    const invalidMailMessage2 =
      ' ist nicht eindeutig. Kontakte müssen eine eindeutige E-Mail-Adresse haben.';

    const validationFailedKey = 'validationFailed';
    const validationFailedMessage1 = 'Der Datensatz mit Id ';
    const validationFailedMessage2 = ' hat ungültige Werte und wird deshalb ignoriert (';
    const validationFailedMessage3 = ').';

    const invalidDataLengthKey = 'invalidDataLength';
    const invalidDataLengthMessage1 = 'Der Datensatz auf Zeile ';
    const invalidDataLengthMessage2 =
      ' hat nicht die erwartete Anzahl Trennzeichen und wird deshalb ignoriert.';

    if (message.indexOf(invalidGroupnameMessage2) > 0) {
      message = message.replace(invalidGroupnameMessage1, '');
      message = message.replace(invalidGroupnameMessage2, '');
      return this.translocoService.translate('import-contact.' + invalidGroupnameKey, {
        value: message,
      });
    } else if (message.indexOf(invalidMailMessage2) > 0) {
      message = message.replace(invalidMailMessage1, '');
      message = message.replace(invalidMailMessage2, '');
      return this.translocoService.translate('import-contact.' + invalidMailKey, {
        value: message,
      });
    } else if (message.indexOf(validationFailedMessage2) > 0) {
      const index1 = message.indexOf(validationFailedMessage2);
      const index2 = message.indexOf(validationFailedMessage3);
      const value1 = message.substring(validationFailedMessage1.length, index1);
      let value2 = message.substring(index1 + validationFailedMessage2.length, index2);

      if (value2.indexOf('Hier müssen Positionsdaten angegeben werden.') > -1) {
        value2 = this.translocoService.translate(
          'import-contact.customContactModel.invalidCoordinates',
        );
      } else if (
        value2.indexOf('Hier muss ein Text mit maximal 100 Zeichen angegeben werden.') > -1
      ) {
        value2 = this.translocoService.translate(
          'import-contact.customContactModel.invalidDescription',
        );
      } else if (
        value2.indexOf('Hier muss ein Text mit 5 bis 100 Zeichen angegeben werden.') > -1
      ) {
        value2 = this.translocoService.translate('import-contact.customContactModel.invalidName');
      }
      return this.translocoService.translate('import-contact.' + validationFailedKey, {
        value: value1,
        value2: value2,
      });
    } else if (message.indexOf(invalidDataLengthMessage2) > 0) {
      message = message.replace(invalidDataLengthMessage1, '');
      message = message.replace(invalidDataLengthMessage2, '');
      return this.translocoService.translate('import-contact.' + invalidDataLengthKey, {
        value: message,
      });
    }

    return message;
  }
}
