<div class="mt-3">
  @if (attachmentService.isAddingAttachment()) {
    <div class="flex justify-center items-center mb-4">
      <axpo-spinner size="xs">
        {{ 'incidentDetail.panelAttachments.isUploading' | transloco }}
      </axpo-spinner>
    </div>
  }
  <app-filedrop
    [multiple]="true"
    [acceptCamera]="true"
    [accept]="'.xlsx, .xls, .csv, .txt'"
    (filesUploaded)="filesUploaded($event)"
    [acceptedFileTypesLabel]="'incidentDetail.panelAttachments.acceptedFormat'"
  >
    @for (item of attachments(); track item.id) {
      <div class="bg-background-1 flex p-3 mb-2">
        <div class="mr-3">
          <button (click)="download(item)">
            <i class="fas fa-download text-primary"></i>
          </button>
        </div>
        <div class="grow">
          {{ item.name }}
        </div>
        <div><button (click)="remove(item)">X</button></div>
      </div>
    }
  </app-filedrop>
</div>
