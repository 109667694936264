@if (mode() === 'edit' || mode() === 'create') {
  <div class="mt-3">
    @if (attachmentService.isAddingAttachment()) {
      <div class="flex justify-center items-center mb-4">
        <axpo-spinner size="xs">
          {{ 'incidentDetail.panelAttachments.isUploading' | transloco }}
        </axpo-spinner>
      </div>
    }
    <app-filedrop
      [multiple]="true"
      [acceptCamera]="true"
      [accept]="'.xlsx, .xls, .csv, .txt'"
      (filesUploaded)="filesUploaded($event)"
      [acceptedFileTypesLabel]="'incidentDetail.panelAttachments.acceptedFormat'"
    >
      @for (item of attachments(); track item.id) {
        <div class="bg-background-1 flex p-3 mb-2">
          <div class="mr-3">
            <button (click)="download(item)">
              <div class="w-6 h-6 text-primary" [innerHTML]="getSvg()['download']"></div>
            </button>
          </div>
          <div class="grow">
            {{ item.name }}
          </div>
          <div>
            <button (click)="remove(item)">
              <div class="w-6 h-6 text-text-primary" [innerHTML]="getSvg()['delete-hard']"></div>
            </button>
          </div>
        </div>
      }
    </app-filedrop>
  </div>
} @else {
  <div class="w-full flex-2-col min-h-40 mt-3">
    @if (!allAttachmentsCached()) {
      <div class="flex-1 flex items-center justify-center p-3">
        <button
          (click)="downloadAllFilesForOfflineUse()"
          class="bg-primary disabled:bg-disabled disabled:text-text-primary font-almarena font-bold hover:bg-interaction-red inline-flex items-center justify-center leading-4 min-w-24 px-6 py-3 rounded-full text-[14px] text-white tracking-[-0.037px]"
        >
          {{ 'incidentDetail.panelAttachments.makeFilesOfflineAvailable' | transloco }}
          @if (attachmentService.isLoadingAttachments()) {
            &nbsp;&nbsp;&nbsp;
            <axpo-spinner size="xs"></axpo-spinner>
          }
        </button>
      </div>
    }
    @for (item of attachments(); track item.id) {
      <div class="bg-background-1 flex p-3 mb-2">
        <div class="mr-3">
          <div class="w-6 h-6 text-tc-green" [innerHTML]="getSvg()['check']"></div>
        </div>
        <div class="grow">{{ item.name }}</div>
        <div>
          <button (click)="download(item)">
            @if (item.isInPwa) {
              <div class="w-5 h-5 text-primary" [innerHTML]="getSvg()['data']"></div>
            } @else {
              <div class="w-5 h-5 text-primary" [innerHTML]="getSvg()['download']"></div>
            }
          </button>
        </div>
      </div>
    }

    @if (showNoAttachments()) {
      <axpo-typography [variant]="'body-m'">
        {{ 'incidentDetail.panelAttachments.noAttachmentsFound' | transloco }}
      </axpo-typography>
    }
  </div>
}
