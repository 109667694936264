import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable, effect, inject, signal, untracked } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { ApiService } from './api.service';
import { IncidentService } from './incident.service';
import { environment } from '../../../environments/environment';
import { FileResponse, IAttachmentLocalModel, IValidationErrorModel } from '../models/api_models';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  private apiService = inject(ApiService);
  private toastr = inject(ToastrService);
  private translocoService = inject(TranslocoService);
  private incidentService = inject(IncidentService);
  environment = environment;
  httpService = inject(HttpClient);

  isRemovingAttachment = signal<boolean | undefined>(undefined);

  addAttachmentValidation = signal<IValidationErrorModel[] | undefined>(undefined);
  isAddingAttachment = signal<boolean | undefined>(undefined);

  Files = signal<FileResponse[] | undefined>(undefined);
  isLoadingAttachments = signal<boolean>(false);
  allAttachmentsCached = signal<boolean>(true);
  allAttachmentsInternalCached = signal<boolean>(true);

  attachments = signal<IAttachmentLocalModel[] | undefined>(undefined);
  attachmentsInternal = signal<IAttachmentLocalModel[] | undefined>(undefined);
  apiCacheName = 'apiCacheFirst';

  _init = effect(() => {
    const attachments = this.incidentService.incidentDetails()?.attachments ?? [];
    const attachmentsInternal = this.incidentService.incidentDetails()?.attachmentsInternal ?? [];
    untracked(() => {
      this.attachments.set(attachments as IAttachmentLocalModel[]);
      this.attachmentsInternal.set(attachmentsInternal as IAttachmentLocalModel[]);
    });
  });

  removeAttachment(accessCode: string, id: number, tenantId: string | null, internal: boolean) {
    this.isRemovingAttachment.set(true);
    this.apiService
      .delete<IValidationErrorModel[]>(`/api/v1/Attachment/remove/${accessCode}/${id}/${tenantId}`)
      .subscribe({
        next: () => {
          const attachmentsCopy = [
            ...((internal ? this.attachmentsInternal() : this.attachments()) ?? []),
          ];
          const idx = attachmentsCopy.findIndex(x => x.id == id);
          if (idx > -1) {
            attachmentsCopy.splice(idx, 1);
          }

          if (internal) {
            this.attachmentsInternal.set(attachmentsCopy);
          } else {
            this.attachments.set(attachmentsCopy);
          }
          // remove from cache if it exists
          if ('caches' in window) {
            const url = this.getAttachmentUrl(accessCode ?? '', id ?? 0, tenantId ?? '', true);
            caches.open(this.apiCacheName).then(function (cache) {
              cache.delete(url);
            });
          }
        },
        error: (errorResponse: any) => {
          this.handleErrorMessage(errorResponse);
        },
        complete: () => {
          this.isRemovingAttachment.set(false);
        },
      });
  }

  addAttachment(accessCode: string, tenantId: string, file: File, internal: boolean) {
    const formData = new FormData();
    if (file !== null && file !== undefined) formData.append('file', new Blob([file]), file.name); // todo: this causes error but still works!
    const url =
      this.environment.apiUrl + `/api/v1/Attachment/add/${accessCode}/${tenantId}/${internal}`;

    this.isAddingAttachment.set(true);
    const req = new HttpRequest('POST', url, formData);

    this.httpService.request(req).subscribe({
      next: (details: any) => {
        this.allAttachmentsCached.set(false);
        const newAttachmentId: number = details.body;

        if (
          newAttachmentId &&
          (this.attachments() ?? []).findIndex(x => x.id == newAttachmentId) < 0
        ) {
          const newAttachmanet = {
            name: file.name,
            size: file.size,
            id: newAttachmentId,
            isInPwa: false,
            internal: internal,
          } as IAttachmentLocalModel;
          const attachmentsCopy = [
            ...((internal ? this.attachmentsInternal() : this.attachments()) ?? []),
            newAttachmanet,
          ];

          if (internal) {
            this.attachmentsInternal.set(attachmentsCopy);
          } else {
            this.attachments.set(attachmentsCopy);
          }
        }
      },
      error: (errorResponse: any) => {
        this.handleErrorMessage(errorResponse);
      },
      complete: () => {
        this.isAddingAttachment.set(false);
      },
    });
  }

  getAttachmentUrl(
    accessCode: string,
    id: number,
    tenantId: string | null,
    withBaseUrl: boolean,
  ): string {
    const url = `/api/v1/Attachment/load/${accessCode}/${id}/${tenantId}`;
    if (withBaseUrl) {
      return this.environment.apiUrl + url;
    } else {
      return url;
    }
  }

  getAttachment(accessCode: string, id: number, tenantId: string | null) {
    const url = this.getAttachmentUrl(accessCode, id, tenantId, false);
    const storeUrl = this.getAttachmentUrl(accessCode, id, tenantId, true);

    this.isLoadingAttachments.set(true);
    this.apiService
      .get<FileResponse>(url, {
        responseType: 'blob',
      })
      .subscribe({
        next: async details => {
          this.Files.set([...(this.Files() ?? []), details]);
          const cacheStorage = await caches.open(this.apiCacheName);
          const response = await cacheStorage.match(storeUrl);
          if (!response) {
            await cacheStorage.add(storeUrl);
          }
        },
        error: (errorResponse: any) => {
          this.handleErrorMessage(errorResponse);
        },
        complete: () => {
          this.isLoadingAttachments.set(false);
        },
      });
  }

  async download(
    attachment: IAttachmentLocalModel,
    accessCode: string | null,
    tenantId: string | null,
  ) {
    const cacheStorage = await caches.open(this.apiCacheName);
    const response = await cacheStorage.match(
      this.getAttachmentUrl(accessCode ?? '', attachment.id ?? 0, tenantId ?? '', true),
    );
    let blob: Blob = new Blob();
    if (response) {
      blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', attachment.name);
      document.body.appendChild(link);
      link.click();
    } else {
      this.downloadFromRemote(attachment, accessCode, tenantId);
    }
  }

  downloadFromRemote(
    attachment: IAttachmentLocalModel,
    accessCode: string | null,
    tenantId: string | null,
  ) {
    const link = document.createElement('a');
    link.download = attachment.name;
    link.href = this.getAttachmentUrl(accessCode ?? '', attachment.id ?? 0, tenantId ?? '', true);
    link.click();
    link.remove();
  }

  async checkIFAllAttachmentsCached(
    accessCode: string,
    tenantId: string | null,
    internal: boolean,
  ) {
    let allInCache = true;
    const attachmentsCopy = [
      ...((internal ? this.attachmentsInternal() : this.attachments()) ?? []),
    ];

    const cacheStorage = await caches.open(this.apiCacheName);
    for (const attachment of attachmentsCopy) {
      const response = await cacheStorage.match(
        this.getAttachmentUrl(accessCode ?? '', attachment.id ?? 0, tenantId ?? '', true),
      );
      if (!response) {
        allInCache = false;
        attachment.isInPwa = false;
      } else {
        attachment.isInPwa = true;
      }
    }
    if (internal) {
      this.allAttachmentsInternalCached.set(allInCache);
      this.attachmentsInternal.set(attachmentsCopy);
    } else {
      this.allAttachmentsCached.set(allInCache);
      this.attachments.set(attachmentsCopy);
    }
  }

  cleanCache(
    attachments: IAttachmentLocalModel[],
    accessCode: string,
    tenantId: string | null,
  ): void {
    if ('caches' in window) {
      for (const attachment of attachments) {
        const url = this.getAttachmentUrl(
          accessCode ?? '',
          attachment.id ?? 0,
          tenantId ?? '',
          true,
        );
        caches.open(this.apiCacheName).then(function (cache) {
          cache.delete(url);
        });
      }
    }
  }

  private handleErrorMessage(errorResponse: any) {
    const errors = Array.isArray(errorResponse.error)
      ? (errorResponse.error as IValidationErrorModel[])
      : [errorResponse.error];
    const msg = errors.map(error =>
      error.key
        ? this.translocoService.translate('incidentDetail.panelAttachments.invalid' + error.key)
        : error.errorMessage,
    );
    this.toastr.error(msg.length > 0 ? String(msg) : 'Error occured');
  }
}
