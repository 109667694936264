import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

import { AxpoButtonComponent } from '../../core/axpo-button/axpo-button.component';
import { AxpoFormElementComponent } from '../../core/axpo-form-element/axpo-form-element.component';
import { AxpoSearchComponent } from '../../core/axpo-search/axpo-search.component';
import {
  AxpoSelectableButtonsComponent,
  IButton,
} from '../../core/axpo-selectable-buttons/axpo-selectable-buttons.component';
import { AxpoSpinnerComponent } from '../../core/axpo-spinner/axpo-spinner.component';
import {
  AxpoTableComponent,
  IRowClick,
  ISort,
  ITableColumn,
  ITableRow,
} from '../../core/axpo-table/axpo-table.component';
import { AxpoTagComponent } from '../../core/axpo-tag/axpo-tag.component';
import { AxpoTypographyComponent } from '../../core/axpo-typography/axpo-typography.component';
import {
  AxpoBreakpoints,
  AxpoResizeService,
  axpoBreakpoints,
} from '../../core/services/axpo-resize.service';
import { SvgService } from '../../core/services/svg.service';
import { TimerComponent } from '../../shared/controls/timer/timer.component';
import { IncidentListFilterSorting } from '../../shared/models/api_models';
import { IncidentService } from '../../shared/services/incident.service';
import { LanguageService } from '../../shared/services/lang.service';
import { TenantService } from '../../shared/services/tenant.service';
import { addTimeToDate, dateToDay } from '../../shared/utils/date';
import { getFilterStateButtons } from '../../shared/utils/incident';

@Component({
  selector: 'app-home',
  imports: [
    TranslocoPipe,
    DatePipe,
    AxpoTableComponent,
    AxpoFormElementComponent,
    AxpoSearchComponent,
    AxpoTypographyComponent,
    AxpoSelectableButtonsComponent,
    AxpoButtonComponent,
    TimerComponent,
    AxpoTagComponent,
    AxpoSpinnerComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './home.component.html',
})
export class HomeComponent {
  incidentService = inject(IncidentService);
  translocoService = inject(TranslocoService);
  langService = inject(LanguageService);
  router = inject(Router);
  tenantService = inject(TenantService);
  resizeService = inject(AxpoResizeService);
  svgService = inject(SvgService);

  activeLanguage = signal<string | undefined>(undefined);
  columns = signal<ITableColumn[]>([]);
  filterFromDate = signal<string>(dateToDay(2, 'subtraction'));
  filterToDate = signal<string>(dateToDay());
  filterSearchText = signal<string>('');
  filterStates = signal([]);
  stateButtons = signal<IButton[]>([]);
  data = signal<ITableRow[]>([]);
  showMobileView = signal<boolean>(false);
  breakpoint: AxpoBreakpoints = axpoBreakpoints.LG;
  getSvg = this.svgService.svgMap;

  onInit = effect(() => {
    this.svgService.loadSvg(['refresh', 'add']);
  });

  _lang = effect(() => {
    const _activeLanguage = this.langService.getLangSignal()();
    const isDesktop = this.resizeService.width() >= this.breakpoint;
    untracked(() => {
      this.showMobileView.set(!isDesktop);
      this.columns.set(this.getTableColumns());
      this.stateButtons.update((stateButtons: IButton[]) => {
        return getFilterStateButtons(this.translocoService, stateButtons, isDesktop);
      });
    });
  });

  _data = effect(() => {
    this.langService.getLangSignal()();
    const incs = this.incidentService.incidents()?.map(i => {
      return {
        id: { value: i.accessCode?.toString() ?? '' },
        name: { value: i.incidentTitle?.toString() ?? '' },
        creation: { value: i.incidentFaultTimeStamp?.toString() ?? '' },
        updated: {
          value: i.incidentLastModificationTimeStamp?.toString() ?? '',
        },
        location: { value: i.locations?.map(l => ' ' + l.name) },
        state: {
          value: this.getStateType(i.incidentStateId ?? 0),
          tagTranslation: this.translocoService.translate(
            'home.filterState.' + i.incidentStateName,
          ),
        },
        incidentStateId: { value: i.incidentStateId },
      } as ITableRow;
    });

    untracked(() => {
      this.data.set(incs ? incs : []);
    });
  });

  _startWizard = effect(() => {
    const accCode = this.incidentService.createdAccessCode();
    if (accCode) {
      untracked(() => {
        this.incidentService.createdAccessCode.set(undefined);
        this.router.navigate(['incident/' + accCode + '/edit/' + 0]);
      });
    }
  });

  tableDataIsLoading = computed(() => {
    return this.incidentService.isLoadingIncidents() || this.tenantService.isLoadingConfig();
  });

  onActionClick = ($event: IRowClick) => {
    const incidentState = Number($event.row['incidentStateId'].value);
    const namedRoute = '/incident/' + $event.row['id'].value + '/' + this.tenantService.tenantId();
    const namedRouteWizzard = '/incident/' + $event.row['id'].value + '/edit/' + incidentState;
    const newRelativeUrl = this.router.createUrlTree([
      incidentState <= 3 ? namedRouteWizzard : namedRoute,
    ]);
    const baseUrl = window.location.origin;

    window.open(baseUrl + newRelativeUrl, '_blank');
  };

  onIncidentClick = (accessCode: string, incidentStateId: number) => {
    const namedRoute = '/incident/' + accessCode + '/' + this.tenantService.tenantId();
    const namedRouteWizzard = '/incident/' + accessCode + '/edit' + incidentStateId;
    const newRelativeUrl = this.router.createUrlTree([
      incidentStateId <= 3 ? namedRouteWizzard : namedRoute,
    ]);
    const baseUrl = window.location.origin;

    window.open(baseUrl + newRelativeUrl, '_blank');
  };

  getTableColumns = (): ITableColumn[] => {
    return [
      {
        title: this.translocoService.translate('home.table.creationDate'),
        field: 'creation',
        sortable: true,
        formatter: ['dateTime'],
      },
      {
        title: this.translocoService.translate('home.table.updated'),
        field: 'updated',
        sortable: true,
        formatter: ['dateTime'],
      },
      {
        title: this.translocoService.translate('home.table.title'),
        field: 'name',
        sortable: true,
      },
      {
        title: this.translocoService.translate('home.table.location'),
        field: 'location',
        sortable: false,
      },
      {
        title: this.translocoService.translate('home.table.state'),
        field: 'state',
        sortable: true,
        formatter: ['tag'],
        tagVariant: 'fullWidth',
      },
    ];
  };

  getStateType = (type: number) => {
    switch (type) {
      case 4: // pending
        return 'pending';
      case 6: // onHold
        return 'openDark';
      case 5: // escalated
        return 'cancelled';
      case 3: // creating step 3
        return 'open';
      case 2: // creating step 2
        return 'open';
      case 1: // creating step 1
        return 'open';
      case 7: // closed
        return 'paid';
      default:
        return 'paid';
    }
  };

  searchFilter = () => {
    this.incidentService.filter.set({
      ...this.incidentService.filter(),
      incidentFaultFrom: this.filterFromDate(),
      incidentFaultTo: addTimeToDate(this.filterToDate(), '23:59'),
      incidentStateIds: this.filterStates(),
      searchText: this.filterSearchText(),
    });
    this.incidentService.getIncidents();
  };

  stateFilter(value: any) {
    this.filterStates.set(value);
    this.searchFilter();
  }
  sortChanged(value: ISort) {
    let sortField: IncidentListFilterSorting = IncidentListFilterSorting.IncidentCategoryAsc;
    switch (value.field + value.direction) {
      case 'creationasc':
        sortField = IncidentListFilterSorting.IncidentFaultTimeStampAsc;
        break;
      case 'creationdesc':
        sortField = IncidentListFilterSorting.IncidentFaultTimeStampDesc;
        break;
      case 'updatedasc':
        sortField = IncidentListFilterSorting.IncidentLastModificationTimeStampAsc;
        break;
      case 'updateddesc':
        sortField = IncidentListFilterSorting.IncidentLastModificationTimeStampDesc;
        break;
      case 'nameasc':
        sortField = IncidentListFilterSorting.IncidentTitleAsc;
        break;
      case 'namedesc':
        sortField = IncidentListFilterSorting.IncidentTitleDesc;
        break;
      case 'locationasc':
        sortField = IncidentListFilterSorting.IncidentCategoryAsc;
        break;
      case 'locationdesc':
        sortField = IncidentListFilterSorting.IncidentCategoryDesc;
        break;
      case 'stateasc':
        sortField = IncidentListFilterSorting.IncidentStateAsc;
        break;
      case 'statedesc':
        sortField = IncidentListFilterSorting.IncidentStateDesc;
        break;
    }
    this.incidentService.filter.set({
      ...this.incidentService.filter(),
      incidentFaultFrom: this.filterFromDate(),
      incidentFaultTo: this.filterToDate(),
      incidentStateIds: this.filterStates(),
      searchText: this.filterSearchText(),
      sorting: IncidentListFilterSorting[sortField],
    });
    this.searchFilter();
  }

  refresh() {
    this.searchFilter();
  }
}
