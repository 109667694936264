import { TranslocoService } from '@jsverse/transloco';

import { IButton } from '../../core/axpo-selectable-buttons/axpo-selectable-buttons.component';
import { IKeyValueModel } from '../models/api_models';

export const getTitle = (
  translocoService: TranslocoService,
  voltages?: IKeyValueModel[],
  alertName?: string,
  impact?: IKeyValueModel,
  withSupplyFailure?: boolean
) => {
  const joinable = [];
  if (voltages) {
    const voltageString = voltages.map(vol => vol.value).join('/');
    joinable.push(voltageString);
  }
  if (alertName) {
    joinable.push(alertName);
  }

  if (impact) {
    joinable.push(translocoService.translate('options.effects.' + impact.value));
  }

  const joinableString = joinable.filter(str => str !== "").join(' - ');
  if (withSupplyFailure) {
    const translation = translocoService.translate('incidentDetail.faults');
    return joinableString + ' ' + translation.charAt(0).toLowerCase() + translation.slice(1);
  }

  return joinableString;
};

export const getFilterStateButtons = (translocoService: TranslocoService, stateButtons: IButton[], isDesktop: boolean) => {
  let buttons = [
    {
      id: 'all',
      title: translocoService.translate('home.filterState.all'),
      value: undefined,
      selected: stateButtons.length > 0 ? stateButtons[0].selected : true,
      colors: undefined,
    },
    {
      id: 'pending',
      title: translocoService.translate('home.filterState.pending'),
      value: 4,
      selected: stateButtons.length > 0 ? stateButtons[1].selected : false,
      colors: undefined,
    },
    {
      id: 'escalated',
      title: translocoService.translate('home.filterState.escalated'),
      value: 5,
      selected: stateButtons.length > 0 ? stateButtons[2].selected : false,
      colors: undefined,
    },
  ];

  if (isDesktop) {
    buttons = [
      ...buttons,
      {
        id: 'feedback',
        title: translocoService.translate('home.filterState.incidentResolutionResponse'),
        value: 6,
        selected: stateButtons.length > 3 ? stateButtons[3].selected : false,
        colors: undefined,
      },
      {
        id: 'closed',
        title: translocoService.translate('home.filterState.completed'),
        value: 7,
        selected: stateButtons.length > 3 ? stateButtons[4].selected : false,
        colors: undefined,
      },
    ];
  }

  return buttons;
}