<section class="bg-white dark:bg-gray-900">
  <div class="flex justify-center gap-8 mt-16">
    <axpo-button
      [disabled]="incidentService.createInProgress()"
      [autofocus]="true"
      (click)="incidentService.createIncident()"
    >
      {{ 'home.createIncident' | transloco }}
    </axpo-button>
  </div>
  <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
    <axpo-typography
      [variant]="'title-xl'"
      [weight]="'bold'"
      data-testid="home-heading"
      class="block mb-4"
    >
      {{ 'home.title' | transloco }}
    </axpo-typography>

    <!-- Grid -->
    <div class="container mx-auto mb-16">
      <div class="flex flex-wrap">
        <div class="flex-initial w-48 grow md:grow-0 md:mr-4">
          <axpo-form-element
            formId="creationDateFrom"
            [label]="'home.creationDateFrom' | transloco"
            formType="date"
            [(value)]="filterFromDate"
            (valueChange)="searchFilter()"
          />
        </div>
        <div class="flex-initial w-48 grow md:grow-0 md:mr-4">
          <axpo-form-element
            formId="creationDateTo"
            [label]="'home.creationDateTo' | transloco"
            formType="date"
            [(value)]="filterToDate"
            (valueChange)="searchFilter()"
          />
        </div>
        <div class="flex-initial w-48 grow md:grow-0 md:mr-4">
          <label
            for="filterSearchText"
            class="font-normal font-open-sans leading-[24px] text-[14px] tracking-[-0.14px]"
          >
            {{ 'home.searchTitle' | transloco }}
          </label>
          <axpo-search
            id="filterSearchText"
            [(searchValue)]="filterSearchText"
            (debounceValue)="searchFilter()"
            [debounceTime]="500"
            [debounce]="true"
          ></axpo-search>
        </div>
        <div class="flex-1 w-96 grow">
          <p
            class="font-normal font-open-sans leading-[24px] text-[14px] tracking-[-0.14px] ng-star-inserted"
          >
            {{ 'home.state' | transloco }}
          </p>
          <axpo-selectable-buttons
            [buttons]="stateButtons()"
            [multiselect]="true"
            (buttonClicked)="stateFilter($event)"
          ></axpo-selectable-buttons>
        </div>
      </div>

      <axpo-table
        [columns]="columns()"
        [data]="data()"
        (sortClick)="sortChanged($event)"
        [actions]="['view']"
        [isLoading]="tableDataIsLoading()"
        (rowClick)="onActionClick($event)"
        (actionClick)="onActionClick($event)"
      ></axpo-table>
    </div>
  </div>
</section>
