import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, input, model, output } from "@angular/core";

import { User } from "../../../../shared/models/api_models";

@Component({
  selector: 'app-radio',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  templateUrl: './radio-button.component.html',
  styleUrl: './radio-button.scss'
})
export class RadioButtonComponent {
  active = input.required<boolean | undefined>();
  checked = model.required<boolean | undefined>();
  selectionGroupId = input.required<string>();
  user = input.required<User>();
  selectionChanged = output<User>();

  toggleSelection() {
    this.checked.set(true);
    this.selectionChanged.emit(this.user());
  }
}