@if (label() && formType() !== 'checkbox') {
  <axpo-typography variant="body-m">{{ label() }}</axpo-typography>
}

@if (formType() === 'select' && options()) {
  <div [class]="computedStyle()" (clickOutside)="isSelectMenuOpen.set(false)">
    <button
      type="button"
      (click)="isSelectMenuOpen.set(!isSelectMenuOpen()); $event.stopPropagation()"
      tabindex="0"
      class="relative flex items-center justify-between w-full h-10 px-3 py-2 group"
      [disabled]="disabled()"
    >
      <div>
        {{ labelOfSelectValue() }}
      </div>
      @if (!disabled()) {
        <ng-container
          [ngTemplateOutlet]="clear"
          [ngTemplateOutletContext]="{ $implicit: 'right-9' }"
        />
        <ng-container [ngTemplateOutlet]="arrowdown" />
      }
    </button>

    @if (isSelectMenuOpen()) {
      <div
        @transformOpacityScale
        class="absolute left-0 z-10 w-full mt-2 overflow-y-auto bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-80"
        role="menu"
        tabindex="-1"
        dropdownPosition
      >
        <div class="py-1">
          @for (option of options(); track option.value) {
            <button
              class="block w-full px-4 py-2 text-sm text-left whitespace-nowrap hover:cursor-pointer hover:bg-background-1"
              [class]="option.value === value() ? 'bg-background-2 font-bold' : ''"
              role="menuitem"
              (click)="selectOption(option); $event.stopPropagation()"
            >
              {{ option.label }}
            </button>
          }
        </div>
      </div>
    }
  </div>
} @else if (formType() === 'multiselect') {
  <div [class]="computedStyle()" (clickOutside)="isSelectMenuOpen.set(false)">
    <button
      type="button"
      (click)="isSelectMenuOpen.set(!isSelectMenuOpen()); $event.stopPropagation()"
      tabindex="0"
      class="relative flex items-center justify-between w-full h-10 px-3 py-2 group right"
      [disabled]="disabled()"
    >
      <div class="pr-4 truncate text-ellipsis whitespace-nowrap">
        {{ labelOfSelectValue() }}
      </div>
      @if (!disabled()) {
        <ng-container
          [ngTemplateOutlet]="clear"
          [ngTemplateOutletContext]="{ $implicit: 'right-7' }"
        />
        <ng-container [ngTemplateOutlet]="arrowdown" />
      }
    </button>

    @if (isSelectMenuOpen()) {
      <div
        @transformOpacityScale
        class="absolute left-0 z-10 w-full mt-2 overflow-y-auto bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-80"
        role="menu"
        tabindex="-1"
        dropdownPosition
      >
        <div class="py-1">
          @for (option of options(); track option.value) {
            <button
              class="block w-full px-4 py-2 text-sm text-left whitespace-nowrap hover:cursor-pointer hover:bg-background-1"
              role="menuitem"
              (click)="selectOptionMultiple(option); $event.stopPropagation()"
            >
              <input
                type="checkbox"
                [checked]="value() && $any(value()).includes(option.value)"
                class="mr-0.5 w-3 h-3 rounded-sm"
              />
              {{ option.label }}
            </button>
          }
        </div>
      </div>
    }
  </div>
} @else if (formType() === 'text') {
  <div class="relative w-full group">
    <input type="text" [class]="computedStyle()" [(ngModel)]="value" [disabled]="disabled()" />
    @if (!disabled()) {
      <ng-container
        [ngTemplateOutlet]="clear"
        [ngTemplateOutletContext]="{ $implicit: 'right-4' }"
      />
    }
  </div>
} @else if (formType() === 'textarea') {
  <div class="relative w-full group">
    <textarea
      rows="5"
      [class]="computedStyle()"
      [(ngModel)]="value"
      [disabled]="disabled()"
    ></textarea>
    @if (!disabled()) {
      <ng-container
        [ngTemplateOutlet]="clear"
        [ngTemplateOutletContext]="{ $implicit: 'right-4' }"
      />
    }
  </div>
} @else if (formType() === 'file') {
  <div class="relative w-full group">
    <input
      type="file"
      [disabled]="disabled()"
      hidden
      id="fileUploadButton"
      (change)="fileAdded($event)"
    />
    <label
      for="fileUploadButton"
      [class]="computedStyle()"
      class="flex items-center px-3 hover:cursor-pointer"
      [ngClass]="{ 'justify-center': !value() }"
    >
      @if (value(); as value) {
        {{ $any(value).name }}
      } @else {
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="file/upload">
            <g id="vector">
              <path
                d="M21.5285 10.9847C21.2694 10.988 21.0603 11.19 21.0569 11.4402V19.3359C21.0569 19.7552 20.705 20.0951 20.271 20.0951H3.77931C3.34322 20.0918 2.99053 19.7511 2.98711 19.3298L2.9431 11.4341C2.9431 11.1826 2.73198 10.9786 2.47155 10.9786C2.21112 10.9786 2 11.1826 2 11.4341L2.04401 19.3298C2.05427 20.2505 2.82621 20.9935 3.77931 21H20.271C21.2259 21 22 20.2522 22 19.3298V11.4341C21.9966 11.1849 21.7864 10.9847 21.5285 10.9847Z"
                fill="#8D8D8D"
              />
              <path
                d="M12.7648 3.15678C12.6964 3.08103 12.6061 3.02729 12.5058 3.00264C12.4722 2.99912 12.4384 2.99912 12.4048 3.00264H12.3163C12.2848 2.99985 12.2531 2.99985 12.2216 3.00264C12.1207 3.02576 12.0299 3.07977 11.9627 3.15678L8.45091 7.54682C8.31003 7.7408 8.34281 8.00747 8.52671 8.1634C8.7331 8.32146 9.03124 8.28851 9.19621 8.08941L11.8742 4.80305V15.6425C11.8742 15.8979 12.0863 16.1049 12.3479 16.1049C12.6096 16.1049 12.8216 15.8979 12.8216 15.6425V4.75372L15.5312 8.13874C15.6939 8.33708 15.9901 8.37012 16.1944 8.21273C16.3832 8.06003 16.419 7.79089 16.2765 7.59615L12.7648 3.15678Z"
                fill="#8D8D8D"
              />
              <path
                d="M21.5285 10.9847C21.2694 10.988 21.0603 11.19 21.0569 11.4402V19.3359C21.0569 19.7552 20.705 20.0951 20.271 20.0951H3.77931C3.34322 20.0918 2.99053 19.7511 2.98711 19.3298L2.9431 11.4341C2.9431 11.1826 2.73198 10.9786 2.47155 10.9786C2.21112 10.9786 2 11.1826 2 11.4341L2.04401 19.3298C2.05427 20.2505 2.82621 20.9935 3.77931 21H20.271C21.2259 21 22 20.2522 22 19.3298V11.4341C21.9966 11.1849 21.7864 10.9847 21.5285 10.9847Z"
                stroke="#8D8D8D"
                stroke-width="0.2"
              />
              <path
                d="M12.7648 3.15678C12.6964 3.08103 12.6061 3.02729 12.5058 3.00264C12.4722 2.99912 12.4384 2.99912 12.4048 3.00264H12.3163C12.2848 2.99985 12.2531 2.99985 12.2216 3.00264C12.1207 3.02576 12.0299 3.07977 11.9627 3.15678L8.45091 7.54682C8.31003 7.7408 8.34281 8.00747 8.52671 8.1634C8.7331 8.32146 9.03124 8.28851 9.19621 8.08941L11.8742 4.80305V15.6425C11.8742 15.8979 12.0863 16.1049 12.3479 16.1049C12.6096 16.1049 12.8216 15.8979 12.8216 15.6425V4.75372L15.5312 8.13874C15.6939 8.33708 15.9901 8.37012 16.1944 8.21273C16.3832 8.06003 16.419 7.79089 16.2765 7.59615L12.7648 3.15678Z"
                stroke="#8D8D8D"
                stroke-width="0.2"
              />
            </g>
          </g>
        </svg>
      }
    </label>
    @if (!disabled()) {
      <ng-container
        [ngTemplateOutlet]="clear"
        [ngTemplateOutletContext]="{ $implicit: 'right-4' }"
      />
    }
  </div>
} @else if (['number', 'date'].includes(formType())) {
  <div class="relative w-full group">
    <input
      [type]="formType()"
      [class]="computedStyle()"
      [(ngModel)]="value"
      [min]="min()"
      [max]="max()"
      [disabled]="disabled()"
    />
    @if (!disabled()) {
      <ng-container
        [ngTemplateOutlet]="clear"
        [ngTemplateOutletContext]="{ $implicit: 'right-9' }"
        class="right-9"
      />
    }
  </div>
} @else if (formType() === 'checkbox') {
  <input type="checkbox" [class]="computedStyle()" [(ngModel)]="value" [disabled]="disabled()" />
  {{ label() }}
} @else if (formType() === 'time') {
  <input type="time" [class]="computedStyle()" [(ngModel)]="value" [disabled]="disabled()" />
}

<ng-template #clear let-additionalClasses>
  @if (value()) {
    <button
      class="hidden group-hover:block absolute top-1.5 text-text-secondary text-lg cursor-pointer"
      [class]="additionalClasses"
      (click)="clearSelection(); $event.stopPropagation()"
    >
      &times;
    </button>
  }
</ng-template>

<ng-template #arrowdown>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    stroke="currentColor"
    class="text-primary"
  >
    <path
      d="M11.1358 3.44783C11.1358 3.44783 6.23656 8.58726 6.02654 8.58726C5.81653 8.58726 0.864246 3.41284 0.864246 3.41284"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</ng-template>
