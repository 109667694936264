@if (displayDialog()) {
  <div
    @background
    class="relative z-10"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div class="fixed inset-0 transition-opacity bg-black bg-opacity-50"></div>

    <div class="fixed inset-0 z-10 overflow-y-auto sm:w-screen">
      <div class="flex items-end justify-center min-h-full p-4 sm:items-center sm:p-0">
        <div
          @foreground
          class="relative flex flex-col p-4 overflow-hidden transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg"
          [ngClass]="{ 'items-center': textAlign() === 'center' }"
        >
          @if (actionTaken() !== 'confirm') {
            <div class="flex justify-end w-full">
              <button (click)="takeAction('cancel')">
                <div class="w-6 h-6 text-dark-gray">
                  <div [innerHTML]="getSvg()['clear']"></div>
                </div>
              </button>
            </div>
          }
          @if (icon() === 'warning') {
            <div class="mb-4 w-11 h-11 text-danger">
              <div [innerHTML]="getSvg()['warning-circle-2']"></div>
            </div>
          }
          @if (title()) {
            <axpo-typography variant="title-m" weight="bold">
              {{ title() }}
            </axpo-typography>
          }
          <div class="w-full mt-4 mb-8" [ngClass]="{ 'text-center': textAlign() === 'center' }">
            <ng-content></ng-content>
          </div>
          <div
            [ngClass]="{
              'flex justify-end': textAlign() === 'left',
              'gap-2': textAlign() === 'left' && cancelBtnType() !== 'ghost',
            }"
          >
            @if (cancelText()) {
              <axpo-button
                (click)="takeAction('cancel')"
                [type]="cancelBtnType()"
                [style]="cancelBtnStyle()"
              >
                {{ cancelText() }}
              </axpo-button>
            }
            @if (actionTaken() === 'confirm' && processBeforeClose() && !abortSaving()) {
              <axpo-button [disabled]="true">
                <axpo-spinner size="xs">{{ loadingStateText() }}</axpo-spinner>
              </axpo-button>
            }
            @if (actionTaken() === undefined && !saveDisabled()) {
              <axpo-button (click)="takeAction('confirm')">
                {{ confirmText() }}
              </axpo-button>
            }
            @if (saveDisabled()) {
              <axpo-button [disabled]="true">
                {{ confirmText() }}
              </axpo-button>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
}
