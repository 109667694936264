<div class="w-full mt-4 mb-4 border border-border rounded" (clickOutside)="isMenuOpen.set(false)">
  <div class="flex">
    <div
      class="p-2 inline-block"
      (click)="focus()"
      (keyup)="focus()"
      tabindex="2"
      style="width: calc(100% - 2rem)"
    >
      @for (entry of selectedEntries(); track entry.id) {
        <div class="mb-2">
          <div
            class="bg-background-1 border-text-primary font-open-sans font-semibold inline-flex items-center justify-center leading-4 px-[6.5px] py-[4.5px] rounded-[4px] text-[14px] text-text-primary tracking-[0.17px]"
          >
            {{ entry.title }}
            &nbsp;
            <p class="text-gray-500">{{ entry.subtitle }}</p>
            <button class="ml-4 p-2 rounded" (click)="removeEntry(entry)">X</button>
          </div>
        </div>
      }
    </div>
  </div>
  @if (selectedEntries().length < 3) {
    <div class="relative p-1">
      <input
        type="text"
        class="w-full border-0 focus:border-0 focus:ring-0"
        #autocompleteTextbox
        [(value)]="locationSearchText"
        (keyup)="keyup()"
        autocomplete="off"
        (click)="focus()"
        [placeholder]="placeholder() ? placeholder() : ''"
      />
      <div class="absolute text-gray-500 transform -translate-y-1/2 right-3 top-1/2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g clip-path="url(#clip0_3983_5220)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.59998 6.9999C1.59998 4.01756 4.01764 1.5999 6.99998 1.5999C9.98232 1.5999 12.4 4.01756 12.4 6.9999C12.4 9.98224 9.98232 12.3999 6.99998 12.3999C4.01764 12.3999 1.59998 9.98224 1.59998 6.9999ZM6.99998 0.399902C3.3549 0.399902 0.399979 3.35482 0.399979 6.9999C0.399979 10.645 3.3549 13.5999 6.99998 13.5999C8.69334 13.5999 10.2378 12.9622 11.4062 11.9138C11.3801 12.0946 11.4366 12.2851 11.5757 12.4242L14.5757 15.4242C14.81 15.6585 15.1899 15.6585 15.4242 15.4242C15.6586 15.1899 15.6586 14.81 15.4242 14.5756L12.4242 11.5756C12.2851 11.4365 12.0947 11.38 11.9139 11.4061C12.9623 10.2377 13.6 8.69326 13.6 6.9999C13.6 3.35482 10.6451 0.399902 6.99998 0.399902Z"
              fill="#8B8D98"
            />
          </g>
          <defs>
            <clipPath id="clip0_3983_5220">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div
        class="absolute left-0 overflow-y-auto z-10 w-full p-3 bg-white border border-border rounded-[4px] shadow-lg border-t-4 border-t-primary"
        [class.h-40]="entries().length > 0"
        [class.hidden]="hideSuggestions()"
      >
        <axpo-spinner [class.hidden]="!isLoading()" size="xs">
          {{ 'core.loading' | transloco }}
        </axpo-spinner>
        @for (entry of entries(); track entry.id) {
          <button (click)="addEntry(entry)" class="py-2 text-left w-full">
            <b>{{ entry.title }}</b>
            @if (entry.subtitle) {
              <br />
              <span class="text-gray-500">{{ entry.subtitle }}</span>
            }
          </button>
        }
      </div>
    </div>
  } @else {
    <div class="m-4">
      <axpo-typography [variant]="'body-m'" class="text-text-secondary">
        {{ 'incidentDetail.panelLocation.max3locations' | transloco }}
      </axpo-typography>
    </div>
  }
</div>
