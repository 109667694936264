import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatText',
  standalone: true,
})
export class FormatTextPipe implements PipeTransform {
  transform(input: string): string {
    if (!input) return '';

    return input
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before uppercase letters
      .toLowerCase() // Convert the entire string to lowercase
      .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize the first letter of each word
  }
}
