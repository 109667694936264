import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

import { AxpoSpinnerComponent } from '../../../core/axpo-spinner/axpo-spinner.component';
import { AxpoTypographyComponent } from '../../../core/axpo-typography/axpo-typography.component';
import { IAutocompleteEntry } from '../../models/component_models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-search-result-list',
  templateUrl: './search-result-list.component.html',
  imports: [TranslocoPipe, AxpoTypographyComponent, AxpoSpinnerComponent],
})
export class SearchResultListComponent {
  translocoService = inject(TranslocoService);
  entries = input.required<IAutocompleteEntry[]>();
  isLoading = input.required<boolean>();
  isMenuOpen = input.required<boolean>();
  emptyPlaceholderText = input.required<string>();

  entryAdded = output<IAutocompleteEntry>();
  entryRemoved = output<IAutocompleteEntry>();

  entrySelected = (entry: IAutocompleteEntry) => {
    this.entryAdded.emit(entry);
  };
}
