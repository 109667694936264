import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
  model,
  output,
} from '@angular/core';
import { RouterLink } from '@angular/router';

import { AxpoLogoComponent } from './axpo-logo/axpo-logo.component';
import {
  AxpoBreadcrumbComponent,
  SeparatorType,
} from '../axpo-breadcrumb/axpo-breadcrumb.component';
import {
  AxpoProfileDropdownComponent,
  ICustomAction,
  ILanguage,
  IUser,
} from '../axpo-profile-dropdown/axpo-profile-dropdown.component';
import { AxpoTypographyComponent } from '../axpo-typography/axpo-typography.component';
import { SvgService } from '../services/svg.service';

@Component({
  standalone: true,
  selector: 'axpo-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AxpoLogoComponent,
    AxpoTypographyComponent,
    RouterLink,
    AxpoBreadcrumbComponent,
    AxpoProfileDropdownComponent,
    NgClass,
  ],
  templateUrl: './axpo-header.component.html',
  styleUrl: './axpo-header.component.css',
})
export class AxpoHeaderComponent {
  private svgService = inject(SvgService);

  title = input<string>();
  feedback = input<boolean>(false);
  feedbackClick = output<void>();
  breadcrumbDeactivated = input<boolean>(false);
  backgroundColorClass = input<string>('');

  separator = input<SeparatorType>('default');

  profileImage = input<string | undefined>(undefined);
  user = input<IUser | undefined>(undefined);
  showLogout = input<boolean>(false);
  availableLanguages = input<ILanguage[]>([]);
  selectedLanguageCode = model<string | undefined>(undefined);
  customActions = input<ICustomAction[]>([]);
  logout = output<void>();
  customAction = output<{ value: string; subValue?: string }>();
  middleTitle = input<string | undefined>(undefined);
  showUsername = input<boolean>(false);
  isOffline = input<boolean>(false);

  onInit = effect(() => {
    this.svgService.loadSvg(['feedback-pen', 'disconnected']);
  });

  getSvg = this.svgService.svgMap;

  handleFeedbackClick(event: Event) {
    event.stopPropagation();
    this.feedbackClick.emit();
  }

  onHeaderLogout = () => {
    this.logout.emit();
  };

  onCustomHeaderAction = (value: string, subValue?: string) => {
    this.customAction.emit({ value, subValue });
  };
}
