<div class="flex flex-wrap justify-between w-full my-4">
  <div class="flex flex-wrap items-center justify-around w-full mb-2 lg:justify-between lg:w-auto">
    <div class="me-3 text-text-secondary">
      {{ startRowNumber() }} - {{ endRowNumber() }} of {{ totalItems() }}
    </div>
    <div>
      {{ itemsPerPage() }}:
      <axpo-dropdown
        [title]="pageSize().toString()"
        [items]="optionsForPageSizeDropdown()"
        [preSelectedItemValue]="pageSize().toString()"
        [style]="'secondary'"
        [showSelectedValue]="true"
        (select)="changePageSize($event)"
      />
    </div>
  </div>

  <div>
    <nav class="inline-flex">
      <button
        [ngClass]="page() === 1 ? unclickablePageClasses() : clickablePageClasses()"
        (click)="changePage(page() - 1)"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="arrow-left">
            <path
              id="Vector"
              d="M10.505 2.86411C10.505 2.86411 5.3656 7.76331 5.3656 7.97333C5.3656 8.18334 10.54 13.1356 10.54 13.1356"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </g>
        </svg>
      </button>

      @for (pageNo of pagesForNavigation(); track pageNo; let index = $index) {
        <button
          (click)="changePage(pageNo)"
          [ngClass]="pageNo === page() ? activePageClasses() : clickablePageClasses()"
        >
          {{ pageNo }}
        </button>
        @if (
          index + 1 !== pagesForNavigation().length &&
          pageNo + 1 !== pagesForNavigation()[index + 1]
        ) {
          <a [class]="unclickablePageClasses()">...</a>
        }
      }
      <button
        [ngClass]="page() === totalPages() ? unclickablePageClasses() : clickablePageClasses()"
        (click)="changePage(page() + 1)"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="arrow-right">
            <path
              id="Vector"
              d="M5.53499 13.1356C5.53499 13.1356 10.6744 8.23645 10.6744 8.02643C10.6744 7.81642 5.5 2.86414 5.5 2.86414"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </g>
        </svg>
      </button>
    </nav>
  </div>
</div>
