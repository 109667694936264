import { DatePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnInit,
  computed,
  input,
  output,
  signal,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { VariantProps, cva } from 'class-variance-authority';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { twMerge } from 'tailwind-merge';

import { HandleMissingImageDirective } from '../../shared/directives/missing-images.directive';
import { FormatTextPipe } from '../../shared/pipes/format-text.pipe';
import { AxpoTagComponent } from '../axpo-tag/axpo-tag.component';

export type ITableRow = Record<
  string,
  {
    value?: string;
    imageValue?: string;
    bold_value?: string;
    link?: string;
    tagTranslation?: string;
    valueArray?: string[];
  }
>;
export type CellFormatter =
  | 'date'
  | 'image'
  | 'internal_link'
  | 'external_link'
  | 'tag'
  | 'roleTag'
  | 'dateTime'
  | 'actions';

export interface ITableColumn {
  title: string;
  field: string;
  sortable?: boolean;
  formatter?: CellFormatter[];
}

export type IActions = 'view' | 'edit' | 'delete' | 'pdf' | 'download';

export interface IActionClick {
  action: IActions;
  row: ITableRow;
  rowIndex: number;
}

export interface IRowClick {
  row: ITableRow;
  rowIndex: number;
}

export interface ISort {
  field: string;
  direction: 'asc' | 'desc';
}

export const axpoTableVariants = cva(['px-4', 'text-left', 'text-dark-gray', 'whitespace-nowrap'], {
  variants: {
    headerType: {
      filled: ['py-4', 'bg-background-1', 'border-separate'],
      ghost: ['py-2'],
    },
  },
  defaultVariants: {
    headerType: 'ghost',
  },
});
export type AxpoTableVariants = VariantProps<typeof axpoTableVariants>;

@Component({
  selector: 'axpo-table',
  standalone: true,
  imports: [
    NgClass,
    NgxSkeletonLoaderModule,
    NgTemplateOutlet,
    DatePipe,
    RouterLink,
    HandleMissingImageDirective,
    AxpoTagComponent,
    FormatTextPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './axpo-table.component.html',
  styleUrl: './axpo-table.component.css',
})
export class AxpoTableComponent implements OnInit {
  columns = input.required<ITableColumn[]>();
  data = input.required<ITableRow[]>();
  headerType = input<AxpoTableVariants['headerType']>('ghost');
  actions = input<IActions[]>([]);
  isLoading = input<boolean>(false);
  skeletonLoadingLines = input<number>(3);
  allowMobileLayout = input<boolean>(true);
  textIfEmpty = input<string>('No content found');
  onActionClick = output<IActionClick>();
  onRowClick = output<IRowClick>();
  onSortClick = output<ISort>();

  sortBy = signal<ISort | undefined>(undefined); // sort by field and direction
  innerWidth = signal<number>(0);

  MOBILE_LAYOUT_BREAKPOINT = 1024;

  headerClass = computed(() => {
    return twMerge(
      axpoTableVariants({
        headerType: this.headerType(),
      }),
    );
  });

  ngOnInit(): void {
    this.innerWidth.set(window.innerWidth);
  }

  showMobileLayout = computed(() => {
    return this.allowMobileLayout() && this.innerWidth() < this.MOBILE_LAYOUT_BREAKPOINT;
  });

  changeSort = (field: string) => {
    let sortBy = this.sortBy();
    if (!sortBy) sortBy = { field: field, direction: 'asc' };
    else if (sortBy.field === field) {
      sortBy.direction = sortBy.direction === 'asc' ? 'desc' : 'asc';
    } else {
      sortBy.field = field;
      sortBy.direction = 'asc';
    }
    this.sortBy.set({ ...sortBy });
    this.onSortClick.emit(sortBy);
  };

  formatText(input: string): string {
    return input
      .replace(/_/g, ' ')
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .toLowerCase()
      .replace(/\b\w/g, char => char.toUpperCase());
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth.set(event.target.innerWidth);
  }
}
