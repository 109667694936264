import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  input,
  model,
  output,
  untracked,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { debounceSet } from '../../shared/utils/debounce';

@Component({
  standalone: true,
  imports: [FormsModule, NgTemplateOutlet],
  selector: 'axpo-search',
  templateUrl: './axpo-search.component.html',
  styleUrl: './axpo-search.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AxpoSearchComponent {
  placeholder = input<string>('');
  debounceTime = input<number>(500);
  debounce = input<boolean>(false);
  searchValue = model<string | undefined>(undefined);
  debounceValue = output<string | undefined>();

  _debounce = effect(() => {
    const value = this.searchValue();
    untracked(() => {
      if (this.debounce()) {
        debounceSet(value, this.debounceTime(), undefined, this.debounceValue);
      } else {
        this.debounceValue.emit(value);
      }
    });
  });
}
