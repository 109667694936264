<section class="bg-white dark:bg-gray-900">
  @if (
    incidentService.isLoadingIncidentDetails() ||
    tenantService.isLoadingSettings() ||
    incidentService.updateInProgress() ||
    commentService.isUpdatingComment()
  ) {
    <div class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div class="p-8 bg-white rounded shadow-lg">
        <axpo-spinner size="normal">{{ 'core.loading' | transloco }}</axpo-spinner>
      </div>
    </div>
  }

  <div class="max-w-screen-xl px-4 py-8 mx-auto md:px-6">
    <div [ngClass]="{ 'flex flex-col md:flex-row md:justify-between': !isLoggedIn() }">
      <div>
        <axpo-typography [variant]="'title-xl'" [weight]="'bold'">
          {{ 'incidentDetail.title' | transloco }}
        </axpo-typography>
        <axpo-typography [variant]="'title-l'">
          {{ incidentTitle() }}
        </axpo-typography>
      </div>
      @if (!isLoggedIn()) {
        <div class="w-full mt-4 md:w-1/4 md:mt-0">
          <axpo-button [autofocus]="true" (click)="openShareModal()">
            <i class="text-xl fas fa-share-nodes white" aria-hidden="true"></i>
            <span class="ml-4 whitespace-nowrap w-24 md:w-auto">
              {{ 'incidentDetail.share' | transloco }}
            </span>
          </axpo-button>
        </div>
      }
    </div>
    @if (isLoggedIn()) {
      <div class="mt-8 flex flex-col md:flex-row justify-center md:justify-start gap-2 lg:gap-16">
        <div class="flex justify-center flex-1 lg:m-3">
          <div class="w-full">
            <axpo-button [autofocus]="true" (click)="openShareModal()">
              <i class="text-xl fas fa-share-nodes white" aria-hidden="true"></i>
              <span class="ml-4 whitespace-nowrap w-24 md:w-auto">
                {{ 'incidentDetail.share' | transloco }}
              </span>
            </axpo-button>
          </div>
        </div>

        <div class="flex justify-center flex-1 lg:m-3">
          <div class="w-full">
            <axpo-button [autofocus]="true" (click)="openIncidentDialog()">
              <i class="text-xl fas fa-copy white" aria-hidden="true"></i>
              <span class="ml-4 whitespace-nowrap w-24 md:w-auto">
                {{ 'incidentDetail.duplicate' | transloco }}
              </span>
            </axpo-button>
          </div>
        </div>

        <div class="flex justify-center flex-1 lg:m-3">
          <div class="w-full">
            <axpo-button [autofocus]="true" (click)="onDeleteIncident()">
              <i class="text-xl fas fa-trash white" aria-hidden="true"></i>
              <span class="ml-4 whitespace-nowrap w-24 md:w-auto">
                {{ 'incidentDetail.deleteIncident' | transloco }}
              </span>
            </axpo-button>
          </div>
        </div>

        @if (isCompleted()) {
          <div class="flex justify-center flex-1 lg:m-3">
            <div class="w-full">
              <axpo-button [autofocus]="true" (click)="confirmReopen()">
                <i class="text-xl fas fa-unlock white" aria-hidden="true"></i>
                <span class="ml-4 whitespace-nowrap w-24 md:w-auto">
                  {{ 'incidentDetail.reopen' | transloco }}
                </span>
              </axpo-button>
            </div>
          </div>
        }
      </div>
    }
    <div class="flex flex-col justify-center gap-8 mt-8 mb-8 lg:flex-row">
      <!-- Left Row -->
      <div class="flex-1 space-y-4">
        @for (key of beforeMedian(); track key) {
          @if (tiles.visible[key] && shouldRenderTile(key)) {
            <ng-container
              [ngTemplateOutlet]="tilesLogic"
              [ngTemplateOutletContext]="{ key: key }"
            ></ng-container>
          }
        }
      </div>

      <!-- Right Row -->
      <div class="flex-1 space-y-4">
        @for (key of afterMedian(); track key) {
          @if (tiles.visible[key] && shouldRenderTile(key)) {
            <ng-container
              [ngTemplateOutlet]="tilesLogic"
              [ngTemplateOutletContext]="{ key: key }"
            ></ng-container>
          }
        }
      </div>
    </div>
  </div>

  @if (duplicateIncident()) {
    <axpo-dialog
      [title]="'incidentDetail.duplicateIncidentDialog.text' | transloco"
      [confirmText]="'core.confirm' | transloco"
      [cancelText]="'core.cancel' | transloco"
      [processBeforeClose]="true"
      [loadingStateText]="'core.loading' | transloco"
      [dataLoaded]="incidentCloned()"
      (takenAction)="duplicateIncidentDialogAction($event)"
      icon="warning"
      textAlign="left"
    ></axpo-dialog>
  }
  @if (shareLink()) {
    <app-share-link [(shareLink)]="shareLink"></app-share-link>
  }

  @if (changeToPending()) {
    <axpo-dialog
      [title]="'incidentDetail.changeToPending' | transloco"
      [confirmText]="'core.confirm' | transloco"
      [cancelText]="'core.cancel' | transloco"
      [processBeforeClose]="true"
      [loadingStateText]="'core.loading' | transloco"
      [dataLoaded]="incidentService.reOpeningDone()"
      (takenAction)="reopenIncident($event)"
      icon="warning"
      textAlign="left"
    ></axpo-dialog>
  }

  @if (deleteIncident()) {
    <axpo-dialog
      [title]="'incidentDetail.deleteIncident' | transloco"
      [confirmText]="'core.confirm' | transloco"
      [cancelText]="'core.cancel' | transloco"
      [processBeforeClose]="true"
      [loadingStateText]="'core.loading' | transloco"
      [dataLoaded]="incidentService.deletingDone()"
      (takenAction)="afterIncidentDeleted($event)"
      icon="warning"
      textAlign="left"
    >
      {{ 'incidentDetail.deleteIncidentText' | transloco }}
    </axpo-dialog>
  }
</section>
<ng-template #tilesLogic let-key="key">
  @if (key === 'comments') {
    <app-comments
      [mode]="tiles.visible[key].mode()"
      [title]="'incidentDetail.comments' | transloco"
      [type]="'public'"
    ></app-comments>

    @if (isLoggedIn() && !isCompleted() && tiles.visible[key]) {
      <app-comments
        [mode]="tiles.visible[key].mode()"
        title="{{ 'incidentDetail.internalComments' | transloco }}"
        type="private"
      ></app-comments>
    }
  } @else {
    <app-tile-component
      [(mode)]="tiles.visible[key].mode"
      [title]="'incidentDetail.' + key | transloco"
      [controlButtons]="tileHasButtons(key) ? ['confirm', 'cancel'] : []"
      (takenAction)="updateAction($event, key)"
      [disabled]="tiles.visible[key].disabled()"
      [bgClass]="key === 'attachmentsInternal' ? 'bg-border-soft' : undefined"
    >
      @if (key === 'details') {
        @if (tiles.visible[key].mode() === 'edit') {
          <app-edit-details
            [mode]="tiles.visible[key].mode()"
            (saveCompleted)="saveCompleted(key)"
            (validationError)="validationChanged($event, key)"
          ></app-edit-details>
        } @else {
          <app-view-details></app-view-details>
        }
      }

      @if (key === 'contacts') {
        <app-contacts
          [mode]="tiles.visible[key].mode()"
          [title]="'incidentDetail.contacts' | transloco"
        ></app-contacts>
      }
      @if (key === 'incidentResolutionResponse') {
        <app-incident-edit-incident-resolution
          [mode]="tiles.visible[key].mode()"
          (saveCompleted)="saveCompleted(key)"
        ></app-incident-edit-incident-resolution>
      }
      @if (key === 'location') {
        <app-location
          [mode]="tiles.visible[key].mode()"
          [title]="'incidentDetail.location' | transloco"
          (validationError)="validationChanged($event, key)"
        ></app-location>
      }
      @if (key === 'alarm') {
        <app-alert
          [mode]="tiles.visible[key].mode()"
          (saveCompleted)="saveCompleted(key)"
          (validationError)="validationChanged($event, key)"
        ></app-alert>
      }
      @if (key === 'attachments' || key === 'attachmentsInternal') {
        @if (tiles.visible[key].mode() === 'edit') {
          <app-edit-attachments
            [internalAttachments]="key === 'attachmentsInternal'"
          ></app-edit-attachments>
        } @else {
          <app-view-attachments
            [internalAttachments]="key === 'attachmentsInternal'"
          ></app-view-attachments>
        }
      }
    </app-tile-component>
  }
</ng-template>
