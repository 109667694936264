import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { MailSearchDropdownComponent } from './mail-search-dropdown/mail-search-dropdown.component';
import { AxpoButtonComponent } from '../../core/axpo-button/axpo-button.component';
import { AxpoFormElementComponent } from '../../core/axpo-form-element/axpo-form-element.component';
import { AxpoSelectableButtonsComponent } from '../../core/axpo-selectable-buttons/axpo-selectable-buttons.component';
import { AxpoTypographyComponent } from '../../core/axpo-typography/axpo-typography.component';
import { IContactModel, ITileModel } from '../../shared/models/api_models';
import { DetailsService } from '../../shared/services/details.service';
import { TenantService } from '../../shared/services/tenant.service';
import { SEARCH_CONTACTS_DEFAULT } from '../../shared/utils/constants';
import { isEmail } from '../../shared/utils/customValidations';

@Component({
  standalone: true,
  selector: 'app-admin',
  imports: [
    TranslocoPipe,
    AxpoTypographyComponent,
    AxpoFormElementComponent,
    AxpoSelectableButtonsComponent,
    AxpoButtonComponent,
    MailSearchDropdownComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './admin.component.html',
})
export class AdminComponent {
  //ToDo until initial data is loaded display a global spinner
  disabledTileNames = ['details', 'location', 'alarm', 'contacts'];

  translocoService = inject(TranslocoService);
  tenantService = inject(TenantService);
  detailsService = inject(DetailsService);
  private toastr = inject(ToastrService);
  private location = inject(Location);

  selectedTenant = signal<string>('');
  appName = signal<string>('');
  contactName = signal<string>('');
  contactEmail = signal<string>('');
  tileModels = signal<ITileModel[]>([]);
  searchContactValue = signal<string>('');
  searchContactResults = signal<IContactModel[]>([]);
  selectedContact = signal<IContactModel | undefined | null>(undefined);
  selectedSupplyFailureContact = signal<IContactModel | undefined | null>(undefined);
  searchDropdownVisible = signal<boolean>(false);

  private _checkRequiredAndLength(
    value: string | undefined,
    maxLength: number,
    formatFunc?: (value: string) => boolean,
  ) {
    if (!this.tenantService.tenantSettings()) return { hasError: false, errorMessage: '' };

    if (value && value.length > maxLength)
      return {
        hasError: true,
        errorMessage: this.translocoService.translate(
          this.translocoService.translate('admin.maxLengthError', { value: maxLength }),
        ),
      };

    if (formatFunc && value && !formatFunc(value))
      return {
        hasError: true,
        errorMessage: this.translocoService.translate('admin.invalidFormatError'),
      };

    return { hasError: false, errorMessage: '' };
  }

  appNameValidation = computed(() => {
    return this._checkRequiredAndLength(this.appName(), 50);
  });

  contactNameValidation = computed(() => {
    return this._checkRequiredAndLength(this.contactName(), 100);
  });

  contactEmailValidation = computed(() => {
    return this._checkRequiredAndLength(this.contactEmail(), 100, isEmail);
  });

  isFormValid = computed(() => {
    return !(
      this.appNameValidation().hasError ||
      this.contactNameValidation().hasError ||
      this.contactEmailValidation().hasError
    );
  });

  tenants = computed(() => {
    return this.tenantService.tenants().map(tenant => ({ value: tenant.key, label: tenant.value }));
  });

  _onInit = effect(() => {
    untracked(() => {
      this.tenantService.getTenants();
    });
  });

  _selectDefaultTenant = effect(() => {
    const tenants = this.tenants();
    untracked(() => {
      if (tenants.length > 0) {
        this.selectedTenant.set(tenants[0].value);
      }
    });
  });

  _getSettingsEffect = effect(() => {
    const selectedTenant = this.selectedTenant();
    untracked(() => {
      if (selectedTenant) {
        this.tenantService.getSettings(selectedTenant);
      }
    });
  });

  _setSettingsEffect = effect(() => {
    const settings = this.tenantService.tenantSettings();
    untracked(() => {
      if (!settings) return;

      this.appName.set(settings.applicationName || '');
      this.contactName.set(settings.contact?.name || '');
      this.contactEmail.set(settings.contact?.email || '');
      this.tileModels.set(settings.tileModels || []);
      this.selectedContact.set(settings.tenantIncidentMailRecipientContact);
      this.selectedSupplyFailureContact.set(settings.tenantSupplyFailureMailRecipientContact);
    });
  });

  searchContactsFilter = (value: string) => {
    this.detailsService.searchContactTemplates(value || SEARCH_CONTACTS_DEFAULT);
  };

  onTileSettingClicked(value: (string | number)[], id: string | null | undefined) {
    this.tileModels.set(
      this.tileModels().map(tile =>
        tile.name === id ? { ...tile, isVisible: Number(value[0]) - 1 } : tile,
      ),
    );
  }

  onSetContact(contact: IContactModel | undefined) {
    this.selectedContact.set(contact);
  }

  onSetSupplyFailureContact(contact: IContactModel | undefined) {
    this.selectedSupplyFailureContact.set(contact);
  }

  onBackClicked() {
    this.location.back();
  }

  onSubmitClicked() {
    // ToDo After block the button and show a spinner
    if (!this.isFormValid()) {
      this.toastr.error(this.translocoService.translate('admin.invalidForm'));
      return;
    }

    this.tenantService.updateSettings(this.selectedTenant(), {
      tenantId: this.selectedTenant(),
      tenantName: this.appName(),
      applicationName: this.appName(),
      contact: { name: this.contactName(), email: this.contactEmail() },
      tenantIncidentMailRecipientContact: this.createContact(this.selectedContact()),
      tenantSupplyFailureMailRecipientContact: this.createContact(
        this.selectedSupplyFailureContact(),
      ),
      tileModels: this.tileModels().map(tile => ({
        name: tile.originalName,
        isVisible: tile.isVisible,
      })),
    });
  }

  createContact(currentContact: IContactModel | null | undefined): IContactModel | null {
    const contact = currentContact
      ? {
          designation: currentContact.designation,
          id: currentContact.id,
          mail: currentContact.mail,
          members: currentContact.members,
          name: currentContact.name,
          phone: currentContact.phone,
        }
      : null;

    return contact;
  }

  isSettingDisabled(name: string | null | undefined) {
    if (!name) return false;
    return this.disabledTileNames.includes(name.toLocaleLowerCase());
  }
}
