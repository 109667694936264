<axpo-typography [variant]="'body-m'">
  <footer
    class="flex flex-wrap items-center justify-center px-5 py-2 mt-4 space-x-3 text-center border-t"
    [ngClass]="backgroundColorClass()"
  >
    <a href="https://axpo.com" target="_blank">&copy; {{ currentYear }} Axpo Services AG</a>
    @for (footerLink of footerLinks(); track footerLink.text) {
      @if (footerLink.link && footerLink.target === 'external_link') {
        <a [href]="footerLink.link" target="_blank" class="hover:text-primary">
          {{ footerLink.text }}
        </a>
      }
      @if (footerLink.link && footerLink.target === 'internal_link') {
        <a [routerLink]="footerLink.link" class="hover:text-primary">{{ footerLink.text }}</a>
      }
      @if (!footerLink.link) {
        <div>{{ footerLink.text }}</div>
      }
    }

    <span>Version: {{ version() }}</span>
  </footer>
</axpo-typography>
