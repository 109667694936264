<section class="bg-white dark:bg-gray-900">
  <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
    <axpo-typography [variant]="'H4'">
      {{ 'import-contact.title' | transloco }}
    </axpo-typography>

    <axpo-stepper
      [steps]="steps()"
      [activeStep]="activeStep()"
      (activeStepChange)="this.activeStep.set($event)"
    ></axpo-stepper>

    @if (this.activeStep() === 0) {
      <app-filedrop
        [multiple]="false"
        [acceptCamera]="false"
        [accept]="'.xlsx, xls'"
        (filesUploaded)="filesUploaded($event)"
      >
        @if (file()) {
          <div class="bg-background-1 flex p-3 mb-2">
            <div class="mr-3">
              <i class="fas fa-check text-ima-green"></i>
            </div>
            <div class="grow">
              {{ file()?.name }}
            </div>
            <div><button (click)="remove()">X</button></div>
          </div>
        }
      </app-filedrop>
    }

    @if (this.activeStep() === 1) {
      @if (importContactService.isUploadingContactImportFile()) {
        <div class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div class="p-8 bg-white rounded shadow-lg">
            <axpo-spinner size="normal">{{ 'core.loading' | transloco }}</axpo-spinner>
          </div>
        </div>
      }

      <div class="bg-yellow-100 p-4 rounded mb-4">
        <div class="flex">
          <span class="w-full m-auto">
            {{ amountOfWarnings() }} {{ 'import-contact.warnings' | transloco }}
          </span>
          <axpo-button
            (click)="showWarnings.set(!showWarnings())"
            [type]="'ghost'"
            [style]="'secondary'"
          >
            <i [class]="showWarnings() ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
          </axpo-button>
        </div>
        @if (showWarnings()) {
          <div>
            @for (item of validationWarnings(); track item) {
              <p>{{ item }}</p>
            }
          </div>
        }
      </div>

      <div class="bg-red-300 p-4 rounded">
        <div class="flex">
          <span class="w-full m-auto">
            {{ amountOfErrors() }} {{ 'import-contact.errors' | transloco }}
          </span>

          <axpo-button
            (click)="showErrors.set(!showErrors())"
            [type]="'ghost'"
            [style]="'secondary'"
          >
            <i [class]="showErrors() ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
          </axpo-button>
        </div>
        @if (showErrors()) {
          <div>
            @for (item of validationErrors(); track item) {
              <p>{{ item }}</p>
            }
          </div>
        }
      </div>
    }

    @if (this.activeStep() === 2) {
      @if (importContactService.isUploadingContactImportFile()) {
        <axpo-spinner size="normal">{{ 'core.loading' | transloco }}</axpo-spinner>
      }
      @if (!importContactService.isUploadingContactImportFile()) {
        <axpo-typography variant="title-m">
          {{ 'import-contact.successfullUpload' | transloco }}
        </axpo-typography>
      }
    }

    <div class="flex py-8 space-x-0 justify-evenly sm:space-x-8 sm:justify-end">
      @if (this.activeStep() > 0) {
        <axpo-button (click)="onBackClicked()" [type]="'outlined'" [style]="'secondary'">
          {{ 'import-contact.back' | transloco }}
        </axpo-button>
      }
      <axpo-button (click)="onSubmitClicked()" [style]="'primary'" [disabled]="!isFormValid()">
        @if (this.activeStep() === 0) {
          {{ 'import-contact.continue' | transloco }}
        }
        @if (this.activeStep() > 0) {
          {{ 'import-contact.save' | transloco }}
        }
      </axpo-button>
    </div>
  </div>
</section>
