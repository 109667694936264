<div class="relative inline-block text-left" (clickOutside)="isMenuOpen.set(false)">
  <div>
    <button
      type="button"
      [class]="computedClass()"
      (click)="isMenuOpen.set(!isMenuOpen()); $event.stopPropagation()"
      [disabled]="disabled()"
      tabindex="0"
    >
      @if (menuText(); as menuText) {
        {{ menuText }}
        @if (isMenuOpen()) {
          <div class="w-4 h-4"><div [innerHTML]="getSvg()['chevron-up']"></div></div>
        } @else {
          <div class="w-4 h-4"><div [innerHTML]="getSvg()['chevron-down']"></div></div>
        }
      } @else {
        <div class="w-5 h-5"><div [innerHTML]="getSvg()['horizontal-points']"></div></div>
      }
    </button>
  </div>

  @if (isMenuOpen()) {
    <div
      @transformOpacityScale
      class="absolute left-0 z-10 mt-2 overflow-y-auto bg-white rounded-md shadow-lg min-w-32 ring-1 ring-black ring-opacity-5 focus:outline-none max-h-80"
      [ngClass]="{ hideSrollbar: !showScrollbar() }"
      role="menu"
      tabindex="-1"
      dropdownPosition
    >
      <div class="py-1">
        @for (item of items(); track item.value) {
          <button
            class="block w-full px-4 py-2 text-sm text-left whitespace-nowrap hover:cursor-pointer hover:bg-background-1"
            [class]="item.value === selectedItemValue() ? 'bg-background-2 font-bold' : ''"
            role="menuitem"
            (click)="selectValue(item.value); $event.stopPropagation()"
          >
            {{ item.label }}
          </button>
        }
      </div>
    </div>
  }
</div>
