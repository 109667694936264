@if (addNewContactDialog()) {
  <axpo-dialog
    title="{{ getContactDialogTitle() | transloco }}"
    cancelText="{{ 'core.cancel' | transloco }}"
    confirmText="{{ 'core.save' | transloco }}"
    textAlign="left"
    [processBeforeClose]="true"
    [loadingStateText]="'core.loading' | transloco"
    [dataLoaded]="newContactProcessed()"
    (takenAction)="contactDialogClosed($event, true)"
    [saveDisabled]="!formManager?.isValid && !!formManager?.showErrorOnLoad()"
    [abortSaving]="abortSaving()"
  >
    <div class="flex flex-col space-y-4">
      <axpo-form-element
        formId="designation"
        label="{{ 'incidentDetail.contactsData.designation' | transloco }}"
        formType="text"
        [(value)]="newContactDesignation"
        [min]="5"
        [max]="100"
        (errorEmitter)="formValidation($event, 'designation')"
        [errorMessageInput]="this.formManager?.getField('designation')?.errorMessage()"
        [required]="true"
      ></axpo-form-element>
      <axpo-form-element
        formId="name"
        label="{{ 'incidentDetail.contactsData.name' | transloco }}"
        formType="text"
        [(value)]="newContactName"
        [min]="5"
        [max]="100"
        (errorEmitter)="formValidation($event, 'name')"
        [errorMessageInput]="this.formManager?.getField('name')?.errorMessage()"
        [required]="true"
      ></axpo-form-element>
      <axpo-form-element
        formId="phone"
        label="{{ 'incidentDetail.contactsData.phone' | transloco }}"
        formType="tel"
        pattern="\+\d{5,18}"
        [(value)]="newContactPhone"
        [min]="1"
        [max]="18"
        (errorEmitter)="formValidation($event, 'phone')"
        [errorMessageInput]="this.formManager?.getField('phone')?.errorMessage()"
        [required]="true"
      ></axpo-form-element>
      <axpo-form-element
        formId="mail"
        label="{{ 'incidentDetail.contactsData.mail' | transloco }}"
        formType="email"
        [(value)]="newContactMail"
        [pattern]="'[a-zA-Z0-9_%+-]+\.[a-zA-Z0-9_%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}'"
        (errorEmitter)="formValidation($event, 'mail')"
        [errorMessageInput]="this.formManager?.getField('mail')?.errorMessage()"
        [required]="true"
      ></axpo-form-element>
      <axpo-form-element
        formId="isExecutor"
        label="{{ 'incidentDetail.shareLinkDialog.executing' | transloco }}"
        formType="checkbox"
        [(value)]="newContactIsExecuting"
      ></axpo-form-element>
    </div>
  </axpo-dialog>
}
<div class="flex flex-col pt-4 space-y-4">
  <div class="flex space-x-4">
    <div class="flex space-x-4">
      <div class="w-6 h-6 text-primary" [innerHTML]="getSvg()['info-circle']"></div>
      <axpo-typography>
        {{ 'core.personToInform' | transloco }}
      </axpo-typography>
    </div>
    <div class="flex space-x-4">
      <div class="w-6 h-6 text-primary" [innerHTML]="getSvg()['helmet']"></div>
      <axpo-typography>
        {{ 'core.executingPerson' | transloco }}
      </axpo-typography>
    </div>
    @if (mode() !== 'create') {
      <div class="hidden space-x-4 sm:flex">
        <div class="w-6 h-6 text-primary" [innerHTML]="getSvg()['check']"></div>
        <axpo-typography>
          {{ 'core.wakeUpCallSuccessful' | transloco }}
        </axpo-typography>
      </div>
    }
  </div>
  @if (noContacts() && mode() !== 'edit') {
    <axpo-typography [variant]="'body-l'">
      {{ 'incidentDetail.noContacts' | transloco }}
    </axpo-typography>
  }
  @for (contact of contacts(); track contact.id) {
    <div class="flex items-center justify-between pb-4 border-b-2 border-border-soft-300">
      <div class="flex flex-none w-16">
        @if (contact.isExecutor === true) {
          <axpo-button
            (click)="toggleExecutor(contact)"
            class="float-right"
            variant="svg"
            [type]="'ghost'"
            [style]="'secondary'"
            [disabled]="isBlocked()"
          >
            <div class="w-6 h-6 text-primary" [innerHTML]="getSvg()['helmet']"></div>
          </axpo-button>
        } @else {
          <axpo-button
            (click)="toggleExecutor(contact)"
            class="float-right"
            variant="svg"
            [type]="'ghost'"
            [style]="'secondary'"
            [disabled]="isBlocked()"
          >
            <div class="w-6 h-6 text-primary" [innerHTML]="getSvg()['info-circle']"></div>
          </axpo-button>
        }
        <div class="w-[24px]">
          @if (contact.callNotificationStateId === 1) {
            <div
              class="w-7 h-7 text-primary relative top-3 left-1"
              [innerHTML]="getSvg()['phone']"
            ></div>
            <div
              class="w-5 h-5 text-primary relative left-5 top-[-18px]"
              [innerHTML]="getSvg()['horizontal-points']"
            ></div>
          }
          @if (contact.callNotificationStateId === 2) {
            <div
              class="w-7 h-7 text-primary relative top-3 left-1"
              [innerHTML]="getSvg()['phone']"
            ></div>
            <div
              class="w-5 h-5 text-primary relative left-4 top-[-18px]"
              [innerHTML]="getSvg()['play-outline']"
            ></div>
          }
          @if (contact.callNotificationStateId === 3) {
            <div
              class="w-7 h-7 text-primary relative top-3 left-1"
              [innerHTML]="getSvg()['phone']"
            ></div>
            <div
              class="w-4 h-4 text-tc-green relative left-5 top-[-16px]"
              [innerHTML]="getSvg()['check']"
            ></div>
          }
          @if (contact.callNotificationStateId === 4) {
            <div
              class="w-7 h-7 text-primary relative top-3 left-1"
              [innerHTML]="getSvg()['phone']"
            ></div>
            <div
              class="w-4 h-4 text-primary relative left-4 top-[-16px]"
              [innerHTML]="getSvg()['warning']"
            ></div>
          }
        </div>
      </div>

      <div class="flex-1 md:ml-8">
        <axpo-typography variant="body-s">
          <span class="text-text-secondary">{{ contact.designation }}</span>
        </axpo-typography>
        <axpo-typography variant="body-m">
          {{ contact.name }}
        </axpo-typography>
        <axpo-typography variant="body-s">
          @if (contact.phone) {
            <span class="text-text-secondary">{{ contact.phone }}</span>
            |
          }
          <span class="text-text-secondary">{{ contact.mail }}</span>
        </axpo-typography>
      </div>

      <div class="flex flex-none space-x-4">
        @if (isBlocked()) {
          @if (contact.phone) {
            <a href="tel:{{ contact.phone }}">
              <div class="w-6 h-6 text-text-secondary" [innerHTML]="getSvg()['phone']"></div>
            </a>
          }
          @if (contact.mail) {
            <a href="mailto:{{ contact.mail }}">
              <div class="w-6 h-6 text-text-secondary" [innerHTML]="getSvg()['mail']"></div>
            </a>
          }
        } @else {
          <div
            role="button"
            (click)="addNewContact(contact)"
            tabindex="0"
            aria-hidden="true"
            class="w-8"
          >
            <div class="w-6 h-6 text-text-secondary" [innerHTML]="getSvg()['pen']"></div>
          </div>
          <div
            role="button"
            (click)="deleteContact(contact)"
            tabindex="0"
            aria-hidden="true"
            class="w-8"
          >
            <div class="w-6 h-6 text-text-secondary" [innerHTML]="getSvg()['delete']"></div>
          </div>
        }
      </div>
    </div>
  }

  @if (!isBlocked()) {
    <div>
      <div class="pb-4">
        <app-location-autocomplete
          [entries]="searchQueryResult()"
          [selectedEntries]="selectedSearchResults()"
          [isLoading]="detailsService.isLoadingSearch()"
          placeholder="{{ 'incidentDetail.contactsData.addExistingContact' | transloco }}"
          (debounceValue)="searchValueChanged($event)"
          (entryAdded)="searchEntrySelected($event)"
          [isLocations]="false"
        ></app-location-autocomplete>
      </div>
      <axpo-button
        [ngClass]="{ 'float-right': isDesktop() }"
        (click)="addNewContact(null)"
        [type]="'outlined'"
        [style]="'secondary'"
      >
        <div class="w-6 h-6 text-primary" [innerHTML]="getSvg()['user-add-filled']"></div>
        <axpo-typography class="ml-2">
          {{ 'incidentDetail.contactsData.addContact' | transloco }}...
        </axpo-typography>
      </axpo-button>
    </div>
    @if (contactService.validationMessages().length > 0) {
      <div class="p-4 bg-yellow-100">
        @for (message of contactService.validationMessages(); track message) {
          {{ contactService.TransformValidationMessagesToGenreicStrings(message) }}
        }
      </div>
    }
  }
</div>
@if (isLoading()) {
  <div class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div class="p-8 bg-white rounded shadow-lg">
      <axpo-spinner size="normal">{{ 'core.loading' | transloco }}</axpo-spinner>
    </div>
  </div>
}
