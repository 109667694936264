import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, model } from '@angular/core';
import { VariantProps, cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import { AxpoDropdownComponent } from '../axpo-dropdown/axpo-dropdown.component';

export const axpoTableNavigationVariants = cva(
  [
    'relative',
    'inline-flex',
    'items-center',
    'p-1',
    'm-1',
    'border-b-2',
    'text-sm',
    'border-transparent',
    'justify-center',
  ],
  {
    variants: {
      clickablePageNumber: {
        default: ['hover:cursor-pointer', 'hover:text-text-link'],
        circled: ['w-8', 'h-8', 'hover:cursor-pointer', 'hover:text-text-link'],
      },
      unclickablePageNumber: {
        default: [],
        circled: [],
      },
      activePageNumber: {
        default: ['text-text-link', 'border-primary'],
        circled: ['w-8', 'h-8', 'rounded-full', 'bg-primary', 'text-white'],
      },
    },
  },
);
export type AxpoTableNavigationVariants = VariantProps<typeof axpoTableNavigationVariants>;

@Component({
  selector: 'axpo-tablenavigation',
  standalone: true,
  imports: [NgClass, AxpoDropdownComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './axpo-tablenavigation.component.html',
  styleUrl: './axpo-tablenavigation.component.css',
})
export class AxpoTableNavigationComponent {
  style = input<AxpoTableNavigationVariants['activePageNumber']>('default');
  totalItems = input.required<number>();
  pageSize = model.required<number>();
  pageSizeOptions = input<number[]>([10, 25, 50]);
  page = model.required<number>();
  totalPages = computed(() => Math.ceil(this.totalItems() / this.pageSize()));
  itemsPerPage = input<string>('Items per page');

  optionsForPageSizeDropdown = computed(() => {
    return this.pageSizeOptions().map(option => {
      return { value: option.toString(), label: option.toString() };
    });
  });

  pagesForNavigation = computed(() => {
    let pages: number[];
    if (this.page() <= 4) {
      pages = [1, 2, 3, 4, 5, this.totalPages()];
    } else if (this.page() >= this.totalPages() - 3) {
      pages = [
        1,
        this.totalPages() - 4,
        this.totalPages() - 3,
        this.totalPages() - 2,
        this.totalPages() - 1,
        this.totalPages(),
      ];
    } else {
      pages = [1, this.page() - 1, this.page(), this.page() + 1, this.totalPages()];
    }
    return [...new Set(pages.filter(page => page > 0 && page <= this.totalPages()))];
  });

  startRowNumber = computed(() =>
    Math.min((this.page() - 1) * this.pageSize() + 1, this.totalItems()),
  );
  endRowNumber = computed(() => Math.min(this.page() * this.pageSize(), this.totalItems()));

  clickablePageClasses = computed(() => {
    return twMerge(
      axpoTableNavigationVariants({
        clickablePageNumber: this.style(),
      }),
    );
  });

  unclickablePageClasses = computed(() => {
    return twMerge(
      axpoTableNavigationVariants({
        unclickablePageNumber: this.style(),
      }),
    );
  });

  activePageClasses = computed(() => {
    return twMerge(
      axpoTableNavigationVariants({
        activePageNumber: this.style(),
      }),
    );
  });

  changePage = (newPage: number) => {
    if (newPage < 1 || newPage > this.pagesForNavigation()[this.pagesForNavigation().length - 1])
      return;
    this.page.set(newPage);
  };

  changePageSize = (newPageSize: number | string) => {
    newPageSize = Number(newPageSize);
    if ((this.page() - 1) * newPageSize + 1 > this.totalItems()) {
      this.page.set(Math.ceil(this.totalItems() / newPageSize));
    }
    this.pageSize.set(newPageSize);
  };
}
