import { LogLevel } from 'angular-auth-oidc-client';

import { IEnvironment } from './environment-interface';

export const environment: IEnvironment = {
  stage: 'DEV',
  apiUrl: 'https://axe-nonprod-appl-ia-as.azurewebsites.net',
  azureMapsKey: 'PzYLj3L7rQpwhU7PI_g9IzcWtABMfcl5HHP1ZUhepVQ',
  auth: {
    passwordlessRoutes: [],
    config: [
      {
        configId: 'keycloak',
        authority: 'https://accounts.withaxpo.com/auth/realms/AXE-IMA-NONPROD',
        redirectUrl: window.location.origin + '/login',
        postLogoutRedirectUri: window.location.origin,
        clientId: 'ima',
        scope: 'openid profile email offline_access',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        ignoreNonceAfterRefresh: true,
        triggerAuthorizationResultEvent: true,
        renewTimeBeforeTokenExpiresInSeconds: 10,
        secureRoutes: ['https://axe-nonprod-appl-ia-as.azurewebsites.net'],
        logLevel: LogLevel.Error,
      },
    ],
  },
};
