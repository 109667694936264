<div class="w-full flex-2-col min-h-40">
  @if (mode() !== 'edit' && mode() !== 'create') {
    <div class="w-full">
      @if (locations().length > 0) {
        <label
          for="filterSearchText"
          class="font-normal font-open-sans leading-[24px] text-[14px] tracking-[-0.14px] text-text-secondary"
        >
          {{ 'incidentDetail.panelLocation.locationErrorLabel' | transloco }}
        </label>
        @for (location of locations(); track location.id) {
          <div class="border-b-[1px] pb-4">
            <b>{{ location.name }}</b>
            <br />
            <p class="text-gray-500">
              {{ location.description }}
              <br />
              {{ location.additionalInformation }}
            </p>
          </div>
        }
      }
      @if (locations().length === 0) {
        <div class="my-4">
          <axpo-typography [variant]="'body-m'">
            {{ 'incidentDetail.panelLocation.noLocations' | transloco }}
          </axpo-typography>
        </div>
      }
    </div>
    <div class="mb-4">
      <axpo-selectable-buttons
        [buttons]="tabs()"
        [multiselect]="false"
        (buttonClicked)="toggleView($event)"
      ></axpo-selectable-buttons>
    </div>
  } @else {
    <label
      for="filterSearchText"
      class="font-normal font-open-sans leading-[24px] text-[14px] tracking-[-0.14px] text-text-secondary"
    >
      {{ 'incidentDetail.panelLocation.locationTypeLabel' | transloco }}
    </label>
    <div class="mb-4">
      <axpo-selectable-buttons
        [buttons]="locationTypes()"
        [multiselect]="false"
        (buttonClicked)="selectLocationType($event)"
      ></axpo-selectable-buttons>
    </div>
    <label
      for="filterSearchText"
      class="font-normal font-open-sans leading-[24px] text-[14px] tracking-[-0.14px] text-text-secondary"
    >
      {{ 'incidentDetail.panelLocation.locationErrorLabel' | transloco }}
    </label>
    <app-location-autocomplete
      [entries]="locationAutocompleteEntries()"
      [selectedEntries]="selectedLocationEntries()"
      [isLoading]="locationService.isLoadingLocations()"
      [placeholder]="'incidentDetail.panelLocation.locationSearchPlaceholder' | transloco"
      (debounceValue)="locationSearch($event)"
      (entryAdded)="addLocation($event)"
      (entryRemoved)="removeLocation($event)"
    ></app-location-autocomplete>
    @if (!formValidator?.isValid) {
      <div class="whitespace-nowrap text-primary pb-2 relative top-[-10px]">
        <axpo-typography variant="body-s">
          {{ formValidator?.getField('locations')?.errorMessage() }}
        </axpo-typography>
      </div>
    }
    <div class="mb-4">
      <axpo-selectable-buttons
        [buttons]="tabs()"
        [multiselect]="false"
        (buttonClicked)="toggleView($event)"
      ></axpo-selectable-buttons>
    </div>
  }
  <div [classList]="mapViewSelected() && incidentService.incidentDetails() ? '' : 'hidden'">
    <div id="map" #map class="h-[350px] m-0 p-0 relative"></div>
  </div>

  <div [classList]="!mapViewSelected() && incidentService.incidentDetails() ? '' : 'hidden'">
    <app-location-list [locations]="locations()"></app-location-list>
  </div>
</div>
