import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TileService {
  detailsChangedTimestamp = signal<string | undefined>(undefined);
  incidentResolutionChangedTimestamp = signal<string | undefined>(undefined);
  alertChangedTimestamp = signal<string | undefined>(undefined);

  saved = signal<string[]>([]);
}
