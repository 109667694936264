import { DatePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  output,
  signal,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { VariantProps, cva } from 'class-variance-authority';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { twMerge } from 'tailwind-merge';

import { HandleMissingImageDirective } from '../../shared/directives/missing-images.directive';
import { FormatTextPipe } from '../../shared/pipes/format-text.pipe';
import { AxpoFormElementComponent } from '../axpo-form-element/axpo-form-element.component';
import { AxpoTagComponent, AxpoTagsVariants } from '../axpo-tag/axpo-tag.component';
import { AxpoBreakpoints, AxpoResizeService } from '../services/axpo-resize.service';
import { SvgService } from '../services/svg.service';

export type ChartColor = 'default' | 'red' | 'green' | 'blue' | 'purple' | 'pink';

export type ITableRow = Record<
  string,
  {
    value?: string;
    imageValue?: string;
    bold_value?: string;
    link?: string;
    tagTranslation?: string;
    valueArray?: string[];
    chartColor?: ChartColor;
  }
>;
export type CellFormatter =
  | 'date'
  | 'image'
  | 'internal_link'
  | 'external_link'
  | 'tag'
  | 'roleTag'
  | 'dateTime'
  | 'actions'
  | 'chart';

export interface ITableColumn {
  title: string;
  field: string;
  sortable?: boolean;
  formatter?: CellFormatter[];
  tagVariant?: AxpoTagsVariants['variant']
}

export type IActions = 'view' | 'edit' | 'delete' | 'pdf' | 'download' | 'select';

export type DateTimeFormats = 'HH:mm - dd.MM.yyyy' | 'dd.MM.yyyy - HH:mm';

export interface IActionClick {
  action: IActions;
  row: ITableRow;
  rowIndex: number;
}

export interface IRowClick {
  row: ITableRow;
  rowIndex: number;
}

export interface ISort {
  field: string;
  direction: 'asc' | 'desc';
}

export const axpoTableVariants = cva(['px-4', 'text-left', 'text-dark-gray', 'whitespace-nowrap'], {
  variants: {
    headerType: {
      filled: ['py-4', 'bg-background-1', 'border-separate'],
      ghost: ['py-2'],
    },
  },
  defaultVariants: {
    headerType: 'ghost',
  },
});
export type AxpoTableVariants = VariantProps<typeof axpoTableVariants>;

@Component({
  selector: 'axpo-table',
  standalone: true,
  imports: [
    NgClass,
    NgxSkeletonLoaderModule,
    NgTemplateOutlet,
    DatePipe,
    RouterLink,
    HandleMissingImageDirective,
    AxpoFormElementComponent,
    AxpoTagComponent,
    FormatTextPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './axpo-table.component.html',
  styleUrl: './axpo-table.component.css',
})
export class AxpoTableComponent {
  svgService = inject(SvgService);
  resizeService = inject(AxpoResizeService);

  columns = input.required<ITableColumn[]>();
  data = input.required<ITableRow[]>();
  headerType = input<AxpoTableVariants['headerType']>('ghost');
  actions = input<IActions[]>([]);
  isLoading = input<boolean>(false);
  skeletonLoadingLines = input<number>(3);
  allowMobileLayout = input<boolean>(true);
  textIfEmpty = input<string>('No content found');
  rowsClickable = input<boolean>(false);
  chartMax = input<number | undefined>(undefined);
  dateTimeFormat = input<DateTimeFormats>('HH:mm - dd.MM.yyyy');
  mobileBreakpoint = input<AxpoBreakpoints>(640);

  actionClick = output<IActionClick>();
  rowClick = output<IRowClick>();
  sortClick = output<ISort>();

  sortBy = signal<ISort | undefined>(undefined); // sort by field and direction

  onInit = effect(() => {
    this.svgService.loadSvg(['edit', 'delete', 'view', 'file-pdf', 'download']);
  });

  getSvg = this.svgService.svgMap;

  headerClass = computed(() => {
    return twMerge(
      axpoTableVariants({
        headerType: this.headerType(),
      }),
    );
  });

  hasSelectAction = computed(() => {
    return this.actions().length > 0 && this.actions().indexOf('select') > -1;
  });

  hasNonSelectAction = computed(() => {
    return (
      this.actions().length > 0 && this.actions().filter(action => action !== 'select').length > 0
    );
  });

  showMobileLayout = computed(() => {
    return this.allowMobileLayout() && this.resizeService.width() < this.mobileBreakpoint();
  });

  changeSort = (field: string) => {
    let sortBy = this.sortBy();
    if (!sortBy) sortBy = { field: field, direction: 'asc' };
    else if (sortBy.field === field) {
      sortBy.direction = sortBy.direction === 'asc' ? 'desc' : 'asc';
    } else {
      sortBy.field = field;
      sortBy.direction = 'asc';
    }
    this.sortBy.set({ ...sortBy });
    this.sortClick.emit(sortBy);
  };

  formatText(input: string): string {
    return input
      .replace(/_/g, ' ')
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .toLowerCase()
      .replace(/\b\w/g, char => char.toUpperCase());
  }

  calculateChartWidth(item: any): string {
    const chartMax = this.chartMax();
    if (!item.value || !chartMax) {
      console.error('Chart column only allowed with numeric value inside item.value property');
      return '0';
    }

    const value = item.value.value;
    if (value === '0') {
      return '0';
    }
    const fraction = (value / chartMax) * 100;

    return Math.round(fraction).toString();
  }

  getChartColor(color: ChartColor): string {
    switch (color) {
      case 'red':
        return '#cd2626';
      case 'default':
        return '#000000';
      case 'green':
        return '#008000';
      case 'blue':
        return '#0000FF';
      case 'purple':
        return '#800080';
      case 'pink':
        return '#FFC0CB';
      default:
        return '#000000';
    }
  }
}
