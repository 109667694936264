<div
  class="px-4 mx-auto lg:px-6"
  [ngClass]="{
    'container py-8': mode() === 'circle',
  }"
>
  @if (circleService.updatingCircle()) {
    <div class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div class="p-8 bg-white rounded shadow-lg">
        <axpo-spinner size="normal">{{ 'core.loading' | transloco }}</axpo-spinner>
      </div>
    </div>
  }
  <div class="m-4">
    @if (mode() === 'circle') {
      <div class="flex justify-between flex-col sm:flex-row">
        <axpo-typography [variant]="'title-xl'" [weight]="'bold'">
          {{ circle()?.name }}
        </axpo-typography>
        @if (!isSmallestView() && isAdmin()) {
          <div class="flex justify-center gap-2 mt-2 sm:justify-end sm:mt-0">
            <axpo-button (click)="addContact()">
              {{ 'switchboard.addContact' | transloco }}
            </axpo-button>
          </div>
        }
        @if (isSmallestView() && isAdmin()) {
          <div class="w-full mt-2">
            <axpo-button (click)="addContact()">
              {{ 'switchboard.addContact' | transloco }}
            </axpo-button>
          </div>
        }
      </div>

      <app-tabs (tabSelected)="tabSelectionChanged($event)" [tabItems]="circleTabs()"></app-tabs>
    }
  </div>
  @if (circleModeBtn() === 'isOnSite' && onSite()) {
    <div class="flex flex-row">
      <div class="w-full lg:w-1/2">
        <app-subgroup-list-selection
          [(clearSelected)]="selectedUser"
          [selectionGroupId]="getSelectionGroupId()"
          [subgroup]="onSite()"
          (userSelected)="onUserChanged($event)"
          [toggleProperty]="circleModeBtn()"
          [mode]="selectionMode"
          [allDisabled]="allDisabled()"
          [circleId]="circle()?.id"
        ></app-subgroup-list-selection>
      </div>
    </div>
  }
  @for (organization of circle()?.organizations; track organization.id) {
    <div class="flex flex-col justify-center lg:flex-row">
      <!-- Left Row -->
      <div class="flex-1">
        @for (subgroup of beforeMedian(organization.subgroups); track subgroup.id) {
          <div
            [ngClass]="{
              'mt-4': $index === 0 && !isMobileView(),
              'mt-8': $index > 0 || isMobileView(),
            }"
          >
            <app-subgroup-list-selection
              [(clearSelected)]="selectedUser"
              [selectionGroupId]="getSelectionGroupId(organization.id)"
              [subgroup]="subgroup"
              (userSelected)="onUserChanged($event)"
              [toggleProperty]="circleModeBtn()"
              [mode]="selectionMode"
              [allDisabled]="allDisabled()"
              [circleId]="circle()?.id"
            ></app-subgroup-list-selection>
          </div>
        }
      </div>

      <!-- Right Row -->
      <div class="flex-1">
        @for (subgroup of afterMedian(organization.subgroups); track subgroup.id) {
          <div
            [ngClass]="{
              'mt-4': $index === 0 && !isMobileView(),
              'mt-8': $index > 0 || isMobileView(),
            }"
          >
            <app-subgroup-list-selection
              [(clearSelected)]="selectedUser"
              [selectionGroupId]="getSelectionGroupId(organization.id)"
              [subgroup]="subgroup"
              (userSelected)="onUserChanged($event)"
              [toggleProperty]="circleModeBtn()"
              [mode]="selectionMode"
              [allDisabled]="allDisabled()"
              [circleId]="circle()?.id"
            ></app-subgroup-list-selection>
          </div>
        }
      </div>
    </div>
  }
  @if (mode() === 'circle') {
    <div class="flex justify-end gap-2 m-4">
      <axpo-button (click)="back()" type="ghost">
        {{ 'core.back' | transloco }}
      </axpo-button>
      @if (circleModeBtn() !== 'active') {
        <axpo-button
          [disabled]="onCallChanged().length === 0 && mainNumberChanged() === undefined"
          (click)="save()"
        >
          {{ 'core.save' | transloco }}
        </axpo-button>
      } @else {
        <axpo-button [disabled]="activeUsersChanged().length === 0" (click)="saveManageContacts()">
          {{ 'core.save' | transloco }}
        </axpo-button>
      }
    </div>
  }
</div>
@if (addNewContactDialog()) {
  <axpo-dialog
    title="{{ 'switchboard.addContact' | transloco }}"
    cancelText="{{ 'core.cancel' | transloco }}"
    confirmText="{{ 'core.save' | transloco }}"
    textAlign="left"
    [processBeforeClose]="true"
    [loadingStateText]="'core.loading' | transloco"
    [dataLoaded]="circleService.updatingCircle()"
    (takenAction)="contactDialogClosed($event)"
    [saveDisabled]="!formManager?.isValid && !!formManager?.showErrorOnLoad()"
    [abortSaving]="abortDialogSaving()"
  >
    <div class="flex flex-col space-y-4 min-h-[350px]">
      <axpo-form-element
        formId="subwork"
        label="{{ 'switchboard.subgroup' | transloco }}"
        formType="select"
        [(value)]="newContactSubwork"
        (errorEmitter)="formValidation($event, 'subwork')"
        [errorMessageInput]="this.formManager?.getField('subwork')?.errorMessage()"
        [options]="subgroupOptions()"
        [required]="true"
      ></axpo-form-element>
      <app-location-autocomplete
        formId="name"
        [required]="true"
        [entries]="searchQueryResult()"
        [selectedEntries]="selectedSearchResults()"
        [isLoading]="circleService.searchingUser()"
        placeholder="{{ 'incidentDetail.contactsData.addExistingContact' | transloco }}"
        (debounceValue)="searchValueChanged($event)"
        (entryAdded)="searchEntrySelected($event)"
        (entryRemoved)="searchEntryRemoved($event)"
        [isLocations]="false"
        [disabled]="!newContactSubwork()"
      ></app-location-autocomplete>
      @if (!formManager?.isValid && formManager?.getField('name')?.errorMessage()) {
        <div class="whitespace-nowrap text-primary pb-2 relative top-[-30px]">
          <axpo-typography variant="body-s">
            {{ formManager?.getField('name')?.errorMessage() }}
          </axpo-typography>
        </div>
      }
    </div>
  </axpo-dialog>
}
