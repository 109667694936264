import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';

import { AxpoTypographyComponent } from '../../../core/axpo-typography/axpo-typography.component';
import { IContactModel } from '../../../shared/models/api_models';

@Component({
  selector: 'app-mailing-list-item',
  imports: [AxpoTypographyComponent, TranslocoPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './mailing-list-item.component.html',
})
export class MailingListItemComponent {
  contact = input<IContactModel | undefined | null>();
  displayDeleteAction = input<boolean>(false);
  deleteClicked = output<void>();
}
