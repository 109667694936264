<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 132 135">
  <defs>
    <linearGradient id="backgroundGradient" x1="0%" y1="10%" x2="100%" y2="90%">
      <stop offset="0%" style="stop-color: rgb(255, 93, 100); stop-opacity: 1" />
      <stop offset="15%" style="stop-color: rgb(255, 237, 87); stop-opacity: 1" />
      <stop offset="30%" style="stop-color: rgb(255, 93, 100); stop-opacity: 1" />
      <stop offset="45%" style="stop-color: rgb(69, 69, 207); stop-opacity: 1" />
      <stop offset="60%" style="stop-color: rgb(255, 93, 100); stop-opacity: 1" />
      <stop offset="75%" style="stop-color: rgb(255, 237, 87); stop-opacity: 1" />
      <stop offset="90%" style="stop-color: rgb(255, 93, 100); stop-opacity: 1" />
    </linearGradient>
  </defs>
  <mask id="mask">
    <rect fill="black" width="100%" height="600%"></rect>
    <path
      fill="white"
      d="m94.74555,67.46222c-0.2,-5.6 9.3,-15.1 16.2,-22.1c9.4,-9.5 17.5,-17.6 17.2,-26.4c-0.1,-3.6 -1.7,-6.9 -4.8,-9.7c-12.1,-10.1 -24.2,1.5 -34.9,14.2c-10.2,12.1 -21.6,28.6 -33.6,28.1c-6.1,-0.1 -13.6,-4.4 -21,-12.3c-7.5,-8.1 -12.2,-16.6 -14.2,-21.8c5.8,2.9 15.8,10.5 24.9,20.2c2.2,2.2 3.7,3.4 5,4.2c0.2,0.1 0.4,0.2 0.7,0.4c0.1,0.1 0.3,0.1 0.4,0.2c1.3,0.6 2.8,1 4.4,0.9c2.5,-0.2 5,-1.8 7.4,-4.6c-11.7,-14.9 -33,-34.3 -45.4,-35.4c-4.4,-0.4 -7.1,1.3 -8.7,2.9c-5.1,5.1 -1.5,14.1 -0.3,17c6.7,16.5 26.2,41 46.4,41.1c18,0.8 32.3,-19 43.8,-32.6c4.8,-5.6 13.6,-13.4 16.4,-12.9c0,0 0.5,0.1 0.6,0.4c0.4,3 -8.7,12.1 -13.5,17c-9.9,10 -20,20.2 -19.9,31.2l0,0l0,0l0,0l0,0c-0.1,10.9 10.1,21.2 19.9,31.2c4.8,4.9 13.9,14 13.5,17c0,0.4 -0.6,0.4 -0.6,0.4c-2.8,0.5 -11.7,-7.2 -16.4,-12.9c-11.4,-13.5 -28.6,-33.4 -46.6,-32.5c-20.2,0.2 -39.6,24.7 -46.4,41.1c-1.2,2.9 -4.9,11.9 0.3,17c1.5,1.5 4.3,3.2 8.7,2.9c12.4,-1.1 33.7,-20.5 45.3,-35.4c-2.4,-2.8 -4.9,-4.4 -7.4,-4.6c-1.6,-0.1 -3.1,0.3 -4.4,0.9c-0.1,0.1 -0.3,0.1 -0.4,0.2c-0.2,0.1 -0.4,0.2 -0.7,0.4c-1.2,0.8 -2.8,2 -5,4.2c-9.1,9.7 -19.1,17.3 -24.9,20.2c2,-5.1 6.7,-13.7 14.2,-21.8c7.4,-7.9 14.8,-12.3 21,-12.3c12,-0.5 26.3,16 36.5,28.1c10.7,12.7 22.8,24.3 34.9,14.2c3.1,-2.7 4.7,-6 4.8,-9.7c0.3,-8.8 -7.8,-16.9 -17.2,-26.4c-7,-7 -16.4,-16.6 -16.2,-22.2z"
      class="st0"
    />
  </mask>
  <g mask="url(#mask)">
    <rect class="bg-gradient" width="600%" height="600%" fill="url(#backgroundGradient)"></rect>
  </g>
</svg>
