@if (label()) {
  <axpo-typography variant="body-m">{{ label() }}</axpo-typography>
}
<div
  class="flex flex-row w-full space-x-1 rounded-md bg-background-1 border border-border focus:border-text-secondary flex-wrap"
>
  @for (button of buttons(); track button.id) {
    <div class="flex-1 rounded">
      <input
        [disabled]="disabled()"
        [id]="getButtonId(button.id)"
        class="peer hidden"
        [type]="multiselect() ? 'checkbox' : 'radio'"
        [name]="getButtonId(button.id)"
        [value]="button.value"
        (click)="buttonClick($event, button.value)"
        [checked]="button.selected"
      />
      <label
        [for]="getButtonId(button.id)"
        [ngClass]="getClasses(button)"
        class="block cursor-pointer select-none rounded-md p-2 text-center add h-[calc(2.5rem_-_1.5px)] whitespace-nowrap"
      >
        {{ button.title }}
      </label>
    </div>
  }
</div>
