export const datetoStringWithoutZone = (date: Date) => {
  // converts a date to a iso-formatted string by ignoring the timezone and milliseconds
  return new Date(date.valueOf() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .replace('.000Z', '');
};

export const dateToDay = (value?: number, operation?: 'sum' | 'subtraction') => {
  const date = new Date();
  if (value) {
    if (operation === 'sum') {
      date.setMonth(date.getMonth() + value);
    }
    if (operation === 'subtraction') {
      date.setMonth(date.getMonth() - value);
    }
  }

  return new Date(date.valueOf() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
};

export const dateLastWeek = () => {
  const date = new Date();
  date.setDate(date.getDate() - 7);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export const isoDateToTime = (isoDate: string): string => {
  if (!isoDate) {
    throw new Error('Invalid date string');
  }

  const date = new Date(isoDate);

  if (isNaN(date.getTime())) {
    throw new Error('Invalid ISO date format');
  }

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${hours}:${minutes}`;
};

export const isoDateToDate = (isoDate: string): string => {
  if (!isoDate) {
    throw new Error('Invalid date string');
  }

  const date = new Date(isoDate);

  if (isNaN(date.getTime())) {
    throw new Error('Invalid ISO date format');
  }

  // Extract the year, month, and day
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const addTimeToDate = (date?: string, time?: string): string | undefined => {
  if (date && time) {
    const changeableDate = new Date(date);
    const timeFragment = time.split(':');
    changeableDate.setHours(parseInt(timeFragment[0]));
    changeableDate.setMinutes(parseInt(timeFragment[1]));

    return datetoStringWithoutZone(changeableDate);
  }

  return date;
};

export function getTimeDifferenceInMinutes(date1: string, date2: string): number {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  const differenceInMilliseconds = Math.abs(d2.getTime() - d1.getTime());
  return Math.floor(differenceInMilliseconds / 60000);
}