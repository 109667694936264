import { Route } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';

import { HomeComponent } from './pages/home/home.component';
import { IncidentDetailComponent } from './pages/incident-detail/incident-detail.component';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';

export const ROUTES: Route[] = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  // { path: 'login', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    component: HomeComponent,
    title: 'IMA',
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
    title: 'IMA',
  },
  {
    path: 'incident/:accessCode/:tenantId',
    component: IncidentDetailComponent,
    title: 'IMA',
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    title: 'Green Gas Customer Portal - Unauthorized Access',
  },
  {
    path: '404',
    component: NotFoundComponent,
    title: 'Green Gas Customer Portal - Not Found',
  },
  {
    path: '**',
    component: NotFoundComponent,
    title: 'Green Gas Customer Portal - Not Found',
  },
];
