import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { type VariantProps, cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

export const axpoButtonVariants = cva(
  ['border-4', 'border-t-4', 'border-gray-200', 'rounded-full', 'border-t-primary', 'animate-spin'],
  {
    variants: {
      size: {
        xs: ['w-4', 'h-4'],
        sm: ['w-6', 'h-6'],
        normal: ['w-8', 'h-8'],
        lg: ['w-12', 'h-12'],
        xl: ['w-16', 'h-16'],
      },
    },
    defaultVariants: {
      size: 'normal',
    },
  },
);
export type AxpoButtonVariants = VariantProps<typeof axpoButtonVariants>;

@Component({
  standalone: true,
  selector: 'axpo-spinner',
  templateUrl: './axpo-spinner.component.html',
  styleUrl: './axpo-spinner.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AxpoSpinnerComponent {
  size = input<AxpoButtonVariants['size']>('normal');
  textClasses = input<string>('');

  computedClass = computed(() => {
    return twMerge(
      axpoButtonVariants({
        size: this.size(),
      }),
    );
  });
}
