import { HttpClient } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { lastValueFrom } from 'rxjs';

import { ALL_ICONS } from '../types/svg-icon-name';
import { SvgIconName } from '../types/svg-icon-name';

@Injectable({ providedIn: 'root' })
export class SvgService {
  private http = inject(HttpClient);
  private sanitizer = inject(DomSanitizer);
  private svgCache = signal<Partial<Record<SvgIconName, SafeHtml>>>({});
  private iconsLoading = new Set<SvgIconName>();

  svgMap = computed(() => this.svgCache());

  private readonly folder = '/svg/';

  loadSvg(names: SvgIconName[] | 'all') {
    const iconsToLoad = names === 'all' ? ALL_ICONS : names;
    const updatedCache = { ...this.svgCache() };

    // Get only icons that are NOT cached AND NOT already loading
    const missingIcons = iconsToLoad.filter(
      name => !updatedCache[name] && !this.iconsLoading.has(name),
    );

    if (missingIcons.length === 0) {
      return;
    }

    // Mark icons as "loading" to prevent duplicate requests
    missingIcons.forEach(name => this.iconsLoading.add(name));

    Promise.all(
      missingIcons.map(async name => {
        try {
          const data = await lastValueFrom(
            this.http.get(`${this.folder}${name}.svg`, { responseType: 'text' }),
          );
          updatedCache[name] = this.sanitizer.bypassSecurityTrustHtml(data);

          // Update cache immediately after each icon loads
          this.svgCache.set({ ...this.svgCache(), [name]: updatedCache[name] });
        } catch {
          console.warn(`Failed to load icon: ${name}`);
        } finally {
          this.iconsLoading.delete(name);
        }
      }),
    ).then(() => {
      console.info('All icons are loaded');
    });
  }
}
