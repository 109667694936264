<div class="mt-3">
  <app-filedrop
    [multiple]="true"
    [acceptCamera]="true"
    [accept]="'.xls,.xlsx,.csv,.txt'"
    (filesUploaded)="filesUploaded($event)"
  >
    @for (
      item of (internalAttachments()
        ? attachmentService.attachmentsInternal()
        : attachmentService.attachments()) ?? [];
      track item.id
    ) {
      <div class="bg-background-1 flex p-3 mb-2">
        <div class="mr-3">
          <button (click)="download(item)">
            <i class="fas fa-download text-primary"></i>
          </button>
        </div>
        <div class="grow">
          {{ item.name }}
        </div>
        <div><button (click)="remove(item)">X</button></div>
      </div>
    }
  </app-filedrop>
</div>
