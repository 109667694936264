import { Directive, effect, inject, input, signal, untracked } from "@angular/core";

import { AxpoResizeService, axpoBreakpoints } from "../../core/services/axpo-resize.service";

@Directive()
export abstract class BaseResizeComponent {
  protected breakpoint = input<number>(axpoBreakpoints.LG);
  protected resizeService = inject(AxpoResizeService);
  public isMobileView = signal<boolean>(false);
  public isSmallestView = signal<boolean>(false);


  _resize = effect(() => {
    const isMobile = this.resizeService.width() < this.breakpoint();
    untracked(() => {
      this.isMobileView.set(isMobile);
      this.isSmallestView.set(this.resizeService.width() <= axpoBreakpoints.SM);
    });
  });
}