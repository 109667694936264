import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { AuthnService } from '../services/authn.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  private authnService = inject(AuthnService);
  private router = inject(Router);

  canActivate(next: ActivatedRouteSnapshot): boolean {
    const expectedRoles: string[] = next.data['roles'];
    const userRoles = this.authnService.user()?.roles || [];

    const hasRoles = expectedRoles.some(role => userRoles.includes(role));

    if (!hasRoles) {
      this.router.navigate(['unauthorized']);
    }

    return hasRoles;
  }
}
