<div class="min-h-40 p-3 border border-border border-dashed rounded-[10px]">
  <div
    id="dropzone"
    ref="dzone"
    tabindex="0"
    :color="dragover ? 'primary' : 'secondary'"
    width="100%"
    class="pa-2"
    (dragleave)="dragover = false; $event.preventDefault()"
    (dragenter)="dragover = true; $event.preventDefault()"
    (dragover)="dragover = true; $event.preventDefault()"
    (drop)="dropEvent($event); $event.preventDefault()"
    (click)="uploadClick()"
    (dragstart)="dropEvent($event)"
    (keypress.enter)="uploadClick()"
  >
    <div class="text-center mx-4 mt-4">
      @if (!dragover) {
        <div>
          <i class="fas fa-upload text-primary text-[44px]"></i>
        </div>
      } @else {
        <div>
          <i class="fas fa-file-upload text-primary text-[44px]"></i>
        </div>
      }
    </div>
    <div class="mx-4 mb-4 text-center">
      <axpo-typography [variant]="'title-m'" [weight]="'bold'">
        {{ 'incidentDetail.panelAttachments.dragAndDropUpload' | transloco }}
      </axpo-typography>
      <axpo-typography [variant]="'body-s'" [weight]="'normal'">
        {{ 'incidentDetail.panelAttachments.acceptedFormat' | transloco }}
      </axpo-typography>
    </div>
    <div class="mx-5 mb-5 text-center">
      <button
        class="bg-primary disabled:bg-disabled disabled:text-text-primary font-almarena font-bold hover:bg-interaction-red inline-flex items-center justify-center leading-4 min-w-24 px-6 py-3 rounded-full text-[14px] text-white tracking-[-0.037px]"
        (click)="uploadClick(); $event.stopPropagation()"
      >
        {{ 'incidentDetail.panelAttachments.buttonSelectFiles' | transloco }}
      </button>
    </div>
  </div>
  <input
    id="fileUpload"
    class="hidden"
    #upload
    type="file"
    [multiple]="multiple()"
    [accept]="accept()"
    (change)="changeEvent($event)"
  />
  <ng-content></ng-content>
</div>
