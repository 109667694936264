import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

export interface HttpRequestOptions {
  body?: any;
  headers?: HttpHeaders | Record<string, string | string[]>;
  context?: HttpContext;
  params?:
    | HttpParams
    | Record<string, string | number | boolean | readonly (string | number | boolean)[]>;
  observe?: 'body' | 'events' | 'response';
  reportProgress?: boolean;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
  transferCache?:
    | {
        includeHeaders?: string[];
      }
    | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  environment = environment;
  http = inject(HttpClient);

  private request = <T>(
    method: string,
    url: string,
    options: HttpRequestOptions = {},
    baseUrl?: string,
  ): Observable<T> => {
    if (url.startsWith('/')) url = url.substring(1);
    url = (baseUrl ? baseUrl : this.environment.apiUrl) + '/' + url;
    options.params ??= {};

    const responseType = options.responseType || 'json';
    const observe = options.observe || 'body';

    return this.http.request<T>(method, url, {
      ...options,
      params: options.params,
      headers: options.headers,
      body: options.body,
      responseType: responseType as 'json',
      observe: observe as 'body',
    });
  };

  public get = <T>(
    url: string,
    options: HttpRequestOptions = {},
    baseUrl?: string,
  ): Observable<T> => this.request('GET', url, options, baseUrl);

  public post = <T>(
    url: string,
    options: HttpRequestOptions = {},
    baseUrl?: string,
  ): Observable<T> => this.request('POST', url, options, baseUrl);

  public put = <T>(
    url: string,
    options: HttpRequestOptions = {},
    baseUrl?: string,
  ): Observable<T> => this.request('PUT', url, options, baseUrl);

  public patch = <T>(
    url: string,
    options: HttpRequestOptions = {},
    baseUrl?: string,
  ): Observable<T> => this.request('PATCH', url, options, baseUrl);

  public delete = <T>(
    url: string,
    options: HttpRequestOptions = {},
    baseUrl?: string,
  ): Observable<T> => this.request('DELETE', url, options, baseUrl);
}
