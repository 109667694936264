<div>
  @if (selectedItem()) {
    <app-mailing-list-item
      [contact]="selectedItem()"
      [displayDeleteAction]="true"
      (deleteClicked)="onRemoveCurrentItem()"
    ></app-mailing-list-item>
  }
  <div class="relative" (clickOutside)="searchDropdownVisible.set(false)">
    <axpo-search
      id="filterSearchText"
      [(searchValue)]="searchItemValue"
      (debounceValue)="searchItemsFilter.emit(searchItemValue())"
      [debounceTime]="500"
      [debounce]="true"
      (click)="searchDropdownVisible.set(true)"
    ></axpo-search>

    @if (searchDropdownVisible()) {
      <div
        class="absolute w-full overflow-auto bg-white border border-border rounded-b-md max-h-56"
      >
        @for (contact of contacts(); track contact.id) {
          <app-mailing-list-item
            [contact]="contact"
            (click)="onClick(contact)"
            class="cursor-pointer"
          ></app-mailing-list-item>
        }
      </div>
    }
  </div>
</div>
