import { Injectable, computed, inject, signal } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { IncidentImpactTranslations, IncidentStatusTranslations, impactName, stateName } from '../models/api_data_translations';
import { IINotifyBody, IIncidentListFilterModel, IIncidentListModel, IIncidentModel, IncidentListFilterSorting } from '../models/api_models';
import { dateToDay } from '../utils/date';

@Injectable({
  providedIn: 'root',
})
export class IncidentService {
  private apiService = inject(ApiService);
  private toastr = inject(ToastrService);
  private translocoService = inject(TranslocoService);
  environment = environment;

  filter = signal<IIncidentListFilterModel>({
    rowsToSkip: 0, // No pager functionality yet
    rowsToReturn: 100000, // We want to return all / lots of data for now
    sorting: IncidentListFilterSorting.IncidentFaultTimeStampDesc,
    incidentFaultFrom: new Date(dateToDay(2, 'subtraction')).toISOString(),
    incidentFaultTo: new Date().toISOString(),
  });
  incidents = signal<IIncidentListModel[] | undefined>(undefined);
  incidentDetails = signal<IIncidentModel | undefined>(undefined);
  isLoadingIncidents = signal<boolean>(false);
  isLoadingIncidentDetails = signal<boolean>(false);
  duplicationInProgress = signal<boolean>(false);
  duplicatedAccessCode = signal<string | undefined>(undefined);
  isNotifyingContacts = signal<boolean>(false);
  updateInProgress = signal<boolean>(false);
  updateResult = signal<string | undefined>(undefined);

  cloneIncident(accessCode: string) {
    this.duplicationInProgress.set(true);
    this.apiService.put<string>(`/api/v1/Incident/copy/${accessCode}`).subscribe({
      next: accessCode => {
        this.duplicatedAccessCode.set(accessCode);
      },
      error: () => {
        const msg = this.translocoService.translate('incidentDetail.duplicateFailed');
        this.toastr.error(msg);
      },
      complete: () => {
        this.duplicationInProgress.set(false);
      },
    });
  }

  getIncidents() {
    if (this.isLoadingIncidents() === true) {
      return;
    }

    this.isLoadingIncidents.set(true);
    const filterBody = computed<string>(() => JSON.stringify(this.filter()));
    this.apiService
      .post<IIncidentListModel[]>(`/api/v1/Incident/load-list`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: filterBody(),
      })
      .subscribe({
        next: incidents => {
          const i18nIncidents = incidents.map(incident => {
            return {
              ...incident,
              incidentStateName:
                IncidentStatusTranslations[incident.incidentStateName as stateName],
            };
          });
          this.incidents.set(i18nIncidents);
        },
        error: () => {
          const msg = this.translocoService.translate('incident.failed');
          this.toastr.error(msg);
        },
        complete: () => {
          this.isLoadingIncidents.set(false);
        },
      });
  }

  getIncidentDetails(accessCode: string, tenantId: string) {
    if (this.isLoadingIncidentDetails() === true) {
      return;
    }

    this.isLoadingIncidentDetails.set(true);
    this.apiService
      .get<IIncidentModel>(`/api/v1/Incident/load/${accessCode}/${tenantId}`)
      .subscribe({
        next: details => {
          const i18nDetails = {
            ...details, 
            incidentStateName: IncidentStatusTranslations[details.incidentStateName as stateName], 
            incidentImpactName: IncidentImpactTranslations[details.incidentImpactName as impactName]
          };

          this.incidentDetails.set(i18nDetails);
        },
        error: () => {
          const msg = this.translocoService.translate('incidentDetail.failed');
          this.toastr.error(msg);
        },
        complete: () => {
          this.isLoadingIncidentDetails.set(false);
        },
      });
  }

  notify(accessCode: string, contacts: IINotifyBody[]) {
    if (this.isNotifyingContacts() === true) {
      return;
    }

    this.isNotifyingContacts.set(true);
    this.apiService
      .put<[]>(`/api/v1/Incident/notify/${accessCode}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: contacts,
      })
      .subscribe({
        next: result => {
          console.log(result);
          this.toastr.info(this.translocoService.translate('incidentDetail.sharedSuccess'));
        },
        error: () => {
          const msg = this.translocoService.translate('incident.failed');
          this.toastr.error(msg);
        },
        complete: () => {
          this.isNotifyingContacts.set(false);
        },
      });
  }

  update(accessCode: string, tenantId: string, incident: IIncidentModel) {
    if (this.updateInProgress() === true) {
      return;
    }

    this.updateInProgress.set(true);
    this.apiService
      .post<[]>(`/api/v1/Incident/update/${accessCode}/${tenantId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: incident,
      })
      .subscribe({
        next: result => {
          console.log(result);
        },
        error: () => {
          const msg = this.translocoService.translate('incident.updateFailed');
          this.toastr.error(msg);
          this.updateInProgress.set(false);
        },
        complete: () => {
          this.updateInProgress.set(false);
        },
      });
  }
}
