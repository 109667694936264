@if (label()) {
  <axpo-typography variant="body-m">{{ label() }}</axpo-typography>
}

@if (isMobileView() && useMobile2Cols()) {
  <div class="rounded-md bg-background-1 border-border flex flex-row justify-center mt-2 mb-2">
    <!-- Left Row -->
    <div class="flex-1">
      @for (button of columnOne(); track button.id) {
        <ng-container
          [ngTemplateOutlet]="buttonTemplate"
          [ngTemplateOutletContext]="{ button: button, mobile: true }"
        ></ng-container>
      }
    </div>

    <!-- Right Row -->
    <div class="flex-1">
      @for (button of columnTwo(); track button.id) {
        <ng-container
          [ngTemplateOutlet]="buttonTemplate"
          [ngTemplateOutletContext]="{ button: button, mobile: true }"
        ></ng-container>
      }
    </div>
  </div>
} @else {
  <div
    class="flex flex-row flex-wrap w-full space-x-1 border rounded-md bg-background-1 border-border focus:border-text-secondary"
  >
    @for (button of buttons(); track button.id) {
      <div class="flex-1 rounded">
        <ng-container
          [ngTemplateOutlet]="buttonTemplate"
          [ngTemplateOutletContext]="{ button: button }"
        ></ng-container>
      </div>
    }
  </div>
}

<ng-template #buttonTemplate let-button="button" let-mobile="mobile">
  <div
    [ngClass]="{
      'border rounded-md bg-background-1 border-border focus:border-text-secondary': mobile,
    }"
  >
    <input
      [disabled]="disabled()"
      [id]="getButtonId(button.id)"
      class="hidden peer"
      [type]="multiselect() ? 'checkbox' : 'radio'"
      [name]="getButtonId(button.id)"
      [value]="button.value"
      (click)="buttonClick($event, button.value)"
      [checked]="multiselect() ? (button.selected ? 'checked' : '') : button.selected"
    />
    <label
      [for]="getButtonId(button.id)"
      [ngClass]="getClasses(button)"
      class="block cursor-pointer select-none rounded-md p-2 text-center add h-[calc(2.5rem_-_1.5px)] whitespace-nowrap"
    >
      {{ button.title }}
    </label>
  </div>
</ng-template>
