import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  effect,
  inject,
  input,
  signal,
  untracked,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { TileComponent } from './tile.component';
import { TileService } from './tile.service';
import { AxpoButtonComponent } from '../../core/axpo-button/axpo-button.component';
import { AxpoDialogComponent } from '../../core/axpo-dialog/axpo-dialog.component';
import { AxpoSpinnerComponent } from '../../core/axpo-spinner/axpo-spinner.component';
import { AxpoTypographyComponent } from '../../core/axpo-typography/axpo-typography.component';
import { AlertComponent } from '../../shared/controls/alert/alert.component';
import { EditAttachmentsComponent } from '../../shared/controls/attachments/edit-attachments.component';
import { ViewAttachmentsComponent } from '../../shared/controls/attachments/view-attachments.component';
import { CommentsComponent } from '../../shared/controls/comments/comments.component';
import { ContactsComponent } from '../../shared/controls/contacts/contacts.component';
import { EditDetailsComponent } from '../../shared/controls/details/edit-details.component';
import { ViewDetailsComponent } from '../../shared/controls/details/view-details.component';
import { IncidentResolutionComponent } from '../../shared/controls/incident-resolution/edit/incident-resolution';
import { LocationComponent } from '../../shared/controls/location/location.component';
import { ShareLinkComponent } from "../../shared/controls/share-link/share-link.component";
import { AuthnService } from '../../shared/services/authn.service';
import { CommentService } from '../../shared/services/comment.service';
import { IncidentService } from '../../shared/services/incident.service';
import { TenantService } from '../../shared/services/tenant.service';
import { FormValidator } from '../../shared/utils/formValidator';
import { ITileMode, TileStates } from '../../shared/utils/tileStates';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-incident-detail',
  templateUrl: './incident-detail.component.html',
  imports: [
    AxpoTypographyComponent,
    AxpoButtonComponent,
    TranslocoPipe,
    AxpoDialogComponent,
    AxpoSpinnerComponent,
    TileComponent,
    ViewDetailsComponent,
    EditDetailsComponent,
    CommentsComponent,
    EditAttachmentsComponent,
    ViewAttachmentsComponent,
    AlertComponent,
    ContactsComponent,
    IncidentResolutionComponent,
    LocationComponent,
    ShareLinkComponent
],
})
export class IncidentDetailComponent implements OnInit, OnDestroy {
  private activatedRoute = inject(ActivatedRoute);
  private toastr = inject(ToastrService);
  private authnService = inject(AuthnService);
  private tileService = inject(TileService);
  incidentService = inject(IncidentService);
  tenantService = inject(TenantService);
  translocoService = inject(TranslocoService);
  commentService = inject(CommentService);

  private accessCode = signal<string | null | undefined>(undefined);
  private router = inject(Router);
  private tenantId = signal<string | null | undefined>(undefined);
  private routeChangeSubscription!: Subscription;
  shareLink = signal<boolean>(false);
  incidentTitle = signal<string | null | undefined>(undefined);
  incidentCreatorName = signal<string | null | undefined>(undefined);
  duplicatedAccessCode = signal<string | undefined>(undefined);
  duplicateIncident = signal<boolean>(false);
  incidentCloned = signal<boolean>(false);
  
  tiles = new TileStates();

  isLoggedIn = signal<boolean>(false);
  isCompleted = signal<boolean>(false);
  changeToPending = signal<boolean>(false);
  deleteIncident = signal<boolean>(false);

  mode = input<ITileMode>('edit');

  ngOnInit(): void {
    this.onRouteChanged();
    this.routeChangeSubscription = this.activatedRoute.paramMap.subscribe(() => {
      this.onRouteChanged();
    });
  }

  _updateIncidentDetails = effect(() => {
    const details = this.incidentService.incidentDetails();

    untracked(() => {
      if (details?.incidentStateId === 7) {
        this.isCompleted.set(true);
      }
      
      this.incidentTitle.set(details?.incidentTitle);
      this.incidentCreatorName.set(details?.incidentCreatorName);
    });
  });

  _tenantSettings = effect(() => {
    const tenantContact = this.tenantService.tenantSettings();
    const user = this.authnService.user();
    untracked(() => {
      this.isLoggedIn.set(!!user);
      if (tenantContact) {
        if (tenantContact.tileModels) {
          for (const tile of tenantContact.tileModels) {
            let mode: ITileMode = 'view';
            if (this.isLoggedIn()) {
              if (tile.name === 'comments') {
                mode = 'edit';
              }
            } else {
              mode = 'locked';
            }
            this.tiles.createTileSignals(tile.name as string, !!tile.isVisible, mode);
          }
        }
      }
    });
  });

  _closeCloneDialogAndRedirect = effect(() => {
    const duplicatedLoading = this.incidentService.duplicationInProgress();
    const clonedAccessCode = this.incidentService.duplicatedAccessCode();
    const duplicatedIncident = this.duplicateIncident();
    untracked(() => {
      if (
        clonedAccessCode !== undefined &&
        duplicatedLoading === false &&
        duplicatedIncident === true
      ) {
        this.incidentCloned.set(true);
        this.duplicateIncident.set(false);
        this.router.navigate([
          '/incident/' +
            this.incidentService.duplicatedAccessCode() +
            '/' +
            this.tenantService.tenantId(),
        ]);
      }
    });
  });

  _statusCompleted = effect(() => {
    const state = this.incidentService.incidentDetails()?.incidentStateId;
    const user = this.authnService.user();
    if (state && !!user) {
      untracked(() => {
        if (state === 7) {
          this.isCompleted.set(true);
        } else {
          this.isCompleted.set(false);
        }

        for (const tile in this.tiles.all) {
          const signalTile = this.tiles.all[tile];
          if (tile === 'comments') {
            signalTile.mode.set(this.isCompleted() ? 'locked' : 'edit');
          } else {
            signalTile.mode.set(this.isCompleted() ? 'locked' : 'view');
          }
        }
      });
    }
  });

  _reopenDone = effect(() => {
    const reopened = this.incidentService.reOpeningDone();
    if (reopened) {
      untracked(() => {
        this.changeToPending.set(false);
        this.incidentService.getIncidentDetails(
          this.accessCode() as string,
          this.tenantId() as string,
        );
      });
    }
  });

  _deletingDone = effect(() => {
    const deleted = this.incidentService.deletingDone();
    if (deleted) {
      untracked(() => {
        this.deleteIncident.set(false);
      });

      this.router.navigate(['/']);
    }
  });

  onRouteChanged(): void {
    const accessCode = this.activatedRoute.snapshot.paramMap.get('accessCode');
    const tenantId = this.activatedRoute.snapshot.paramMap.get('tenantId');
    const isCloned = this.incidentService.duplicatedAccessCode();
    this.accessCode.set(accessCode);
    this.tenantId.set(tenantId);
    this.incidentService.duplicatedAccessCode.set(undefined);

    if (accessCode && tenantId) {
      this.incidentService.getIncidentDetails(accessCode, tenantId);
      this.tenantService.getSettings(tenantId);
    }

    if (isCloned !== undefined) {
      this.toastr.info(this.translocoService.translate('incidentDetail.duplicateSuccessful'));
    }
  }

  duplicateIncidentDialogAction(action: 'confirm' | 'cancel') {
    if (action === 'cancel') {
      this.duplicateIncident.set(false);
      return;
    }

    const accessCode = this.accessCode();
    if (action === 'confirm' && accessCode) {
      this.incidentService.cloneIncident(accessCode);
    }
  }

  openIncidentDialog() {
    this.duplicateIncident.set(true);
  }

  openShareModal() {
    this.shareLink.set(true);
  }

  confirmReopen() {
    this.changeToPending.set(true);
  }

  reopenIncident(action: 'confirm' | 'cancel') {
    if (action === 'cancel') {
      this.changeToPending.set(false);
      return;
    }

    if (action === 'confirm' && this.isCompleted() === true) {
      this.incidentService.changeToPending(this.accessCode() as string);
    }
  }

  updateAction(timestampEvent: string | undefined, key: string) {
    if (timestampEvent) {
      this.tiles.all[key].action.set(timestampEvent);

      if (key === 'details') {
        this.tileService.detailsChangedTimestamp.set(timestampEvent);
      }

      if (key === 'incidentResolutionResponse') {
        this.tileService.incidentResolutionChangedTimestamp.set(timestampEvent);
      }

      if (key === 'alarm') {
        this.tileService.alertChangedTimestamp.set(timestampEvent);
      }
    }
  }

  saveCompleted(tileName: string) {
    this.tiles.all[tileName].mode.set('view');
  }

  validationChanged(form: FormValidator, tileName: string) {
    this.tiles.all[tileName].disabled.set(!form.isValid);
  }

  onDeleteIncident() {
    this.deleteIncident.set(true);
  }

  afterIncidentDeleted(action: 'confirm' | 'cancel') {
    if (action === 'confirm') {
      this.incidentService.deleteIncident(this.accessCode() as string);
    } else {
      this.deleteIncident.set(false);
    }
  }

  ngOnDestroy(): void {
    if (this.routeChangeSubscription) {
      this.routeChangeSubscription.unsubscribe();
    }
  }
}
