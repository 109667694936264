<header>
  <div class="flex px-4 py-2 align-middle border-b" [ngClass]="backgroundColorClass()">
    <div class="w-1/3">
      <axpo-typography [variant]="'title-xl'">
        <a
          routerLink="/"
          class="flex items-center align-middle text-text-primary hover:text-primary"
        >
          <div class="block w-[35px] overflow-hidden relative">
            <axpo-logo />
          </div>
          <div class="hidden md:block ps-2 whitespace-nowrap">{{ title() }}</div>
        </a>
      </axpo-typography>
    </div>
    @if (breadcrumbDeactivated()) {
      <div class="flex items-center justify-center w-1/3">
        @if (middleTitle()) {
          <axpo-typography [variant]="'body-m'">
            <div class="text-center">{{ middleTitle() }}</div>
          </axpo-typography>
        }
      </div>
    } @else {
      <div class="flex items-center justify-center w-1/3">
        <axpo-breadcrumb [separator]="separator()"></axpo-breadcrumb>
      </div>
    }
    <div class="flex justify-end w-1/3 gap-3 place-content-center">
      @if (feedback()) {
        <button type="button" class="w-8" (click)="handleFeedbackClick($event)">
          <div class="w-7 h-7"><div [innerHTML]="getSvg()['feedback-pen']"></div></div>
        </button>
      }
      @if (isOffline()) {
        <div class="flex items-center justify-center text-primary">
          <div class="w-8 h-8"><div [innerHTML]="getSvg()['disconnected']"></div></div>
        </div>
      }

      @if (showUsername()) {
        <div class="items-center justify-center hidden md:flex">
          <axpo-typography [variant]="'body-m'">
            <div class="text-center">{{ user()?.name }}</div>
          </axpo-typography>
        </div>
      }

      <axpo-profile-dropdown
        class="text-primary"
        [availableLanguages]="availableLanguages()"
        [(selectedLanguageCode)]="selectedLanguageCode"
        [profileImage]="profileImage()"
        [user]="user()"
        [showLogout]="showLogout()"
        (logout)="onHeaderLogout()"
        [customActions]="customActions()"
        (customAction)="onCustomHeaderAction($event.value, $event.subValue)"
      ></axpo-profile-dropdown>
    </div>
  </div>
</header>
