import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, computed, effect, inject, input, output, signal, untracked } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { VariantProps, cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import { generateSimpleUID } from '../../shared/utils/uid';
import { AxpoTypographyComponent } from '../axpo-typography/axpo-typography.component';
import { AxpoBreakpoints, AxpoResizeService } from '../services/axpo-resize.service';

export type ButtonColors = '1' | '2' | '3' | '4' | '5' | undefined;
export interface IButton {
  id: string | undefined | null;
  title: string;
  value: string | number | undefined;
  selected: boolean;
  colors: ButtonColors;
}

export const axpoSelectableButtonsVariants = cva(
  '', // Base styles
  {
    variants: {
      key: {
        '1': ['text-tc-turquoise'],
        '2': ['text-tc-red'],
        '3': ['text-tc-orange'],
        '4': ['text-tc-green'],
        '5': ['text-tc-violet'],
        '6': ['text-dark-yellow'],
        '7': ['text-success'],
        undefined: ['text-grey'],
      },
      selected: {
        true: [],
        false: [],
      },
      disabled: {
        true: ['cursor-not-allowed', 'opacity-50'],
        false: [],
      },
    },
    defaultVariants: {
      key: undefined,
      selected: false,
      disabled: false,
    },
    compoundVariants: [
      {
        key: undefined,
        selected: true,
        class: ['bg-primary text-white'],
      },
      {
        key: '1',
        selected: true,
        class: ['bg-tc-turquoise text-white'],
      },
      {
        key: '2',
        selected: true,
        class: ['bg-tc-red text-white'],
      },
      {
        key: '3',
        selected: true,
        class: ['bg-tc-orange text-white'],
      },
      {
        key: '4',
        selected: true,
        class: ['bg-tc-green text-white'],
      },
      {
        key: '5',
        selected: true,
        class: ['bg-tc-violet text-white'],
      },
      {
        key: '6',
        selected: true,
        class: ['bg-dark-yellow text-white'],
      },
      {
        key: '7',
        selected: true,
        class: ['bg-success text-white'],
      },
    ],
  },
);
export type AxpoSelectableButtonsVariants = VariantProps<typeof axpoSelectableButtonsVariants>;

@Component({
  standalone: true,
  imports: [FormsModule, NgClass, NgTemplateOutlet, AxpoTypographyComponent],
  selector: 'axpo-selectable-buttons',
  templateUrl: './axpo-selectable-buttons.component.html',
  styleUrl: './axpo-selectable-buttons.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AxpoSelectableButtonsComponent implements OnInit {
  resizeService = inject(AxpoResizeService);

  key = input<AxpoSelectableButtonsVariants['key']>(undefined);
  selected = input<AxpoSelectableButtonsVariants['selected']>(false);
  disabled = input<AxpoSelectableButtonsVariants['disabled']>(false);

  buttons = input<IButton[]>([]);
  multiselect = input<boolean>(false);
  label = input<string | undefined>(undefined);
  mobileBreakpoint = input<AxpoBreakpoints>(640);
  useMobile2Cols = input<boolean>(false);
  buttonClicked = output<(string | number)[]>();

  selectedValues = signal<(string | number)[]>([]);
  elementId = generateSimpleUID();
  isMobileView = signal<boolean>(false);

  columnOne = computed(() => {
    return this.buttons().filter((button) => this.buttons().indexOf(button) % 2 === 0);
  });
  columnTwo = computed(() => {
    return this.buttons().filter((button) => this.buttons().indexOf(button) % 2 === 1);
  });
  

  ngOnInit(): void {
    const selectedValues = this.buttons()
      .filter(button => button.selected && button.value)
      .map(button => (button.value ? button.value?.toString() : ''));
    this.selectedValues.set(selectedValues);
  }

  _resize = effect(() => {
    const isMobile = this.resizeService.width() < this.mobileBreakpoint();
    untracked(() => {
      this.isMobileView.set(isMobile);
    });
  });
  
  getClasses(button: IButton): string {
    return twMerge(
      axpoSelectableButtonsVariants({
        key: button.colors,
        selected: button.selected,
        disabled: this.disabled(),
      }),
    );
  }

  buttonClick = (event: any, value: string | number | undefined): void => {
    event.preventDefault();
    if (this.multiselect()) this.handleMultiSelect(value);
    else this.handleSingleSelect(value);
  };

  handleMultiSelect = (value: string | number | undefined): void => {
    let emitEvent = true;
    const selected = [...this.selectedValues()];
    const indexOfSearchedKey = selected.findIndex(x => x.toString() == value?.toString());

    if (value !== undefined) {
      if (indexOfSearchedKey == -1) {
        selected.push(value);
      } else {
        selected.splice(indexOfSearchedKey, 1);
      }
      this.setSelectedValues(selected);

      if (selected.length == 0) {
        this.uncheckAllCheckboxesExceptUndefined();
      }
    } else {
      // prevent event emit if value hasn't changed
      if (selected.length == 0) {
        emitEvent = false;
      }
      selected.length = 0;

      this.uncheckAllCheckboxesExceptUndefined();
    }

    this.selectedValues.set(selected);

    if (emitEvent) {
      this.buttonClicked.emit(selected);
    }
  };

  handleSingleSelect(value: string | number | undefined) {
    if (value !== undefined) {
      this.selectedValues.set([value]);
    } else {
      this.selectedValues.set([]);
    }
    this.setSelectedValues([value]);
    this.buttonClicked.emit(this.selectedValues());
  }

  uncheckAllCheckboxesExceptUndefined = (): void => {
    this.buttons().forEach(element => {
      const checkboxValue = element.value;
      element.selected = checkboxValue == undefined || checkboxValue == 'undefined';
    });
  };

  getButtonId(buttonId: string | null | undefined) {
    return this.elementId + '_' + buttonId;
  }

  setSelectedValues = (selectedValues: (string | number | undefined)[]): void => {
    this.buttons().forEach(element => {
      const indexOfSearchedKey = selectedValues.findIndex(
        x => x?.toString() == element.value?.toString(),
      );
      element.selected = indexOfSearchedKey > -1;
    });
  };
}
