<div class="container py-8 mx-auto lg:py-8">
  <div class="mt-2 mx-4">
    <axpo-typography [variant]="'title-xl'" [weight]="'bold'">
      {{ 'switchboard.overview' | transloco }}
    </axpo-typography>
  </div>
  <app-tabs (tabSelected)="tabSelectionChanged($event)" [tabItems]="circleTabs()"></app-tabs>
  <div class="mt-8">
    @for (circle of circles(); track circle.id) {
      <div class="rounded-lg shadow-lg p-4 hover:cursor-pointer">
        <div
          class="mt-2 mx-4 flex justify-between flex-row"
          (click)="changeState(circle.id)"
          aria-hidden="true"
        >
          <div class="flex">
            <axpo-typography [variant]="'title-m'">
              {{ circle.name }}
            </axpo-typography>
            <div
              class="w-5 h-5 ml-4 mt-1"
              [innerHTML]="getSvg()['shortcut']"
              (click)="navigateToCircle(circle.id)"
              aria-hidden="true"
            ></div>
          </div>
          <div>
            @if (isToggled(circle.id)) {
              <div class="w-5 h-5" [innerHTML]="getSvg()['chevron-up']"></div>
            } @else {
              <div class="w-5 h-5" [innerHTML]="getSvg()['chevron-down']"></div>
            }
          </div>
        </div>
        @if (isToggled(circle.id)) {
          <app-circles
            [selectedTab]="selectedTab()"
            [circleId]="circle.id"
            mode="overview"
            [allDisabled]="allDisabled()"
          ></app-circles>
        }
      </div>
    }
  </div>
</div>
