import { OutputEmitterRef, WritableSignal } from '@angular/core';

let debounceTimer: any;

export function debounceSet<T>(
  value: T,
  debounceTime: number,
  signal?: WritableSignal<T>,
  output?: OutputEmitterRef<T>,
): void {
  clearTimeout(debounceTimer);
  debounceTimer = setTimeout(() => {
    if (signal) signal.set(value);
    if (output) output.emit(value);
  }, debounceTime);
}
