import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  output,
  signal,
  untracked,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { TranslocoService } from '@jsverse/transloco';

import {
  AxpoFormElementComponent,
  IOption,
} from '../../../../core/axpo-form-element/axpo-form-element.component';
import { AxpoTypographyComponent } from '../../../../core/axpo-typography/axpo-typography.component';
import { TileService } from '../../../../pages/incident-detail/tile.service';
import {
  IIncidentIncidentResolutionUpdateModel,
  IIncidentUpdateModel,
} from '../../../models/api_models';
import { DataFragments, DetailsService } from '../../../services/details.service';
import { IncidentService } from '../../../services/incident.service';
import { LanguageService } from '../../../services/lang.service';
import { TenantService } from '../../../services/tenant.service';
import { ITileMode } from '../../../utils/tileStates';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-incident-edit-incident-resolution',
  templateUrl: './incident-resolution.html',
  imports: [AxpoFormElementComponent, TranslocoPipe, AxpoTypographyComponent],
})
export class IncidentResolutionComponent {
  saveCompleted = output<string>();
  incidentService = inject(IncidentService);
  tenantService = inject(TenantService);
  detailsService = inject(DetailsService);
  tileService = inject(TileService);
  activatedRoute = inject(ActivatedRoute);
  private translocoService = inject(TranslocoService);
  private langService = inject(LanguageService);

  mode = input.required<ITileMode>();
  incidentSource = signal<string | undefined>(undefined);
  incidentLocation = signal<string | undefined>(undefined);
  damageLine = signal<string | undefined>(undefined);
  damagePlant = signal<string | undefined>(undefined);
  incidentSourceName = signal<string | undefined>(undefined);
  incidentLocationName = signal<string | undefined>(undefined);
  damageLineName = signal<string | undefined>(undefined);
  damagePlantName = signal<string | undefined>(undefined);
  damagePlantLocation = signal<string | undefined>(undefined);
  damageDescription = signal<string | undefined>(undefined);
  substation = signal<string | undefined>(undefined);
  numberInsulators = signal<number | undefined>(undefined);

  isBlocked = signal<boolean>(false);

  incidentSourceOptions = signal<IOption[] | undefined>([]);
  incidentLocationOptions = signal<IOption[] | undefined>([]);
  damageLineOptions = signal<IOption[] | undefined>([]);
  damagePlantOptions = signal<IOption[] | undefined>([]);

  accessCode = computed(() => {
    return this.incidentService.incidentDetails()?.accessCode;
  });
  tenantId = computed(() => {
    const tenantid =
      this.tenantService.tenantId() ||
      (this.activatedRoute.snapshot.paramMap.get('tenantId') ?? undefined);
    return tenantid;
  });

  _init = effect(() => {
    const accessCode = this.accessCode();
    const tenantId = this.tenantId();
    const _activeLanguage = this.langService.getLangSignal()();

    if (accessCode) {
      untracked(() => {
        this.detailsService.loadDataFragment(DataFragments.faultcauses, accessCode, tenantId);
        this.detailsService.loadDataFragment(DataFragments.faultlocations, accessCode, tenantId);
        this.detailsService.loadDataFragment(DataFragments.faultlinedamages, accessCode, tenantId);
        this.detailsService.loadDataFragment(DataFragments.faultplantdamages, accessCode, tenantId);
      });
    }
  });

  _causes = effect(() => {
    const faultCauses =
      this.detailsService.fragmentToSignal[DataFragments.faultcauses].dataSignal();
    if (faultCauses) {
      untracked(() => {
        return this.incidentSourceOptions.set(
          faultCauses.map(option => ({
            value: option.key as string,
            label: this.translocoService.translate(
              ('options.causeEffect.' + option.value) as string,
            ),
          })),
        );
      });
    }
  });

  _location = effect(() => {
    const faultLocations =
      this.detailsService.fragmentToSignal[DataFragments.faultlocations].dataSignal();
    if (faultLocations) {
      untracked(() => {
        this.incidentLocationOptions.set(
          faultLocations.map(option => ({
            value: option.key as string,
            label: this.translocoService.translate(
              ('options.causeLocation.' + option.value) as string,
            ),
          })),
        );
      });
    }
  });

  _linedamages = effect(() => {
    const damageLines =
      this.detailsService.fragmentToSignal[DataFragments.faultlinedamages].dataSignal();
    if (damageLines) {
      untracked(() => {
        this.damageLineOptions.set(
          damageLines.map(option => ({
            value: option.key as string,
            label: this.translocoService.translate(
              ('options.damageLine.' + option.value) as string,
            ),
          })),
        );
      });
    }
  });

  _damagePlants = effect(() => {
    const damagePlants =
      this.detailsService.fragmentToSignal[DataFragments.faultplantdamages].dataSignal();
    if (damagePlants) {
      untracked(() => {
        this.damagePlantOptions.set(
          damagePlants.map(option => ({
            value: option.key as string,
            label: this.translocoService.translate(
              ('options.damagePlant.' + option.value) as string,
            ),
          })),
        );
      });
    }
  });

  _data = effect(() => {
    const faultTroubleshootingData = this.incidentService.incidentDetails();
    if (faultTroubleshootingData) {
      untracked(() => {
        if (faultTroubleshootingData.faultCauseId) {
          this.incidentSource.set(faultTroubleshootingData.faultCauseId.toString());
          this.incidentSourceName.set(faultTroubleshootingData.faultCauseName);
        } else {
          this.incidentSource.set(undefined);
          this.incidentSourceName.set(undefined);
        }

        if (faultTroubleshootingData.faultLocationId) {
          this.incidentLocation.set(faultTroubleshootingData.faultLocationId.toString());
          this.incidentLocationName.set(faultTroubleshootingData.faultLocationName);
        } else {
          this.incidentLocation.set(undefined);
          this.incidentLocationName.set(undefined);
        }

        if (faultTroubleshootingData.faultLineDamageId) {
          this.damageLine.set(faultTroubleshootingData.faultLineDamageId.toString());
          this.damageLineName.set(faultTroubleshootingData.faultLineDamageName);
        } else {
          this.damageLine.set(undefined);
          this.damageLineName.set(undefined);
        }

        if (faultTroubleshootingData.faultPlantDamageId) {
          this.damagePlant.set(faultTroubleshootingData.faultPlantDamageId.toString());
          this.damagePlantName.set(faultTroubleshootingData.faultPlantDamageName);
        } else {
          this.damagePlant.set(undefined);
          this.damagePlantName.set(undefined);
        }

        this.damagePlantLocation.set(faultTroubleshootingData.faultDamagedLocation);
        this.damageDescription.set(faultTroubleshootingData.faultDescription);
        this.substation.set(faultTroubleshootingData.faultSubstation);
        this.numberInsulators.set(faultTroubleshootingData.faultIsolators);
      });
    }
  });

  _tileOnSave = effect(() => {
    const save = this.tileService.incidentResolutionChangedTimestamp();
    if (save && this.tileService.saved().indexOf(save) === -1) {
      this.tileService.saved().push(save);
      untracked(() => {
        this.onSave('confirm');
      });
    }
  });

  onSave(action: 'confirm' | 'cancel') {
    const savingData: IIncidentIncidentResolutionUpdateModel = {
      faultCauseId: this.incidentSource() ? parseInt(this.incidentSource() as string) : undefined,
      faultLocationId: this.incidentLocation()
        ? parseInt(this.incidentLocation() as string)
        : undefined,
      faultLineDamageId: this.damageLine() ? parseInt(this.damageLine() as string) : undefined,
      faultPlantDamageId: this.damagePlant() ? parseInt(this.damagePlant() as string) : undefined,
      faultDamagedLocation: this.damagePlantLocation(),
      faultDescription: this.damageDescription(),
      faultSubstation: this.substation(),
      faultIsolators: this.numberInsulators(),
    };

    const accessCode = this.incidentService.incidentDetails()?.accessCode;
    const tenantId = this.tenantId();
    if (action === 'confirm' && accessCode && tenantId) {
      this.incidentService.updateTroublelshooting(accessCode, tenantId, savingData);
    }

    this.saveCompleted.emit(Date.now().toString());
  }
}
