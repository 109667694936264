<div
  @transformOpacityScale
  class="absolute right-5 z-10 text-text-primary bg-white rounded-md shadow-lg min-w-32 ring-1 ring-black ring-opacity-5 focus:outline-none"
  role="menu"
  tabindex="-1"
  dropdownPosition
>
  <div class="py-1">
    @for (item of items(); track item) {
      <button
        class="block w-full px-4 py-2 text-sm text-left whitespace-nowrap hover:cursor-pointer hover:bg-background-1"
        role="menuitem"
        (click)="clickOption(item); $event.stopPropagation()"
      >
        {{ item.label }}
      </button>
    }
  </div>
</div>
