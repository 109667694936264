<section class="bg-white dark:bg-gray-900">
  @if (
    stepSignals()[activeStep()].saveTrigger() ||
    incidentService.isLoadingIncidentDetails() ||
    incidentService.updateInProgress() ||
    tenantService.isLoadingConfig()
  ) {
    <div class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div class="p-8 bg-white rounded shadow-lg">
        <axpo-spinner size="normal">{{ 'core.loading' | transloco }}</axpo-spinner>
      </div>
    </div>
  }
  <div class="py-8 px-4 mx-auto max-w-screen-xl lg:px-6">
    <axpo-stepper
      [steps]="steps()"
      [activeStep]="activeStep()"
      (activeStepChange)="this.performNavigation($event)"
      [allowForwardStep]="true"
      [validateNavigation]="true"
      [(navigationValid)]="navigationValid"
      (beforeNavigate)="beforeStepperNav($event)"
    ></axpo-stepper>
    @if (activeStep() === 0) {
      <div class="w-full">
        <app-alert
          [saveTrigger]="stepSignals()[0].saveTrigger()"
          (validationError)="validateForm($event, 0)"
          mode="create"
          (saveCompleted)="saveCanceled($event)"
        />
      </div>
    }
    @if (activeStep() === 1) {
      <app-location
        title="{{ 'incidentDetail.location' | transloco }}"
        mode="create"
        (validationError)="validateForm($event, 1)"
      ></app-location>
    }
    @if (activeStep() === 2) {
      <app-edit-details
        [saveTrigger]="stepSignals()[2].saveTrigger()"
        (validationError)="validateForm($event, 2)"
        mode="create"
        (saveCompleted)="saveCanceled($event)"
      ></app-edit-details>
    }
    @if (activeStep() === 3) {
      <app-contacts mode="create" title=""></app-contacts>
    }
    @if (activeStep() === 4) {
      <app-attachments [internalAttachments]="false" mode="create" title=""></app-attachments>
      <div class="text-center mt-4">
        <axpo-typography [variant]="'body-xl'">
          {{ 'incident-wizard.publicAttachmentNote' | transloco }}
        </axpo-typography>
      </div>

      <div class="bg-border-soft p-10 rounded-md mt-8">
        <app-attachments [internalAttachments]="true" mode="create" title=""></app-attachments>
        <div class="text-center mt-4">
          <axpo-typography [variant]="'body-xl'">
            {{ 'incident-wizard.internalAttachmentNote' | transloco }}
          </axpo-typography>
        </div>
      </div>
    }
    @if (activeStep() === 5) {
      <app-tile-component
        [(mode)]="tiles.all['alarm'].mode"
        title="{{ 'incidentDetail.alert' | transloco }}"
        (takenAction)="updateAction($event, 'alarm')"
        [controlButtons]="['confirm', 'cancel']"
        editMode="create"
      >
        <app-alert
          [mode]="tiles.all['alarm'].mode()"
          (saveCompleted)="saveCompleted('alarm')"
        ></app-alert>
      </app-tile-component>

      <app-tile-component
        [(mode)]="tiles.all['location'].mode"
        title="{{ 'incidentDetail.location' | transloco }}"
        editMode="create"
      >
        <app-location
          title="{{ 'incidentDetail.location' | transloco }}"
          [mode]="tiles.all['location'].mode()"
          (validationError)="validationChanged($event, 'location')"
        ></app-location>
      </app-tile-component>

      <app-tile-component
        [(mode)]="tiles.all['details'].mode"
        title="{{ 'incidentDetail.details' | transloco }}"
        [controlButtons]="['confirm', 'cancel']"
        (takenAction)="updateAction($event, 'details')"
        key="details"
        [disabled]="tiles.all['details'].disabled()"
        editMode="create"
      >
        @if (tiles.all['details'].mode() === 'edit') {
          <app-edit-details
            [mode]="tiles.all['details'].mode()"
            (saveCompleted)="saveCompleted('details')"
            (validationError)="validationChanged($event, 'details')"
          ></app-edit-details>
        } @else {
          <app-view-details></app-view-details>
        }
      </app-tile-component>

      <app-tile-component
        [(mode)]="tiles.all['contacts'].mode"
        title="{{ 'incidentDetail.contacts' | transloco }}"
        editMode="create"
      >
        <app-contacts
          [mode]="tiles.all['contacts'].mode()"
          title="{{ 'incidentDetail.contacts' | transloco }}"
        ></app-contacts>
      </app-tile-component>

      <app-tile-component
        [(mode)]="tiles.all['attachments'].mode"
        title="{{ 'incidentDetail.publicAttachments' | transloco }}"
        editMode="create"
      >
        <app-attachments
          [mode]="tiles.all['attachments'].mode()"
          [internalAttachments]="false"
        ></app-attachments>
      </app-tile-component>
      <app-tile-component
        [(mode)]="tiles.all['attachmentsInternal'].mode"
        title="{{ 'incidentDetail.attachmentsInternal' | transloco }}"
        editMode="create"
      >
        <app-attachments
          [mode]="tiles.all['attachmentsInternal'].mode()"
          [internalAttachments]="true"
        ></app-attachments>
      </app-tile-component>
    }

    <div class="mt-8 flex justify-center gap-8">
      @if (activeStep() > 0) {
        <axpo-button (click)="backwardNavClicked()">
          {{ 'incidentCreate.back' | transloco }}
        </axpo-button>
      }

      @if (activeStep() < steps().length) {
        <axpo-button
          [disabled]="
            stepSignals()[activeStep()].isInvalid() || !!stepSignals()[activeStep()].saveTrigger()
          "
          (click)="forwardNavClicked()"
        >
          {{
            (activeStep() !== steps().length - 1
              ? 'incidentCreate.continue'
              : 'incidentCreate.save'
            ) | transloco
          }}
        </axpo-button>
      }
    </div>
  </div>
  @if (shareLink()) {
    <app-share-link
      (shareLinkClosed)="shareLinkClosed($event)"
      [(shareLink)]="shareLink"
      mode="create"
    ></app-share-link>
  }
</section>
