@if (mode() === 'edit') {
<div class="flex flex-col space-y-4">
  <axpo-form-element
    formId="incidentSource"
    label="{{ 'incidentDetail.incidentResolution.incidentSource' | transloco }}"
    formType="select"
    [(value)]="incidentSource"
    [options]="incidentSourceOptions()"
    [disabled]="isBlocked()"
  ></axpo-form-element>
  <axpo-form-element
    formId="incidentLocation"
    label="{{ 'incidentDetail.incidentResolution.incidentLocation' | transloco }}"
    formType="select"
    [(value)]="incidentLocation"
    [options]="incidentLocationOptions()"
    [disabled]="isBlocked()"
  ></axpo-form-element>
  <axpo-form-element
    formId="damageLine"
    label="{{ 'incidentDetail.incidentResolution.damageLine' | transloco }}"
    formType="select"
    [(value)]="damageLine"
    [options]="damageLineOptions()"
    [disabled]="isBlocked()"
  ></axpo-form-element>
  <axpo-form-element
    formId="damagePlant"
    label="{{ 'incidentDetail.incidentResolution.damagePlant' | transloco }}"
    formType="select"
    [(value)]="damagePlant"
    [options]="damagePlantOptions()"
    [disabled]="isBlocked()"
  ></axpo-form-element>
  <axpo-form-element
    formId="damagePlantLocation"
    label="{{ 'incidentDetail.incidentResolution.damagePlantLocation' | transloco }}"
    formType="textarea"
    [(value)]="damagePlantLocation"
    [disabled]="isBlocked()"
  ></axpo-form-element>
  <axpo-form-element
    formId="damageDescription"
    label="{{ 'incidentDetail.incidentResolution.damageDescription' | transloco }}"
    formType="textarea"
    [(value)]="damageDescription"
    [disabled]="isBlocked()"
  ></axpo-form-element>
  <axpo-form-element
    formId="substation"
    label="{{ 'incidentDetail.incidentResolution.substation' | transloco }}"
    formType="textarea"
    [(value)]="substation"
    [disabled]="isBlocked()"
  ></axpo-form-element>
  <axpo-form-element
    formId="numberInsulators"
    label="{{ 'incidentDetail.incidentResolution.numberInsulators' | transloco }}"
    formType="number"
    [(value)]="numberInsulators"
    [disabled]="isBlocked()"
  ></axpo-form-element>
</div>
} @else {
<div class="flex flex-col pt-4 space-y-4">
  <div>
    <axpo-typography [variant]="'body-m'" class="text-text-secondary">
      {{ 'incidentDetail.incidentResolution.incidentSource' | transloco }}
    </axpo-typography>
    <div class="flex">
      @if (!incidentSourceName()){
      <div class="w-8 h-8 text-primary" [innerHTML]="getSvg()['warning-triangle-filled']"></div>
      }
      <axpo-typography [variant]="'body-m'">{{ incidentSourceName() }}</axpo-typography>
    </div>
  </div>

  <div>
    <axpo-typography [variant]="'body-m'" class="text-text-secondary">
      {{ 'incidentDetail.incidentResolution.incidentLocation' | transloco }}
    </axpo-typography>
    <div class="flex">
      @if (!incidentLocationName()) {
      <div class="w-8 h-8 text-primary" [innerHTML]="getSvg()['warning-triangle-filled']"></div>
      }
      <axpo-typography [variant]="'body-m'">{{ incidentLocationName() }}</axpo-typography>
    </div>
  </div>

  <div>
    <axpo-typography [variant]="'body-m'" class="text-text-secondary">
      {{ 'incidentDetail.incidentResolution.damageLine' | transloco }}
    </axpo-typography>
    <div class="flex">
      @if (!damageLineName()){
      <div class="w-8 h-8 text-primary" [innerHTML]="getSvg()['warning-triangle-filled']"></div>
      }
      <axpo-typography [variant]="'body-m'">{{ damageLineName() }}</axpo-typography>
    </div>
  </div>

  <div>
    <axpo-typography [variant]="'body-m'" class="text-text-secondary">
      {{ 'incidentDetail.incidentResolution.damagePlant' | transloco }}
    </axpo-typography>
    <div class="flex">
      @if (!damagePlantName()){
      <div class="w-8 h-8 text-primary" [innerHTML]="getSvg()['warning-triangle-filled']"></div>
      }
      <axpo-typography [variant]="'body-m'">{{ damagePlantName() }}</axpo-typography>
    </div>
  </div>

  <div>
    <axpo-typography [variant]="'body-m'" class="text-text-secondary">
      {{ 'incidentDetail.incidentResolution.damagePlantLocation' | transloco }}
    </axpo-typography>
    <div class="flex">
      @if (!damagePlantLocation()){
      <div class="w-8 h-8 text-primary" [innerHTML]="getSvg()['warning-triangle-filled']"></div>
      }
      <axpo-typography [variant]="'body-m'">{{ damagePlantLocation() }}</axpo-typography>
    </div>
  </div>

  <div>
    <axpo-typography [variant]="'body-m'" class="text-text-secondary">
      {{ 'incidentDetail.incidentResolution.damageDescription' | transloco }}
    </axpo-typography>
    <div class="flex">
      @if (!damageDescription()){
      <div class="w-8 h-8 text-primary" [innerHTML]="getSvg()['warning-triangle-filled']"></div>
      }
      <axpo-typography [variant]="'body-m'">{{ damageDescription() }}</axpo-typography>
    </div>
  </div>

  <div>
    <axpo-typography [variant]="'body-m'" class="text-text-secondary">
      {{ 'incidentDetail.incidentResolution.substation' | transloco }}
    </axpo-typography>
    <div class="flex">
      @if (!substation()){
      <div class="w-8 h-8 text-primary" [innerHTML]="getSvg()['warning-triangle-filled']"></div>
      }
      <axpo-typography [variant]="'body-m'">{{ substation() }}</axpo-typography>
    </div>
  </div>

  <div>
    <axpo-typography [variant]="'body-m'" class="text-text-secondary">
      {{ 'incidentDetail.incidentResolution.numberInsulators' | transloco }}
    </axpo-typography>
    <div class="flex">
      @if (!numberInsulators()){
      <div class="w-8 h-8 text-primary" [innerHTML]="getSvg()['warning-triangle-filled']"></div>
      }
      <axpo-typography [variant]="'body-m'">{{ numberInsulators() }}</axpo-typography>
    </div>
  </div>
</div>
}
