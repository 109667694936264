import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  signal,
  untracked,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';

import { AxpoTypographyComponent } from '../../../core/axpo-typography/axpo-typography.component';
import { TileService } from '../../../pages/incident-detail/tile.service';
import { IncidentService } from '../../services/incident.service';
import { TenantService } from '../../services/tenant.service';
import { isoDateToTime } from '../../utils/date';
import { ITileMode } from '../../utils/tileStates';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-view-details',
  templateUrl: './view-details.component.html',
  imports: [TranslocoPipe, AxpoTypographyComponent],
})
export class ViewDetailsComponent {
  incidentService = inject(IncidentService);
  tenantService = inject(TenantService);

  tileService = inject(TileService);
  activatedRoute = inject(ActivatedRoute);
  categoryName = signal<string | undefined>(undefined);
  creatorName = signal<string | undefined>(undefined);
  description = signal<string | undefined>(undefined);
  timeStamp = signal<string | undefined>(undefined);
  fixedTimeStamp = signal<string | undefined>(undefined);
  faultEffectName = signal<string | undefined>(undefined);
  impactName = signal<string | undefined>(undefined);
  incidentStateName = signal<string | undefined>(undefined);
  incidentTitle = signal<string | undefined>(undefined);
  hasInterruptions = signal<string>('no');
  accessCode = computed(() => {
    return this.incidentService.incidentDetails()?.accessCode;
  });
  tenantId = computed(() => {
    return this.tenantService.tenantId();
  });
  mode = input<ITileMode>('edit');

  _ = effect(() => {
    const details = this.incidentService.incidentDetails();
    untracked(() => {
      this.categoryName.set(details?.incidentCategoryName ?? undefined);
      this.creatorName.set(details?.incidentCreatorName ?? undefined);
      this.description.set(details?.incidentDescription ?? undefined);
      this.timeStamp.set(
        details?.incidentFaultTimeStamp
          ? new Date(details.incidentFaultTimeStamp).toLocaleDateString() +
              ' ' +
              isoDateToTime(details.incidentFaultTimeStamp)
          : undefined,
      );
      this.fixedTimeStamp.set(
        details?.incidentFixedTimeStamp
          ? new Date(details.incidentFixedTimeStamp).toLocaleDateString() +
              ' ' +
              isoDateToTime(details.incidentFixedTimeStamp)
          : undefined,
      );
      this.faultEffectName.set(details?.faultEffectName ?? undefined);
      this.impactName.set(details?.incidentImpactName ?? undefined);
      this.incidentStateName.set(details?.incidentStateName ?? 'pending');
      this.incidentTitle.set(details?.incidentTitle ?? undefined);
      this.hasInterruptions.set(details?.hasInterruptions === 1 ? 'yes' : 'no');
    });
  });

  _incidentChanged = effect(() => {
    const updated = this.incidentService.updateResult();
    if (updated) {
      untracked(() => {
        this.incidentService.getIncidentDetails(
          this.accessCode() as string,
          this.tenantId() as string,
        );
      });
    }
  });

  _onRouteChanged = effect(() => {
    this.activatedRoute.snapshot.url.toString();
    const accessCode = this.activatedRoute.snapshot.paramMap.get('accessCode');
    if (this.mode() === 'create') {
      untracked(() => {
        this.incidentService.getIncidentDetails(accessCode as string);
      });
    }
  });
}
