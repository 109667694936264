<axpo-typography [variant]="'body-m'">
  <nav aria-label="breadcrumb">
    <div class="flex max-w-80">
      <div class="flex items-center justify-center">
        <!-- Check if there are more than 3 items -->
        @if (breadcrumbLength() > 3) {
          <!-- Always display the first breadcrumb item -->
          <div class="px-1 whitespace-nowrap">
            <a [routerLink]="breadcrumbs()[0].value">
              {{ breadcrumbs()[0].label }}
            </a>
          </div>
          <div class="px-2"><ng-container [ngTemplateOutlet]="separatorTemplate" /></div>
          <axpo-dropdown
            [style]="'secondary'"
            [items]="processedBreadcrumb()"
            (onSelect)="router.navigate([$event])"
          />
          <div class="px-2"><ng-container [ngTemplateOutlet]="separatorTemplate" /></div>
          <div class="flex">
            <!-- Display the second last breadcrumb item -->
            <div class="px-1 whitespace-nowrap">
              <a [routerLink]="breadcrumbs()[breadcrumbLength() - 2].value">
                {{ breadcrumbs()[breadcrumbLength() - 2].label }}
              </a>
            </div>
            <div class="px-2"><ng-container [ngTemplateOutlet]="separatorTemplate" /></div>
            <!-- Display the last breadcrumb item -->
            <div class="px-1 whitespace-nowrap">
              {{ breadcrumbs()[breadcrumbLength() - 1].label }}
            </div>
          </div>
        } @else {
          <!-- Display all breadcrumb items if 3 or fewer -->
          @for (breadcrumb of breadcrumbs(); track breadcrumb.label; let last = $last) {
            <div class="flex">
              @if (!last) {
                <div class="px-1 whitespace-nowrap">
                  <a [routerLink]="breadcrumb.value">{{ breadcrumb.label }}</a>
                </div>
                <div class="px-2"><ng-container [ngTemplateOutlet]="separatorTemplate" /></div>
              } @else {
                <div class="px-1 whitespace-nowrap">
                  <div>{{ breadcrumb.label }}</div>
                </div>
              }
            </div>
          }
        }
      </div>
    </div>
  </nav>
</axpo-typography>

<ng-template #separatorTemplate>
  @switch (separator()) {
    @case ('bar') {
      /
    }
    @default {
      >
    }
  }
</ng-template>
