@if (isMenuOpen()) {
  <div
    class="relative left-0 overflow-y-auto z-10 w-full p-3 bg-white border border-border rounded-[4px] shadow-lg border-t-4 border-t-primary"
    [class.h-40]="entries().length > 0"
  >
    <axpo-spinner [class.hidden]="!isLoading()" size="xs">
      {{ 'core.loading' | transloco }}
    </axpo-spinner>
    @if (entries().length > 0) {
      @for (entry of entries(); track entry.id) {
        <div
          role="button"
          tabindex="0"
          aria-hidden="true"
          (click)="entrySelected(entry)"
          class="py-2 text-left w-full"
        >
          <b>{{ entry.title }}</b>
          @if (entry.subtitle) {
            <br />
            <span class="text-gray-500">{{ entry.subtitle }}</span>
          }
        </div>
      }
    } @else if (entries().length === 0) {
      <div>
        <axpo-typography class="ml-2">{{ emptyPlaceholderText() }}</axpo-typography>
      </div>
    }
  </div>
}
