@if (label() && formType() !== 'checkbox') {
  <axpo-typography variant="body-m">{{ label() }}</axpo-typography>
}

@if (formType() === 'select' && options()) {
  <div [class]="'relative' + (noPadding() ? '' : ' pb-4')">
    <div [class]="computedStyle()" (clickOutside)="isSelectMenuOpen.set(false)">
      <button
        type="button"
        (click)="handleClick(); $event.stopPropagation()"
        tabindex="0"
        class="relative flex items-center justify-between w-full h-10 px-3 py-2 group"
        [disabled]="disabled()"
      >
        <div>
          {{ labelOfSelectValue() }}
        </div>
        @if (!disabled()) {
          <ng-container
            [ngTemplateOutlet]="clear"
            [ngTemplateOutletContext]="{ $implicit: 'right-9' }"
          />
          <div class="w-4 h-4 text-primary"><div [innerHTML]="getSvg()['chevron-down']"></div></div>
        }
      </button>

      @if (isSelectMenuOpen()) {
        <div
          @transformOpacityScale
          class="absolute left-0 z-10 w-full mt-2 overflow-y-auto bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-80"
          role="menu"
          tabindex="-1"
          dropdownPosition
        >
          <div class="py-1">
            @for (option of options(); track option.value) {
              <button
                class="block w-full px-4 py-2 text-sm text-left whitespace-nowrap hover:cursor-pointer hover:bg-background-1"
                [class]="option.value === value() ? 'bg-background-2 font-bold' : ''"
                role="menuitem"
                (click)="selectOption(option); $event.stopPropagation()"
              >
                {{ option.label }}
              </button>
            }
          </div>
        </div>
      }
    </div>
    <ng-container [ngTemplateOutlet]="errorMessage" />
  </div>
} @else if (formType() === 'multiselect') {
  <div [class]="'relative' + (noPadding() ? '' : ' pb-4')">
    <div [class]="computedStyle()" (clickOutside)="isSelectMenuOpen.set(false)">
      <button
        type="button"
        (click)="handleClick(); $event.stopPropagation()"
        tabindex="0"
        class="relative flex items-center justify-between w-full h-10 px-3 py-2 group right"
        [disabled]="disabled()"
      >
        <div class="pr-4 truncate text-ellipsis whitespace-nowrap">
          {{ labelOfSelectValue() }}
        </div>
        @if (!disabled()) {
          <ng-container
            [ngTemplateOutlet]="clear"
            [ngTemplateOutletContext]="{ $implicit: 'right-7' }"
          />
          <div class="w-4 h-4 text-primary"><div [innerHTML]="getSvg()['chevron-down']"></div></div>
        }
      </button>

      @if (isSelectMenuOpen()) {
        <div
          @transformOpacityScale
          class="absolute left-0 z-10 w-full mt-2 overflow-y-auto bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-80"
          role="menu"
          tabindex="-1"
          dropdownPosition
        >
          <div class="py-1">
            @for (option of options(); track option.value) {
              <button
                class="block w-full px-4 py-2 text-sm text-left whitespace-nowrap hover:cursor-pointer hover:bg-background-1"
                role="menuitem"
                (click)="selectOptionMultiple(option); $event.stopPropagation()"
              >
                <input
                  type="checkbox"
                  [checked]="value() && $any(value()).includes(option.value)"
                  class="mr-0.5 w-3 h-3 rounded-sm"
                />
                {{ option.label }}
              </button>
            }
          </div>
        </div>
      }
    </div>
    <ng-container [ngTemplateOutlet]="errorMessage" />
  </div>
} @else if (formType() === 'text') {
  <div [class]="'relative w-full group' + (noPadding() ? '' : ' pb-4')">
    <input
      id="text"
      type="text"
      [attr.minlength]="min()"
      [attr.maxlength]="max()"
      [attr.pattern]="pattern()"
      [attr.placeholder]="placeholder()"
      [attr.size]="size()"
      [class]="computedStyle()"
      [(ngModel)]="value"
      [required]="required()"
      [disabled]="disabled()"
      (blur)="touched.set(true)"
    />
    @if (!disabled()) {
      <ng-container
        [ngTemplateOutlet]="clear"
        [ngTemplateOutletContext]="{ $implicit: 'right-4' }"
      />
    }
    <ng-container [ngTemplateOutlet]="errorMessage" />
  </div>
} @else if (formType() === 'email') {
  <div [class]="'relative w-full group' + (noPadding() ? '' : ' pb-4')">
    <input
      id="email"
      type="email"
      [attr.minlength]="min()"
      [attr.maxlength]="max()"
      [attr.pattern]="pattern()"
      [attr.placeholder]="placeholder()"
      [attr.size]="size()"
      [multiple]="multiple()"
      [class]="computedStyle()"
      [(ngModel)]="value"
      [required]="required()"
      [disabled]="disabled()"
      (blur)="touched.set(true)"
    />
    @if (!disabled()) {
      <ng-container
        [ngTemplateOutlet]="clear"
        [ngTemplateOutletContext]="{ $implicit: 'right-4' }"
      />
    }
    <ng-container [ngTemplateOutlet]="errorMessage" />
  </div>
} @else if (formType() === 'tel') {
  <div [class]="'relative w-full group' + (noPadding() ? '' : ' pb-4')">
    <input
      id="tel"
      type="tel"
      [attr.minlength]="min()"
      [attr.maxlength]="max()"
      [attr.pattern]="pattern()"
      [attr.placeholder]="placeholder()"
      [attr.size]="size()"
      [class]="computedStyle()"
      [(ngModel)]="value"
      [required]="required()"
      [disabled]="disabled()"
      (blur)="touched.set(true)"
    />
    @if (!disabled()) {
      <ng-container
        [ngTemplateOutlet]="clear"
        [ngTemplateOutletContext]="{ $implicit: 'right-4' }"
      />
    }
    <ng-container [ngTemplateOutlet]="errorMessage" />
  </div>
} @else if (formType() === 'file') {
  <div [class]="'relative w-full group' + (noPadding() ? '' : ' pb-4')">
    <input
      id="fileUploadButton"
      type="file"
      [attr.accept]="accept()"
      [multiple]="multiple()"
      [disabled]="disabled()"
      [required]="required()"
      hidden
      (change)="fileAdded($event)"
      (blur)="touched.set(true)"
    />
    <label
      for="fileUploadButton"
      [class]="computedStyle()"
      class="flex items-center px-3 hover:cursor-pointer"
      [ngClass]="{ 'justify-center': !value() }"
    >
      @if (value(); as value) {
        {{ $any(value).name }}
      } @else {
        <div class="w-6 h-6 text-text-secondary"><div [innerHTML]="getSvg()['upload']"></div></div>
      }
    </label>
    @if (!disabled()) {
      <ng-container
        [ngTemplateOutlet]="clear"
        [ngTemplateOutletContext]="{ $implicit: 'right-4' }"
      />
    }
    <ng-container [ngTemplateOutlet]="errorMessage" />
  </div>
} @else if (['number', 'date', 'time'].includes(formType())) {
  <div [class]="'relative w-full group' + (noPadding() ? '' : ' pb-4')">
    <input
      [type]="formType()"
      [class]="computedStyle()"
      [(ngModel)]="value"
      [min]="min()"
      [max]="max()"
      [attr.placeholder]="placeholder()"
      [attr.step]="step()"
      [disabled]="disabled()"
      [required]="required()"
      (blur)="touched.set(true)"
    />
    @if (!disabled()) {
      <ng-container
        [ngTemplateOutlet]="clear"
        [ngTemplateOutletContext]="{ $implicit: 'right-9' }"
        class="right-9"
      />
    }
    <ng-container [ngTemplateOutlet]="errorMessage" />
  </div>
} @else if (formType() === 'checkbox') {
  <div [class]="'relative w-full group' + (noPadding() ? '' : ' pb-4')">
    <input
      type="checkbox"
      [class]="computedStyle()"
      [(ngModel)]="value"
      [disabled]="disabled()"
      [required]="required()"
      (blur)="touched.set(true)"
    />
    {{ label() }}
    <ng-container [ngTemplateOutlet]="errorMessage" />
  </div>
} @else if (formType() === 'textarea') {
  <div [class]="'relative w-full group' + (noPadding() ? '' : ' pb-4')">
    <textarea
      [rows]="rows()"
      [class]="computedStyle()"
      [attr.minlength]="min()"
      [attr.maxlength]="max()"
      [attr.placeholder]="placeholder()"
      [(ngModel)]="value"
      [disabled]="disabled()"
      [required]="required()"
      (blur)="touched.set(true)"
    ></textarea>
    @if (!disabled()) {
      <ng-container
        [ngTemplateOutlet]="clear"
        [ngTemplateOutletContext]="{ $implicit: 'right-4' }"
      />
    }
    <ng-container [ngTemplateOutlet]="errorMessage" />
  </div>
}

<ng-template #clear let-additionalClasses>
  @if (value()) {
    <button
      class="hidden group-hover:block absolute top-1.5 text-text-secondary text-lg cursor-pointer"
      [class]="additionalClasses"
      (click)="clearSelection(); $event.stopPropagation()"
    >
      &times;
    </button>
  }
</ng-template>

<ng-template #errorMessage>
  @if (!disabled() && (errorMessageInput() || displayErrorMessage())) {
    <div class="px-2 overflow-x-auto whitespace-nowrap hideSrollbar text-primary">
      <axpo-typography variant="body-s">
        @if (displayErrorMessage() && hasError()) {
          {{ hasError()?.message }}
        } @else {
          {{ errorMessageInput() }}
        }
      </axpo-typography>
    </div>
  }
</ng-template>
