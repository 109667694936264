@if (mode() === 'edit' || mode() === 'create') {
  <div class="flex flex-col pt-4 space-y-4">
    <div class="flex flex-col md:flex-row">
      <axpo-form-element
        formId="alertName"
        [formType]="'text'"
        [(value)]="this.alertName"
        [label]="'incidentDetail.panelAlert.alertDescriptionLabel' | transloco"
        class="flex-1 lg:w-full md:mr-3"
        [required]="true"
        [min]="5"
        (errorEmitter)="formValidation($event, 'alertName')"
        [errorMessageInput]="formValidator?.getField('alertName')?.errorMessage()"
      ></axpo-form-element>

      <axpo-form-element
        formId="alertMessageInfo"
        [formType]="'text'"
        [(value)]="this.alertMessageInfo"
        [label]="'incidentDetail.panelAlert.alertIdLabel' | transloco"
        class="flex-1 lg:w-full"
      ></axpo-form-element>
    </div>
    <div class="my-4">
      <axpo-form-element
        [label]="'incidentDetail.panelAlert.protectionReportRequired' | transloco"
        formType="checkbox"
        [(value)]="protectionReportRequired"
        formId="protectionReportRequired"
      ></axpo-form-element>
    </div>
    <div>
      <axpo-selectable-buttons
        [buttons]="buttons()"
        [multiselect]="true"
        [label]="'incidentDetail.panelAlert.voltageLabel' | transloco"
        (buttonClicked)="voltageSelected($event.toString())"
        [useMobile2Cols]="true"
        [mobileBreakpoint]="breakpoint"
      ></axpo-selectable-buttons>
      <div class="absolute px-2 text-primary">
        <axpo-typography variant="body-s">
          {{ formValidator?.getField('voltage')?.errorMessage() }}
        </axpo-typography>
      </div>
    </div>
    <div class="flex justify-between flex-row gap-1">
      <axpo-form-element
        formId="alertRelayData1"
        [formType]="'text'"
        [(value)]="this.alertRelayData1"
        [label]="'incidentDetail.panelAlert.alertRelayData1Label' | transloco"
        class="flex-1 lg:w-full md:mr-3"
      ></axpo-form-element>

      <axpo-form-element
        formId="alertErrorSource1"
        [formType]="'text'"
        [(value)]="this.alertErrorSource1"
        [label]="'incidentDetail.panelAlert.alertErrorSource1Label' | transloco"
        class="flex-1 lg:w-full"
      ></axpo-form-element>
    </div>

    <div class="flex flex-row gap-1">
      <axpo-form-element
        formId="alertRelayData2"
        [formType]="'text'"
        [(value)]="this.alertRelayData2"
        [label]="'incidentDetail.panelAlert.alertRelayData2Label' | transloco"
        class="flex-1 lg:w-full md:mr-3"
      ></axpo-form-element>

      <axpo-form-element
        formId="alertErrorSource2"
        [formType]="'text'"
        [(value)]="this.alertErrorSource2"
        [label]="'incidentDetail.panelAlert.alertErrorSource2Label' | transloco"
        class="flex-1 lg:w-full"
      ></axpo-form-element>
    </div>
    <div class="flex flex-row gap-1">
      <axpo-form-element
        formId="alertRelayData3"
        [formType]="'text'"
        [(value)]="this.alertRelayData3"
        [label]="'incidentDetail.panelAlert.alertRelayData3Label' | transloco"
        class="flex-1 lg:w-full md:mr-3"
      ></axpo-form-element>

      <axpo-form-element
        formId="alertErrorSource3"
        [formType]="'text'"
        [(value)]="this.alertErrorSource3"
        [label]="'incidentDetail.panelAlert.alertErrorSource3Label' | transloco"
        class="flex-1 lg:w-full"
        [debounceTime]="0"
      ></axpo-form-element>
    </div>

    <div class="flex flex-row gap-1">
      <axpo-form-element
        formId="alertRelayData4"
        [formType]="'text'"
        [(value)]="this.alertRelayData4"
        [label]="'incidentDetail.panelAlert.alertRelayData4Label' | transloco"
        class="flex-1 lg:w-full md:mr-3"
      ></axpo-form-element>

      <axpo-form-element
        formId="alertErrorSource4"
        [formType]="'text'"
        [(value)]="this.alertErrorSource4"
        [label]="'incidentDetail.panelAlert.alertErrorSource4Label' | transloco"
        class="flex-1 lg:w-full"
      ></axpo-form-element>
    </div>
  </div>
}
@if (mode() === 'view' || mode() === 'locked') {
  <div class="flex flex-col pt-4 space-y-4">
    <div>
      <axpo-typography [variant]="'body-m'" class="text-text-secondary">
        {{ 'incidentDetail.panelAlert.alertDescriptionLabel' | transloco }}
      </axpo-typography>
      <axpo-typography [variant]="'body-m'">{{ alertMessageInfo() }}</axpo-typography>

      <axpo-typography [variant]="'body-m'" class="text-text-secondary">
        {{ 'incidentDetail.panelAlert.alertIdLabel' | transloco }}
      </axpo-typography>
      <axpo-typography [variant]="'body-m'">{{ alertName() }}</axpo-typography>
      <axpo-typography [variant]="'body-m'" class="text-text-secondary">
        {{ 'incidentDetail.panelAlert.protectionReportRequired' | transloco }}
      </axpo-typography>
      <axpo-typography [variant]="'body-m'">
        {{ (protectionReportRequired() ? 'core.yes' : 'core.no') | transloco }}
      </axpo-typography>
    </div>
    <div>
      <axpo-typography [variant]="'body-m'" class="text-text-secondary">
        {{ 'incidentDetail.panelAlert.voltageLabel' | transloco }}
      </axpo-typography>
      @for (item of this.incidentService.incidentDetails()?.voltages; track $index) {
        <axpo-typography
          [variant]="'body-m'"
          [classList]="[getTextColorByVoltageKey(item.key ?? '')]"
        >
          {{ item.value }}
        </axpo-typography>
      }
    </div>

    <div class="flex flex-col justify-between md:flex-row">
      <div class="w-1/2">
        <axpo-typography [variant]="'body-m'" class="text-text-secondary">
          {{ 'incidentDetail.panelAlert.alertRelayData1Label' | transloco }}
        </axpo-typography>
        <axpo-typography [variant]="'body-m'">{{ alertRelayData1() }}</axpo-typography>
      </div>

      <div class="w-1/2">
        <axpo-typography [variant]="'body-m'" class="text-text-secondary">
          {{ 'incidentDetail.panelAlert.alertErrorSource1Label' | transloco }}
        </axpo-typography>
        <axpo-typography [variant]="'body-m'">{{ alertErrorSource1() }}</axpo-typography>
      </div>
    </div>

    <div class="flex flex-col justify-between md:flex-row">
      <div class="w-1/2">
        <axpo-typography [variant]="'body-m'" class="text-text-secondary">
          {{ 'incidentDetail.panelAlert.alertRelayData2Label' | transloco }}
        </axpo-typography>
        <axpo-typography [variant]="'body-m'">{{ alertRelayData2() }}</axpo-typography>
      </div>

      <div class="w-1/2">
        <axpo-typography [variant]="'body-m'" class="text-text-secondary">
          {{ 'incidentDetail.panelAlert.alertErrorSource2Label' | transloco }}
        </axpo-typography>
        <axpo-typography [variant]="'body-m'">{{ alertErrorSource2() }}</axpo-typography>
      </div>
    </div>

    <div class="flex flex-col justify-between md:flex-row">
      <div class="w-1/2">
        <axpo-typography [variant]="'body-m'" class="text-text-secondary">
          {{ 'incidentDetail.panelAlert.alertRelayData3Label' | transloco }}
        </axpo-typography>
        <axpo-typography [variant]="'body-m'">{{ alertRelayData3() }}</axpo-typography>
      </div>

      <div class="w-1/2">
        <axpo-typography [variant]="'body-m'" class="text-text-secondary">
          {{ 'incidentDetail.panelAlert.alertErrorSource3Label' | transloco }}
        </axpo-typography>
        <axpo-typography [variant]="'body-m'">{{ alertErrorSource3() }}</axpo-typography>
      </div>
    </div>

    <div class="flex flex-col justify-between md:flex-row">
      <div class="w-1/2">
        <axpo-typography [variant]="'body-m'" class="text-text-secondary">
          {{ 'incidentDetail.panelAlert.alertRelayData4Label' | transloco }}
        </axpo-typography>
        <axpo-typography [variant]="'body-m'">{{ alertRelayData4() }}</axpo-typography>
      </div>

      <div class="w-1/2">
        <axpo-typography [variant]="'body-m'" class="text-text-secondary">
          {{ 'incidentDetail.panelAlert.alertErrorSource4Label' | transloco }}
        </axpo-typography>
        <axpo-typography [variant]="'body-m'">{{ alertErrorSource4() }}</axpo-typography>
      </div>
    </div>
  </div>
}
