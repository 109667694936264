import { ChangeDetectionStrategy, Component, OnInit, computed, effect, inject, input, signal, untracked } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

import { AxpoFormElementComponent, IOption } from '../../../../core/axpo-form-element/axpo-form-element.component';
import { AxpoSelectableButtonsComponent, IButton } from '../../../../core/axpo-selectable-buttons/axpo-selectable-buttons.component';
import { AxpoTypographyComponent } from '../../../../core/axpo-typography/axpo-typography.component';
import { ITileMode } from '../../../../pages/incident-detail/incident-detail.component';
import { IIncidentDetailsUpdateModel, IIncidentUpdateModel } from '../../../models/api_models';
import { DataFragments, DetailsService } from '../../../services/details.service';
import { IncidentService } from '../../../services/incident.service';
import { LanguageService } from '../../../services/lang.service';
import { TenantService } from '../../../services/tenant.service';
import { addTimeToDate, dateToDay, isoDateToTime } from '../../../utils/date';
import { getTitle } from '../../../utils/incident';

@Component({
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-incident-edit-details',
    templateUrl: './panel-edit-details.component.html',
    imports: [AxpoFormElementComponent, AxpoSelectableButtonsComponent, TranslocoPipe, AxpoTypographyComponent],
  })
  
  export class PanelEditDetailsComponent implements OnInit {
    mode = input.required<ITileMode>();
    takenAction = input<'confirm' | 'cancel' | undefined>(undefined);
    activatedRoute = inject(ActivatedRoute);
    detailsService = inject(DetailsService);
    incidentService = inject(IncidentService);
    langService = inject(LanguageService);
    tenantService = inject(TenantService);
    translocoService = inject(TranslocoService);
    isBlocked = computed(() => this.mode() !== 'edit' );

    categoryId = signal<number | undefined>(undefined);
    categoryName = signal<string | undefined>(undefined);
    creatorName = signal<string | undefined>(undefined);
    description = signal<string | undefined>(undefined);
    timeStamp = signal<string | undefined>(undefined);
    fixedTimeStamp = signal<string | undefined>(undefined);
    timeStampTime = signal<string | undefined>(undefined);
    fixedTimeStampTime = signal<string | undefined>(undefined);
    faultEffectId = signal<string | undefined>(undefined);
    faultEffectName = signal<string | undefined>(undefined);
    impactId = signal<number | undefined>(undefined);
    impactName = signal<string | undefined>(undefined);
    lastModificationTimeStamp = signal<Date | undefined>(undefined);
    incidentStateId = signal<number | undefined>(undefined);
    incidentStateName = signal<string | undefined>(undefined);
    incidentTitle = signal<string | undefined>(undefined);
    impactOptions = signal<IOption []>([]);
    hasInterruptions = signal<boolean>(false);

    alarmButtons = signal<IButton[]>([]);
    stateButtons = signal<IButton[]>([]);
    impactButtons = signal<IButton[]>([]);
    faultButtons = signal<IButton[]>([]);

    ngOnInit(): void {
      const accessCode = this.activatedRoute.snapshot.paramMap.get('accessCode');
      const tenantId = this.activatedRoute.snapshot.paramMap.get('tenantId');
      
      if (accessCode) {
        this.detailsService.loadDataFragment(DataFragments.incidentstates, accessCode);
        this.detailsService.loadDataFragment(DataFragments.incidentimpacts, accessCode);
        this.detailsService.loadDataFragment(DataFragments.incidentcategories, accessCode);

        if (tenantId) {
          this.detailsService.loadDataFragment(DataFragments.faulteffects, accessCode, tenantId);
        }
      }
    }

    _initDetailsMeta = effect(() => {
      const states = this.detailsService.fragmentToSignal[DataFragments.incidentstates].dataSignal();
      const effects = this.detailsService.fragmentToSignal[DataFragments.faulteffects].dataSignal();
      const alarmCategories= this.detailsService.fragmentToSignal[DataFragments.incidentcategories].dataSignal();
      const impacts = this.detailsService.fragmentToSignal[DataFragments.incidentimpacts].dataSignal();
      const _activeLanguage = this.langService.getLangSignal()();
      
      const buttonStatesData = states?.map((state) => {
        return { id: state.value, value: state.key as string, selected: this.incidentStateName() === state.value, title: this.translocoService.translate('home.filterState.' + state.value), colors: undefined };
      });

      const alarmsButtonData = alarmCategories?.map((alarm) => {
        return { id: alarm.value, value: alarm.key as string, selected: this.categoryName() === alarm.value, title: alarm.value as string, colors: undefined };
      });

      let effectsData: IOption[] = [];
      if (effects) {
        effectsData = effects?.map((effect) => {
          return { value: effect.key as string, label: effect.value as string };
        });
      }
      
      const impactsData = impacts?.map((impact) => {
        return { id: impact.value, value: impact.key as string, selected: this.impactName() === impact.value, title: this.translocoService.translate('incidentDetail.' + impact.value), colors: undefined };
      });

      const impactsBtnData = [
        { id: 'yes', value: 1, selected: this.hasInterruptions(), title: this.translocoService.translate('core.yes'), colors: undefined },
        { id: 'no', value: 0, selected: !this.hasInterruptions(), title: this.translocoService.translate('core.no'), colors: undefined }
      ];
      
      untracked(() => {
        if (buttonStatesData) {
          this.stateButtons.set(buttonStatesData);
        }
        if (alarmsButtonData) {
          this.alarmButtons.set(alarmsButtonData);
        }
        if (impactsBtnData) {
          this.faultButtons.set(impactsBtnData);
        }
        if (impactsData) {
          this.impactButtons.set(impactsData);
        }
        if (effectsData) {
          this.impactOptions.set(effectsData);
        }
      });
    });

    _ = effect(() => {
        const details = this.incidentService.incidentDetails();
        
        untracked(() => {
          this.categoryId.set(details?.incidentCategoryId ?? undefined);
          this.categoryName.set(details?.incidentCategoryName ?? undefined);
          this.creatorName.set(details?.incidentCreatorName ?? undefined);
          this.description.set(details?.incidentDescription ?? undefined);
          this.timeStamp.set(details?.incidentFaultTimeStamp ? dateToDay(new Date(details.incidentFaultTimeStamp).getTime()): undefined)
          this.fixedTimeStamp.set(details?.incidentFixedTimeStamp ? dateToDay(new Date(details.incidentFixedTimeStamp).getTime()): undefined);
          this.timeStampTime.set(details?.incidentFaultTimeStamp ? isoDateToTime(details.incidentFaultTimeStamp): undefined)
          this.fixedTimeStampTime.set(details?.incidentFixedTimeStamp ? isoDateToTime(details.incidentFixedTimeStamp): undefined);
          this.faultEffectId.set(details?.faultEffectId?.toString() ?? undefined);
          this.faultEffectName.set(details?.faultEffectName ?? undefined);
          this.impactId.set(details?.incidentImpactId ?? undefined);
          this.impactName.set(details?.incidentImpactName ?? undefined);
          this.lastModificationTimeStamp.set(details?.incidentLastModificationTimeStamp ?? undefined);
          this.incidentStateId.set(details?.incidentStateId ?? undefined);
          this.incidentStateName.set(details?.incidentStateName ?? undefined);
          this.incidentTitle.set(details?.incidentTitle ?? undefined);
          this.hasInterruptions.set(details?.hasInterruptions === 1);
        });
    });

    _triggerSave = effect(() => {
      const takenAction = this.takenAction();
      untracked(() => {
        if (takenAction === 'confirm') {
          this.onSave(takenAction);
        }
      });
    });

    alarmButtonsClick(selected: any) {
      if (selected.length > 0) {
        this.categoryId.set(selected[0]);
      }
    }

    stateButtonsClick(selected: any) {
      if (selected.length > 0) {
        this.incidentStateId.set(selected[0]);
      }
    }

    impactButtonsClick(selected: any) {
      if (selected.length > 0) {
        this.impactId.set(selected[0]);
      }
    }

    faultButtonsClick(selected: any) {
      if (selected.length > 0) {
        this.hasInterruptions.set(selected[0] == 1);
      }
    }

    onSave(action: 'confirm' | 'cancel') {
      const dateTimeFault = addTimeToDate(this.timeStamp(), this.timeStampTime());
      const dateTimeFixed = addTimeToDate(this.fixedTimeStamp(), this.fixedTimeStampTime());  

      const savingData: IIncidentDetailsUpdateModel = {
        incidentCategoryId: this.categoryId(),
        incidentDescription: this.description(),
        incidentTitle: this.incidentTitle(),
        faultEffectId: this.faultEffectId(),
        incidentStateId: this.incidentStateId(),
        incidentImpactId: this.impactId(),
        incidentFaultTimeStamp: dateTimeFault,
        incidentFixedTimeStamp: dateTimeFixed,
        hasInterruptions: (this.hasInterruptions() === true) ? 1: 0
      }

      const incidentDetails = this.incidentService.incidentDetails();
      const apiData: IIncidentUpdateModel = {
        alertErrorSource1: incidentDetails?.alertErrorSource1,
        alertErrorSource2: incidentDetails?.alertErrorSource2,
        alertErrorSource3: incidentDetails?.alertErrorSource3,
        alertErrorSource4: incidentDetails?.alertErrorSource4,
        alertMessageInfo: incidentDetails?.alertMessageInfo,
        alertName: incidentDetails?.alertName,
        alertRelayData1: incidentDetails?.alertRelayData1,
        alertRelayData2: incidentDetails?.alertRelayData2,
        alertRelayData3: incidentDetails?.alertRelayData3,
        alertRelayData4: incidentDetails?.alertRelayData4,
        faultCauseId: incidentDetails?.faultCauseId,
        faultDamagedLocation: incidentDetails?.faultDamagedLocation,
        faultDescription: incidentDetails?.faultDescription,
        faultEffectId: savingData.faultEffectId,
        faultIsolators: incidentDetails?.faultIsolators,
        faultLineDamageId: incidentDetails?.faultLineDamageId,
        faultLocationId: incidentDetails?.faultLocationId,
        faultPlantDamageId: incidentDetails?.faultPlantDamageId,
        faultSubstation: incidentDetails?.faultSubstation,
        incidentCategoryId: savingData.incidentCategoryId,
        incidentDescription: savingData.incidentDescription,
        incidentFaultTimeStamp: savingData.incidentFaultTimeStamp,
        incidentFixedTimeStamp: savingData.incidentFixedTimeStamp,
        incidentImpactId: savingData.incidentImpactId,
        incidentStateId: savingData.incidentStateId,
        incidentTitle: getTitle(incidentDetails?.voltages, incidentDetails?.alertName, this.detailsService.fragmentToSignal[DataFragments.incidentimpacts].dataSignal()?.find((elem) => { return elem.key === savingData.incidentImpactId })),
        hasInterruptions: savingData.hasInterruptions
      };
      
      const accessCode = this.incidentService.incidentDetails()?.accessCode;
      const tenantId = this.tenantService.tenantId();
      if (action === 'confirm' && accessCode && tenantId) {
        this.incidentService.update(accessCode, tenantId, apiData);
      }
    }
}