<div class="flex-1 space-y-4 mb-8 p-4 rounded-lg shadow-lg">
  <div class="flex items-center justify-between">
    <axpo-typography [variant]="'title-m'">
      {{ title() }}
    </axpo-typography>
    @if (mode() === 'view') {
      <div role="button" (click)="editTile()" tabindex="0" aria-hidden="true">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="operation/edit">
            <mask
              id="mask0_10469_12808"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="24"
            >
              <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_10469_12808)">
              <path
                id="vector"
                d="M3 21.5164C2.73205 21.5164 2.4984 21.4199 2.29905 21.227C2.09968 21.034 2 20.7972 2 20.5164C2 20.2485 2.09968 20.0148 2.29905 19.8154C2.4984 19.6161 2.73205 19.5164 3 19.5164H21C21.268 19.5164 21.5016 19.6129 21.701 19.8059C21.9003 19.9988 22 20.2356 22 20.5164C22 20.7844 21.9003 21.018 21.701 21.2174C21.5016 21.4167 21.268 21.5164 21 21.5164H3ZM5.80768 16.5164C5.57563 16.5164 5.383 16.4398 5.2298 16.2866C5.0766 16.1334 5 15.9408 5 15.7087V14.4799C5 14.3722 5.01859 14.2718 5.05578 14.1789C5.09294 14.0859 5.15512 13.9959 5.2423 13.9087L13.8192 5.37023L16.1462 7.69715L7.6077 16.2741C7.52052 16.3613 7.43045 16.4234 7.3375 16.4606C7.24455 16.4978 7.14423 16.5164 7.03655 16.5164H5.80768ZM6 15.5164H6.9L14.7308 7.69715L13.8192 6.78563L6 14.6164V15.5164ZM17.0404 6.71255L14.7135 4.42408L15.8596 3.27793C16.043 3.09075 16.2699 2.99811 16.5404 3.00003C16.8109 3.00196 17.0378 3.0946 17.2212 3.27793L18.1481 4.20486C18.3314 4.38819 18.4295 4.61415 18.4423 4.88273C18.4551 5.15133 18.3699 5.37922 18.1866 5.56641L17.0404 6.71255Z"
              />
            </g>
          </g>
        </svg>
      </div>
    }
    @if (mode() === 'edit') {
      <div role="button" (click)="viewTile()" tabindex="0" aria-hidden="true">
        <svg
          version="1.2"
          id="Ebene_1"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          overflow="visible"
          xml:space="preserve"
          width="24"
          height="24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:svg="http://www.w3.org/2000/svg"
        >
          <defs id="defs15" />
          <g
            id="g10"
            transform="matrix(0.81300813,0,0,0.81300813,-7.625203,-7.026829)"
            style="stroke-width: 1.23"
          >
            <path
              d="M 24.4,32.1 C 17.7,32.1 13,26.2 12.8,25.9 l -0.1,-0.2 0.1,-0.2 c 0,-0.1 3.8,-9.5 11.8,-9.6 1.3,0 2.5,0.2 3.6,0.6 0.2,0.1 0.3,0.3 0.2,0.5 -0.1,0.2 -0.3,0.3 -0.5,0.2 -1,-0.4 -2.2,-0.6 -3.4,-0.6 -6.9,0.1 -10.5,7.6 -11.1,9 0.9,1 5.9,6.5 12.4,5.6 7.7,-1.1 10.3,-5.7 10.7,-6.6 -0.5,-0.8 -2.7,-4.2 -6.2,-6.3 -0.2,-0.1 -0.2,-0.3 -0.1,-0.5 0.1,-0.2 0.3,-0.2 0.5,-0.1 4,2.5 6.4,6.6 6.5,6.7 l 0.1,0.2 -0.1,0.2 c -0.1,0.2 -2.4,5.9 -11.4,7.2 -0.3,0 -0.9,0.1 -1.4,0.1 z"
              id="path2"
              style="stroke-width: 1.5129"
            />
            <path
              fill="none"
              stroke-width="0.9225"
              stroke-linecap="round"
              stroke-miterlimit="10"
              d="M 30.7,18"
              id="path4"
            />
            <path
              d="m 24.7,29 c -2.7,0 -5,-2.2 -5,-5 0,-2.8 2.2,-5 5,-5 2.8,0 5,2.2 5,5 0,2.8 -2.2,5 -5,5 z m 0,-9.2 c -2.3,0 -4.2,1.9 -4.2,4.2 0,2.3 1.9,4.2 4.2,4.2 2.3,0 4.2,-1.9 4.2,-4.2 0.1,-2.3 -1.8,-4.2 -4.2,-4.2 z"
              id="path6"
              style="stroke-width: 1.5129"
            />
            <path
              d="m 24.8,26.7 c -1.5,0 -2.8,-1.2 -2.8,-2.8 0,-1.6 1.2,-2.8 2.8,-2.8 1.6,0 2.8,1.2 2.8,2.8 0,1.6 -1.2,2.8 -2.8,2.8 z m 0,-4.8 c -1.1,0 -2,0.9 -2,2 0,1.1 0.9,2 2,2 1.1,0 2,-0.9 2,-2 0.1,-1.1 -0.9,-2 -2,-2 z"
              id="path8"
              style="stroke-width: 1.5129"
            />
          </g>
        </svg>
      </div>
    }
  </div>
  <ng-content></ng-content>
  @if (mode() === 'edit' && controlButtons().length > 0) {
    <div class="text-right pt-4 border-t-2 border-border-soft-300">
      @if (hasCancelButton()) {
        <axpo-button (click)="onSave('cancel')" [type]="'ghost'" [style]="'secondary'">
          {{ 'core.cancel' | transloco }}
        </axpo-button>
      }

      @if (hasConfirmButton()) {
        <axpo-button [disabled]="disabled()" (click)="onSave('confirm')">
          {{ 'core.save' | transloco }}
        </axpo-button>
      }
    </div>
  }
</div>
