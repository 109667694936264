import { Injectable, signal } from "@angular/core";

export type IFormDebouncePair = Record<string, boolean>;
@Injectable({ providedIn: "root" })
export class FormDebounceService {
  private formToDebounce = signal<IFormDebouncePair>({});

  registerForm(formId: string): string {
    if (this.formToDebounce()[formId]) {
      return formId;
    }
    
    this.formToDebounce.update(forms => {
      const updatedForms = { ...forms };
      updatedForms[formId] = false;

      return updatedForms;
    });

    return formId;
  }

  setActive(formId: string) {
    this.formToDebounce()[formId] = true;
  }

  setInactive(formId: string) {
    this.formToDebounce()[formId] = false;
  }

  isActive(formId: string) {
    const forms = this.formToDebounce();
    if (!forms[formId]) {
      return false;
    }

    return true;
  }
}