import { Injectable, inject, signal } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { ILocationModel, IValidationErrorModel } from '../models/api_models';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private apiService = inject(ApiService);
  private toastr = inject(ToastrService);
  private translocoService = inject(TranslocoService);
  environment = environment;

  removoveLocationValidation = signal<IValidationErrorModel[] | undefined>(undefined);
  isRemovingLocation = signal<boolean | undefined>(undefined);

  addLocationValidation = signal<IValidationErrorModel[] | undefined>(undefined);
  isAddingLocation = signal<boolean | undefined>(undefined);

  locations = signal<ILocationModel[] | undefined>(undefined);
  isLoadingLocations = signal<boolean>(false);

  removeLocation(accessCode: string, locationId: number) {
    this.isRemovingLocation.set(true);
    this.apiService
      .delete<
        IValidationErrorModel[]
      >(`/api/v1/Location/remove-location/${accessCode}/${locationId}`)
      .subscribe({
        next: details => {
          this.removoveLocationValidation.set(details);
        },
        error: (errorResponse: any) => {
          const error = errorResponse.error as IValidationErrorModel;
          const msg = error.key ? this.translocoService.translate(error.key) : error.errorMessage;
          this.toastr.error(msg ?? 'Error occured');
        },
        complete: () => {
          this.isRemovingLocation.set(false);
        },
      });
  }

  addLocation(accessCode: string, locationId: number) {
    this.isAddingLocation.set(true);
    this.apiService
      .put<IValidationErrorModel[]>(`/api/v1/Location/add-location/${accessCode}/${locationId}`)
      .subscribe({
        next: details => {
          this.addLocationValidation.set(details);
        },
        error: (errorResponse: any) => {
          const error = errorResponse.error as IValidationErrorModel;
          const msg = error.key ? this.translocoService.translate(error.key) : error.errorMessage;
          this.toastr.error(msg ?? 'Error occured');
        },
        complete: () => {
          this.isAddingLocation.set(false);
        },
      });
  }

  getLocations(
    accessCode: string,
    locationTypeId: number | null | undefined,
    name: string | null | undefined,
  ) {
    let url = `/api/v1/Location/load-list/${accessCode}?`;
    if (locationTypeId !== undefined && locationTypeId !== null)
      url += 'locationTypeId=' + encodeURIComponent('' + locationTypeId) + '&';
    if (name !== undefined && name !== null) url += 'name=' + encodeURIComponent('' + name) + '&';

    url = url.replace(/[?&]$/, '');

    this.isLoadingLocations.set(true);
    this.apiService.get<ILocationModel[]>(url).subscribe({
      next: details => {
        this.locations.set(details);
      },
      error: (errorResponse: any) => {
        const error = errorResponse.error as IValidationErrorModel;
        const msg = error.key ? this.translocoService.translate(error.key) : error.errorMessage;
        this.toastr.error(msg ?? 'Error occured');
      },
      complete: () => {
        this.isLoadingLocations.set(false);
      },
    });
  }
}
