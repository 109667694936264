import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  computed,
  effect,
  inject,
  input,
  output,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import atlas from 'azure-maps-control';

import {
  IAutocompleteEntry,
  LocationAutocompleteComponent,
} from './location-autocomplete/location-autocomplete.component';
import { LocationListComponent } from './location-list/location-list.component';
import { IFormError } from '../../../core/axpo-form-element/axpo-form-element.component';
import {
  AxpoSelectableButtonsComponent,
  IButton,
} from '../../../core/axpo-selectable-buttons/axpo-selectable-buttons.component';
import { AxpoTypographyComponent } from '../../../core/axpo-typography/axpo-typography.component';
import { LocationModelType } from '../../models/api_models';
import { DataFragments, DetailsService } from '../../services/details.service';
import { IncidentService } from '../../services/incident.service';
import { LanguageService } from '../../services/lang.service';
import { LocationService } from '../../services/location.service';
import { TenantService } from '../../services/tenant.service';
import { addMarkers, initMap } from '../../utils/azureMaps';
import { FormValidator } from '../../utils/formValidator';
import createGeoIsLinkLink from '../../utils/geoIsLink';
import createNavigationLink from '../../utils/navigationLink';
import { ITileMode } from '../../utils/tileStates';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-location',
  templateUrl: './location.component.html',
  imports: [
    TranslocoPipe,
    AxpoSelectableButtonsComponent,
    LocationAutocompleteComponent,
    LocationListComponent,
    AxpoTypographyComponent,
  ],
})
export class LocationComponent implements AfterViewInit {
  translocoService = inject(TranslocoService);
  langService = inject(LanguageService);
  locationService = inject(LocationService);
  incidentService = inject(IncidentService);
  detailsService = inject(DetailsService);
  private tenantService = inject(TenantService);
  private activatedRoute = inject(ActivatedRoute);
  title = input.required<string>();
  locationChanged = output();
  incidentTitle = signal<string | undefined>(undefined);
  locationSearchText = signal<string | undefined>(undefined);
  takenAction = output<{ action: 'confirm' | 'cancel'; title: string }>();
  selectedLocationType = signal<number | undefined>(undefined);

  createNavigationLink = createNavigationLink;
  createGeoIsLinkLink = createGeoIsLinkLink;

  mapViewSelected = signal<boolean>(true);
  mapInitialized = signal<boolean>(false);
  tabs = signal<IButton[]>([]);
  onInitRan = signal<boolean>(false);
  private mapsViewChild = viewChild.required<ElementRef>('map');
  private currentMap?: atlas.Map = undefined;
  mode = input<ITileMode>('edit');
  formValidator: FormValidator | undefined;
  validationError = output<FormValidator>();

  locations = computed(() => {
    return this.incidentService.incidentDetails()?.locations || [];
  });

  _lang = effect(() => {
    const _activeLanguage = this.langService.getLangSignal()();
    untracked(() => {
      this.tabs.update(() => {
        return [
          {
            id: 'map',
            title: this.translocoService.translate('incidentDetail.panelLocation.tabMap'),
            value: 'map',
            selected: true,
            colors: undefined,
          },
          {
            id: 'list',
            title: this.translocoService.translate('incidentDetail.panelLocation.tabList'),
            value: 'list',
            selected: false,
            colors: undefined,
          },
        ];
      });
    });
  });

  _ = effect(() => {
    const _loc = this.locationAutocompleteEntries();

    if (!this.formValidator) {
      this.formValidator = new FormValidator(
        [
          {
            key: 'locations',
            errorMessageSignal: signal(undefined),
            errorIdSignal: signal(undefined),
          },
        ],
        this.translocoService,
      );
    }
    if (this.incidentService.incidentDetails() && this.locations().length === 0) {
      this.formValidator?.setError(
        'locations',
        -1,
        this.translocoService.translate('validation.required'),
      );
    } else {
      const currentError = this.formValidator?.getField('locations');
      if (currentError?.errorId() === -1) {
        this.formValidator?.clearError('locations');
      }
    }

    if (this.formValidator) {
      this.validationError.emit(this.formValidator);
    }
  });

  ngAfterViewInit(): void {
    this.onInitRan.set(true);
  }

  locationAutocompleteEntries = computed(() => {
    return (
      this.locationService.locations()?.map(l => {
        return {
          id: l.id,
          value: l.id,
          title: l.name,
          subtitle:
            l.type == LocationModelType.Location
              ? l.description
              : l.description + ' ' + l.additionalInformation,
        } as IAutocompleteEntry;
      }) ?? []
    );
  });

  selectedLocationEntries = computed(() => {
    return (
      this.incidentService.incidentDetails()?.locations?.map(l => {
        return {
          id: l.id,
          value: l.id,
          title: l.name,
          subtitle:
            l.type == LocationModelType.Location
              ? l.description
              : l.description + ' ' + l.additionalInformation,
        } as IAutocompleteEntry;
      }) ?? []
    );
  });

  locationTypes = computed(() => {
    const _activeLanguage = this.langService.getLangSignal()();
    const types =
      this.detailsService.fragmentToSignal[DataFragments.locationtypes]
        .dataSignal()
        ?.map(locationType => {
          return {
            id: locationType.key,
            title: this.translocoService.translate(
              'incidentDetail.panelLocation.locationTYpes.' + locationType.key,
            ),
            value: locationType.key,
            colors: undefined,
          } as IButton;
        }) ?? [];

    return [
      {
        id: 'all',
        title: this.translocoService.translate('incidentDetail.panelLocation.locationTYpes.all'),
        value: '',
        selected: true,
        colors: undefined,
      },
      ...types,
    ];
  });

  _locationChanges = effect(() => {
    const isRemovingLocation = this.locationService.isRemovingLocation();
    const isAddingLocation = this.locationService.isAddingLocation();
    untracked(() => {
      if (isRemovingLocation === false) {
        this.locationService.isRemovingLocation.set(undefined);
        this.locationChanged.emit();
      } else if (isAddingLocation === false) {
        this.locationService.isAddingLocation.set(undefined);
        this.locationSearchText.set(undefined);
        this.locationService.locations.set([]);
        this.locationChanged.emit();
      }
    });
  });

  _init = effect(() => {
    const details = this.incidentService.incidentDetails();
    if (details) {
      untracked(() => {
        this.detailsService.loadDataFragment(
          DataFragments.locationtypes,
          this.incidentService.incidentDetails()?.accessCode,
        );

        const didOnInit = this.onInitRan();
        if (details && didOnInit && this.mapsViewChild().nativeElement) {
          untracked(() => {
            if (!this.mapInitialized() && this.incidentService.incidentDetails()) {
              this.currentMap = initMap(this.locations(), 'map');
              this.mapInitialized.set(true);
            } else if (this.currentMap) {
              addMarkers(this.currentMap, this.locations());
            }
          });
        }
      });
    }
  });

  _onRouteChanged = effect(() => {
    this.activatedRoute.snapshot.url.toString();
    const details = this.incidentService.incidentDetails();

    if (this.mode() === 'create' && details) {
      untracked(() => {
        if (!this.mapInitialized() && this.incidentService.incidentDetails()) {
          this.currentMap = initMap(this.locations(), 'map');
          this.mapInitialized.set(true);
        }
      });
    }
  });

  _removeeffect = effect(() => {
    const remove = this.locationService.removoveLocationValidation();
    untracked(() => {
      if (remove) {
        this.incidentService.getIncidentDetails(
          this.incidentService.incidentDetails()?.accessCode as string,
          this.tenantId() as string,
        );
        this.locationService.removoveLocationValidation.set(undefined);
      }
    });
  });

  _addeffect = effect(() => {
    const add = this.locationService.addLocationValidation();
    untracked(() => {
      if (add) {
        this.incidentService.getIncidentDetails(
          this.incidentService.incidentDetails()?.accessCode as string,
          this.tenantId() as string,
        );
        this.locationService.addLocationValidation.set(undefined);
      }
    });
  });

  toggleView = (mapview: any) => {
    this.mapViewSelected.set((mapview as string[]).indexOf('map') > -1);
  };

  selectLocationType = (value: any) => {
    this.selectedLocationType.set(value);
    this.locationSearch(this.locationSearchText());
  };

  onSave(action: 'confirm' | 'cancel') {
    this.takenAction.emit({ action: action, title: this.title() });
  }

  openNavigationLink = (lat: number, lng: number): void => {
    window.open(createNavigationLink(lat, lng), '_blank');
  };

  removeLocation = (entry: IAutocompleteEntry) => {
    this.locationService.removeLocation(
      this.incidentService.incidentDetails()?.accessCode ?? '',
      entry.id as number,
    );
  };

  tenantId = computed(() => {
    return this.tenantService.tenantId();
  });
  addLocation = (entry: IAutocompleteEntry) => {
    this.locationService.addLocation(
      this.incidentService.incidentDetails()?.accessCode ?? '',
      entry.id as number,
    );

    this.incidentService.getIncidentDetails(
      this.incidentService.incidentDetails()?.accessCode as string,
      this.tenantId() as string,
    );
  };

  locationSearch = (val: any): void => {
    this.locationSearchText.set(val);
    if (val !== undefined && val !== '')
      this.locationService.getLocations(
        this.incidentService.incidentDetails()?.accessCode ?? '',
        this.selectedLocationType(),
        val,
      );
  };

  formValidation(formError: IFormError | undefined, key: string) {
    if (formError) {
      this.formValidator?.setError(
        formError.formId,
        formError.value,
        formError.message,
        formError.replacement,
      );
    } else {
      this.formValidator?.clearError(key);
    }
    if (this.formValidator) {
      this.validationError.emit(this.formValidator);
    }
  }
}
