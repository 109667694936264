import { ChangeDetectionStrategy, Component, computed, input, model, output } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";

import { ITileMode } from './incident-detail.component';
import { AxpoButtonComponent } from '../../core/axpo-button/axpo-button.component';
import { AxpoTypographyComponent } from '../../core/axpo-typography/axpo-typography.component';

export type availableButtons = 'confirm' | 'cancel';
export interface ITileButtonAction { action: availableButtons, key: string };

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tile-component',
  templateUrl: './tile.component.html',
  imports: [AxpoTypographyComponent, AxpoButtonComponent, TranslocoPipe],
})
export class TileComponent {
  key = input.required<string>();
  title = input.required<string>();
  mode = model.required<ITileMode>();
  takenAction = output<ITileButtonAction>();
  controlButtons = input<availableButtons[]>([]);

  hasConfirmButton = computed(() => {
    return this.controlButtons().indexOf('confirm') > -1;
  });
  hasCancelButton = computed(() => {
    return this.controlButtons().indexOf('cancel') > -1;
  });

  editTile() {
    this.mode.set('edit');
  }

  viewTile() {
    this.mode.set('view');
  }

  onSave(action: availableButtons) {
    this.takenAction.emit({action: action, key: this.key()});
    this.viewTile();
  }
}
