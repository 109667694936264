import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, effect, inject, input, output, signal, untracked } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";

import { AxpoButtonComponent } from "../../../core/axpo-button/axpo-button.component";
import { AxpoTypographyComponent } from "../../../core/axpo-typography/axpo-typography.component";
import { AxpoResizeService, axpoBreakpoints } from "../../../core/services/axpo-resize.service";

export interface ITabItem {
  title: string,
  id: string
};

@Component({
  selector: 'app-tabs',
  imports: [
    AxpoTypographyComponent,
    AxpoButtonComponent,
    TranslocoPipe,
    NgClass
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './tabs.component.html',
})
export class TabsComponent {
  resizeService = inject(AxpoResizeService);
  tabItems = input.required<ITabItem[]>();
  selectedTab = signal<ITabItem | undefined>(undefined);
  breakpoint = input<number>(axpoBreakpoints.LG);
  tabSelected = output<ITabItem>();
  isMobileView = signal<boolean>(false);

  _resize = effect(() => {
    const isMobile = this.resizeService.width() < this.breakpoint();
    untracked(() => {
      this.isMobileView.set(isMobile);
    });
  });

  _tabItemsSet = effect(() => {
    const tabItems = this.tabItems();

    if (tabItems.length > 0) {
      untracked(() => {
        this.selectedTab.set(tabItems[0]);
      });
    }
  });

  selectTab(tab: ITabItem) {
    this.selectedTab.set(tab);
    this.tabSelected.emit(tab);
  }
}