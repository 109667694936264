export const getTextColorByVoltageKey = (key: string | null | undefined) => {
  switch (key) {
    case '1': // 16kv
      return 'text-ima-turquoise';
    case '2': // 50kv
      return 'text-ima-red';
    case '3': // 110kv
      return 'text-ima-orange';
    case '4': // 220kv
      return 'text-ima-green';
    case '5': //380kv
      return 'text-ima-violet';
    case '6': //64kv
      return 'text-dark-yellow';
    case '7': //132kv
      return 'text-success';
    default:
      return 'text-ima-red';
  }
};
