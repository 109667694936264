<div class="flex pt-4 ml-4">
  @for (tab of tabItems(); track tab.id) {
    <div
      class="border-b-2"
      [ngClass]="{
        'border-b-primary': selectedTab()?.id === tab.id,
      }"
    >
      <axpo-button
        (click)="selectTab(tab)"
        type="ghost"
        [style]="selectedTab()?.id === tab.id ? 'primary' : 'secondary'"
      >
        <axpo-typography [variant]="isMobileView() ? 'body-s' : 'body-l'">
          {{ tab.title | transloco }}
        </axpo-typography>
      </axpo-button>
    </div>
  }
</div>
