import { ChangeDetectionStrategy, Component, effect, inject, signal, untracked } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";

import { AxpoTypographyComponent } from "../../../../core/axpo-typography/axpo-typography.component";
import { IncidentService } from "../../../services/incident.service";
import { isoDateToTime } from "../../../utils/date";

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-incident-view-details',
  templateUrl: './panel-view-details.component.html',
  imports: [TranslocoPipe, AxpoTypographyComponent],
})

export class PanelViewDetailsComponent {
  incidentService = inject(IncidentService);

  categoryName = signal<string | undefined>(undefined);
  creatorName = signal<string | undefined>(undefined);
  description = signal<string | undefined>(undefined);
  timeStamp = signal<string | undefined>(undefined);
  fixedTimeStamp = signal<string | undefined>(undefined);
  faultEffectName = signal<string | undefined>(undefined);
  impactName = signal<string | undefined>(undefined);
  incidentStateName = signal<string | undefined>(undefined);
  incidentTitle = signal<string | undefined>(undefined);
  hasInterruptions = signal<string>('no');

  _ = effect(() => {
    const details = this.incidentService.incidentDetails();
    
    untracked(() => {
      this.categoryName.set(details?.incidentCategoryName ?? undefined);
      this.creatorName.set(details?.incidentCreatorName ?? undefined);
      this.description.set(details?.incidentDescription ?? undefined);
      this.timeStamp.set(details?.incidentFaultTimeStamp ? new Date(details.incidentFaultTimeStamp).toLocaleDateString() + ' ' + isoDateToTime(details.incidentFaultTimeStamp): undefined)
      this.fixedTimeStamp.set(details?.incidentFixedTimeStamp ? new Date(details.incidentFixedTimeStamp).toLocaleDateString() + ' ' + isoDateToTime(details.incidentFixedTimeStamp): undefined);
      this.faultEffectName.set(details?.faultEffectName ?? undefined);
      this.impactName.set(details?.incidentImpactName ?? undefined);
      this.incidentStateName.set(details?.incidentStateName ?? undefined);
      this.incidentTitle.set(details?.incidentTitle ?? undefined);
      this.hasInterruptions.set(details?.hasInterruptions === 1 ? 'yes' : 'no');
    });
});
  
}