<div class="flex items-center justify-between p-2">
  <div>
    <axpo-typography [variant]="'body-s'" class="text-text-secondary">
      {{ contact()?.designation || 'admin.group' | transloco }}
    </axpo-typography>
    <axpo-typography [variant]="'body-m'" weight="bold">
      {{ contact()?.name }}
    </axpo-typography>

    @if (contact()?.members) {
      <axpo-typography [variant]="'body-s'" class="text-text-secondary">
        {{ 'admin.members' | transloco }}
        {{ contact()?.members }}
      </axpo-typography>
    }
    <axpo-typography [variant]="'body-s'" class="text-text-secondary">
      {{ contact()?.phone }}
      @if (contact()?.phone && contact()?.mail) {
        {{ '|' }}
      }
      {{ contact()?.mail }}
    </axpo-typography>
  </div>

  @if (displayDeleteAction()) {
    <i class="text-xl fas fa-trash white" (click)="deleteClicked.emit()" aria-hidden="true"></i>
  }
</div>
