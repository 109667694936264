import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { ApiService } from './api.service';
import { DataFragments, DetailsService } from './details.service';
import { environment } from '../../../environments/environment';
import {
  CauseDamagePlantTranslations,
  CauseLocationTranslations,
  DamageLineTranslations,
  FaultCauseTranslations,
  ImpactDataTranslations,
  IncidentImpactTranslations,
  IncidentStatusTranslations,
  causeName,
  effectName,
  impactName,
  lineDamage,
  locationCause,
  plantDamage,
  stateName,
} from '../models/api_data_translations';
import {
  IINotifyBody,
  IIncidentIncidentResolutionUpdateModel,
  IIncidentListFilterModel,
  IIncidentListModel,
  IIncidentModel,
  IStatisticsModel,
  IncidentListFilterSorting,
} from '../models/api_models';
import { dateToDay } from '../utils/date';
import { getTitle } from '../utils/incident';

@Injectable({
  providedIn: 'root',
})
export class IncidentService {
  private apiService = inject(ApiService);
  private toastr = inject(ToastrService);
  private translocoService = inject(TranslocoService);
  private router = inject(Router);
  private detailsService = inject(DetailsService);
  environment = environment;

  filter = signal<IIncidentListFilterModel>({
    rowsToSkip: 0, // No pager functionality yet
    rowsToReturn: 100000, // We want to return all / lots of data for now
    sorting: IncidentListFilterSorting.IncidentFaultTimeStampDesc,
    incidentFaultFrom: new Date(dateToDay(2, 'subtraction')).toISOString(),
    incidentFaultTo: new Date().toISOString(),
  });

  statisticsFilter = signal<IIncidentListFilterModel>({
    incidentFaultFrom: new Date(dateToDay(2, 'subtraction')).toISOString(),
    incidentFaultTo: new Date().toISOString(),
    searchText: null,
    locale: 'Europe/Berlin',
  });

  createdAccessCode = signal<string | undefined>(undefined);
  incidents = signal<IIncidentListModel[] | undefined>(undefined);
  incidentDetails = signal<IIncidentModel | undefined>(undefined);
  isLoadingIncidents = signal<boolean>(false);
  isLoadingIncidentDetails = signal<boolean>(false);
  duplicationInProgress = signal<boolean>(false);
  duplicatedAccessCode = signal<string | undefined>(undefined);
  isNotifyingContacts = signal<boolean>(false);
  updateInProgress = signal<boolean>(false);
  updateResult = signal<string | undefined>(undefined);
  reopeningInProgress = signal<boolean>(false);
  reOpeningDone = signal<boolean>(false);
  deleteInProgress = signal<boolean>(false);
  deletingDone = signal<any>(undefined);
  createInProgress = signal<boolean>(false);
  incidentStatistics = signal<IStatisticsModel | undefined>(undefined);
  incidentStatisticsLoading = signal<boolean>(false);
  reviewLoading = signal<boolean>(false);
  reviewData = signal<Blob | undefined>(undefined);
  incidentReviewLoading = signal<boolean>(false);
  incidentReview = signal<IIncidentListModel[] | undefined>(undefined);

  cloneIncident(accessCode: string) {
    this.duplicationInProgress.set(true);
    this.apiService.put<string>(`/api/v1/Incident/copy/${accessCode}`).subscribe({
      next: accessCode => {
        this.duplicatedAccessCode.set(accessCode);
      },
      error: () => {
        const msg = this.translocoService.translate('incidentErrors.duplicate');
        this.toastr.error(msg);
      },
      complete: () => {
        this.duplicationInProgress.set(false);
      },
    });
  }

  createIncident() {
    this.createInProgress.set(true);
    this.apiService.put<IIncidentModel>(`/api/v1/Incident/create`).subscribe({
      next: newIncident => {
        this.incidentDetails.set(newIncident);
        this.createdAccessCode.set(newIncident.accessCode);
      },
      error: () => {
        const msg = this.translocoService.translate('incidentErrors.create');
        this.toastr.error(msg);
      },
      complete: () => {
        this.createInProgress.set(false);
      },
    });
  }

  getIncidents() {
    this.isLoadingIncidents.set(true);
    const filterBody = computed<string>(() => JSON.stringify(this.filter()));
    this.apiService
      .post<IIncidentListModel[]>(`/api/v1/Incident/load-list`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: filterBody(),
      })
      .subscribe({
        next: incidents => {
          const i18nIncidents = incidents.map(incident => {
            return {
              ...incident,
              incidentStateName:
                IncidentStatusTranslations[incident.incidentStateName as stateName],
            };
          });
          this.incidents.set(i18nIncidents);
        },
        error: () => {
          const msg = this.translocoService.translate('incidentErrors.fetch');
          this.toastr.error(msg);
        },
        complete: () => {
          this.isLoadingIncidents.set(false);
        },
      });
  }

  getIncidentDetails(accessCode: string, tenantId: string | undefined = undefined) {
    this.isLoadingIncidentDetails.set(true);
    const url = tenantId
      ? `/api/v1/Incident/load/${accessCode}/${tenantId}`
      : `/api/v1/Incident/load/${accessCode}`;

    this.apiService.get<IIncidentModel>(url).subscribe({
      next: details => {
        const i18nDetails = {
          ...details,
          incidentStateName: IncidentStatusTranslations[details.incidentStateName as stateName],
          incidentImpactName: IncidentImpactTranslations[details.incidentImpactName as impactName],
        };

        this.incidentDetails.set(i18nDetails);
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 403) {
          this.router.navigate(['unauthorized']);
        } else {
          const msg = this.translocoService.translate('incidentErrors.fetchDetails');
          this.toastr.error(msg);
        }
      },
      complete: () => {
        this.isLoadingIncidentDetails.set(false);
      },
    });
  }

  notify(accessCode: string, contacts: IINotifyBody[]) {
    this.isNotifyingContacts.set(true);
    this.apiService
      .put<[]>(`/api/v1/Incident/notify/${accessCode}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: contacts,
      })
      .subscribe({
        next: () => {
          this.toastr.info(this.translocoService.translate('incidentDetail.sharedSuccess'));
        },
        error: () => {
          const msg = this.translocoService.translate('incidentErrors.notify');
          this.toastr.error(msg);
        },
        complete: () => {
          this.isNotifyingContacts.set(false);
        },
      });
  }

  update(accessCode: string, tenantId: string | undefined = undefined, incident: IIncidentModel) {
    this.updateInProgress.set(true);
    incident.incidentTitle = getTitle(
      this.translocoService,
      incident?.voltages,
      incident?.alertName,
      this.detailsService.fragmentToSignal[DataFragments.incidentimpacts]
        .dataSignal()
        ?.find(elem => {
          return elem.key == incident.incidentImpactId;
        }),
      !!incident?.hasInterruptions,
    );
    const url = tenantId
      ? `/api/v1/Incident/update/${accessCode}/${tenantId}`
      : `/api/v1/Incident/update/${accessCode}`;

    this.apiService
      .post<[]>(url, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: incident,
      })
      .subscribe({
        next: () => {
          this.updateResult.set(Date.now().toString());
        },
        error: () => {
          const msg = this.translocoService.translate('incidentErrors.update');
          this.toastr.error(msg);
          this.updateInProgress.set(false);
        },
        complete: () => {
          this.updateInProgress.set(false);
        },
      });
  }

  updateTroublelshooting(
    accessCode: string,
    tenantId: string | undefined = undefined,
    incidentTroubleShooting: IIncidentIncidentResolutionUpdateModel,
  ) {
    this.updateInProgress.set(true);
    const url = tenantId
      ? `/api/v1/Incident/update-troubleshooting/${accessCode}/${tenantId}`
      : `/api/v1/Incident/update-troubleshooting/${accessCode}`;

    this.apiService
      .post<[]>(url, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: incidentTroubleShooting,
      })
      .subscribe({
        next: () => {
          this.updateResult.set(Date.now().toString());
        },
        error: () => {
          const msg = this.translocoService.translate('incidentErrors.update');
          this.toastr.error(msg);
          this.updateInProgress.set(false);
        },
        complete: () => {
          this.updateInProgress.set(false);
        },
      });
  }

  changeToPending(accessCode: string) {
    this.reopeningInProgress.set(true);
    this.apiService
      .post<[]>(`/api/v1/Incident/change-to-pending/${accessCode}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .subscribe({
        next: () => {
          this.reOpeningDone.set(true);
        },
        error: () => {
          const msg = this.translocoService.translate('incidentErrors.changeToPending');
          this.toastr.error(msg);
          this.reopeningInProgress.set(false);
        },
        complete: () => {
          this.reopeningInProgress.set(false);
        },
      });
  }

  changeState(accessCode: string, stateId: number, tenantId: string) {
    this.reopeningInProgress.set(true);
    this.apiService
      .post<[]>(`/api/v1/Incident/change-state/${accessCode}/${tenantId}/${stateId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .subscribe({
        next: () => {
          this.updateResult.set(Date.now().toString());
        },
        error: () => {
          const msg = this.translocoService.translate('incidentErrors.changeToPending');
          this.toastr.error(msg);
          this.updateInProgress.set(false);
        },
        complete: () => {
          this.updateInProgress.set(false);
        },
      });
  }

  deleteIncident(accessCode: string) {
    this.deleteInProgress.set(true);
    this.apiService.delete<[]>(`/api/v1/Incident/delete/${accessCode}`).subscribe({
      next: result => {
        this.deletingDone.set(result);
      },
      error: () => {
        const msg = this.translocoService.translate('incidentErrors.delete');
        this.toastr.error(msg);
        this.deleteInProgress.set(false);
      },
      complete: () => {
        this.deleteInProgress.set(false);
      },
    });
  }

  getIncidentStatistics() {
    this.incidentStatisticsLoading.set(true);
    const filterBody = computed<string>(() => JSON.stringify(this.filter()));
    this.apiService
      .post<IStatisticsModel>(`/api/v1/Incident/load-statistics`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: filterBody(),
      })
      .subscribe({
        next: incidents => {
          this.incidentStatistics.set({
            averageResolvingMinutes: incidents.averageResolvingMinutes,
            faultCauses: incidents.faultCauses.map(faultCause => {
              return {
                key: FaultCauseTranslations[faultCause.key as causeName],
                value: faultCause.value,
              };
            }),
            faultEffects: incidents.faultEffects.map(effect => {
              return {
                key: ImpactDataTranslations[effect.key as effectName],
                value: effect.value,
              };
            }),
            faultLineDamages: incidents.faultLineDamages.map(faultLine => {
              return {
                key: DamageLineTranslations[faultLine.key as lineDamage],
                value: faultLine.value,
              };
            }),
            faultLocations: incidents.faultLocations.map(faultLocation => {
              return {
                key: CauseLocationTranslations[faultLocation.key as locationCause],
                value: faultLocation.value,
              };
            }),
            faultPlantDamages: incidents.faultPlantDamages.map(faultPlant => {
              return {
                key: CauseDamagePlantTranslations[faultPlant.key as plantDamage],
                value: faultPlant.value,
              };
            }),
          });
        },
        error: () => {
          const msg = this.translocoService.translate('incidentErrors.statistics');
          this.toastr.error(msg);
        },
        complete: () => {
          this.incidentStatisticsLoading.set(false);
        },
      });
  }

  loadReviewFile() {
    this.reviewLoading.set(true);
    const filterBody = computed<string>(() => JSON.stringify(this.filter()));
    this.apiService
      .post<Blob>(`/api/v1/Incident/load-review-file`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: filterBody(),
        responseType: 'blob',
      })
      .subscribe({
        next: incidents => {
          this.reviewData.set(incidents);
        },
        error: () => {
          const msg = this.translocoService.translate('incidentErrors.reviewFile');
          this.toastr.error(msg);
        },
        complete: () => {
          this.reviewLoading.set(false);
        },
      });
  }

  getIncidentReview() {
    this.incidentReviewLoading.set(true);
    const filterBody = computed<string>(() => JSON.stringify(this.filter()));
    this.apiService
      .post<IIncidentListModel[]>(`/api/v1/Incident/load-review`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: filterBody(),
      })
      .subscribe({
        next: incidents => {
          const i18nIncidents = incidents.map(incident => {
            return {
              ...incident,
              incidentStateName:
                IncidentStatusTranslations[incident.incidentStateName as stateName],
              faultCauseName: FaultCauseTranslations[incident.faultCauseName as causeName],
            };
          });
          this.incidentReview.set(i18nIncidents);
        },
        error: () => {
          const msg = this.translocoService.translate('incidentErrors.getReview');
          this.toastr.error(msg);
        },
        complete: () => {
          this.incidentReviewLoading.set(false);
        },
      });
  }
}
