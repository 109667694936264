import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { Router } from '@angular/router';

import { ISubOption } from '../axpo-profile-dropdown.component';

@Component({
  selector: 'axpo-profile-submenu',
  standalone: true,
  imports: [],
  templateUrl: './axpo-profile-submenu.component.html',
  styleUrl: './axpo-profile-submenu.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('transformOpacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
      ]),
    ]),
  ],
})
export class AxpoProfileSubmenuComponent {
  items = input<ISubOption[]>([]);
  onItemSelected = output<ISubOption>();
  router = inject(Router);

  clickOption = (option: ISubOption) => {
    this.onItemSelected.emit(option);
  };
}
