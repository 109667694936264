import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Translation, TranslocoLoader, TranslocoService } from '@jsverse/transloco';
import { forkJoin, lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);

  public getTranslation = (lang: string) => {
    return this.http.get<Translation>(`/i18n/${lang}.json`);
  };
}

export function appInitializerFactory(translateService: TranslocoService) {
  return (): Promise<Translation> => {
    // Load both 'en' and 'de' translations
    const translationsToLoad = [translateService.load('de'), translateService.load('en')];
    // Use forkJoin to wait for all translations to load
    return lastValueFrom(forkJoin(translationsToLoad));
  };
}
