<header>
  <div class="flex px-4 py-2 align-middle border-b">
    <div class="w-1/3">
      <axpo-typography [variant]="'title-xl'">
        <a
          routerLink="/"
          class="flex items-center align-middle text-text-primary hover:text-primary"
        >
          <div class="block w-[35px] overflow-hidden relative">
            <axpo-logo />
          </div>
          <div class="hidden md:block ps-2 whitespace-nowrap">{{ title() }}</div>
        </a>
      </axpo-typography>
    </div>
    @if (breadcrumbDeactivated()) {
      <div class="flex items-center justify-center w-1/3">
        @if (middleTitle()) {
          <axpo-typography [variant]="'body-m'">
            <div class="text-center">{{ middleTitle() }}</div>
          </axpo-typography>
        }
      </div>
    } @else {
      <div class="flex items-center justify-center w-1/3">
        <axpo-breadcrumb [separator]="separator()"></axpo-breadcrumb>
      </div>
    }
    <div class="flex justify-end w-1/3 gap-3 place-content-center">
      @if (feedback()) {
        <button type="button" class="w-8" (click)="handleFeedbackClick($event)">
          <img src="images/feedback.svg" alt="feedback" class="w-full h-full" />
        </button>
      }
      @if (isOffline()) {
        <svg
          class="h-6 w-6 text-red-500 my-2"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <line x1="1" y1="1" x2="23" y2="23" />
          <path d="M16.72 11.06A10.94 10.94 0 0 1 19 12.55" />
          <path d="M5 12.55a10.94 10.94 0 0 1 5.17-2.39" />
          <path d="M10.71 5.05A16 16 0 0 1 22.58 9" />
          <path d="M1.42 9a15.91 15.91 0 0 1 4.7-2.88" />
          <path d="M8.53 16.11a6 6 0 0 1 6.95 0" />
          <line x1="12" y1="20" x2="12.01" y2="20" />
        </svg>
      }
      <axpo-profile-dropdown
        class="text-primary"
        [availableLanguages]="availableLanguages()"
        [(selectedLanguageCode)]="selectedLanguageCode"
        [profileImage]="profileImage()"
        [user]="user()"
        [showLogout]="showLogout()"
        (onLogout)="onHeaderLogout()"
        [customActions]="customActions()"
        (onCustomAction)="onCustomHeaderAction($event.value, $event.subvalue)"
      ></axpo-profile-dropdown>
    </div>
  </div>
</header>
