import { Injectable, effect, signal, untracked } from '@angular/core';
import { Event, NavigationEnd, Route, Router } from '@angular/router';

export interface BreadcrumbProps {
  value: string;
  label: string;
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  public _breadcrumbs = signal<BreadcrumbProps[]>([{ value: '/', label: 'Home' }]);
  private currentUrl = signal<string>('');
  private replacedBreadcrumbs = signal<Record<string, string>>({});

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (this.currentUrl() === this.router.url) return;
        this.currentUrl.set(this.router.url);
      }
    });

    effect(() => {
      const url = this.currentUrl();
      const _replacedBreadcrumbs = this.replacedBreadcrumbs();
      untracked(() => {
        const breadcrumbs = this.generateBreadcrumbs(url, this.router.config);
        this._breadcrumbs.set(breadcrumbs);
      });
    });
  }

  get breadcrumbs() {
    return this._breadcrumbs();
  }

  public generateBreadcrumbs(url: string, routes: Route[]): BreadcrumbProps[] {
    if (!this.matchRoute(url, routes)) {
      return [];
    }
    const normalizedUrl = this.normalizeUrl(url);
    const urlParts = normalizedUrl.split('/');

    const breadcrumbs: { value: string; label: string }[] = [{ value: '/', label: 'Home' }];

    urlParts.reduce((acc, segment, index) => {
      if (segment === '' || segment === 'home') {
        return acc;
      }
      const path = urlParts.slice(0, index + 1).join('/');

      const polishedLabel = this.getPolishedBreadcrumb(segment);
      acc.push({ value: `/${path}`, label: polishedLabel });
      return acc;
    }, breadcrumbs);
    return breadcrumbs;
  }

  public replaceBreadcrumbs(newBreadcrumbs: Record<string, string>): void {
    this.replacedBreadcrumbs.update(old => {
      return { ...old, ...newBreadcrumbs };
    });
  }

  private formatBreadcrumb(segment: string): string {
    const withSpaces = segment.replace(/-/g, ' ');
    return withSpaces.replace(/\b\w/g, char => char.toUpperCase());
  }

  private matchRoute(url: string, routes: Route[]): boolean {
    const normalizedUrl = this.normalizeUrl(url);
    return this.checkRoute(normalizedUrl, routes);
  }

  private normalizeUrl(url: string): string {
    return url.replace(/^\/|\/$/g, '');
  }

  private checkRoute(url: string, routes: any[]): boolean {
    return routes.some(route => {
      if (route.path) {
        const routeParts = route.path.split('/');
        const urlParts = url.split('/');

        if (routeParts.length !== urlParts.length) {
          return false;
        }

        return routeParts.every((part: string, index: number) => {
          if (part.startsWith(':')) {
            return true;
          }
          return part === urlParts[index];
        });
      }
      if (route.children) {
        return this.checkRoute(url, route.children);
      }
      return false;
    });
  }

  private isId(segment: string): boolean {
    // check if segment is an integer or guid
    const guidPattern = new RegExp(
      '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
      'i',
    );
    return !isNaN(Number(segment)) || guidPattern.test(segment);
  }

  private getPolishedBreadcrumb(segment: string): string {
    let polishedLabel = segment;
    if (this.isId(segment)) {
      polishedLabel = this.replacedBreadcrumbs()[segment] || this.getPolishedBreadcrumb('Overview');
    } else {
      const label = this.formatBreadcrumb(segment);
      polishedLabel =
        this.replacedBreadcrumbs()[label] || this.replacedBreadcrumbs()[segment] || label;
    }
    return polishedLabel;
  }
}
