import { Injectable, inject, signal } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { ApiService } from './api.service';
import {
  IContactModel,
  IValueTupleOfIntegerAndValidationErrorModelOfAndString,
} from '../models/api_models';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  private apiService = inject(ApiService);
  private toastr = inject(ToastrService);
  private translocoService = inject(TranslocoService);

  isLoadingSearch = signal<boolean>(false);
  searchResult = signal<IContactModel[]>([]);
  isAddFromTemplate = signal<boolean>(false);
  addFromTemplateResult = signal<any>(undefined);
  isAdding = signal<boolean>(false);
  isUpdating = signal<boolean>(false);
  addResult = signal<any>(undefined);
  updateResult = signal<any>(undefined);
  isDeleting = signal<boolean>(false);
  deleteResult = signal<boolean | undefined>(undefined);
  validationMessages = signal<string[]>([]);

  searchContacts(search: string, external: boolean) {
    this.isLoadingSearch.set(true);
    this.apiService
      .delete<IContactModel[]>(`/api/v1/Contact/loadList/${search}/${external}`)
      .subscribe({
        next: details => {
          this.searchResult.set(details);
        },
        error: () => {
          const msg = this.translocoService.translate('contact.fetchError');
          this.toastr.error(msg);
          this.isLoadingSearch.set(false);
        },
        complete: () => {
          this.isLoadingSearch.set(false);
        },
      });
  }

  addFromTemplate(accessCode: string, contactId: number) {
    this.isAddFromTemplate.set(true);
    this.validationMessages.set([]);
    this.apiService
      .put<any>(`/api/v1/Contact/add-from-template/${accessCode}/${contactId}`)
      .subscribe({
        next: details => {
          this.addFromTemplateResult.set(details);
        },
        error: (error: any) => {
          const msg = this.translocoService.translate('contact.updateError');
          this.toastr.error(msg);
          this.isAddFromTemplate.set(false);
          const messages =
            (error.error as IValueTupleOfIntegerAndValidationErrorModelOfAndString).item2?.map(
              validation => validation.errorMessage ?? '',
            ) ?? [];
          this.validationMessages.set(messages);
        },
        complete: () => {
          this.isAddFromTemplate.set(false);
        },
      });
  }

  addOrUpdate(accessCode: string, contact: IContactModel) {
    if (contact.id) {
      this.isUpdating.set(true);
    } else {
      this.isAdding.set(true);
    }

    this.apiService
      .post<any>(`/api/v1/Contact/add-or-update/${accessCode}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: contact,
      })
      .subscribe({
        next: details => {
          if (contact.id) {
            this.updateResult.set(details);
          } else {
            this.addResult.set(details);
          }
        },
        error: () => {
          const msg = this.translocoService.translate('contact.addOrUpdateError');
          this.toastr.error(msg);
        },
        complete: () => {
          if (contact.id) {
            this.isUpdating.set(false);
          } else {
            this.isAdding.set(false);
          }
        },
      });
  }

  remove(accessCode: string, id: number) {
    this.isDeleting.set(true);
    this.apiService.delete<any>(`/api/v1/Contact/remove/${accessCode}/${id}`).subscribe({
      next: () => {
        this.deleteResult.set(true);
      },
      error: () => {
        const msg = this.translocoService.translate('contact.deleteError');
        this.toastr.error(msg);
      },
      complete: () => {
        this.isDeleting.set(false);
      },
    });
  }

  TransformValidationMessagesToGenreicStrings(message: string) {
    const alreadyExistsKey = 'alreadyExists';
    const alreadyExistsMessage1 = 'Eintrag ';
    const alreadyExistsMessage2 = ' Existiert schon';

    if (message.indexOf(alreadyExistsMessage2) > 0) {
      message = message.replace(alreadyExistsMessage1, '');
      message = message.replace(alreadyExistsMessage2, '');
      return this.translocoService.translate('contact.' + alreadyExistsKey, {
        value: message,
      });
    }

    return message;
  }
}
