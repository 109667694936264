<section class="bg-white dark:bg-gray-900">
  @if (
    incidentService.isLoadingIncidentDetails() ||
    tenantService.isLoadingSettings() ||
    incidentService.updateInProgress()
  ) {
    <div class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div class="bg-white p-8 rounded shadow-lg">
        <axpo-spinner size="normal">{{ 'core.loading' | transloco }}</axpo-spinner>
      </div>
    </div>
  }

  <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
    <axpo-typography [variant]="'title-xl'" [weight]="'bold'">
      {{ 'incidentDetail.title' | transloco }}
    </axpo-typography>
    <axpo-typography [variant]="'title-l'">
      {{ incidentTitle() }}
    </axpo-typography>
    <div class="mb-16 mt-16 flex justify-center gap-8">
      <axpo-button [autofocus]="true" (click)="openShareModal()">
        {{ 'incidentDetail.share' | transloco }}
      </axpo-button>

      <axpo-button [autofocus]="true" (click)="openIncidentDialog()">
        {{ 'incidentDetail.duplicate' | transloco }}
      </axpo-button>
    </div>
    <div class="mb-16 mt-16 flex justify-center gap-8 flex-col md:flex-row">
      <!-- Left Row -->
      <div class="flex-1 space-y-4">
        @if (tilesSignals()['details'] && tilesSignals()['details'].visible()) {
          <app-tile-component
            [(mode)]="tilesSignals()['details'].mode"
            title="{{ 'incidentDetail.details' | transloco }}"
            [controlButtons]="['confirm', 'cancel']"
            (takenAction)="updateAction($event)"
            key="details"
          >
            @if (tilesSignals()['details'].mode() === 'edit') {
              <app-incident-edit-details
                [mode]="tilesSignals()['details'].mode()"
                [takenAction]="tilesSignals()['details'].action()"
              ></app-incident-edit-details>
            } @else {
              <app-incident-view-details></app-incident-view-details>
            }
          </app-tile-component>
        }
        <div class="flex-1 bg-green-300 h-48 flex items-center justify-center text-white font-bold">
          Comments
        </div>
        <div
          class="flex-1 bg-yellow-300 h-40 flex items-center justify-center text-white font-bold"
        >
          Internal Comments
        </div>
        <div class="flex-1 bg-red-300 h-36 flex items-center justify-center text-white font-bold">
          Contacts
        </div>
        <div
          class="flex-1 bg-yellow-300 h-40 flex items-center justify-center text-white font-bold"
        >
          Incident Resolution
        </div>
      </div>

      <!-- Right Row -->
      <div class="flex-1 space-y-4">
        <div class="flex-1 bg-red-300 h-36 flex items-center justify-center text-white font-bold">
          Location
        </div>
        <div class="flex-1 bg-red-300 h-36 flex items-center justify-center text-white font-bold">
          Alert
        </div>
        <div class="flex-1 bg-green-300 h-48 flex items-center justify-center text-white font-bold">
          Attachments
        </div>
        <div
          class="flex-1 bg-yellow-300 h-40 flex items-center justify-center text-white font-bold"
        >
          Internal Attachments
        </div>
      </div>
    </div>
  </div>

  @if (duplicateIncident()) {
    <axpo-dialog
      [title]="'incidentDetail.duplicateIncidentDialog.text' | transloco"
      [confirmText]="'core.confirm' | transloco"
      [cancelText]="'core.cancel' | transloco"
      [processBeforeClose]="true"
      [dataLoaded]="incidentCloned()"
      (takenProcessAction)="duplicateIncidentDialogAction($event)"
      icon="warning"
    ></axpo-dialog>
  }
  @if (shareLink()) {
    <axpo-dialog
      title="{{ 'incidentDetail.share' | transloco }}"
      cancelText="{{ 'core.cancel' | transloco }}"
      confirmText="{{ 'incidentDetail.share' | transloco }}"
      textAlign="left"
      (takenAction)="onShareClosed($event)"
    >
      {{ 'incidentDetail.shareLinkDialog.text' | transloco }}
      <div class="border-b-2 border-border-soft-300 pb-4 pt-4">
        <axpo-button
          [type]="getShareLinkBtnStyle()"
          [style]="'secondary'"
          [autofocus]="true"
          (click)="copyLinkToClipboard()"
        >
          {{ getShareLinkBtnText() | transloco }}
        </axpo-button>
      </div>
      <div class="pt-4">
        {{ 'incidentDetail.shareLinkDialog.textShareBy' | transloco }}
      </div>
      <div class="ml-auto w-[80px] flex justify-between pt-10 relative -left-1">
        <div style="transform: rotate(-80deg)" class="text-nowrap w-[20px]">
          {{ 'incidentDetail.shareLinkDialog.sms' | transloco }}
        </div>
        <div style="transform: rotate(-80deg)" class="text-nowrap w-[20px]">
          {{ 'incidentDetail.shareLinkDialog.mail' | transloco }}
        </div>
        <div style="transform: rotate(-80deg)" class="text-nowrap w-[20px]">
          {{ 'incidentDetail.shareLinkDialog.wakeUpCall' | transloco }}
        </div>
      </div>
      <div class="ml-auto flex justify-between w-[80px]">
        <axpo-form-element
          formType="checkbox"
          [value]="linkShareSMS()"
          (valueChange)="updateSMSLinkCheck($event)"
          size="medium"
        ></axpo-form-element>
        <axpo-form-element
          formType="checkbox"
          [value]="linkShareMail()"
          (valueChange)="updateMailLinkCheck($event)"
          size="medium"
        ></axpo-form-element>
        <axpo-form-element
          formType="checkbox"
          [value]="linkShareCall()"
          (valueChange)="updateCallLinkCheck($event)"
          size="medium"
        ></axpo-form-element>
      </div>
      <div class="border-b-2 border-border-soft-300 pb-4 pt-4">
        <div class="flex space-x-4">
          <div class="flex justify-between space-x-4">
            <i class="fas fa-info-circle text-primary text-2xl"></i>
            <axpo-typography>
              {{ 'core.personToInform' | transloco }}
            </axpo-typography>
          </div>
          <div class="flex space-x-4">
            <svg class="fill-primary h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                d="M482 363.58V316c0-49.388-15.925-96.331-46.053-135.753-26.743-34.993-63.719-61.83-105.003-76.443C329.795 80.007 310.077 61 286 61h-60c-24.077 0-43.795 19.007-44.944 42.804-41.284 14.613-78.26 41.45-105.003 76.443C45.925 219.669 30 266.612 30 316v47.58C12.541 369.772 0 386.445 0 406c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 0-19.555-12.541-36.228-30-42.42zM60 316c0-54.091 23.106-104.728 61-140.855V226c0 8.284 6.716 15 15 15s15-6.716 15-15v-74.383a195.128 195.128 0 0130-15.679V256c0 8.284 6.716 15 15 15s15-6.716 15-15V114.932 106c0-8.271 6.729-15 15-15h60c8.271 0 15 6.729 15 15v150c0 8.284 6.716 15 15 15s15-6.716 15-15V135.938a195.304 195.304 0 0130 15.679V226c0 8.284 6.716 15 15 15s15-6.716 15-15v-50.855c37.894 36.127 61 86.764 61 140.855v45H60v-45zm407 105H45c-8.271 0-15-6.729-15-15s6.729-15 15-15h422c8.271 0 15 6.729 15 15s-6.729 15-15 15z"
              ></path>
            </svg>
            <axpo-typography>
              {{ 'core.executingPerson' | transloco }}
            </axpo-typography>
          </div>
        </div>
        <div class="pt-4">
          @for (contact of contacts(); track contact.id) {
            <div
              class="border-b-2 border-border-soft-300 flex justify-between items-center pb-4 pt-4"
            >
              <div class="flex-none">
                @if (contact.isExecutor === true) {
                  <svg
                    class="fill-primary h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M482 363.58V316c0-49.388-15.925-96.331-46.053-135.753-26.743-34.993-63.719-61.83-105.003-76.443C329.795 80.007 310.077 61 286 61h-60c-24.077 0-43.795 19.007-44.944 42.804-41.284 14.613-78.26 41.45-105.003 76.443C45.925 219.669 30 266.612 30 316v47.58C12.541 369.772 0 386.445 0 406c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 0-19.555-12.541-36.228-30-42.42zM60 316c0-54.091 23.106-104.728 61-140.855V226c0 8.284 6.716 15 15 15s15-6.716 15-15v-74.383a195.128 195.128 0 0130-15.679V256c0 8.284 6.716 15 15 15s15-6.716 15-15V114.932 106c0-8.271 6.729-15 15-15h60c8.271 0 15 6.729 15 15v150c0 8.284 6.716 15 15 15s15-6.716 15-15V135.938a195.304 195.304 0 0130 15.679V226c0 8.284 6.716 15 15 15s15-6.716 15-15v-50.855c37.894 36.127 61 86.764 61 140.855v45H60v-45zm407 105H45c-8.271 0-15-6.729-15-15s6.729-15 15-15h422c8.271 0 15 6.729 15 15s-6.729 15-15 15z"
                    ></path>
                  </svg>
                } @else {
                  <i class="fas fa-info-circle text-primary text-2xl"></i>
                }
              </div>
              <div class="flex-1 ml-8">
                <axpo-typography variant="body-s">
                  <span class="light-gray">{{ contact.designation }}</span>
                </axpo-typography>
                <axpo-typography variant="body-m">
                  {{ contact.name }}
                </axpo-typography>
                <axpo-typography variant="body-s">
                  <span class="light-gray">{{ contact.mail }}</span>
                </axpo-typography>
              </div>

              <div class="w-[80px] flex justify-between">
                @if (contact.phone) {
                  <axpo-form-element
                    formType="checkbox"
                    [value]="getContactNotifySignal(contact.id, 'phone')()"
                    (valueChange)="updateContactNotifySignal(contact.id, 'phone')"
                    size="medium"
                  ></axpo-form-element>
                } @else {
                  <div class="w-[20px] h-[20px]"></div>
                }

                @if (contact.mail) {
                  <axpo-form-element
                    formType="checkbox"
                    [value]="getContactNotifySignal(contact.id, 'mail')()"
                    (valueChange)="updateContactNotifySignal(contact.id, 'mail')"
                    size="medium"
                  ></axpo-form-element>
                } @else {
                  <div class="w-[20px] h-[20px]"></div>
                }

                @if (contact.phone) {
                  <axpo-form-element
                    formType="checkbox"
                    [value]="getContactNotifySignal(contact.id, 'wakeUp')()"
                    (valueChange)="updateContactNotifySignal(contact.id, 'wakeUp')"
                    size="medium"
                  ></axpo-form-element>
                } @else {
                  <div class="w-[20px] h-[20px]"></div>
                }
              </div>
            </div>
          }
          <div class="flex justify-between items-center pt-4">
            <i class="fas fa-info-circle text-primary text-2xl"></i>
            <div class="flex-1 ml-8">
              <axpo-typography variant="body-m">
                {{ tenantContactName() }}
              </axpo-typography>
            </div>

            <div class="flex justify-between w-[80px]">
              <div class="w-[20px] h-[20px]"></div>
              <axpo-form-element
                formType="checkbox"
                [value]="tenantContactInformed()"
                (valueChange)="updateContactTenantInformedCheck($event)"
                size="medium"
                class="min-w-30"
              ></axpo-form-element>
              <div class="w-[20px] h-[20px]"></div>
            </div>
          </div>
        </div>
      </div>
    </axpo-dialog>
  }
</section>
