export const getTextColorByVoltageKey = (key: string | null | undefined) => {
  switch (key) {
    case '1': // 16kv
      return 'text-ima-turquoise';
    case '2': // 50kv
      return 'text-ima-red';
    case '3': // 110kv
      return 'text-ima-orange';
    case '4': // 220kv
      return 'text-ima-green';
    case '5': //380kv
      return 'text-ima-violet';
    default:
      return 'text-ima-red';
  }
};

export const getBackgroundColorByVoltageKey = (key: string | null | undefined) => {
  switch (key) {
    case '1': // 16kv
      return 'bg-ima-turquoise';
    case '2': // 50kv
      return 'bg-ima-red';
    case '3': // 110kv
      return 'bg-ima-orange';
    case '4': // 220kv
      return 'bg-ima-green';
    case '5': //380kv
      return 'bg-ima-violet';
    default:
      return 'bg-ima-red';
  }
};
