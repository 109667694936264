import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { type VariantProps, cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

export const axpoButtonVariants = cva(
  [
    'font-almarena',
    'inline-flex',
    'justify-center',
    'items-center',
    'text-[14px]',
    'font-bold',
    'leading-4',
    'tracking-[-0.037px]',
  ],
  {
    variants: {
      variant: {
        default: ['min-w-24', 'px-6', 'py-3', 'rounded-full'],
        icon: ['p-[14px]', 'rounded-full'],
        svg: ['py-3', 'rounded-full'],
      },
      type: {
        filled: [
          'text-white',
          'bg-primary',
          'hover:bg-interaction-red',
          'disabled:bg-disabled',
          'disabled:text-text-primary',
        ],
        outlined: [
          'border-[1.25px]',
          'bg-transparent',
          'text-text-link',
          'border-primary',
          'hover:border-interaction-red',
          'hover:text-interaction-red',
          'disabled:border-disabled',
          'disabled:text-text-secondary',
        ],
        ghost: [
          'bg-transparent',
          'border-[1.25px]',
          'border-transparent',
          'text-text-link',
          'hover:text-interaction-red',
          'disabled:text-text-secondary',
        ],
      },
      style: {
        primary: [],
        secondary: [],
      },
      focus: {
        true: [],
        false: [],
      },
    },
    defaultVariants: {
      variant: 'default',
      type: 'filled',
      style: 'primary',
      focus: false,
    },
    compoundVariants: [
      {
        type: 'filled',
        style: 'primary',
        focus: true,
        class: ['focus:border', 'focus:border-white', 'focus:ring-2', 'focus:ring-primary'],
      },
      {
        type: 'outlined',
        focus: true,
        class: ['focus:ring', 'focus:ring-[#F4F4F5]'],
      },
      {
        type: 'filled',
        style: 'secondary',
        class: [
          'text-white',
          'bg-text-primary',
          'hover:bg-text-highlight',
          'disabled:bg-surface-1',
          'disabled:text-text-primary',
        ],
      },
      {
        type: 'filled',
        style: 'secondary',
        focus: true,
        class: ['focus:border', 'focus:border-white', 'focus:ring-2', 'focus:ring-text-primary'],
      },
      {
        type: 'outlined',
        style: 'secondary',
        class: [
          'text-text-primary',
          'border-border',
          'hover:border-text-primary',
          'hover:text-text-highlight',
          'disabled:border-border',
          'disabled:text-text-secondary',
        ],
      },
      {
        variant: 'default',
        type: 'ghost',
        style: 'secondary',
        class: ['text-text-primary', 'hover:text-text-highlight', 'disabled:text-text-secondary'],
      },
    ],
  },
);
export type AxpoButtonVariants = VariantProps<typeof axpoButtonVariants>;

@Component({
  selector: 'axpo-button',
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './axpo-button.component.html',
  styleUrl: './axpo-button.component.css',
})
export class AxpoButtonComponent {
  variant = input<AxpoButtonVariants['variant']>('default');
  type = input<AxpoButtonVariants['type']>('filled');
  style = input<AxpoButtonVariants['style']>('primary');
  focus = input<AxpoButtonVariants['focus']>(false);
  disabled = input<boolean>(false);

  computedClass = computed(() => {
    return twMerge(
      axpoButtonVariants({
        variant: this.variant(),
        type: this.type(),
        style: this.style(),
        focus: this.focus(),
      }),
    );
  });
}
