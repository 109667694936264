import { ChangeDetectionStrategy, Component, computed, input, model, output } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';

import { AxpoButtonComponent } from '../../core/axpo-button/axpo-button.component';
import { AxpoTypographyComponent } from '../../core/axpo-typography/axpo-typography.component';
import { ITileMode } from '../../shared/utils/tileStates';

export type availableButtons = 'confirm' | 'cancel';
export interface ITileButtonAction {
  action: string | undefined;
  key: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tile-component',
  templateUrl: './tile.component.html',
  imports: [AxpoTypographyComponent, AxpoButtonComponent, TranslocoPipe],
})
export class TileComponent {
  title = input.required<string>();
  mode = model.required<ITileMode>();
  takenAction = output<string | undefined>();
  controlButtons = input<availableButtons[]>([]);
  disabled = input<boolean>(false);
  editMode = input<'edit' | 'create'>('edit');

  hasConfirmButton = computed(() => {
    return this.controlButtons().indexOf('confirm') > -1;
  });
  hasCancelButton = computed(() => {
    return this.controlButtons().indexOf('cancel') > -1;
  });

  editTile() {
    this.mode.set('edit');
  }

  viewTile() {
    this.mode.set('view');
  }

  onSave(action: availableButtons) {
    if (action === 'confirm') {
      this.takenAction.emit(Date.now().toString());
    } else {
      this.takenAction.emit(undefined);
      this.viewTile();
    }
  }
}
