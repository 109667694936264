import { Injectable, OnDestroy, inject, signal } from "@angular/core";
import { DeviceDetectorService } from 'ngx-device-detector'

export const axpoBreakpoints = {
  SM: 640,
  MD: 768,
  LG: 1024,
  XL: 1280,
  '2XL': 1536,
} as const;
 
export type BreakpointKey = keyof typeof axpoBreakpoints;
 
export type AxpoBreakpoints = (typeof axpoBreakpoints)[BreakpointKey];

@Injectable({ providedIn: "root" })
export class AxpoResizeService implements OnDestroy {
  private deviceService = inject(DeviceDetectorService);

  width = signal(window.innerWidth);
  height = signal(window.innerHeight);
  orientation = signal(this.deviceService.getDeviceInfo().orientation);
  isMobile = signal(this.deviceService.isMobile());
  isDesktop = signal(this.deviceService.isDesktop());
  isTablet = signal(this.deviceService.isTablet());

  constructor() {
    window.addEventListener("resize", this.resizeHandler);
  }

  private resizeHandler = () => {
    this.width.set(window.innerWidth);
    this.height.set(window.innerHeight);
    this.orientation.set(this.width() > this.height()? 'landscape': 'portrait');
  } 

  getDeviceInfo() {
    return this.deviceService.getDeviceInfo();
  }

  ngOnDestroy() {
    window.removeEventListener("resize", this.resizeHandler)
  }
}