import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  WritableSignal,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { ITileButtonAction, TileComponent, availableButtons } from './tile.component';
import { AxpoButtonComponent } from '../../core/axpo-button/axpo-button.component';
import { AxpoDialogComponent } from '../../core/axpo-dialog/axpo-dialog.component';
import { AxpoFormElementComponent } from '../../core/axpo-form-element/axpo-form-element.component';
import { AxpoSpinnerComponent } from '../../core/axpo-spinner/axpo-spinner.component';
import { AxpoTypographyComponent } from '../../core/axpo-typography/axpo-typography.component';
import { PanelEditDetailsComponent } from '../../shared/controls/details/edit/panel-edit-details.component';
import { PanelViewDetailsComponent } from '../../shared/controls/details/view/panel-view-details.component';
import { IContactModel, IINotifyBody } from '../../shared/models/api_models';
import { IncidentService } from '../../shared/services/incident.service';
import { TenantService } from '../../shared/services/tenant.service';

interface ISignalTilePair {
  visible: WritableSignal<boolean>,
  mode: WritableSignal<ITileMode>,
  action: WritableSignal<availableButtons | undefined>
};
type ITileSignalPair = Record<string, ISignalTilePair>;
type IBooleanSignalPair = Record<string, { phone: WritableSignal<boolean>, mail: WritableSignal<boolean>, wakeUp: WritableSignal<boolean> }>;
export type ITileMode = 'create' | 'edit' | 'view' | 'locked';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-incident-detail',
  templateUrl: './incident-detail.component.html',
  imports: [
    AxpoTypographyComponent,
    AxpoButtonComponent,
    AxpoFormElementComponent,
    TranslocoPipe,
    AxpoDialogComponent,
    AxpoSpinnerComponent,
    TileComponent,
    PanelEditDetailsComponent,
    PanelViewDetailsComponent
],
})
export class IncidentDetailComponent implements OnInit, OnDestroy {
  private activatedRoute = inject(ActivatedRoute);
  private toastr = inject(ToastrService);
  incidentService = inject(IncidentService);
  tenantService = inject(TenantService);
  translocoService = inject(TranslocoService);

  private accessCode = signal<string | null | undefined>(undefined);
  private router = inject(Router);
  private tenantId = signal<string | null | undefined>(undefined);
  private routeChangeSubscription!: Subscription;

  incidentTitle = signal<string | null | undefined>(undefined);
  incidentCreatorName = signal<string | null | undefined>(undefined);
  duplicatedAccessCode = signal<string | undefined>(undefined);
  clipboardLink = signal<string | undefined>(undefined);
  duplicateIncident = signal<boolean>(false);
  incidentCloned = signal<boolean>(false);
  shareLink = signal<boolean>(false);
  linkShareMail = signal<boolean>(false);
  linkShareSMS = signal<boolean>(false);
  linkShareCall = signal<boolean>(false);
  tenantContactInformed = signal<boolean>(false);
  contacts = signal<IContactModel[] | null | undefined>(undefined);
  contactSignals: IBooleanSignalPair = {};
  tenantContactName = signal<string | undefined>(undefined);
  tenantContactId = signal<number | undefined>(undefined);

  tilesSignals = signal<ITileSignalPair>({});

  ngOnInit(): void {
    this.onRouteChanged();
    this.routeChangeSubscription = this.activatedRoute.paramMap.subscribe(
      () => {
        this.onRouteChanged();
      }
    );
  }

  createTileSignals(tileTitle: string, isVisible: boolean) {
    this.tilesSignals.update((tileSignals) =>
    {
      const signals = {...tileSignals};
      signals[tileTitle] = {
        visible: signal<boolean>(isVisible),
        mode: signal<ITileMode>('view'),
        action: signal<availableButtons | undefined>(undefined)
      }

      return signals;
    });
  }

  _updateIncidentDetails = effect(() => {
    const details = this.incidentService.incidentDetails();
    untracked(() => {
      this.incidentTitle.set(details?.incidentTitle);
      this.incidentCreatorName.set(details?.incidentCreatorName);
      this.contacts.set(details?.contacts);
      const contacts = details?.contacts;
      if (contacts) {
        for (const contact of contacts) {
          const id = contact?.id;
          if (id) {
            this.contactSignals[id] = {
              phone: signal<boolean>(false),
              mail: signal<boolean>(false),
              wakeUp: signal<boolean>(false),
            };
          }
        }
      }
    });
  });

  _tenantSettings = effect(() => {
    const tenantContact = this.tenantService.tenantSettings();
    untracked(() => {
      if (tenantContact && tenantContact.tenantIncidentMailRecipientContact) {
        this.tenantContactId.set(tenantContact.tenantIncidentMailRecipientContact.id);
        this.tenantContactName.set(tenantContact.tenantIncidentMailRecipientContact.name);
        if (tenantContact.tileModels) {
          for ( const tile of tenantContact.tileModels ) {
            this.createTileSignals(tile.name as string, !!tile.isVisible);
          }
        }
      }
    });
  });

  _closeCloneDialogAndRedirect = effect(() => {
    const duplicatedLoading = this.incidentService.duplicationInProgress();
    const clonedAccessCode = this.incidentService.duplicatedAccessCode();
    const duplicatedIncident = this.duplicateIncident();
    untracked(() => {
      if (
        clonedAccessCode !== undefined &&
        duplicatedLoading === false &&
        duplicatedIncident === true
      ) {
        this.incidentCloned.set(true);
        this.duplicateIncident.set(false);
        this.router.navigate([
          '/incident/' +
            this.incidentService.duplicatedAccessCode() +
            '/' +
            this.tenantService.tenantId(),
        ]);
      }
    });
  });

  onRouteChanged(): void {
    const accessCode = this.activatedRoute.snapshot.paramMap.get('accessCode');
    const tenantId = this.activatedRoute.snapshot.paramMap.get('tenantId');
    const isCloned = this.incidentService.duplicatedAccessCode();
    this.accessCode.set(accessCode);
    this.tenantId.set(tenantId);
    this.incidentService.duplicatedAccessCode.set(undefined);

    if (accessCode && tenantId) {
      this.incidentService.getIncidentDetails(accessCode, tenantId);
      this.tenantService.getSettings(tenantId);
    }

    if (isCloned !== undefined) {
      this.toastr.info(this.translocoService.translate('incidentDetail.duplicateSuccessful'));
    }
  }

  updateAction(event: ITileButtonAction) {
    if (event.action === 'cancel') {
      return;
    }

    this.tilesSignals()[event.key].action.set(event.action);
  }

  // share modal dialog
  getShareLinkBtnStyle(): 'filled' | 'outlined' {
    if (this.clipboardLink()) {
      return 'filled';
    }
    return 'outlined';
  }

  getShareLinkBtnText(): string {
    if (this.clipboardLink()) {
      return 'incidentDetail.shareLinkDialog.linkCopied';
    }
    return 'incidentDetail.shareLinkDialog.copyLink';
  }

  markAllContacts(type: 'mail' | 'phone' | 'wakeUp', valueEvent: boolean): void {
    for (const checkedId in this.contactSignals) {
      this.contactSignals[checkedId][type].set(valueEvent);
    }
    if (type === 'mail') {
      this.tenantContactInformed.set(valueEvent);
    }
  }

  getContactNotifySignal(
    contactId: number | undefined,
    type: 'phone' | 'mail' | 'wakeUp',
  ): WritableSignal<boolean> {
    if (contactId) {
      if (!this.contactSignals[contactId]) {
        this.contactSignals[contactId] = {
          phone: signal<boolean>(false),
          mail: signal<boolean>(false),
          wakeUp: signal<boolean>(false),
        };
      }

      return this.contactSignals[contactId][type];
    }

    return signal<boolean>(false);
  }

  updateContactNotifySignal(
    contactId: number | undefined,
    type: 'phone' | 'mail' | 'wakeUp',
  ): void {
    if (contactId) {
      this.contactSignals[contactId][type].update(oldValue => !oldValue);
    }
  }

  onShareClosed(action: 'confirm' | 'cancel') {
    if (action === 'confirm') {
      const apiContacts: IINotifyBody[] = [];
      for (const checkedId in this.contactSignals) {
        const mailChecked = this.contactSignals[checkedId]['mail']();
        const phoneChecked = this.contactSignals[checkedId]['phone']();
        const wakeUpChecked = this.contactSignals[checkedId]['wakeUp']();

        if (mailChecked || phoneChecked || wakeUpChecked) {
          const contact = this.contacts()?.find(contact => contact.id === parseInt(checkedId));
          apiContacts.push({
            contactId: parseInt(checkedId),
            sendMail: mailChecked,
            sendSms: phoneChecked,
            makeCall: wakeUpChecked,
            hasMail: contact && contact.mail ? true : false,
            hasPhone: contact && contact.phone ? true : false,
          });
        }

        if (this.tenantContactInformed() === true) {
          apiContacts.push({
            contactId: this.tenantContactId() ?? -1,
            sendMail: this.linkShareMail(),
            sendSms: this.linkShareSMS(),
            makeCall: this.linkShareCall(),
            hasMail: true,
            hasPhone: false,
          });
        }
      }
      const accessCode = this.accessCode();
      if (accessCode) {
        this.incidentService.notify(accessCode, apiContacts);
      }
    }
    this.clipboardLink.set(undefined);
    this.shareLink.set(false);
  }

  copyLinkToClipboard() {
    this.clipboardLink.set(window.location.href);
    navigator.clipboard.writeText(window.location.href);
  }

  duplicateIncidentDialogAction(action: any) {
    if (action === 'cancel') {
      this.duplicateIncident.set(false);
      return;
    }

    const accessCode = this.accessCode();
    if (action === 'confirm' && accessCode) {
      this.incidentService.cloneIncident(accessCode);
    }
  }

  openIncidentDialog() {
    this.duplicateIncident.set(true);
  }

  openShareModal() {
    this.shareLink.set(true);
  }

  updateMailLinkCheck(checkValue: any) {
    this.linkShareMail.set(checkValue);
    this.markAllContacts('mail', checkValue);
  }

  updateSMSLinkCheck(checkValue: any) {
    this.linkShareSMS.set(checkValue);
    this.markAllContacts('phone', checkValue);
  }

  updateCallLinkCheck(checkValue: any) {
    this.linkShareCall.set(checkValue);
    this.markAllContacts('wakeUp', checkValue);
  }

  updateContactTenantInformedCheck(checkValue: any) {
    this.tenantContactInformed.set(checkValue);
  }

  ngOnDestroy(): void {
    if (this.routeChangeSubscription) {
      this.routeChangeSubscription.unsubscribe();
    }
  }
}
