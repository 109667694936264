import { ApplicationRef, Injectable, signal } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CheckForUpdateService {
  constructor(appRef: ApplicationRef, updates: SwUpdate) {
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
    const intervalInSeconds$ = interval(10 * 1000);
    const everyIntervalInSecondsOnceAppIsStable$ = concat(appIsStable$, intervalInSeconds$);

    everyIntervalInSecondsOnceAppIsStable$.subscribe(async () => {
      try {
        const updateFound = await updates.checkForUpdate();
        if (updateFound) {
          this.showNewSwVersionPrompt.set(updateFound);
        }
      } catch (err) {
        console.warn('Failed to check for updates:', err);
      }
    });
  }

  showNewSwVersionPrompt = signal<boolean | undefined>(undefined);
}
