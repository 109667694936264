import { Injectable, inject, signal } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { ApiService } from './api.service';
import { ICommentModel, IValidationErrorModel } from '../models/api_models';

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  private apiService = inject(ApiService);
  private toastr = inject(ToastrService);

  isUpdatingComment = signal<boolean>(false);
  commentUpdateResult = signal<ICommentModel | undefined>(undefined);
  commentCreateResult = signal<ICommentModel | undefined>(undefined);
  commentDeleteResult = signal<number | undefined>(undefined);

  addOrUpdate(accessCode: string, tenantId: string, comment: ICommentModel) {
    this.isUpdatingComment.set(true);
    this.apiService
      .post<number>(`/api/v1/Comment/add-or-update/${accessCode}/${tenantId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: comment,
      })
      .subscribe({
        next: details => {
          if (comment.id) {
            this.commentUpdateResult.set(comment);
          } else {
            this.commentCreateResult.set({ ...comment, id: details });
          }
        },
        error: (error: IValidationErrorModel) => {
          console.error(error?.key as string);
          this.toastr.error(error?.errorMessage as string);
        },
        complete: () => {
          this.isUpdatingComment.set(false);
        },
      });
  }

  delete(accessCode: string, id: string) {
    this.isUpdatingComment.set(true);
    this.apiService.delete<any>(`/api/v1/Comment/delete/${accessCode}/${id}`).subscribe({
      next: details => {
        this.commentDeleteResult.set(details);
      },
      error: (error: IValidationErrorModel) => {
        console.error(error?.key as string);
        this.toastr.error(error?.errorMessage as string);
      },
      complete: () => {
        this.isUpdatingComment.set(false);
      },
    });
  }
}
