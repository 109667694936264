import { Injectable, inject, signal } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { IKeyValueModel, IValidationErrorModel } from '../models/api_models';

@Injectable({
  providedIn: 'root',
})
export class VoltageService {
  private apiService = inject(ApiService);
  private toastr = inject(ToastrService);
  private translocoService = inject(TranslocoService);
  environment = environment;

  voltages = signal<IKeyValueModel[] | undefined>(undefined);
  isLoadingVoltages = signal<boolean>(false);

  removeVoltageValidation = signal<IValidationErrorModel[] | undefined>(undefined);
  isRemovingVoltage = signal<boolean>(false);

  addVoltageValidation = signal<IValidationErrorModel[] | undefined>(undefined);
  isAddingVoltage = signal<boolean>(false);

  getVoltages(accessCode: string) {
    const url = `/api/v1/voltage/load-list/${accessCode}`;

    this.isLoadingVoltages.set(true);
    this.apiService.get<IKeyValueModel[]>(url).subscribe({
      next: details => {
        this.voltages.set(details);
      },
      error: () => {
        this.toastr.error(this.translocoService.translate('incidentDetail.panelAlert.getError'));
      },
      complete: () => {
        this.isLoadingVoltages.set(false);
      },
    });
  }

  removeVoltage(accessCode: string, voltageId: number) {
    this.isRemovingVoltage.set(true);
    this.apiService
      .delete<IValidationErrorModel[]>(`/api/v1/voltage/remove/${accessCode}/${voltageId}`)
      .subscribe({
        next: details => {
          this.removeVoltageValidation.set(details);
        },
        error: () => {
          this.toastr.error(
            this.translocoService.translate('incidentDetail.panelAlert.removeError'),
          );
        },
        complete: () => {
          this.isRemovingVoltage.set(false);
        },
      });
  }

  addVoltage(accessCode: string, voltageId: number) {
    this.isAddingVoltage.set(true);
    this.apiService
      .put<IValidationErrorModel[]>(`/api/v1/voltage/add/${accessCode}/${voltageId}`)
      .subscribe({
        next: details => {
          this.addVoltageValidation.set(details);
        },
        error: () => {
          this.toastr.error(this.translocoService.translate('incidentDetail.panelAlert.getError'));
        },
        complete: () => {
          this.isAddingVoltage.set(false);
        },
      });
  }
}
