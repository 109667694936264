<div class="flex flex-col space-y-4">
  <div>
    <axpo-form-element
      label="{{ 'home.table.title' | transloco }}"
      formType="text"
      [(value)]="incidentTitle"
      [disabled]="true"
    ></axpo-form-element>
    <axpo-typography class="text-text-secondary" variant="body-s">
      {{ 'incidentDetail.generated' | transloco }}
    </axpo-typography>
  </div>
  <axpo-form-element
    label="{{ 'incidentDetail.creator' | transloco }}"
    formType="text"
    [(value)]="creatorName"
    [disabled]="isBlocked()"
  ></axpo-form-element>
  <axpo-form-element
    label="{{ 'incidentDetail.description' | transloco }}"
    formType="textarea"
    [(value)]="description"
    [disabled]="isBlocked()"
  ></axpo-form-element>
  <axpo-selectable-buttons
    [buttons]="alarmButtons()"
    [multiselect]="false"
    (buttonClicked)="alarmButtonsClick($event)"
    [disabled]="isBlocked()"
    label="{{ 'incidentDetail.alarmCategory' | transloco }}"
    class="w-60"
  ></axpo-selectable-buttons>
  <axpo-selectable-buttons
    [buttons]="stateButtons()"
    [multiselect]="false"
    (buttonClicked)="stateButtonsClick($event)"
    [disabled]="isBlocked()"
    label="{{ 'home.state' | transloco }}"
  ></axpo-selectable-buttons>
  <div class="flex flex-col justify-between md:flex-row">
    <axpo-selectable-buttons
      [buttons]="impactButtons()"
      [multiselect]="false"
      (buttonClicked)="impactButtonsClick($event)"
      [disabled]="isBlocked()"
      label="{{ 'incidentDetail.impact' | transloco }}"
      class="w-60"
    ></axpo-selectable-buttons>
    <axpo-selectable-buttons
      [buttons]="faultButtons()"
      [multiselect]="false"
      (buttonClicked)="faultButtonsClick($event)"
      [disabled]="isBlocked()"
      label="{{ 'incidentDetail.faults' | transloco }}"
      class="w-60"
    ></axpo-selectable-buttons>
  </div>
  <axpo-form-element
    label="{{ 'incidentDetail.impact' | transloco }}"
    formType="select"
    [(value)]="faultEffectId"
    [options]="impactOptions()"
    [disabled]="isBlocked()"
  ></axpo-form-element>
  <div class="flex space-x-4">
    <axpo-form-element
      label="{{ 'incidentDetail.faultDate' | transloco }}"
      formType="date"
      [(value)]="timeStamp"
      [disabled]="isBlocked()"
      class="w-60"
    ></axpo-form-element>
    <axpo-form-element
      label="{{ 'incidentDetail.time' | transloco }}"
      formType="time"
      [(value)]="timeStampTime"
      [disabled]="isBlocked()"
    ></axpo-form-element>
  </div>
  <div class="flex space-x-4">
    <axpo-form-element
      label="{{ 'incidentDetail.faultFixedDate' | transloco }}"
      formType="date"
      [(value)]="fixedTimeStamp"
      [disabled]="isBlocked()"
      class="w-60"
    ></axpo-form-element>
    <axpo-form-element
      label="{{ 'incidentDetail.time' | transloco }}"
      formType="time"
      [(value)]="fixedTimeStampTime"
      [disabled]="isBlocked()"
    ></axpo-form-element>
  </div>
</div>
