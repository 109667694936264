import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { type VariantProps, cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

export const axpoTypographyVariants = cva('', {
  variants: {
    variant: {
      H1: ['text-[52px]', 'leading-[64px]', 'tracking-[-0.52px]', 'font-bold', 'font-almarena'],
      H2: ['text-[44px]', 'leading-[48.4px]', 'tracking-[-0.44px]', 'font-bold', 'font-almarena'],
      H3: ['text-[36px]', 'leading-[39.6px]', 'tracking-[-0.36px]', 'font-bold', 'font-almarena'],
      H4: ['text-[28px]', 'leading-[32px]', 'tracking-[-0.28px]', 'font-bold', 'font-almarena'],
      'title-xl': [
        'text-[32px]',
        'leading-[40px]',
        'tracking-[-0.32px]',
        'font-almarena',
        'font-normal',
      ],
      'title-l': [
        'text-[24px]',
        'leading-[32px]',
        'tracking-[-0.24]',
        'font-almarena',
        'font-normal',
      ],
      'title-m': [
        'text-[24px]',
        'leading-[32px]',
        'tracking-[-0.24]',
        'font-almarena',
        'font-normal',
      ],
      'title-s': [
        'text-[24px]',
        'leading-[32px]',
        'tracking-[-0.24]',
        'font-almarena',
        'font-normal',
      ],
      'body-xl': [
        'text-[20px]',
        'leading-[32px]',
        'tracking-[-0.2px]',
        'font-normal',
        'font-open-sans',
      ],
      'body-l': [
        'text-[16px]',
        'leading-[24px]',
        'tracking-[-0.16px]',
        'font-normal',
        'font-open-sans',
      ],
      'body-m': [
        'text-[14px]',
        'leading-[24px]',
        'tracking-[-0.14px]',
        'font-normal',
        'font-open-sans',
      ],
      'body-s': [
        'text-[12px]',
        'leading-[16px]',
        'tracking-[0px]',
        'font-normal',
        'font-open-sans',
      ],
    },
    family: {
      none: [],
      'almarena-mono': ['font-light', 'font-almarena-mono'],
    },
    weight: {
      none: [],
      light: ['font-light'],
      normal: ['font-normal'],
      bold: ['font-bold'],
      semibold: ['font-semibold'],
    },
  },
  compoundVariants: [],
});

export type AxpoTypographyProps = VariantProps<typeof axpoTypographyVariants>;

@Component({
  selector: 'axpo-typography',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  templateUrl: './axpo-typography.component.html',
  styleUrl: './axpo-typography.component.css',
})
export class AxpoTypographyComponent {
  variant = input<AxpoTypographyProps['variant']>('body-m');
  family = input<AxpoTypographyProps['family']>('none');
  weight = input<AxpoTypographyProps['weight']>('none');

  computedClass = computed(() => {
    const mergedClasses = twMerge(
      axpoTypographyVariants({
        variant: this.variant(),
        family: this.family(),
        weight: this.weight(),
      }),
    );
    return mergedClasses;
  });
}
