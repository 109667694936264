import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  computed,
  effect,
  inject,
  input,
  signal,
  untracked,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCheck, faDownload } from '@fortawesome/free-solid-svg-icons';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

import { FileDropComponent } from './filedrop/filedrop.component';
import { AxpoSpinnerComponent } from '../../../core/axpo-spinner/axpo-spinner.component';
import { IAttachmentLocalModel } from '../../models/api_models';
import { AttachmentService } from '../../services/attachment.service';
import { IncidentService } from '../../services/incident.service';
import { LanguageService } from '../../services/lang.service';
import { TenantService } from '../../services/tenant.service';
import * as customValidations from '../../utils/customValidations';
import formatBytes from '../../utils/formatBytes';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-edit-attachments',
  templateUrl: './edit-attachments.component.html',
  imports: [FileDropComponent, AxpoSpinnerComponent, TranslocoPipe],
})
export class EditAttachmentsComponent implements OnInit {
  translocoService = inject(TranslocoService);
  activatedRoute = inject(ActivatedRoute);
  langService = inject(LanguageService);
  faLib = inject(FaIconLibrary);
  incidentService = inject(IncidentService);
  attachmentService = inject(AttachmentService);
  internalAttachments = input.required<boolean>();
  tenantService = inject(TenantService);

  filesArray = signal<(File | null)[]>([]);

  maxUploadBytes: number = 1024 * 1024 * 15;
  uploadProgress = 0;

  attachments = computed(() => {
    if (this.internalAttachments()) {
      return this.attachmentService.attachmentsInternal();
    } 
    
    return this.attachmentService.attachments();
  });

  _uploadEffect = effect(() => {
    const tenantId = this.tenantService.tenantId();
    const files = this.filesArray();
    untracked(() => {
      if (files && files.length > 0 && tenantId) {
        this.uploadFiles(files, tenantId);
      }
    });
  });

  ngOnInit(): void {
    this.faLib.addIcons(faDownload, faCheck);
  }

  filesUploaded(files: FileList) {
    const fileArray: (File | null)[] = [];
    for (let index = 0; index < files.length; index++) {
      const element = files.item(index);
      fileArray.push(element);
    }
    this.filesArray.set(fileArray);
  }

  uploadFiles(files: any, tenantId: string | undefined) {
    const fileUploadErrors = {};
    const filesAmount = files.length;
    const accessCode = this.activatedRoute.snapshot.paramMap.get('accessCode');

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const validationMessages = this.validateFile(file as any);
      if (validationMessages) {
        Object.assign(fileUploadErrors, validationMessages);
        this.setProgress(filesAmount, i++);
        continue;
      }
      this.attachmentService.addAttachment(
        accessCode ?? '',
        tenantId ?? '',
        file as any,
        this.internalAttachments(),
      );
    }

    this.filesArray.set([]);
  }

  setProgress(total: number, current: number) {
    this.uploadProgress = Math.round((100 / total) * current);
  }

  validateFile(file: File) {
    if (!customValidations.hasMax100(file.name)) {
      return {
        [file.name]: 'Models_AttachmentModel_InvalidName',
      };
    }

    if (file.size >= this.maxUploadBytes) {
      const maxUploadExceededMessage = 'Models_AttachmentModel_MaxUploadExceeded';
      return {
        [file.name]: maxUploadExceededMessage.replace('{0} MB', formatBytes(this.maxUploadBytes)),
      };
    }

    return null;
  }

  download(attachment: IAttachmentLocalModel) {
    const accessCode = this.activatedRoute.snapshot.paramMap.get('accessCode');
    const tenantId = this.activatedRoute.snapshot.paramMap.get('tenantId');

    this.attachmentService.download(attachment, accessCode, tenantId);
  }

  remove(attachment: IAttachmentLocalModel) {
    const accessCode = this.activatedRoute.snapshot.paramMap.get('accessCode');
    const tenantId = this.activatedRoute.snapshot.paramMap.get('tenantId');
    this.attachmentService.removeAttachment(
      accessCode ?? '',
      attachment.id ?? 0,
      tenantId ?? '',
      this.internalAttachments(),
    );
  }
}
