import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

import { AxpoSpinnerComponent } from '../../../core/axpo-spinner/axpo-spinner.component';
import { AxpoTypographyComponent } from '../../../core/axpo-typography/axpo-typography.component';
import { IAttachmentLocalModel } from '../../models/api_models';
import { AttachmentService } from '../../services/attachment.service';
import { IncidentService } from '../../services/incident.service';
import { LanguageService } from '../../services/lang.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-view-attachments',
  templateUrl: './view-attachments.component.html',
  imports: [AxpoTypographyComponent, AxpoSpinnerComponent, TranslocoPipe],
})
export class ViewAttachmentsComponent implements AfterViewChecked {
  translocoService = inject(TranslocoService);
  langService = inject(LanguageService);
  faLib = inject(FaIconLibrary);
  activatedRoute = inject(ActivatedRoute);
  incidentService = inject(IncidentService);
  attachmentService = inject(AttachmentService);
  internalAttachments = input.required<boolean>();

  attachments = computed(() => {
    if (this.internalAttachments()) {
      return this.attachmentService.attachmentsInternal();
    } 
    
    return this.attachmentService.attachments();
  });

  ngAfterViewChecked(): void {
    const accessCode = this.activatedRoute.snapshot.paramMap.get('accessCode') ?? '';
    const tenantId = this.activatedRoute.snapshot.paramMap.get('tenantId') ?? '';
    this.attachmentService.checkIFAllAttachmentsCached(
      accessCode,
      tenantId,
      this.internalAttachments(),
    );
  }

  async download(attachment: IAttachmentLocalModel) {
    const accessCode = this.activatedRoute.snapshot.paramMap.get('accessCode');
    const tenantId = this.activatedRoute.snapshot.paramMap.get('tenantId');
    this.attachmentService.download(attachment, accessCode, tenantId);
  }

  downloadFromRemote(attachment: IAttachmentLocalModel) {
    const accessCode = this.activatedRoute.snapshot.paramMap.get('accessCode');
    const tenantId = this.activatedRoute.snapshot.paramMap.get('tenantId');

    this.attachmentService.downloadFromRemote(attachment, accessCode ?? '', tenantId ?? '');
  }

  downloadAllFilesForOfflineUse(): void {
    for (const attachment of (this.internalAttachments()
      ? this.attachmentService.attachmentsInternal()
      : this.attachmentService.attachments()) ?? []) {
      this.downloadOffline(attachment);
    }
  }

  downloadOffline(attachment: IAttachmentLocalModel) {
    const accessCode = this.activatedRoute.snapshot.paramMap.get('accessCode');
    const tenantId = this.activatedRoute.snapshot.paramMap.get('tenantId');

    this.attachmentService.getAttachment(accessCode ?? '', attachment.id ?? 0, tenantId ?? '');
  }

  showNoAttachments = computed(() => {
    if (
      (this.internalAttachments()
        ? this.attachmentService.attachmentsInternal()
        : this.attachmentService.attachments()
      )?.length === 0
    ) {
      return true;
    }
    return false;
  });
}
