import { NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnDestroy, WritableSignal, effect, inject, input, model, output, signal, untracked } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';

import { AxpoButtonComponent } from "../../../core/axpo-button/axpo-button.component";
import { AxpoDialogComponent } from "../../../core/axpo-dialog/axpo-dialog.component";
import { AxpoFormElementComponent } from "../../../core/axpo-form-element/axpo-form-element.component";
import { AxpoTypographyComponent } from "../../../core/axpo-typography/axpo-typography.component";
import { IContactModel, IINotifyBody } from "../../models/api_models";
import { AuthnService } from "../../services/authn.service";
import { IncidentService } from '../../services/incident.service';
import { TenantService } from '../../services/tenant.service';
import { ITileMode } from "../../utils/tileStates";

type IBooleanSignalPair = Record<
  string,
  { phone: WritableSignal<boolean>; mail: WritableSignal<boolean>; wakeUp: WritableSignal<boolean> }
>;


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-share-link',
  templateUrl: './share-link.component.html',
  imports: [AxpoDialogComponent, AxpoTypographyComponent, AxpoFormElementComponent, NgTemplateOutlet, AxpoButtonComponent, TranslocoPipe],
})
export class ShareLinkComponent implements OnDestroy{
  private tenantService = inject(TenantService);
  private authnService = inject(AuthnService);
  private activatedRoute = inject(ActivatedRoute);
  incidentService = inject(IncidentService);

  accessCode = signal<string | undefined>(undefined);
  tenantContactName = signal<string | undefined>(undefined);
  tenantContactId = signal<number | undefined>(undefined);
  tenantSupplyFailureContactName = signal<string | undefined>(undefined);
  tenantSupplyFailureContactId = signal<number | undefined>(undefined);
  clipboardLink = signal<string | undefined>(undefined);
  isLoggedIn = signal<boolean>(false);
  linkShareMail = signal<boolean>(false);
  linkShareSMS = signal<boolean>(false);
  linkShareCall = signal<boolean>(false);
  tenantContactInformed = signal<boolean>(false);
  tenantSupplyFailureContactInformed = signal<boolean>(false);
  contacts = signal<IContactModel[] | null | undefined>(undefined);
  contactSignals: IBooleanSignalPair = {};

  shareLink = model<boolean>(true);
  shareLinkClosed = output<'confirm' | 'cancel' | 'confirmAndLoad' | 'share'>();
  mode = input<ITileMode>('edit');

  _tenantSettings = effect(() => {
    const tenantContact = this.tenantService.tenantSettings();
    const user = this.authnService.user();
    untracked(() => {
      this.isLoggedIn.set(!!user);
      if (tenantContact) {
        if (tenantContact.tenantIncidentMailRecipientContact) {
          this.tenantContactId.set(tenantContact.tenantIncidentMailRecipientContact.id);
          this.tenantContactName.set(tenantContact.tenantIncidentMailRecipientContact.name);
        }
        if (tenantContact.tenantSupplyFailureMailRecipientContact) {
          this.tenantSupplyFailureContactId.set(
            tenantContact.tenantSupplyFailureMailRecipientContact.id,
          );
          this.tenantSupplyFailureContactName.set(
            tenantContact.tenantSupplyFailureMailRecipientContact.name,
          );
        }
      }
    });
  });

  _updateIncidentDetails = effect(() => {
    const details = this.incidentService.incidentDetails();

    untracked(() => {
      if ((details?.hasInterruptions ?? 0) > 0) {
        this.tenantSupplyFailureContactInformed.set(true);
      }
      
      this.contacts.set(details?.contacts);
      const contacts = details?.contacts;
      if (contacts) {
        for (const contact of contacts) {
          const id = contact?.id;
          if (id) {
            this.contactSignals[id] = {
              phone: signal<boolean>(false),
              mail: signal<boolean>(false),
              wakeUp: signal<boolean>(false),
            };
          }
        }
      }
    });
  });

  _onRouteChange = effect(() => {
    const accessCode = this.activatedRoute.snapshot.paramMap.get('accessCode');
    if (accessCode) {
      untracked(() => {
        this.accessCode.set(accessCode);
      });
    }
  });

  // share modal dialog
  getShareLinkBtnStyle(): 'filled' | 'outlined' {
    if (this.clipboardLink()) {
      return 'filled';
    }
    return 'outlined';
  }

  getShareLinkBtnText(): string {
    if (this.clipboardLink()) {
      return 'incidentDetail.shareLinkDialog.linkCopied';
    }
    return 'incidentDetail.shareLinkDialog.copyLink';
  }

  markAllContacts(type: 'mail' | 'phone' | 'wakeUp', valueEvent: boolean): void {
    for (const checkedId in this.contactSignals) {
      this.contactSignals[checkedId][type].set(valueEvent);
    }
    if (type === 'mail') {
      this.tenantContactInformed.set(valueEvent);
      this.tenantSupplyFailureContactInformed.set(valueEvent);
    }
  }

  getContactNotifySignal(
    contactId: number | undefined,
    type: 'phone' | 'mail' | 'wakeUp',
  ): WritableSignal<boolean> {
    if (contactId) {
      if (!this.contactSignals[contactId]) {
        this.contactSignals[contactId] = {
          phone: signal<boolean>(false),
          mail: signal<boolean>(false),
          wakeUp: signal<boolean>(false),
        };
      }

      return this.contactSignals[contactId][type];
    }

    return signal<boolean>(false);
  }

  updateContactNotifySignal(
    contactId: number | undefined,
    type: 'phone' | 'mail' | 'wakeUp',
  ): void {
    if (contactId) {
      this.contactSignals[contactId][type].update(oldValue => !oldValue);
    }
  }

  onShareClosed(action: 'confirm' | 'cancel', additionalAction?: 'confirmAndLoad' | 'share') {
    if ( (action === 'confirm' && additionalAction !== 'confirmAndLoad') && this.isLoggedIn() ) {
      const apiContacts: IINotifyBody[] = [];
      for (const checkedId in this.contactSignals) {
        const mailChecked = this.contactSignals[checkedId]['mail']();
        const phoneChecked = this.contactSignals[checkedId]['phone']();
        const wakeUpChecked = this.contactSignals[checkedId]['wakeUp']();

        if (mailChecked || phoneChecked || wakeUpChecked) {
          const contact = this.contacts()?.find(contact => contact.id === parseInt(checkedId));
          apiContacts.push({
            contactId: parseInt(checkedId),
            sendMail: mailChecked,
            sendSms: phoneChecked,
            makeCall: wakeUpChecked,
            hasMail: contact && contact.mail ? true : false,
            hasPhone: contact && contact.phone ? true : false,
          });
        }

        if (this.tenantContactInformed() === true) {
          apiContacts.push({
            contactId: this.tenantContactId() ?? -1,
            sendMail: this.linkShareMail(),
            sendSms: this.linkShareSMS(),
            makeCall: this.linkShareCall(),
            hasMail: true,
            hasPhone: false,
          });
        }
      }
      const accessCode = this.accessCode();
      if (accessCode) {
        this.incidentService.notify(accessCode, apiContacts);
      }
    }
    this.clipboardLink.set(undefined);
    this.shareLink.set(false);
    if (additionalAction === 'confirmAndLoad') {
      this.shareLinkClosed.emit(additionalAction);
    } else {
      this.shareLinkClosed.emit(action);
    }
  }

  copyLinkToClipboard() {
    this.clipboardLink.set(window.location.href);
    navigator.clipboard.writeText(window.location.href);
  }

  updateMailLinkCheck(checkValue: any) {
    this.linkShareMail.set(checkValue);
    this.markAllContacts('mail', checkValue);
  }

  updateSMSLinkCheck(checkValue: any) {
    this.linkShareSMS.set(checkValue);
    this.markAllContacts('phone', checkValue);
  }

  updateCallLinkCheck(checkValue: any) {
    this.linkShareCall.set(checkValue);
    this.markAllContacts('wakeUp', checkValue);
  }

  updateContactTenantInformedCheck(checkValue: any) {
    this.tenantContactInformed.set(checkValue);
  }

  updateSupplyFailureContactTenantInformedCheck(checkValue: any) {
    this.tenantSupplyFailureContactInformed.set(checkValue);
  }

  getTitle() {
    if (this.mode() === 'create') {
      return 'incidentCreate.success';
    }

    return 'incidentDetail.share';
  }

  getCancelText() {
    if (this.mode() === 'edit') {
      return 'core.cancel';
    }

    return 'incidentCreate.create'
  }

  getConfirmText() {
    if (this.mode() === 'edit') {
      return 'incidentDetail.share';
    }

    return 'incidentCreate.show';
  }

  ngOnDestroy(): void {
    this.shareLink.set(false);
  }
}