<button [class]="computedClass()" [disabled]="disabled()">
  @if (variant() === 'icon') {
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      stroke="currentColor"
    >
      <path
        d="M11.1358 3.44783C11.1358 3.44783 6.23656 8.58726 6.02654 8.58726C5.81653 8.58726 0.864246 3.41284 0.864246 3.41284"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  } @else {
    <ng-content></ng-content>
  }
</button>
