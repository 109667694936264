<div class="flex flex-col space-y-4">
  <div>
    <axpo-form-element
      formId="incidentTitle"
      label="{{ 'home.table.title' | transloco }}"
      formType="text"
      [(value)]="incidentTitle"
      [disabled]="true"
    ></axpo-form-element>
    <div class="relative top-[-12px]">
      <axpo-typography class="text-text-secondary" variant="body-s">
        {{ 'incidentDetail.generated' | transloco }}
      </axpo-typography>
    </div>
  </div>
  <div>
    <axpo-form-element
      formId="description"
      label="{{ 'incidentDetail.description' | transloco }}"
      formType="textarea"
      [(value)]="description"
      [disabled]="isBlocked() || !isLoggedIn()"
      [min]="5"
      (errorEmitter)="formValidation($event, 'description')"
      [errorMessageInput]="this.formValidator?.getField('description')?.errorMessage()"
      [required]="true"
      [debounceTime]="0"
    ></axpo-form-element>
  </div>
  <div class="w-60">
    <axpo-selectable-buttons
      [buttons]="alarmButtons()"
      [multiselect]="false"
      (buttonClicked)="alarmButtonsClick($event)"
      [disabled]="isBlocked() || !isLoggedIn()"
      label="{{ 'incidentDetail.alarmCategory' | transloco }}"
    ></axpo-selectable-buttons>
    <div class="relative px-2 text-primary">
      <axpo-typography variant="body-s">
        {{ formValidator?.getField('alarmButtons')?.errorMessage() }}
      </axpo-typography>
    </div>
  </div>
  @if (mode() === 'edit') {
    <axpo-selectable-buttons
      [buttons]="stateButtons()"
      [multiselect]="false"
      (buttonClicked)="stateButtonsClick($event)"
      [disabled]="isBlocked()"
      label="{{ 'home.state' | transloco }}"
    ></axpo-selectable-buttons>
  }
  <div class="flex flex-col justify-between md:flex-row">
    <div>
      <axpo-selectable-buttons
        [buttons]="impactButtons()"
        [multiselect]="false"
        (buttonClicked)="impactButtonsClick($event)"
        [disabled]="isBlocked() || !isLoggedIn()"
        label="{{ 'incidentDetail.impact' | transloco }}"
        class="w-60"
      ></axpo-selectable-buttons>
      <div class="relative px-2 text-primary">
        <axpo-typography variant="body-s">
          {{ formValidator?.getField('impactButtons')?.errorMessage() }}
        </axpo-typography>
      </div>
    </div>
    <div>
      <axpo-selectable-buttons
        [buttons]="faultButtons()"
        [multiselect]="false"
        (buttonClicked)="faultButtonsClick($event)"
        [disabled]="isBlocked() || !isLoggedIn()"
        label="{{ 'incidentDetail.faults' | transloco }}"
        class="w-60"
      ></axpo-selectable-buttons>
      <div class="relative px-2 text-primary">
        <axpo-typography variant="body-s">
          {{ formValidator?.getField('faultButtons')?.errorMessage() }}
        </axpo-typography>
      </div>
    </div>
  </div>
  <axpo-form-element
    formId="impact"
    label="{{ 'incidentDetail.impact' | transloco }}"
    formType="select"
    [(value)]="faultEffectId"
    [options]="impactOptions()"
    [disabled]="isBlocked() || !isLoggedIn()"
    [errorMessageInput]="this.formValidator?.getField('impact')?.errorMessage()"
    [required]="true"
    (errorEmitter)="formValidation($event, 'impact')"
    [debounceTime]="0"
  ></axpo-form-element>
  <div class="flex space-x-4">
    <axpo-form-element
      formId="faultDate"
      label="{{ 'incidentDetail.faultDate' | transloco }}"
      formType="date"
      [(value)]="timeStamp"
      [disabled]="isBlocked() || !isLoggedIn()"
      class="w-60"
      [required]="true"
      (errorEmitter)="formValidation($event, 'faultDate')"
      [errorMessageInput]="this.formValidator?.getField('faultDate')?.errorMessage()"
      [debounceTime]="0"
    ></axpo-form-element>
    <axpo-form-element
      formId="faultTime"
      label="{{ 'incidentDetail.time' | transloco }}"
      formType="time"
      [(value)]="timeStampTime"
      [disabled]="isBlocked() || !isLoggedIn()"
      [required]="true"
      (errorEmitter)="formValidation($event, 'faultTime')"
      [errorMessageInput]="this.formValidator?.getField('faultTime')?.errorMessage()"
      [debounceTime]="0"
    ></axpo-form-element>
  </div>
  <div class="flex space-x-4">
    <axpo-form-element
      formId="faultFixedDate"
      label="{{ 'incidentDetail.faultFixedDate' | transloco }}"
      formType="date"
      [(value)]="fixedTimeStamp"
      [disabled]="isBlocked() || !isLoggedIn()"
      class="w-60"
      [debounceTime]="0"
    ></axpo-form-element>
    <div class="relative sm:static">
      <axpo-form-element
        formId="faultFixedTime"
        label="{{ 'incidentDetail.time' | transloco }}"
        formType="time"
        [(value)]="fixedTimeStampTime"
        [disabled]="isBlocked() || !isLoggedIn()"
        [debounceTime]="0"
      ></axpo-form-element>
    </div>
  </div>
</div>
@if (checkCompleted()) {
  <axpo-dialog
    title="{{ 'incidentDetail.checkCompleted' | transloco }}"
    cancelText="{{ 'core.cancel' | transloco }}"
    confirmText="{{ 'core.save' | transloco }}"
    textAlign="left"
    (takenAction)="closeCompletedDialog($event)"
    [saveDisabled]="!faultEffectId() || !faultEffectLocationId()"
  >
    <axpo-typography variant="body-m">
      {{ 'incidentDetail.checkCompletedText' | transloco }}
    </axpo-typography>
    <axpo-form-element
      formId="faultEffectLocation"
      label="{{ 'incidentDetail.incidentResolution.incidentLocation' | transloco }}"
      formType="select"
      [(value)]="faultEffectLocationId"
      [options]="faultEffectLocationOptions()"
      [ariaRequired]="true"
      [debounceTime]="0"
    ></axpo-form-element>
    <axpo-form-element
      formId="faultEffect"
      label="{{ 'incidentDetail.impact' | transloco }}"
      formType="select"
      [(value)]="faultEffectId"
      [options]="impactOptions()"
      [ariaRequired]="true"
      [debounceTime]="0"
    ></axpo-form-element>
  </axpo-dialog>
}
