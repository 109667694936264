import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  signal,
  untracked,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

import { FileDropComponent } from './filedrop/filedrop.component';
import { AxpoSpinnerComponent } from '../../../core/axpo-spinner/axpo-spinner.component';
import { AxpoTypographyComponent } from '../../../core/axpo-typography/axpo-typography.component';
import { SvgService } from '../../../core/services/svg.service';
import { IAttachmentLocalModel } from '../../models/api_models';
import { AttachmentService } from '../../services/attachment.service';
import { IncidentService } from '../../services/incident.service';
import { LanguageService } from '../../services/lang.service';
import { TenantService } from '../../services/tenant.service';
import * as customValidations from '../../utils/customValidations';
import formatBytes from '../../utils/formatBytes';
import { ITileMode } from '../../utils/tileStates';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  imports: [AxpoTypographyComponent, AxpoSpinnerComponent, TranslocoPipe, FileDropComponent],
})
export class AttachmentsComponent {
  translocoService = inject(TranslocoService);
  langService = inject(LanguageService);
  svgService = inject(SvgService);
  tenantService = inject(TenantService);
  getSvg = this.svgService.svgMap;
  activatedRoute = inject(ActivatedRoute);
  incidentService = inject(IncidentService);
  attachmentService = inject(AttachmentService);
  internalAttachments = input.required<boolean>();
  filesArray = signal<(File | null)[]>([]);
  mode = input.required<ITileMode>();

  maxUploadBytes: number = 1024 * 1024 * 15;
  uploadProgress = 0;
  tenantId = computed(() => {
    return this.tenantService.tenantId();
  });
  attachments = computed(() => {
    const internalAttachments = this.attachmentService.attachmentsInternal();
    const attachmentsPublic = this.attachmentService.attachments();
    if (this.internalAttachments()) {
      return internalAttachments;
    }

    return attachmentsPublic;
  });
  allAttachmentsCached = computed(() => {
    const pwad = this.attachments()?.filter((elem) => {
      return (elem.isInPwa === true)
    });

    return pwad?.length === this.attachments()?.length;
  });

  onInit = effect(() => {
    this.svgService.loadSvg(['download', 'data', 'delete-hard', 'check', 'upload']);
  });

  _uploadEffect = effect(() => {
    const tenantId = this.activatedRoute.snapshot.paramMap.get('tenantId') ?? this.tenantId();
    const files = this.filesArray();
    untracked(() => {
      if (files && files.length > 0 && tenantId) {
        this.uploadFiles(files, tenantId);
      }
    });
  });

  initService = effect(() => {
    const accessCode = this.activatedRoute.snapshot.paramMap.get('accessCode') ?? '';
    const tenantId = this.activatedRoute.snapshot.paramMap.get('tenantId') ?? this.tenantId();

    const _attachments = this.incidentService.incidentDetails()?.attachments ?? [];
    const _attachmentsInternal = this.incidentService.incidentDetails()?.attachmentsInternal ?? [];

    this.attachmentService.initAttachments(accessCode, tenantId ?? '');
  });

  async download(attachment: IAttachmentLocalModel) {
    const accessCode = this.activatedRoute.snapshot.paramMap.get('accessCode');
    const tenantId = this.activatedRoute.snapshot.paramMap.get('tenantId') ?? this.tenantId();
    this.attachmentService.download(attachment, accessCode, tenantId ?? '');
  }

  downloadFromRemote(attachment: IAttachmentLocalModel) {
    const accessCode = this.activatedRoute.snapshot.paramMap.get('accessCode');
    const tenantId = this.activatedRoute.snapshot.paramMap.get('tenantId') ?? this.tenantId();

    this.attachmentService.downloadFromRemote(attachment, accessCode ?? '', tenantId ?? '');
  }

  downloadAllFilesForOfflineUse(): void {
    const attachments = this.attachments();
    if (attachments) {
      for (const attachment of attachments) {
        this.downloadOffline(attachment);
      }
    }
  }

  downloadOffline(attachment: IAttachmentLocalModel) {
    const accessCode = this.activatedRoute.snapshot.paramMap.get('accessCode');
    const tenantId = this.activatedRoute.snapshot.paramMap.get('tenantId') ?? this.tenantId();

    this.attachmentService.getAttachment(accessCode ?? '', attachment.id ?? 0, tenantId ?? '');
  }

  showNoAttachments = computed(() => {
    const attachments = this.attachments();
    if (!attachments || attachments.length === 0) {
      return true;
    }

    return false;
  });

  remove(attachment: IAttachmentLocalModel) {
    const accessCode = this.activatedRoute.snapshot.paramMap.get('accessCode');
    const tenantId = this.activatedRoute.snapshot.paramMap.get('tenantId') ?? this.tenantId();
    this.attachmentService.removeAttachment(
      accessCode ?? '',
      attachment.id ?? 0,
      tenantId ?? '',
      this.internalAttachments(),
    );
  }

  filesUploaded(files: FileList) {
    const fileArray: (File | null)[] = [];
    for (let index = 0; index < files.length; index++) {
      const element = files.item(index);
      fileArray.push(element);
    }
    this.filesArray.set(fileArray);
  }

  uploadFiles(files: any, tenantId: string | undefined) {
    const fileUploadErrors = {};
    const filesAmount = files.length;
    const accessCode = this.activatedRoute.snapshot.paramMap.get('accessCode');

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const validationMessages = this.validateFile(file as any);
      if (validationMessages) {
        Object.assign(fileUploadErrors, validationMessages);
        this.setProgress(filesAmount, i++);
        continue;
      }
      this.attachmentService.addAttachment(
        accessCode ?? '',
        tenantId ?? '',
        file as any,
        this.internalAttachments(),
      );
    }

    this.filesArray.set([]);
  }

  setProgress(total: number, current: number) {
    this.uploadProgress = Math.round((100 / total) * current);
  }

  validateFile(file: File) {
    if (!customValidations.hasMax100(file.name)) {
      return {
        [file.name]: 'Models_AttachmentModel_InvalidName',
      };
    }

    if (file.size >= this.maxUploadBytes) {
      const maxUploadExceededMessage = 'Models_AttachmentModel_MaxUploadExceeded';
      return {
        [file.name]: maxUploadExceededMessage.replace('{0} MB', formatBytes(this.maxUploadBytes)),
      };
    }

    return null;
  }
}
