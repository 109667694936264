import { animate, style, transition, trigger } from '@angular/animations';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  model,
  output,
  signal,
  untracked,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { twMerge } from 'tailwind-merge';

import { ClickOutsideDirective } from '../../shared/directives/click-outside.directive';
import { AxpoTypographyComponent } from '../axpo-typography/axpo-typography.component';
import { SvgService } from '../services/svg.service';

export type FormElementTypes =
  | 'text'
  | 'select'
  | 'multiselect'
  | 'number'
  | 'date'
  | 'time'
  | 'file'
  | 'checkbox'
  | 'textarea'
  | 'email'
  | 'tel';
export interface IOption {
  value: string;
  label: string;
}

export interface IFormError {
  value: number;
  message: string;
  replacement?: string;
  formId: string;
}

export type IValue = string | string[] | number | File | boolean | undefined;

@Component({
  selector: 'axpo-form-element',
  standalone: true,
  imports: [AxpoTypographyComponent, FormsModule, ClickOutsideDirective, NgTemplateOutlet, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './axpo-form-element.component.html',
  styleUrl: './axpo-form-element.component.css',
  animations: [
    trigger('transformOpacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
      ]),
    ]),
  ],
})
export class AxpoFormElementComponent {
  private svgService = inject(SvgService);

  formType = input.required<FormElementTypes>();
  formId = input.required<string>();
  label = input<string | undefined>();
  value = model.required<IValue>();
  debounceValue = signal<IValue>(undefined);
  labelOfSelectValue = computed(() => {
    if (this.formType() === 'select' && this.options()) {
      return this.options()!.find(option => option.value === this.value())?.label;
    } else if (this.formType() === 'multiselect' && this.options()) {
      return this.options()!
        .filter(option => ((this.value() || []) as string[]).includes(option.value))
        .map(option => option.label)
        .join(', ');
    }
    return '';
  });
  options = input<IOption[]>();
  isSelectMenuOpen = signal<boolean>(false);
  checkboxSize = input<'large' | 'medium'>('large');
  touched = signal<boolean>(false);
  hasError = signal<IFormError | undefined>(undefined);
  errorMessageInput = input<string | undefined>(undefined);
  displayErrorMessage = input<boolean>(false);
  errorEmitter = output<IFormError | undefined>();

  min = input<string | number | undefined>();
  max = input<string | number | undefined>();
  pattern = input<string | RegExp | undefined>();
  placeholder = input<string | undefined>();
  size = input<string | number | undefined>();
  accept = input<string | undefined>();
  step = input<string | number | undefined>();
  rows = input<number | undefined>(5);
  multiple = input<boolean>(false);
  disabled = input<boolean>(false);
  required = input<boolean>(false);
  noPadding = input<boolean>(false);
  debounceTime = input<number>(0);

  onInit = effect(() => {
    this.svgService.loadSvg(['upload', 'chevron-down']);
  });

  getSvg = this.svgService.svgMap;

  computedStyle = computed(() => {
    let elementHeight = 'h-10';
    if (this.formType() === 'textarea') {
      elementHeight = 'h-30';
    }
    let styles = [
      'border',
      'border-border',
      'rounded',
      'text-left',
      'relative',
      'focus:border-text-secondary',
      'focus:ring-0',
    ];
    if (this.formType() === 'checkbox') {
      styles = styles.concat(['w-7', 'h-7', 'text-primary', 'hover:cursor-pointer']);
    } else {
      styles = styles.concat(['block', 'w-full', elementHeight]);
    }
    if (this.hasError() !== undefined || this.errorMessageInput() !== undefined) {
      styles = styles.concat(['border-interaction-red-2']);
    }
    if (this.isSelectMenuOpen()) {
      styles = styles.concat(['border-text-secondary']);
    }
    if (this.disabled()) {
      styles = styles.concat(['bg-background-1']);
    }
    return twMerge(styles);
  });

  _debounce = effect(onCleanup => {
    const value = this.value();
    if (this.debounceTime() < 1) {
      this.debounceValue.set(value);
      return;
    }

    untracked(() => {
      const timeout = setTimeout(() => {
        this.debounceValue.set(value);
      }, this.debounceTime());
      onCleanup(() => clearTimeout(timeout));
    });
  });

  _errorCheckEffect = effect(() => {
    const _touched = this.touched();
    const formType = this.formType();
    const rawMin = this.min();
    const rawMax = this.max();
    const rawValue = this.debounceValue();

    const min = this.standardizeNumber(rawMin, formType);
    const max = this.standardizeNumber(rawMax, formType);
    const value = this.standardizeNumber(rawValue, formType);
    untracked(() => {
      if (
        this.required() &&
        this.touched() &&
        (rawValue === undefined || rawValue === null || rawValue === '')
      ) {
        this.hasError.set({ value: 1, message: 'This field is required', formId: this.formId()! });
        return;
      }

      if (this.required() && this.touched() && this.formType() === 'select') {
        if (rawValue === undefined || rawValue === null || rawValue === '') {
          this.hasError.set({
            value: 1,
            message: 'This field is required',
            formId: this.formId()!,
          });
          return;
        }
      }

      if (this.required() && this.touched() && this.formType() === 'multiselect') {
        if (
          rawValue === undefined ||
          rawValue === null ||
          (Array.isArray(rawValue) && rawValue.length === 0)
        ) {
          this.hasError.set({
            value: 1,
            message: 'This field is required',
            formId: this.formId()!,
          });
          return;
        }
      }

      if (rawValue !== undefined && this.pattern() !== undefined) {
        const pattern = this.pattern() as string;
        const isValid = new RegExp(pattern).test(rawValue.toString());
        if (!isValid) {
          this.hasError.set({ value: 2, message: 'Invalid format', formId: this.formId()! });
          return;
        }
      }

      // for numbers
      if (this.formType() === 'number') {
        if (min !== undefined && value !== undefined && value < min) {
          this.hasError.set({
            value: 3,
            message: 'A minimum value of ' + min + ' required',
            replacement: min.toString(),
            formId: this.formId()!,
          });
          return;
        }
        if (max !== undefined && value !== undefined && value > max) {
          this.hasError.set({
            value: 4,
            message: 'A maximum value of ' + max + ' allowed',
            replacement: max.toString(),
            formId: this.formId()!,
          });
          return;
        }
      } else {
        if (min !== undefined && rawValue && rawValue.toString().length < min) {
          this.hasError.set({
            value: 5,
            message: 'A minimum of ' + min + ' characters required',
            replacement: min.toString(),
            formId: this.formId()!,
          });
          return;
        }
        if (max !== undefined && rawValue !== undefined && rawValue.toString().length > max) {
          this.hasError.set({
            value: 6,
            message: 'A maximum of ' + max + ' characters allowed',
            replacement: max.toString(),
            formId: this.formId()!,
          });
          return;
        }
      }
      this.hasError.set(undefined);
    });
  });

  _errorEmitterEffect = effect(() => {
    const hasError = this.hasError();
    this.errorEmitter.emit(hasError);
  });

  handleClick() {
    if (!this.touched()) {
      this.touched.set(true);
    }
    this.isSelectMenuOpen.set(!this.isSelectMenuOpen());
  }

  fileAdded = (event: Event) => {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.value.set(input.files[0]);
      input.value = ''; // reset the input value to allow the same file to be selected again
    }
  };

  selectOption = (option: IOption) => {
    this.value.set(option.value);
    this.isSelectMenuOpen.set(false);
  };

  selectOptionMultiple = (option: IOption) => {
    const values = (this.value() || []) as string[];
    if (values.includes(option.value)) {
      this.value.set(values.filter(value => value !== option.value));
    } else {
      this.value.set([...values, option.value]);
    }
  };

  clearSelection = () => {
    this.touched.set(true);
    if (this.formType() === 'multiselect') {
      this.value.set([]);
    } else {
      this.value.set(undefined);
    }
  };

  standardizeNumber = (input: any, formType: FormElementTypes): number | undefined => {
    if (input === undefined || input === null) return undefined;
    if (formType === 'date') {
      const date = new Date(input);
      return isNaN(date.getTime()) ? undefined : date.getTime();
    }
    if (
      formType === 'number' ||
      formType === 'text' ||
      formType === 'textarea' ||
      formType === 'tel' ||
      formType === 'email'
    ) {
      const num = parseFloat(input);
      return isNaN(num) ? undefined : num;
    }
    return undefined;
  };
}
