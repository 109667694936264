import atlas from 'azure-maps-control';

import geoIsLink from './geoIsLink';
import createNavigationLink from './navigationLink';
import { environment } from '../../../environments/environment';
import { ILocationModel } from '../models/api_models';

export const addMarkers = (map: atlas.Map, locations: ILocationModel[]) => {
  // Clear markers
  map.markers.clear();

  //Create an HTML marker and add it to the map.
  for (const loc of locations) {
    // create pins html
    const div = createPinHtmlElement(loc);

    const marker = new atlas.HtmlMarker({
      color: '#ff5d64',
      position: [loc.coordinates?.longitude ?? 0, loc.coordinates?.latitude ?? 0],
      popup: new atlas.Popup({
        content: div,
        pixelOffset: [0, -30],
      }),
    });
    map.markers.add(marker);
    //Add a click event to toggle the popup.
    map.events.add('click', marker, () => {
      marker.togglePopup();
    });
  }
};

export const createPinHtmlElement = (loc: ILocationModel): HTMLElement => {
  const div = document.createElement('div');
  div.classList.add('p-3');

  const addButtonClass = (button: HTMLButtonElement) => {
    button.classList.add('bg-primary');
    button.classList.add('items-center');
    button.classList.add('justify-center');
    button.classList.add('text-white');
    button.classList.add('rounded-full');
    button.classList.add('p-3');
    button.classList.add('mb-2');
    button.classList.add('block');
    button.classList.add('w-full');
  };
  const btnNavigationLink = document.createElement('button');
  btnNavigationLink.innerHTML = 'Routenplaner';
  addButtonClass(btnNavigationLink);

  btnNavigationLink.onclick = function () {
    window.open(
      createNavigationLink(loc.coordinates?.latitude, loc.coordinates?.longitude),
      '_blank',
    );
  };

  const btnGoIs = document.createElement('button');
  btnGoIs.innerHTML = 'GeoIs';
  addButtonClass(btnGoIs);
  btnGoIs.onclick = function () {
    window.open(geoIsLink(loc), '_blank');
  };

  div.appendChild(btnGoIs);
  div.appendChild(btnNavigationLink);

  return div;
};

export const initMap = (locations: ILocationModel[], mapId: string): atlas.Map => {
  const map = new atlas.Map(mapId, {
    view: 'Auto',
    // Add authentication details for connecting to Azure Maps.
    authOptions: {
      authType: atlas.AuthenticationType.subscriptionKey,
      subscriptionKey: environment.azureMapsKey,
    },
    showLogo: false,
    zoom: 11,
    center:
      locations.length > 0
        ? [locations[0].coordinates?.longitude ?? 0, locations[0].coordinates?.latitude ?? 0]
        : [8.308, 47.4729], // default is Baden (CH)
  });

  map.controls.add([new atlas.control.ZoomControl()], {
    position: atlas.ControlPosition.BottomRight,
  });

  addMarkers(map, locations);

  return map;
};
