import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { ApiService } from './api.service';
import {
  CauseDamagePlantTranslations,
  CauseLocationTranslations,
  DamageLineTranslations,
  FaultCauseTranslations,
  ImpactDataTranslations,
  IncidentImpactTranslations,
  IncidentStatusTranslations,
  causeName,
  effectName,
  impactName,
  lineDamage,
  locationCause,
  plantDamage,
  stateName,
} from '../models/api_data_translations';
import { IContactModel, IKeyValueModel } from '../models/api_models';

export interface ISignalPair {
  dataSignal: WritableSignal<IKeyValueModel[] | undefined>;
  loadingSignal: WritableSignal<boolean>;
}
export type IIndexableSignalPair = Record<string, ISignalPair>;

export enum DataFragments {
  contacttemplates = 'contacttemplates',
  faulteffects = 'faulteffects',
  faultlocations = 'faultlocations',
  incidentcategories = 'incidentcategories',
  faultplantdamages = 'faultplantdamages',
  faultlinedamages = 'faultlinedamages',
  faultcauses = 'faultcauses',
  incidentimpacts = 'incidentimpacts',
  incidentstates = 'incidentstates',
  locationtypes = 'locationtypes',
  translations = 'translations',
  voltage = 'voltage',
}

@Injectable({
  providedIn: 'root',
})
export class DetailsService {
  private apiService = inject(ApiService);
  private toastr = inject(ToastrService);
  private translocoService = inject(TranslocoService);

  fragmentToSignal: IIndexableSignalPair = {};
  isLoadingSearch = signal<boolean>(false);
  contactSearch = signal<IContactModel[]>([]);

  constructor() {
    for (const fragment in DataFragments) {
      this.createSignalPair(fragment, undefined, false);
    }
  }

  createSignalPair(
    fragmentKey: string,
    initialData: IKeyValueModel[] | undefined,
    loadingState: boolean,
  ) {
    this.fragmentToSignal[fragmentKey] = {
      dataSignal: signal<IKeyValueModel[] | undefined>(initialData),
      loadingSignal: signal<boolean>(loadingState),
    };
  }

  /**
   * Different fragments got different parameters please check before using this function
   * @param fragment
   * @param accessCode faulteffects, faultlocations, incidentcategories, faultplantdamages, faultlinedamages, faultcauses, incidentimpacts, incidentstates, locationtypes
   * @param tenantId faulteffects, faultlocations, faultplantdamages, faultlinedamages, faultcauses
   */
  loadDataFragment(fragment: DataFragments, accessCode?: string, tenantId?: string) {
    if (tenantId && !accessCode) {
      this.toastr.error('Cant set tenantId without accessCode');
    }

    let route = `/api/v1/Data/${fragment}`;
    if (accessCode) {
      route = route + '/' + accessCode;
    }

    if (tenantId) {
      route = route + '/' + tenantId;
    }

    this.fragmentToSignal[fragment].loadingSignal.set(true);
    this.apiService.get<IKeyValueModel[]>(route).subscribe({
      next: dataFragment => {
        if (fragment === DataFragments.incidentstates) {
          dataFragment = dataFragment.map(state => ({
            ...state,
            value: IncidentStatusTranslations[state.value as stateName],
          }));
        } else if (fragment === DataFragments.incidentimpacts) {
          dataFragment = dataFragment.map(impact => ({
            ...impact,
            value: IncidentImpactTranslations[impact.value as impactName],
          }));
        } else if (fragment === DataFragments.faultlinedamages) {
          dataFragment = dataFragment.map(faultlocation => ({
            ...faultlocation,
            value: DamageLineTranslations[faultlocation.value as lineDamage],
          }));
        } else if (fragment === DataFragments.faultlocations) {
          dataFragment = dataFragment.map(faultlocation => ({
            ...faultlocation,
            value: CauseLocationTranslations[faultlocation.value as locationCause],
          }));
        } else if (fragment === DataFragments.faultplantdamages) {
          dataFragment = dataFragment.map(plantdamage => ({
            ...plantdamage,
            value: CauseDamagePlantTranslations[plantdamage.value as plantDamage],
          }));
        } else if (fragment === DataFragments.faulteffects) {
          dataFragment = dataFragment.map(faulteffect => ({
            ...faulteffect,
            value: ImpactDataTranslations[faulteffect.value as effectName],
          }));
        } else if (fragment === DataFragments.faultcauses) {
          dataFragment = dataFragment.map(faultcause => ({
            ...faultcause,
            value: FaultCauseTranslations[faultcause.value as causeName],
          }));
        }

        this.fragmentToSignal[fragment].dataSignal.set(dataFragment);
      },
      error: () => {
        const msg = this.translocoService.translate('incidentDetail.failed');
        this.fragmentToSignal[fragment].loadingSignal.set(false);
        this.toastr.error(msg);
      },
      complete: () => {
        this.fragmentToSignal[fragment].loadingSignal.set(false);
      },
    });
  }

  searchContactTemplates(name: string) {
    this.isLoadingSearch.set(true);
    this.apiService
      .get<IContactModel[]>(`/api/v1/Data/contacttemplates?name=` + encodeURIComponent(name))
      .subscribe({
        next: details => {
          this.contactSearch.set(details);
        },
        error: () => {
          const msg = this.translocoService.translate('Contact.fetchError');
          this.toastr.error(msg);
        },
        complete: () => {
          this.isLoadingSearch.set(false);
        },
      });
  }
}
