<div class="ml-4 mr-4 mt-4">
  <axpo-typography variant="title-s">{{ subgroup()?.name }}</axpo-typography>
</div>
@for (user of subgroup()?.users; track user.id) {
  <div
    class="m-4 flex justify-between border-b-2 lg:max-w-[calc(50vw_-_2.5rem)] pb-1"
    [ngClass]="{ 'text-text-secondary': !user.active }"
  >
    <div class="w-1/3">
      <axpo-typography variant="body-m">{{ user.first_name }} {{ user.last_name }}</axpo-typography>
      <div class="h-4">
        <axpo-typography variant="body-s">
          {{ user.role }}
        </axpo-typography>
      </div>
    </div>
    <div class="w-1/3">
      <axpo-typography>{{ user.mobile }}</axpo-typography>
    </div>
    <div class="w-6 h-6">
      @if (isAdmin() === true && user.id !== 0) {
        <div
          [ngClass]="{
            'text-light-gray': user.onCall || user.isOnSite || user.automaticallyAdded,
            'hover:text-dark-gray hover:cursor-pointer text-primary':
              !user.onCall && !user.isOnSite && !user.automaticallyAdded,
          }"
          aria-hidden="true"
          (click)="deleteContact(user)"
          [innerHTML]="getSvg()['delete']"
        ></div>
      }
    </div>
    <div>
      @if (mode() === 'radio' && toggleProperty() === 'isOnSite') {
        <app-radio
          [(checked)]="user.isOnSite"
          [active]="user.active && !allDisabled()"
          [selectionGroupId]="selectionGroupId()"
          [user]="user"
          (selectionChanged)="toggleSelection(user)"
        ></app-radio>
      }
      @if (mode() === 'radio' && toggleProperty() === 'onCall') {
        <app-radio
          [(checked)]="user.onCall"
          [active]="user.active && !allDisabled()"
          [selectionGroupId]="selectionGroupId()"
          [user]="user"
          (selectionChanged)="toggleSelection(user)"
        ></app-radio>
      }
      @if (mode() === 'switch') {
        <ng-container
          [ngTemplateOutlet]="toggleSwitch"
          [ngTemplateOutletContext]="{ user: user, subgroupId: subgroup()?.id }"
        ></ng-container>
      }
    </div>
  </div>
}

<ng-template #toggleSwitch let-user="user" let-subgroupId="subgroupId">
  <label class="switch">
    <input
      type="checkbox"
      [checked]="user.active ? 'checked' : ''"
      [name]="selectionGroupId()"
      (click)="toggleSelection(user)"
      [disabled]="user.onCall || user.isOnSite || allDisabled()"
    />
    <span class="slider round"></span>
  </label>
</ng-template>

@if (showDeleteDialog()) {
  <axpo-dialog
    [title]="'switchboard.deleteUser' | transloco"
    [confirmText]="'core.confirm' | transloco"
    [cancelText]="'core.cancel' | transloco"
    [processBeforeClose]="true"
    [loadingStateText]="'core.loading' | transloco"
    [dataLoaded]="circleService.updatingCircle()"
    (takenAction)="afterUserDeleted($event)"
    icon="warning"
    textAlign="left"
  >
    {{
      'switchboard.deleteUserText'
        | transloco: { person: userToDelete()?.first_name + ' ' + userToDelete()?.last_name }
    }}
  </axpo-dialog>
}
