import { Injectable, computed, inject, signal } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { ApiService } from './api.service';
import { IncidentTilesTranslations, tileName } from '../models/api_data_translations';
import {
  ITenantSettingsModel,
  ITenantSettingsUpdateModel,
  ITenantsModel,
} from '../models/api_models';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  private apiService = inject(ApiService);
  private toastr = inject(ToastrService);
  private translocoService = inject(TranslocoService);

  tenantId = signal<string | undefined>(undefined);
  tenantSettings = signal<ITenantSettingsModel | undefined>(undefined);
  tenants = signal<ITenantsModel[]>([]);

  isLoadingConfig = signal<boolean>(false);
  isLoadingSettings = signal<boolean>(false);
  isLoadingTenants = signal<boolean>(false);

  getConfig() {
    this.isLoadingConfig.set(true);
    this.apiService.get<any>(`/api/v1/Tenant/Config`).subscribe({
      next: tenantConfig => {
        this.tenantId.set(tenantConfig.tenantId);
      },
      error: () => {
        const msg = this.translocoService.translate('core.tenantFailed');
        this.toastr.error(msg);
        this.isLoadingConfig.set(false);
      },
      complete: () => {
        this.isLoadingConfig.set(false);
      },
    });
  }

  getSettings(tenantId: string) {
    this.isLoadingSettings.set(true);
    this.apiService.get<ITenantSettingsModel>(`/api/v1/tenant/settings/` + tenantId).subscribe({
      next: tenantSetting => {
        this.checkAndUpdateMissingTiles(tenantSetting);

        const tenantSettingsI18n = {
          ...tenantSetting,
          tileModels: tenantSetting.tileModels?.map(model => ({
            isVisible: model.isVisible,
            name: IncidentTilesTranslations[model.name as tileName],
            originalName: model.name,
          })),
        };
        this.tenantSettings.set(tenantSettingsI18n);
      },
      error: () => {
        const msg = this.translocoService.translate('core.tenantFailed');
        this.toastr.error(msg);
      },
      complete: () => {
        this.isLoadingSettings.set(false);
      },
    });
  }

  updateSettings(tenantId: string, settings: ITenantSettingsUpdateModel) {
    const settingsJson = computed<string>(() => JSON.stringify(settings));

    this.apiService
      .post<ITenantSettingsModel>(`/api/v1/Tenant/update/` + tenantId, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: settingsJson(),
      })
      .subscribe({
        next: () => {
          this.checkAndUpdateMissingTiles(settings);
          const tenantSettingsI18n = {
            ...settings,
            tileModels: settings.tileModels?.map(model => ({
              isVisible: model.isVisible,
              name: IncidentTilesTranslations[model.name as tileName],
              originalName: model.name,
            })),
          };
          this.tenantSettings.set(tenantSettingsI18n);
          this.toastr.success(this.translocoService.translate('core.tenantSettingsUpdated'));
        },
        error: () => {
          this.toastr.error(this.translocoService.translate('core.tenantSettingsUpdateFailed'));
        },
      });
  }

  getTenants() {
    if (this.isLoadingTenants() === true) {
      return;
    }

    this.isLoadingTenants.set(true);
    this.apiService.get<ITenantsModel[]>(`/api/v1/Tenant/tenants`).subscribe({
      next: tenants => {
        this.tenants.set(tenants);
      },
      error: () => {
        const msg = this.translocoService.translate('core.tenantsFailed');
        this.toastr.error(msg);
      },
      complete: () => {
        this.isLoadingTenants.set(false);
      },
    });
  }

  private checkAndUpdateMissingTiles(tenantSettingsI18n: ITenantSettingsModel) {
    const missingTiles = [];

    for (const item in IncidentTilesTranslations) {
      if (isNaN(Number(item))) {
        if (tenantSettingsI18n.tileModels?.filter(tile => tile.name == item).length == 0) {
          missingTiles.push(item);
        }
      }
    }

    for (const missingTile of missingTiles) {
      tenantSettingsI18n.tileModels = [
        ...(tenantSettingsI18n.tileModels ?? []),
        {
          name: missingTile,
          isVisible: 0,
        },
      ];
    }
  }
}
