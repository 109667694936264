import { DatePipe, registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  isDevMode,
  provideZoneChangeDetection,
} from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { TranslocoService, provideTransloco } from '@jsverse/transloco';
import { AbstractSecurityStorage, authInterceptor, provideAuth } from 'angular-auth-oidc-client';
import { provideToastr } from 'ngx-toastr';

import { AppComponent } from './app/app.component';
import { ROUTES } from './app/routes';
import { httpErrorInterceptor } from './app/shared/interceptors/http-error.interceptor';
import { AuthnStorageService } from './app/shared/services/authn-storage.service';
import { TranslocoHttpLoader, appInitializerFactory } from './app/shared/transloco-loader';
import { environment } from './environments/environment.dev';

registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe, 'de');

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(withInterceptors([httpErrorInterceptor, authInterceptor()])),
    provideRouter(ROUTES),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideToastr(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideHttpClient(),
    provideTransloco({
      config: {
        availableLangs: ['en', 'de'],
        defaultLang: 'en',
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideAuth({
      config: environment.auth.config,
    }),
    { provide: AbstractSecurityStorage, useClass: AuthnStorageService },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslocoService],
      multi: true,
    },
    DatePipe,
  ],
};

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
