import { ChangeDetectionStrategy, Component, input, model, output } from '@angular/core';
import { RouterLink } from '@angular/router';

import { AxpoLogoComponent } from './axpo-logo/axpo-logo.component';
import {
  AxpoBreadcrumbComponent,
  SeparatorType,
} from '../axpo-breadcrumb/axpo-breadcrumb.component';
import {
  AxpoProfileDropdownComponent,
  ICustomAction,
  ILanguage,
  IUser,
} from '../axpo-profile-dropdown/axpo-profile-dropdown.component';
import { AxpoTypographyComponent } from '../axpo-typography/axpo-typography.component';

@Component({
  selector: 'axpo-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AxpoLogoComponent,
    AxpoTypographyComponent,
    RouterLink,
    AxpoBreadcrumbComponent,
    AxpoProfileDropdownComponent,
  ],
  templateUrl: './axpo-header.component.html',
  styleUrl: './axpo-header.component.css',
})
export class AxpoHeaderComponent {
  title = input<string>();
  feedback = input<boolean>(false);
  feedbackClick = output<void>();
  breadcrumbDeactivated = input<boolean>(false);

  separator = input<SeparatorType>('default');

  profileImage = input<string | undefined>(undefined);
  user = input<IUser | undefined>(undefined);
  showLogout = input<boolean>(false);
  availableLanguages = input<ILanguage[]>([]);
  selectedLanguageCode = model<string | undefined>(undefined);
  customActions = input<ICustomAction[]>([]);
  logout = output<void>();
  customAction = output<{ value: string; subValue?: string }>();
  middleTitle = input<string | undefined>(undefined);

  isOffline = input<boolean>(false);

  handleFeedbackClick(event: Event) {
    event.stopPropagation();
    this.feedbackClick.emit();
  }

  onHeaderLogout = () => {
    this.logout.emit();
  };

  onCustomHeaderAction = (value: string, subValue?: string) => {
    this.customAction.emit({ value, subValue });
  };
}
