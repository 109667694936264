import { ChangeDetectionStrategy, Component, WritableSignal, effect, inject, signal } from "@angular/core";
import { Router } from "@angular/router";
import { TranslocoPipe } from "@jsverse/transloco";

import { AxpoTypographyComponent } from "../../../core/axpo-typography/axpo-typography.component";
import { SvgService } from "../../../core/services/svg.service";
import { Circle } from "../../../shared/models/api_models";
import { CircleService } from "../../../shared/services/circle.service";
import { BaseResizeComponent } from "../base.resize.component";
import { CirclesComponent } from "../circles.component";
import { IToggleProperty } from "../sub-group/sub-group.component";
import { ITabItem, TabsComponent } from "../tabs/tabs.component";

export type accordionGroup = Record<string, WritableSignal<boolean>>;


@Component({
  selector: 'app-circle-overview',
  imports: [
    AxpoTypographyComponent,
    TranslocoPipe,
    CirclesComponent,
    TabsComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './overview.component.html',
})
export class CircleOverviewComponent extends BaseResizeComponent {
  circles = signal<Circle[] | undefined>(undefined);
  svgService = inject(SvgService);
  getSvg = this.svgService.svgMap;
  circleAccordionGroup = signal<accordionGroup>({});
  circleService = inject(CircleService);
  selectedTab = signal<IToggleProperty>('onCall');
  circleTabs = signal<ITabItem[]>([
    {id: 'onCall', title: 'switchboard.onCall'},
    {id: 'isOnSite', title: 'switchboard.onSite'}
  ]);
  allDisabled = signal<boolean>(true);
  router = inject(Router);

  _init = effect(() => {
    this.svgService.loadSvg(['chevron-down', 'chevron-up', 'shortcut']);
    if (!this.circleService.circles() && !this.circleService.loadingCircles()) {
      this.circleService.getCircles();
    }
  });

  _circles = effect(() => {
    const circles = this.circleService.circles();
    if (circles) {
      this.circles.set(circles);
      for (const circle of circles) {
        this.circleAccordionGroup.update(group => {
          group[circle.id as number] = signal<boolean>(false);
          return group;
        });
      }
    }
  });

  changeState(circleId: number | undefined) {
    if (circleId) {
      this.circleAccordionGroup.update(group => {
        group[circleId].set(!group[circleId]());
        return group;
      });
    }
  }

  tabSelectionChanged(tab: ITabItem) {
    this.selectedTab.set(tab.id as IToggleProperty);
  }

  navigateToCircle(circleId: number | undefined) {
    if (circleId) {
      this.router.navigate(['circle/' + circleId]);
    }
  }

  isToggled(circleId: number | undefined) {
    return this.circleAccordionGroup()[circleId as number]() === true;
  }
}