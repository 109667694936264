import { animate, style, transition, trigger } from '@angular/animations';
import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  effect,
  inject,
  input,
  output,
  signal,
  untracked,
} from '@angular/core';

import { AxpoButtonComponent, AxpoButtonVariants } from '../axpo-button/axpo-button.component';
import { AxpoSpinnerComponent } from '../axpo-spinner/axpo-spinner.component';
import { AxpoTypographyComponent } from '../axpo-typography/axpo-typography.component';
import { SvgService } from '../services/svg.service';

export type ProcessingCallback = () => Promise<boolean>;

@Component({
  selector: 'axpo-dialog',
  standalone: true,
  imports: [AxpoButtonComponent, AxpoTypographyComponent, NgClass, AxpoSpinnerComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './axpo-dialog.component.html',
  styleUrl: './axpo-dialog.component.css',
  animations: [
    trigger('foreground', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' })),
      ]),
    ]),
    trigger('background', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class AxpoDialogComponent implements OnInit, OnDestroy {
  private svgService = inject(SvgService);
  private renderer = inject(Renderer2);

  title = input<string>();
  icon = input<'warning' | undefined>();
  confirmText = input.required<string>();
  cancelText = input<string>();
  textAlign = input<'center' | 'left'>('center');
  saveDisabled = input<boolean>(false);
  processBeforeClose = input<boolean>(false);
  loadingStateText = input<string>('');
  dataLoaded = input<boolean>(false);
  abortSaving = input<boolean>(false);
  cancelBtnStyle = input<AxpoButtonVariants['style']>('secondary');
  cancelBtnType = input<AxpoButtonVariants['type']>('ghost');

  displayDialog = signal<boolean>(true);
  actionTaken = signal<'confirm' | 'cancel' | undefined>(undefined);

  takenAction = output<'confirm' | 'cancel'>();

  onInit = effect(() => {
    this.svgService.loadSvg(['clear', 'warning-circle-2']);
  });

  getSvg = this.svgService.svgMap;

  _loadDataEffect = effect(() => {
    if (this.processBeforeClose() === true) {
      const actionTaken = this.actionTaken();
      const isDataLoaded = this.dataLoaded();
      untracked(() => {
        if (actionTaken === 'confirm' && isDataLoaded) {
          this.takenAction.emit(actionTaken);
          this.displayDialog.set(false);
          this.actionTaken.set(undefined);
        }
      });
    }
  });

  _resetModalChoice = effect(() => {
    const aborted = this.abortSaving();
    if (aborted) {
      untracked(() => {
        this.actionTaken.set(undefined);
      });
    }
  });

  takeAction = (action: 'confirm' | 'cancel') => {
    if (this.processBeforeClose()) {
      this.actionTaken.set(action);
      this.takenAction.emit(action);
    } else {
      this.takenAction.emit(action);
    }

    if (action === 'cancel' || !this.processBeforeClose()) {
      this.displayDialog.set(false);
    }
  };

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'overflow-hidden');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'overflow-hidden');
  }
}
