<section class="bg-white dark:bg-gray-900">
  <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
    <axpo-typography [variant]="'H4'">
      {{ 'import-location.title' | transloco }}
    </axpo-typography>

    <axpo-stepper
      [steps]="steps()"
      [activeStep]="activeStep()"
      (activeStepChange)="this.activeStep.set($event)"
    ></axpo-stepper>

    @if (this.activeStep() === 0) {
      <app-filedrop
        [multiple]="false"
        [acceptCamera]="false"
        [accept]="'.xlsx, xls'"
        (filesUploaded)="filesUploaded($event)"
      >
        @if (file()) {
          <div class="bg-background-1 flex p-3 mb-2">
            <div class="mr-3">
              <i class="fas fa-check text-ima-green"></i>
            </div>
            <div class="grow">
              {{ file()?.name }}
            </div>
            <div><button (click)="remove()">X</button></div>
          </div>
        }
      </app-filedrop>

      <!-- This is disabled until it's decided if Import from API will be completly fixed or removed -->
      <!--       
      <div class="mt-4 items-center justify-center flex-1 flex">
        <axpo-button (click)="importLocationService.uploadFromApi(false)" [style]="'primary'">
          {{ 'import-location.importFromApi' | transloco }}
        </axpo-button>
      </div> 
      -->
    }

    @if (this.activeStep() === 1) {
      <div class="bg-blue-300 p-4 mb-4 rounded">
        {{ totalAmount() }} {{ 'import-location.foundRecords' | transloco }}
      </div>

      @if (importLocationService.isUploadingLocationImportFile()) {
        <div class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div class="p-8 bg-white rounded shadow-lg">
            <axpo-spinner size="normal">{{ 'core.loading' | transloco }}</axpo-spinner>
          </div>
        </div>
      }

      @if (totalAmount() > 0) {
        <axpo-selectable-buttons
          class="bg-white"
          [multiselect]="false"
          [buttons]="buttons()"
          (buttonClicked)="tabSelect($event)"
        ></axpo-selectable-buttons>

        @if (selectedTab() === '1') {
          <div class="flex-1 space-y-4 mb-8 p-4 rounded-lg shadow-lg">
            <axpo-typography variant="title-m">
              {{ 'import-location.activatedLocations' | transloco }}
            </axpo-typography>
            <axpo-table
              [columns]="columns()"
              [data]="dataOfActivated()"
              [isLoading]="importLocationService.isUploadingLocationImportFile()"
            ></axpo-table>
            <axpo-tablenavigation
              [(pageSize)]="pageSizeOfActivated"
              [totalItems]="amountOfActivated()"
              [(page)]="selectedPageOfActivated"
              [style]="'default'"
            />
          </div>
        }
        @if (selectedTab() === '2') {
          <div class="flex-1 space-y-4 mb-8 p-4 rounded-lg shadow-lg">
            <axpo-typography variant="title-m">
              {{ 'import-location.changedLocations' | transloco }}
            </axpo-typography>
            <axpo-table
              [columns]="columns()"
              [data]="dataOfChanged()"
              [isLoading]="importLocationService.isUploadingLocationImportFile()"
            ></axpo-table>
            <axpo-tablenavigation
              [(pageSize)]="pageSizeOfChanged"
              [totalItems]="amountOfChanged()"
              [(page)]="selectedPageOfChanged"
              [style]="'default'"
            />
          </div>
        }
        @if (selectedTab() === '3') {
          <div class="flex-1 space-y-4 mb-8 p-4 rounded-lg shadow-lg">
            <axpo-typography variant="title-m">
              {{ 'import-location.deactivatedLocations' | transloco }}
            </axpo-typography>
            <axpo-table
              [columns]="columns()"
              [data]="dataOfDeactivated()"
              [isLoading]="importLocationService.isUploadingLocationImportFile()"
            ></axpo-table>
            <axpo-tablenavigation
              [(pageSize)]="pageSizeOfDeactivated"
              [totalItems]="amountOfDeactivated()"
              [(page)]="selectedPageOfDeactivated"
              [style]="'default'"
            />
          </div>
        }
        <div class="bg-yellow-100 p-4 rounded mb-4">
          <div class="flex">
            <span class="w-full m-auto">
              {{ amountOfWarnings() }} {{ 'import-location.warnings' | transloco }}
            </span>
            <axpo-button
              (click)="showWarnings.set(!showWarnings())"
              [type]="'ghost'"
              [style]="'secondary'"
            >
              <i [class]="showWarnings() ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
            </axpo-button>
          </div>
          @if (showWarnings()) {
            <div>
              @for (item of validationWarnings(); track item) {
                <p>{{ item }}</p>
              }
            </div>
          }
        </div>

        <div class="bg-red-300 p-4 rounded">
          <div class="flex">
            <span class="w-full m-auto">
              {{ amountOfErrors() }} {{ 'import-location.errors' | transloco }}
            </span>

            <axpo-button
              (click)="showErrors.set(!showErrors())"
              [type]="'ghost'"
              [style]="'secondary'"
            >
              <i [class]="showErrors() ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
            </axpo-button>
          </div>
          @if (showErrors()) {
            <div>
              @for (item of validationErrors(); track item) {
                <p>{{ item }}</p>
              }
            </div>
          }
        </div>
      }
    }

    @if (this.activeStep() === 2) {
      @if (importLocationService.isUploadingLocationImportFile()) {
        <axpo-spinner size="normal">{{ 'core.loading' | transloco }}</axpo-spinner>
      }
      @if (!importLocationService.isUploadingLocationImportFile()) {
        <axpo-typography variant="title-m">
          {{ 'import-location.successfullUpload' | transloco }}
        </axpo-typography>
      }
    }

    <div class="flex py-8 space-x-0 justify-evenly sm:space-x-8 sm:justify-end">
      @if (this.activeStep() > 0) {
        <axpo-button (click)="onBackClicked()" [type]="'outlined'" [style]="'secondary'">
          {{ 'import-location.back' | transloco }}
        </axpo-button>
      }
      <axpo-button (click)="onSubmitClicked()" [style]="'primary'" [disabled]="!isFormValid()">
        @if (this.activeStep() === 0) {
          {{ 'import-location.continue' | transloco }}
        }
        @if (this.activeStep() > 0) {
          {{ 'import-location.save' | transloco }}
        }
      </axpo-button>
    </div>
  </div>
</section>
