<div class="w-full p-3">
  @for (location of locations(); track location.id) {
    <div class="mb-2 border-b-[1px] pb-4 flex">
      <div class="flex-none w-14">
        <i class="fas fa-location-dot text-primary text-2xl"></i>
      </div>
      <div class="flex-1">
        <b>{{ location.name }}</b>
        <br />
        <p class="text-gray-500">
          {{ location.description }}
          @if (location.additionalInformation) {
            <br />
            {{ location.additionalInformation }}
          }
          @if (location?.coordinates) {
            <br />
            {{ 'incidentDetail.panelLocation.coordinates' | transloco }}:
            {{ location.coordinates?.latitude }} {{ location.coordinates?.longitude }}
          }
        </p>
      </div>
      <div class="flex-none w-14 cursor-pointer">
        <button
          (click)="
            openNavigationLink(
              location.coordinates?.latitude ?? 0,
              location.coordinates?.longitude ?? 0
            )
          "
        >
          <i class="fas fa-location-arrow text-2xl"></i>
        </button>
      </div>
    </div>
  }
</div>
