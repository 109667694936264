export type stateName =
  | 'Alle'
  | 'Pendent'
  | 'Eskaliert'
  | 'Rückmeldung Störungsbehebung'
  | 'Abgeschlossen';
export enum IncidentStatusTranslations {
  'Alle' = 'all',
  'Pendent' = 'pending',
  'Eskaliert' = 'escalated',
  'Rückmeldung Störungsbehebung' = 'incidentResolutionResponse',
  'Abgeschlossen' = 'completed',
  'Entwurf' = 'draft',
}

export type impactName = 'Bleibend' | 'Nicht bleibend';
export enum IncidentImpactTranslations {
  'Bleibend' = 'permanent',
  'Nicht bleibend' = 'notPermanent',
}

export type tileName =
  | 'Details'
  | 'Standort'
  | 'Alarm'
  | 'Kontakte'
  | 'Störungsbehebung'
  | 'Kommentare'
  | 'Anhänge'
  | 'AnhängeInternal';
export enum IncidentTilesTranslations {
  'Details' = 'details',
  'Standort' = 'location',
  'Alarm' = 'alarm',
  'Kontakte' = 'contacts',
  'Störungsbehebung' = 'incidentResolutionResponse',
  'Kommentare' = 'comments',
  'Anhänge' = 'attachments',
  'AnhängeInternal' = 'attachmentsInternal',
}

export type effectName =
  | 'Definitiv «AUS»'
  | 'KU erfolglos, LU autom. Erfolgreich'
  | 'KU erfolglos, Probeschaltung erfolgreich'
  | 'KU erfolgreich'
  | 'KU und Probeschaltung erfolglos'
  | 'Andere atmosphärische Einwirkung'
  | 'Andere Fehlerart'
  | 'Andere Ursache'
  | 'Brand'
  | 'Buchholz'
  | 'Diff.- Schutz Störung'
  | 'Eigenbedarfs-, Notstromanlage'
  | 'Einwirkung von Personen'
  | 'Fehlfunktion des Netzschutzes'
  | 'Haustechnik Heizung'
  | 'Hilfsbetriebe'
  | 'Isolatoren, Durchführungen'
  | 'Kompensationsdrossel'
  | 'Kühlerstörung'
  | 'Löschdrossel'
  | 'Naturereignis'
  | 'Schwallschutz'
  | 'Spannungsregelung'
  | 'Thermobox Kupfer- und Öltemperatur'
  | 'Trafonebeneinrichtungen'
  | 'Trafostation, begehbar'
  | 'Transformator'
  | 'Ueberspannungsableiter in Anlagen'
  | 'Versagen eines Apparates / Betriebsmittels';
export enum ImpactDataTranslations {
  'Definitiv «AUS»' = 'defOff',
  'KU erfolglos, LU autom. Erfolgreich' = 'kuUnsuccessfulLuAutoSuccess',
  'KU erfolglos, Probeschaltung erfolgreich' = 'kuUnsuccessfulTestSwitchingSuccess',
  'KU erfolgreich' = 'kuSuccess',
  'KU und Probeschaltung erfolglos' = 'kuAndTestCircuitUnsuccessful',
  'Andere atmosphärische Einwirkung' = 'otherAtmosphericInfluence',
  'Andere Fehlerart' = 'otherTypeOfFault',
  'Andere Ursache' = 'otherCause',
  'Brand' = 'fire',
  'Buchholz' = 'buchholz',
  'Diff.- Schutz Störung' = 'diffProtectionFault',
  'Eigenbedarfs-, Notstromanlage' = 'ownConsumptionEmergencyPowerSystem',
  'Einwirkung von Personen' = 'impactOfPersons',
  'Fehlfunktion des Netzschutzes' = 'malfunctionOfMainsProtection',
  'Haustechnik Heizung' = 'domesticHeatingSystem',
  'Hilfsbetriebe' = 'auxiliaryServices',
  'Isolatoren, Durchführungen' = 'insulatorsBushings',
  'Kompensationsdrossel' = 'compensatingReactor',
  'Kühlerstörung' = 'coolerFault',
  'Löschdrossel' = 'extinguishingChoke',
  'Naturereignis' = 'naturalEvent',
  'Schwallschutz' = 'surgeProtection',
  'Spannungsregelung' = 'voltageRegulation',
  'Thermobox Kupfer- und Öltemperatur' = 'thermoboxCopperAndOilTemp',
  'Trafonebeneinrichtungen' = 'transformerLevelEquipment',
  'Trafostation, begehbar' = 'transformerStationAccessible',
  'Transformator' = 'transformer',
  'Ueberspannungsableiter in Anlagen' = 'surgeArrestersInSystems',
  'Versagen eines Apparates / Betriebsmittels' = 'failureOfAnApparatusOrEquipment',
}

export type lineDamage =
  | 'Kein Schaden'
  | 'Polyäthylen-Kabel (PE)'
  | 'Porzellan-Isolator'
  | 'Kunststoff-Isolator'
  | 'Glas-Isolator'
  | 'Überspannungsableiter'
  | 'Spannungswandler'
  | 'Einzeldrahtbruch'
  | 'Polleiterbruch'
  | 'GroundWireSingleBreak'
  | 'Erdseilbruch'
  | 'Erdseilbruch mit Nachrichtenkabel'
  | 'Betonmast'
  | 'Metallmast (Stahvollwandmasten, Gittermasten)'
  | 'Holzmast'
  | 'Massekabel'
  | 'Ölkabel'
  | 'Schaltvorrichtung in der Leitung'
  | 'Armatur'
  | 'Arbeitserder';
export enum DamageLineTranslations {
  'Kein Schaden' = 'noDamage',
  'Polyäthylen-Kabel (PE)' = 'polyethyleneCablePe',
  'Porzellan-Isolator' = 'porcelainInsulator',
  'Kunststoff-Isolator' = 'plasticInsulator',
  'Glas-Isolator' = 'glassInsulator',
  'Überspannungsableiter' = 'surgeArrester',
  'Spannungswandler' = 'voltageTransformer',
  'Einzeldrahtbruch' = 'singleWireBreak',
  'Polleiterbruch' = 'poleConductorBreak',
  'GroundWireSingleBreak' = 'groundWireSingleBreak',
  'Erdseilbruch' = 'groundWireBreak',
  'Erdseilbruch mit Nachrichtenkabel' = 'groundWireBreakWithMessageCable',
  'Betonmast' = 'concreteMast',
  'Metallmast (Stahvollwandmasten, Gittermasten)' = 'metalMast',
  'Holzmast' = 'woodenMast',
  'Massekabel' = 'groundCable',
  'Ölkabel' = 'oilCable',
  'Schaltvorrichtung in der Leitung' = 'switchingDeviceInLine',
  'Armatur' = 'fitting',
  'Arbeitserder' = 'workingEarthElectrode',
}

export type locationCause = 'Freileitung' | 'Kabelleitung' | 'Unterwerk';
export enum CauseLocationTranslations {
  'Freileitung' = 'overheadLine',
  'Kabelleitung' = 'cableLine',
  'Unterwerk' = 'substation',
}

export type plantDamage =
  | 'Kein Schaden'
  | 'Sammelschiene'
  | 'Leitungsschalter Ölkabel festeingebaut'
  | 'Leitungsschalter SF6, festeingebaut'
  | 'Trenner'
  | 'Transformator'
  | 'Überspannungsableiter in Anlagen'
  | 'Messgruppe'
  | 'Erdungstrenner, Arbeitserder'
  | 'Spannungswandler'
  | 'Stromwandler'
  | 'Hilfsbetriebe'
  | 'Löschdrossel'
  | 'Kompensationsdrossel';
export enum CauseDamagePlantTranslations {
  'Kein Schaden' = 'noDamage',
  'Sammelschiene' = 'busbar',
  'Leitungsschalter Ölkabel festeingebaut' = 'circuitBreakerOilCableFixed',
  'Leitungsschalter SF6, festeingebaut' = 'circuitBreakerSf6PermanentlyInstalled',
  'Trenner' = 'disconnector',
  'Transformator' = 'transformer',
  'Überspannungsableiter in Anlagen' = 'surgeArresterInSystems',
  'Messgruppe' = 'measuringGroup',
  'Erdungstrenner, Arbeitserder' = 'earthingIsolatorWorkingEarthElectrode',
  'Spannungswandler' = 'voltageTransformer',
  'Stromwandler' = 'currentTransformer',
  'Hilfsbetriebe' = 'auxiliaryOperations',
  'Löschdrossel' = 'extinguishingChoke',
  'Kompensationsdrossel' = 'compensatingChoke',
}

export type causeName =
  | 'Gewitter'
  | 'Unbekannte Ursache'
  | 'Vogel'
  | 'Sturm'
  | 'Schnee-, Eislast'
  | 'Landwirtschafts- u. Forstarbeiten'
  | 'Baum'
  | 'Hoch- u. Tiefbauarbeiten'
  | 'Brand'
  | 'Fehlfunktion des Netzschutzes'
  | 'Alterung des Materials'
  | 'Rückwirkung aus eigenem Netz'
  | 'Rückwirkung aus Fremdem Netz mit gleicher Spannung'
  | 'Rückwirkung aus Fremdem Netz mit anderer Spannung'
  | 'Unbeabsichtigte Betätigung'
  | 'Fehlbedienung';
export enum FaultCauseTranslations {
  'Gewitter' = 'thunderstorm',
  'Unbekannte Ursache' = 'unknownCause',
  'Vogel' = 'bird',
  'Sturm' = 'storm',
  'Schnee-, Eislast' = 'snowIceLoad',
  'Landwirtschafts- u. Forstarbeiten' = 'agriculturalAndForestryWork',
  'Baum' = 'tree',
  'Hoch- u. Tiefbauarbeiten' = 'buildingAndCivilEngineeringWork',
  'Brand' = 'fire',
  'Fehlfunktion des Netzschutzes' = 'malfunctionOfMainsProtection',
  'Alterung des Materials' = 'ageingOfMaterial',
  'Rückwirkung aus eigenem Netz' = 'reactionFromOwnNetwork',
  'Rückwirkung aus Fremdem Netz mit gleicher Spannung' = 'reactionFromExternalNetworkSameVoltage',
  'Rückwirkung aus Fremdem Netz mit anderer Spannung' = 'reactionFromExternalNetworkDifferentVoltage',
  'Unbeabsichtigte Betätigung' = 'unintentionalActuation',
  'Fehlbedienung' = 'incorrectOperation',
}
