import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  WritableSignal,
  computed,
  effect,
  inject,
  output,
  signal,
  untracked,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

import { AxpoButtonComponent } from '../../core/axpo-button/axpo-button.component';
import { AxpoSpinnerComponent } from '../../core/axpo-spinner/axpo-spinner.component';
import { AxpoStepperComponent } from '../../core/axpo-stepper/axpo-stepper.component';
import { AxpoTypographyComponent } from '../../core/axpo-typography/axpo-typography.component';
import { AlertComponent } from '../../shared/controls/alert/alert.component';
import { AttachmentsComponent } from '../../shared/controls/attachments/attachments.component';
import { ContactsComponent } from '../../shared/controls/contacts/contacts.component';
import { EditDetailsComponent } from '../../shared/controls/details/edit-details.component';
import { ViewDetailsComponent } from '../../shared/controls/details/view-details.component';
import { LocationComponent } from '../../shared/controls/location/location.component';
import { ShareLinkComponent } from '../../shared/controls/share-link/share-link.component';
import { IncidentService } from '../../shared/services/incident.service';
import { LanguageService } from '../../shared/services/lang.service';
import { TenantService } from '../../shared/services/tenant.service';
import { FormValidator } from '../../shared/utils/formValidator';
import { TileStates } from '../../shared/utils/tileStates';
import { TileComponent } from '../incident-detail/tile.component';
import { availableButtons } from '../incident-detail/tile.component';
import { TileService } from '../incident-detail/tile.service';

interface ISignalSteps {
  index: number;
  stepName: string;
  action: WritableSignal<availableButtons | undefined>;
  actionOnNext: availableButtons | undefined;
  validator: FormValidator | undefined;
  isInvalid: WritableSignal<boolean>;
  saveTrigger: WritableSignal<string | undefined>;
}
type IStepSignalPair = Record<number, ISignalSteps>;
const STEPS_WITH_SAVE = [0, 2];
const VIEW_TILES = ['alarm', 'location', 'details', 'contacts', 'attachments', 'attachmentsInternal'];

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-incident-wizard',
  templateUrl: './incident-wizard.component.html',
  imports: [
    AxpoButtonComponent,
    TranslocoPipe,
    AxpoStepperComponent,
    EditDetailsComponent,
    AlertComponent,
    LocationComponent,
    ContactsComponent,
    AxpoSpinnerComponent,
    TileComponent,
    ViewDetailsComponent,
    AttachmentsComponent,
    ShareLinkComponent,
    AxpoTypographyComponent,
  ],
})
export class IncidentWizardComponent implements OnInit {
  private activatedRoute = inject(ActivatedRoute);
  incidentService = inject(IncidentService);
  langService = inject(LanguageService);
  tenantService = inject(TenantService);
  translocoService = inject(TranslocoService);
  router = inject(Router);
  tileService = inject(TileService);

  activeStep = signal<number>(0);
  stepSignals = signal<IStepSignalPair>({});
  submitClicked = output<void>();
  shareLink = signal<boolean>(false);
  maxClickableStep = signal<number>(0);
  nextPage = signal<number>(0);
  navigationValid = signal<boolean>(false);
  navigationAborted = signal<boolean>(false);

  tenantId = computed(() => {
    return this.tenantService.tenantId();
  });

  accessCode = computed(() => {
    return this.activatedRoute.snapshot.paramMap.get('accessCode');
  });

  creationStepId = computed(() => {
    return this.incidentService.incidentDetails()?.incidentStateId;
  });

  incidentDetails = computed(() => {
    return this.incidentService.incidentDetails();
  });

  activatedStepRoute = computed(() => {
    const step = this.activatedRoute.snapshot.paramMap.get('step') ?? '-1';
    return parseInt(step);
  });

  tiles = new TileStates(VIEW_TILES);

  ngOnInit(): void {
    const step = this.activatedRoute.snapshot.paramMap.get('step');

    if (step) {
      untracked(() => {
        this.activeStep.set(parseInt(step));
      });
    }
  }

  _lang = effect(() => {
    const _activeLanguage = this.langService.getLangSignal()();
    const stepConfigs = this.wizardConfiguration();
    untracked(() => {
      for (const stepConfig of stepConfigs) {
        this.createStepSignal(stepConfig.index, stepConfig.stepNameLabel);
      }
    });
  });

  _navigate = effect(() => {
    const step = this.activeStep();
    const incidentsUpdated = this.incidentService.updateResult();
    const isSaving = this.stepSignals()[this.activeStep()].saveTrigger();

    if (isSaving && incidentsUpdated) {
      this.stepSignals()[this.activeStep()].saveTrigger.set(undefined);
      this.incidentService.updateResult.set(undefined);
      this.performNavigation(this.nextPage());
      if (this.nextPage() > this.maxClickableStep()) {
        this.maxClickableStep.set(this.nextPage());
      }
    } else if (step !== this.activatedStepRoute()) {
      this.performNavigation(step);
    }
  });

  _initDetails = effect(() => {
    const tenantId = this.tenantId();
    const accessCode = this.accessCode();

    if (accessCode && tenantId) {
      untracked(() => {
        this.incidentService.getIncidentDetails(accessCode, tenantId);
      });
    }
  });

  _routeChanged = effect(() => {
    const _actualStep = this.activatedStepRoute();
    const serverStep = this.creationStepId() ?? -1;
    const details = this.incidentDetails();

    if ( serverStep > 3 ) {
      this.router.navigate(['404']);
      return;
    }

    if (!details) {
      return;
    }

    let maxStep = -1;
    if (!details?.alertName) {
      maxStep = 0;
    }  
    
    if (details.alertName && details.locations?.length === 0) {
      maxStep = 1;
    }
    
    if (details.locations && details.locations?.length > 0) {
      maxStep = 3;
    }
    
    if (details.incidentDescription) {
      maxStep = 5;
    }
    
    if (_actualStep > maxStep) {
      this.router.navigate(['404']);
    }
  });

  _pageChanged = effect(() => {
    const activePage = this.activeStep();
    const nextPage = this.nextPage();
    
    // Pagination is finished
    if (nextPage === activePage && !this.navigationAborted()) {
      const accessCode = this.accessCode();
      const wizardStepName = 'incidentCreate.step' + (activePage + 1);

      this.incidentService.getIncidentDetails(accessCode as string);
      if (this.stepSignals()[activePage].isInvalid()) {
        this.createStepSignal(activePage, wizardStepName);
      }
    }
  });

  createStepSignal(index: number, stepNameLabel: string) {
    this.stepSignals.update(tileSignals => {
      const signals = { ...tileSignals };
      signals[index] = {
        index: index,
        stepName: this.translocoService.translate(stepNameLabel),
        action: signal<availableButtons | undefined>(undefined),
        actionOnNext: 'confirm',
        validator: undefined,
        isInvalid: signal<boolean>(false),
        saveTrigger: signal<string | undefined>(undefined),
      };

      return signals;
    });
  }

  steps = computed(() => {
    const stepsArray = [];
    for (const key in this.stepSignals()) {
      stepsArray.push(this.stepSignals()[key].stepName);
    }
    return stepsArray;
  });

  wizardConfiguration = computed(() => {
    return [
      {
        index: 0,
        stepNameLabel: 'incidentCreate.step1',
        actionOnNext: 'confirm',
      },
      {
        index: 1,
        stepNameLabel: 'incidentCreate.step2',
        actionOnNext: 'confirm',
      },
      {
        index: 2,
        stepNameLabel: 'incidentCreate.step3',
        actionOnNext: 'confirm',
      },
      {
        index: 3,
        stepNameLabel: 'incidentCreate.step4',
        actionOnNext: 'confirm',
      },
      {
        index: 4,
        stepNameLabel: 'incidentCreate.step5',
        actionOnNext: 'confirm',
      },
      {
        index: 5,
        stepNameLabel: 'incidentCreate.step6',
        actionOnNext: 'confirm',
      },
    ];
  });

  validateForm(form: FormValidator, stepIndex: number) {
    if (this.stepSignals()[stepIndex]) {
      this.stepSignals()[stepIndex].validator = form;
      if (form.validateAfterSubmit() === false) {
        this.stepSignals()[stepIndex].isInvalid.set(!form.isValid);
      }
    }
  }

  saveStep(direction: number) {
    if (STEPS_WITH_SAVE.indexOf(this.activeStep()) > -1) {
      this.stepSignals()[this.activeStep()].saveTrigger.set(Date.now().toString());
    } else {
      this.stepSignals()[this.activeStep()].validator?.showErrors();
      if (
        this.stepSignals()[this.activeStep()].validator &&
        !this.stepSignals()[this.activeStep()].validator?.isValid
      ) {
        this.stepSignals()[this.activeStep()].isInvalid.set(true);
        return;
      }
      const stepId = this.creationStepId() ?? -1;
      if (stepId <= this.nextPage()) {
        const details = this.incidentDetails();
        if (details) {
          if (direction > 0) {
            const savingStep = this.nextPage() > 3 ? 3 : this.nextPage();
            details.incidentStateId = this.nextPage() === this.steps().length ? 4 : savingStep;
          }
          this.incidentService.update(this.accessCode() as string, undefined, details);
        }
        this.stepSignals()[this.activeStep()].saveTrigger.set(Date.now().toString());
      } else {
        this.performNavigation(this.nextPage());
      }
    }
  }

  performNavigation(step: number) {
    const accessCode = this.accessCode();

    if (step < this.steps().length) {
      window.history.replaceState({}, '', `incident/${accessCode}/edit/${step}`);
      untracked(() => {
        this.activeStep.set(step);
      });
    }
    if (step === this.steps().length) {
      this.shareLink.set(true);
    }
  }

  saveCompleted(tileName: string) {
    this.tiles.all[tileName].mode.set('view');
  }

  saveCanceled(result: string) {
    if (result === '-1') {
      this.stepSignals()[this.activeStep()].saveTrigger.set(undefined);
      // this.stepSignals()[this.activeStep()].isInvalid.set(true);
    }
  }

  updateAction(timestampEvent: string | undefined, key: string) {
    if (timestampEvent) {
      this.tiles.all[key].action.set(timestampEvent);

      if (key === 'details') {
        this.tileService.detailsChangedTimestamp.set(timestampEvent);
      }

      if (key === 'alarm') {
        this.tileService.alertChangedTimestamp.set(timestampEvent);
      }
    }
  }

  validationChanged(form: FormValidator, tileName: string) {
    this.tiles.all[tileName].disabled.set(!form.isValid);
  }

  shareLinkClosed(action: 'confirm' | 'cancel' | 'confirmAndLoad' | 'share') {
    if (action === 'confirmAndLoad') {
      this.router.navigate([`incident/${this.accessCode()}/${this.tenantId()}`]);
    } else {
      this.router.navigate(['home']);
    }
  }

  backwardNavClicked() {
    this.nextPage.set(this.activeStep() - 1);
    this.stepSignals()[this.activeStep()].validator?.showErrors();
    if (!this.stepSignals()[this.activeStep()].validator?.isValid) {
      this.performNavigation(this.nextPage());
    } else {
      this.saveStep(-1);
    }
  }

  forwardNavClicked() {
    this.nextPage.set(this.activeStep() + 1);
    this.saveStep(1);
  }

  beforeStepperNav(direction: number) {
    this.navigationAborted.set(false);
    const nextStep = this.activeStep() + direction;
    let maxStep = this.creationStepId();
    if (maxStep === undefined || maxStep === null) {
      maxStep = 0;
    }

    if (maxStep === 3) {
      maxStep = 5;
    }

    if (!this.stepSignals()[this.activeStep()].validator && nextStep < maxStep + 1) {
      this.navigationValid.set(true);
      this.nextPage.set(nextStep);
      return;
    }
    
    this.stepSignals()[this.activeStep()].validator?.showErrors();
    if (this.stepSignals()[this.activeStep()].validator?.isValid) {
      this.nextPage.set(nextStep);
      this.saveStep(direction);
      this.navigationValid.set(true);
      return;
    }

    if (!this.stepSignals()[this.activeStep()].validator?.isValid && direction < 0) {
      this.nextPage.set(nextStep);
      this.navigationValid.set(true);
      return;
    }

    this.navigationAborted.set(true);
  }
}
