import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';

import { AxpoButtonComponent } from '../../core/axpo-button/axpo-button.component';
import { AxpoTypographyComponent } from '../../core/axpo-typography/axpo-typography.component';

@Component({
  selector: 'app-unauthorized',
  standalone: true,
  imports: [TranslocoPipe, AxpoTypographyComponent, AxpoButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './unauthorized.component.html',
  styleUrl: './unauthorized.component.css',
})
export class UnauthorizedComponent {
  router = inject(Router);

  navigateToHome() {
    this.router.navigate(['/home']);
  }
}
