import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Signal,
  WritableSignal,
  computed,
  inject,
  input,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { BreadcrumbProps, BreadcrumbService } from '../../shared/services/breadcrumb.service';
import { AxpoDropdownComponent } from '../axpo-dropdown/axpo-dropdown.component';
import { AxpoTypographyComponent } from '../axpo-typography/axpo-typography.component';

export type SeparatorType = 'default' | 'bar';

@Component({
  selector: 'axpo-breadcrumb',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    AxpoTypographyComponent,
    FontAwesomeModule,
    AxpoDropdownComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './axpo-breadcrumb.component.html',
  styleUrl: './axpo-breadcrumb.component.css',
})
export class AxpoBreadcrumbComponent {
  router = inject(Router);
  breadcrumbService = inject(BreadcrumbService);
  breadcrumbs: WritableSignal<BreadcrumbProps[]> = this.breadcrumbService._breadcrumbs;
  separator = input<SeparatorType>('default');
  breadcrumbLength = computed(() => this.breadcrumbs().length);
  processedBreadcrumb: Signal<BreadcrumbProps[]> = computed(() => {
    const items = this.breadcrumbs();
    if (items.length > 3) {
      return items.slice(1, items.length - 2);
    } else {
      return [...items];
    }
  });
}
