import { Injectable, Signal, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private translocoService = inject(TranslocoService);
  lang = toSignal(this.translocoService.langChanges$);

  getLangSignal(): Signal<string | undefined> {
    return this.lang;
  }
}
