<div class="flex items-center justify-between p-2">
  <div class="max-w-72">
    <axpo-typography [variant]="'body-s'" class="text-text-secondary">
      {{ contact()?.designation || 'admin.group' | transloco }}
    </axpo-typography>
    <axpo-typography [variant]="'body-m'" weight="bold">
      {{ contact()?.name }}
    </axpo-typography>

    @if (contact()?.members) {
      <axpo-typography [variant]="'body-s'" class="text-text-secondary">
        {{ 'admin.members' | transloco }}
        {{ contact()?.members }}
      </axpo-typography>
    }
    <axpo-typography [variant]="'body-s'" class="text-text-secondary">
      {{ contact()?.phone }}
      @if (contact()?.phone && contact()?.mail) {
        {{ '|' }}
      }
      {{ contact()?.mail }}
    </axpo-typography>
  </div>

  @if (displayDeleteAction()) {
    <div
      class="w-5 h-5 text-text-secondary hover:cursor-pointer"
      [innerHTML]="getSvg()['delete']"
    ></div>
  }
</div>
