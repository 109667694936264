import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, effect, inject, input, signal, untracked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { Subscription } from 'rxjs';

import { BaseResizeComponent } from './base.resize.component';
import { ISelection, ISubgroupMode, IToggleProperty, SubgroupComponent, UserSelection } from './sub-group/sub-group.component';
import { ITabItem, TabsComponent } from './tabs/tabs.component';
import { AxpoButtonComponent } from "../../core/axpo-button/axpo-button.component";
import { AxpoDialogComponent } from '../../core/axpo-dialog/axpo-dialog.component';
import { AxpoFormElementComponent, IFormError, IOption } from '../../core/axpo-form-element/axpo-form-element.component';
import { AxpoSpinnerComponent } from "../../core/axpo-spinner/axpo-spinner.component";
import { AxpoTypographyComponent } from "../../core/axpo-typography/axpo-typography.component";
import { LocationAutocompleteComponent } from "../../shared/controls/location/location-autocomplete/location-autocomplete.component";
import { Circle, ICircleContact, IOnCall, IUserState, Organization, Subgroup, User } from '../../shared/models/api_models';
import { IAutocompleteEntry } from '../../shared/models/component_models';
import { AuthnService } from '../../shared/services/authn.service';
import { CircleService } from "../../shared/services/circle.service";
import { FormDebounceService } from '../../shared/services/form-debounce.service';
import { Roles } from '../../shared/utils/constants';
import { FormValidator } from '../../shared/utils/formValidator';


export type circleMode = 'overview' | 'circle';

@Component({
  selector: 'app-circles',
  imports: [
    SubgroupComponent,
    AxpoTypographyComponent,
    AxpoButtonComponent,
    AxpoDialogComponent,
    AxpoFormElementComponent,
    TabsComponent,
    TranslocoPipe,
    NgClass,
    LocationAutocompleteComponent,
    AxpoSpinnerComponent
],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './circles.component.html',
})
export class CirclesComponent extends BaseResizeComponent implements OnDestroy {
  private activatedRoute = inject(ActivatedRoute);
  private debounceService = inject(FormDebounceService);
  private translocoService = inject(TranslocoService);
  private authnService = inject(AuthnService);
  private router = inject(Router);
  private routeChangeSubscription!: Subscription;
  circleService = inject(CircleService);
  circle = signal<Circle | undefined>(undefined);
  subgroups = signal<Subgroup[] | undefined>(undefined);
  selectedUser = signal<UserSelection>({});
  circleModeBtn = signal<IToggleProperty>('onCall');
  onSiteSubgroup = signal<Subgroup | undefined>(undefined);
  addNewContactDialog = signal<boolean>(false);
  formManager: FormValidator | undefined;
  newContactSubwork = signal<number | undefined>(undefined);
  editedContact = signal<ICircleContact | undefined>(undefined);
  isAdmin = signal<boolean | undefined>(undefined);
  circleTabs = signal<ITabItem[]>([]);
  subgroupOptions = signal<IOption[]>([]);
  onSite = signal<Subgroup | undefined>(undefined);
  contacts = signal<User[] | null | undefined>(undefined);
  filterContactSearch = signal<string>('');
  selectedSearchResults = signal<IAutocompleteEntry[]>([]);
  searchQueryResult = signal<IAutocompleteEntry[]>([]);
  abortDialogSaving = signal<boolean>(false);
  activeUsersChanged = signal<IUserState[]>([]);
  onCallChanged = signal<IOnCall[]>([]);
  mainNumberChanged = signal<number | null | undefined>(undefined);
  circleChangedId = signal<number | undefined>(undefined);

  // controls to manage the state of the component externally
  mode = input<circleMode>('circle');
  circleId = input<number | undefined>(undefined);
  selectedTab = input<IToggleProperty | undefined>(undefined);
  allDisabled = input<boolean>(false);

  // allow tabs to be set from the outside
  _tab = effect(() => {
    const tab = this.selectedTab();
    if (tab) {
      const tabItem = this.circleTabs().find((t) => t.id === tab);
      if (tabItem) {
        this.tabSelectionChanged(tabItem);
      }
    }
  });

  _userUpdated = effect(() => {
    const updated = this.circleService.updatingUserState();
    if (updated) {
      this.activeUsersChanged.set([]);
    }
  });

  initCircleAdmin() {
    if (!this.circleService.circles() && !this.circleService.loadingCircles() && !this.circle()) {
      this.circleService.getCircles();
    }

    this.routeChangeSubscription = this.activatedRoute.paramMap.subscribe(() => {
      const circleId = Number(this.activatedRoute.snapshot.paramMap.get('id'));

      if (circleId !== 0 && this.circle() && !this.circleService.loadingCircles() && circleId !== this.circle()?.id) {
        this.circleChangedId.set(circleId);
        this.circleService.getCircles();
      }
    });
    this.isAdmin.set(true);
  }

  initCircleNormal() {
    const initialCircleId = Number(this.activatedRoute.snapshot.paramMap.get('id'));

    if (!this.circleService.loadingCircle() && !this.circle()) {
      this.circleService.getCircle(initialCircleId);
    }

    this.routeChangeSubscription = this.activatedRoute.paramMap.subscribe(() => {
      const circleId = Number(this.activatedRoute.snapshot.paramMap.get('id'));
      if (circleId !== 0 && this.circle() && !this.circleService.loadingCircle() && circleId !== this.circle()?.id) {
        this.circleChangedId.set(circleId);
        this.circleService.getCircle(circleId);
      }
    });
    this.isAdmin.set(false);
  }

  addContact() {
    this.formManager = new FormValidator(
      [
        {
          key: 'subwork',
          errorMessageSignal: signal(undefined),
          errorIdSignal: signal(undefined),
        },
        {
          key: 'name',
          errorMessageSignal: signal(undefined),
          errorIdSignal: signal(undefined),
        },
      ],
      this.translocoService,
      this.debounceService
    );
    
    this.validateForm();
    this.addNewContactDialog.set(true);
  }

  validateForm() {
    this.formManager?.checkRequired('subwork', this.newContactSubwork());
    this.formManager?.checkRequired('name', this.selectedSearchResults().map((elem) => { return elem.title }).join(''));
  }

  contactDialogClosed(action: any) {
    this.abortDialogSaving.set(false);
    if (action === 'cancel') {
      this.addNewContactDialog.set(false);
      this.editedContact.set(undefined);
      this.newContactSubwork.set(undefined);
      this.selectedSearchResults.set([]);
      return;
    }

    this.validateForm();
    if (!this.formManager?.showErrorOnLoad()) {
      this.formManager?.showErrors();
    }

    if (!this.formManager?.isValid) {
      this.abortDialogSaving.set(true);
      return;
    }

    const circleId = this.circle()?.id;
    const subworkId = this.newContactSubwork();
    const addUsers = this.selectedSearchResults();

    if (circleId && subworkId && addUsers && addUsers.length > 0) {
      this.circleService.updateCircle(circleId, {
        main_number_user_id: undefined,
        add_users: addUsers.map(user => {
          return {
            subgroup_id: Number(subworkId),
            user_id:Number(user.id)
          }
        }),
        remove_users: undefined,
        on_call: undefined,
      });

      this.addNewContactDialog.set(false);
      this.editedContact.set(undefined);
      this.newContactSubwork.set(undefined);
      this.selectedSearchResults.set([]);
    }
  }

  formValidation(formError: IFormError | undefined, key: string) {
    const field = this.formManager?.getField(key);
    const fieldErrorId = field?.errorId();

    if (formError) {
      this.formManager?.setError(
        formError.formId,
        formError.value,
        formError.message,
        formError.replacement,
      );
    } else if (fieldErrorId && fieldErrorId > -1){
      this.formManager?.clearError(key);
    }
  }

  beforeMedian = (organizationSubgroups: Subgroup[]) => {
    if (organizationSubgroups) {
      const median = (organizationSubgroups.length % 2 === 0)? organizationSubgroups.length / 2: organizationSubgroups.length / 2 + 1;
      if (organizationSubgroups.length > median) {
        return organizationSubgroups.slice(0, median);
      }

      return organizationSubgroups;
    }

    return [];
  };

  afterMedian = (organizationSubgroups: Subgroup[]) => {
    if (organizationSubgroups) {
      const median = (organizationSubgroups.length % 2 === 0)? organizationSubgroups.length / 2: organizationSubgroups.length / 2 + 1;
      if (organizationSubgroups.length > median) {
        return organizationSubgroups.slice(median);
      }
    }

    return [];
  };
  selectionMode: ISubgroupMode = 'radio';

  _circles = effect(() => {
    const isAdmin = this.isAdmin();
    let circles: Circle[] | undefined = [];

    if (isAdmin) {
      circles = this.circleService.circles();
    } else if (isAdmin === false) {
      const circle = this.circleService.circle();

      if (circle) {
        circles = [circle];
      }
    }

    const circleId = this.circleChangedId() || this.circleId() || this.activatedRoute.snapshot.paramMap.get('id');

    if (circles && circleId) {
      const circle = circles.find((c: Circle) => c.id == circleId);
    
      const allSubgroups = circle?.organizations?.flatMap(
        (org: Organization) => org.subgroups || [],
      );

      untracked(() => {
        this.circle.set(circle);
        this.onSite.set(circle?.onSite);
        this.subgroups.set(allSubgroups);
        if (allSubgroups) {
          this.subgroupOptions.set(allSubgroups.map((sg: Subgroup) => {
            return {
              value: sg.id?.toString(),
              label: sg.name
            };
          }) as IOption[]);
        }
      });
    }
  });

  _tabChange = effect(() => {
    const tab = this.circleModeBtn();
    if (tab !== 'active') {
      this.selectionMode = 'radio';
    }
  });

  _isAdminContacts = effect(() => {
    const userRoles = this.authnService.user()?.roles || [];
    const isAdmin = [Roles.SUPER_ADMIN, Roles.CIRCLE_ADMIN].some(role => userRoles.includes(role));
    const circleTabs: ITabItem[] = [
      {id: 'onCall', title: 'switchboard.onCall'},
      {id: 'isOnSite', title: 'switchboard.onSite'}
    ];

    if (isAdmin) {
      circleTabs.push({id: 'active', title: 'switchboard.manageContacts'});
      this.initCircleAdmin();
    } else {
      this.initCircleNormal();
    }

    this.circleTabs.set(circleTabs);
  });

  _nameValidationEffect = effect(() => {
    const results = this.selectedSearchResults();
    const name = results.map((elem) => { return elem.title }).join('');

    untracked(() => {
      this.formManager?.checkRequired('name', name);
    });
  });

  _subworkValidationEffect = effect(() => {
    const subwork = this.newContactSubwork();

    untracked(() => {
      this.formManager?.checkRequired('subwork', subwork);

      if (subwork) {
        this.selectedSearchResults.set([]);
      }
    });
  });

  updateUserOnCall(selection: ISelection, selectedId: number) {
    const onCallIndex = this.onCallChanged().findIndex((us) => {
      return us.user_id === selection.user.id
    });

    if (onCallIndex > -1) {
      this.onCallChanged.update((onCallList: IOnCall[]) => {
        onCallList[onCallIndex] = { organization_id: selectedId, user_id: selection.user.id };
        return onCallList;
      });
    } else {
      this.onCallChanged.update((onCallList: IOnCall[]) => {
        onCallList.push({ organization_id: selectedId, user_id: selection.user.id });
        return onCallList;
      });
    }
  }

  updateUserOnSite(selection: ISelection) {
    const userId = selection?.user?.id > 0? selection?.user?.id: null;
    this.mainNumberChanged.set(userId)
  }

  updateActive(selection: ISelection) {
    this.activeUsersChanged.update((activeUsers) => {
      const index = activeUsers.findIndex(item => item.subgroup_id === selection.subgroupId);
      if (index !== -1) {
        // update subgroup
        const userIndex = activeUsers[index].users.findIndex((us) => {
          return us.user_id === selection.user.id
        });
        if (userIndex > -1) {
          activeUsers[index].users[userIndex].active = !!selection.user.active;
        } else {
          activeUsers[index].users.push({
            user_id: selection.user.id,
            active: !!selection.user.active
          });
        }
      } else {
        // add subgroup including user
        activeUsers.push({
          subgroup_id: selection.subgroupId,
          users: [
            {
              user_id: selection.user.id,
              active: !!selection.user.active
            }
          ]
        });
      }
      return activeUsers;
    });
  }

  onUserChanged = (selection: ISelection) => {
    this.selectedUser.update((userSelection) => {
      userSelection[selection.groupId] = selection.user;

      return userSelection;
    });
    
    if (selection.property === 'isOnSite') {
      this.updateUserOnSite(selection);
    } else if (selection.property === 'onCall') {
      const selectionGroup = Object.keys(this.selectedUser())[0];
      const selectedId = Number(selectionGroup.split('_')[1]);

      this.updateUserOnCall(selection, selectedId);
    } else {
      this.updateActive(selection);
    }
    
  };

  getSelectionGroupId(organizationId?: number) {
    const setId = this.circleId() || this.activatedRoute.snapshot.paramMap.get('id');
    if (this.circleModeBtn() === 'isOnSite') {
      return 'circle_' +  setId;
    }

    return 'organization_' + organizationId;
  }

  manageContacts() {
    this.selectionMode = 'switch';
    this.circleModeBtn.set('active');
  }

  back() {
    this.router.navigate(['/']);
  }

  save() {
    const circleId = this.circle()?.id;
    const circleUpdate = {
      main_number_user_id: this.mainNumberChanged(),
      on_call: this.onCallChanged()
    };

    if (circleId && circleUpdate) {
      this.circleService.updateCircle(circleId, circleUpdate);
    }
  }

  tabSelectionChanged(tab: ITabItem) {
    if (tab.id === 'onCall') {
      this.selectionMode = 'radio';
    } else if (tab.id === 'isOnSite') {
      this.selectionMode = 'radio';
    } else if (tab.id === 'active') {
      this.selectionMode = 'switch';
    }
    
    this.circleModeBtn.set(tab.id as IToggleProperty);
  }

  searchValueChanged(searchterm: string | undefined) {
    if (searchterm && searchterm.length > 2) {
      this.circleService.searchUser(searchterm as string);
    }
  }

  searchEntrySelected(entry: IAutocompleteEntry) {
    this.selectedSearchResults.update((results) => {
      results.push(entry);

      return results;
    });
  }

  searchEntryRemoved(entry: IAutocompleteEntry) {
    this.selectedSearchResults.update((results) => {
      results.splice(results.indexOf(entry), 1);

      return results;
    });
  }

  _updateSearchResults = effect(() => {
    // exclude users of selected subwork - only activate after subwork was selected
    const searchResults = this.circleService.searchedUsers();
    if (searchResults) {
      const contactsFound = searchResults.map((result) => {
        return {
          id: result.id ?? -1,
          title: result.first_name + ' ' + result.last_name,
          subtitle: result.role,
          value: result.id,
        };
      }).filter((result) => {
        const organization = this.circle()?.organizations?.find((org: Organization) => {
          return org.subgroups.find((subgroup: Subgroup) => {
            return subgroup.id === Number(this.newContactSubwork());
          });
        });
        
        const subgroup = organization?.subgroups.find((subgroup: Subgroup) => {
          return subgroup.id === Number(this.newContactSubwork());
        });
        
        const user = subgroup?.users?.find((user: User) => {
          return user.id === result.id;
        });

        return !user;
      });
      this.searchQueryResult.set(contactsFound);
    }
  });

  _updatedCircle = effect(() => {
    const updatedCircle = this.circleService.updatedCircle();
    if (updatedCircle) {
      untracked(() => {
        this.onCallChanged.set([]);
        this.mainNumberChanged.set(undefined);
      });
    }
  });

  saveManageContacts() {
    this.circleService.setUserState(this.activeUsersChanged());
  }

  ngOnDestroy(): void {
    this.routeChangeSubscription.unsubscribe();
  }
}