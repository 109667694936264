import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  computed,
  effect,
  inject,
  input,
  model,
  output,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

import { AxpoSpinnerComponent } from '../../../../core/axpo-spinner/axpo-spinner.component';
import { AxpoTypographyComponent } from '../../../../core/axpo-typography/axpo-typography.component';
import { ClickOutsideDirective } from '../../../directives/click-outside.directive';

export interface IAutocompleteEntry {
  id: number | string;
  title: string;
  subtitle: string | undefined | null;
  value: string | number | undefined;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-location-autocomplete',
  templateUrl: './location-autocomplete.component.html',
  imports: [TranslocoPipe, ClickOutsideDirective, AxpoSpinnerComponent, AxpoTypographyComponent],
  animations: [
    trigger('transformOpacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
      ]),
    ]),
  ],
})
export class LocationAutocompleteComponent {
  autocompleteTextbox = viewChild<ElementRef>('autocompleteTextbox');
  translocoService = inject(TranslocoService);
  entries = input.required<IAutocompleteEntry[]>();
  selectedEntries = model.required<IAutocompleteEntry[]>();
  isLoading = input.required<boolean>();
  placeholder = input<string | undefined>();
  debounceTime = input<number>(500);
  entryAdded = output<IAutocompleteEntry>();
  entryRemoved = output<IAutocompleteEntry>();
  searchTextChanged = output<string>();
  debounceValue = output<string | undefined>();
  locationSearchText = signal<string | undefined>('');
  isMenuOpen = signal<boolean>(false);

  hideSuggestions = computed(() => {
    if (this.isLoading()) {
      return false;
    } else {
      return (
        this.locationSearchText()?.length === 0 ||
        this.entries().length === 0 ||
        this.locationSearchText() === undefined ||
        !this.isMenuOpen()
      );
    }
  });

  removeEntry = (entry: IAutocompleteEntry) => {
    const copy = [...this.selectedEntries()];
    copy.splice(copy.indexOf(entry), 1);
    this.selectedEntries.set(copy);
    this.entryRemoved.emit(entry);
  };

  addEntry = (entry: IAutocompleteEntry) => {
    const copy = [...this.selectedEntries()];
    copy.push(entry);
    this.selectedEntries.set(copy);
    this.entryAdded.emit(entry);
  };

  focus = () => {
    this.autocompleteTextbox()?.nativeElement.focus();
    this.isMenuOpen.set(true);
  };

  keyup = (): void => {
    this.locationSearchText.set(this.autocompleteTextbox()?.nativeElement.value);
  };

  _debounce = effect(onCleanup => {
    const value = this.locationSearchText();
    untracked(() => {
      const timeout = setTimeout(() => this.debounceValue.emit(value), 500);
      onCleanup(() => clearTimeout(timeout));
    });
  });
}
