<div class="flex flex-wrap justify-between w-full my-4">
  <div class="flex flex-wrap items-center justify-around w-full mb-2 lg:justify-between lg:w-auto">
    <div class="me-3 text-text-secondary">
      {{ startRowNumber() }} - {{ endRowNumber() }} of {{ totalItems() }}
    </div>
    <div>
      {{ itemsPerPage() }}:
      <axpo-dropdown
        [title]="pageSize().toString()"
        [items]="optionsForPageSizeDropdown()"
        [preSelectedItemValue]="pageSize().toString()"
        [style]="'secondary'"
        [showSelectedValue]="true"
        (select)="changePageSize($event)"
      />
    </div>
  </div>

  <div>
    <nav class="inline-flex">
      <button
        [ngClass]="page() === 1 ? unclickablePageClasses() : clickablePageClasses()"
        (click)="changePage(page() - 1)"
      >
        <div class="w-4 h-4"><div [innerHTML]="getSvg()['chevron-left']"></div></div>
      </button>

      @for (pageNo of pagesForNavigation(); track pageNo; let index = $index) {
        <button
          (click)="changePage(pageNo)"
          [ngClass]="pageNo === page() ? activePageClasses() : clickablePageClasses()"
        >
          {{ pageNo }}
        </button>
        @if (
          index + 1 !== pagesForNavigation().length &&
          pageNo + 1 !== pagesForNavigation()[index + 1]
        ) {
          <a [class]="unclickablePageClasses()">...</a>
        }
      }
      <button
        [ngClass]="page() === totalPages() ? unclickablePageClasses() : clickablePageClasses()"
        (click)="changePage(page() + 1)"
      >
        <div class="w-4 h-4"><div [innerHTML]="getSvg()['chevron-right']"></div></div>
      </button>
    </nav>
  </div>
</div>
