<div class="flex items-center w-full" role="tablist">
  @for (step of steps(); track step; let index = $index; let last = $last) {
    <button
      class="inline-flex flex-wrap items-center justify-center text-center md:p-5 text-text-primary whitespace-nowrap"
      role="tab"
      (click)="onBeforeNavigate(index)"
      [ngClass]="{
        'pointer-events-none': !isStepActive(index),
        'hover:bg-background-2': isStepActive(index),
      }"
    >
      <span
        class="content-center justify-center w-8 h-8 m-1 border rounded-full text-primary border-primary"
        [ngClass]="{
          'bg-primary text-white': activeStep() === index,
        }"
      >
        {{ index + 1 }}
      </span>
      <span class="m-1 hidden sm:block">{{ step }}</span>
    </button>
    @if (!last) {
      <div class="flex-grow h-px bg-border-soft"></div>
    }
  }
</div>
