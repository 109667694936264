import { Injectable, inject, signal } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

export interface IImaUser {
  name: string;
  email: string;
  roles: string[];
}

@Injectable({
  providedIn: 'root',
})
export class AuthnService {
  private oidcSecurityService = inject(OidcSecurityService);

  public user = signal<IImaUser | undefined>(undefined);

  public login = () => {
    console.log('start login');
    this.oidcSecurityService.authorize();
  };

  public refreshSession = () => {
    console.log('start refreshSession');
    this.oidcSecurityService.authorize();
  };

  public logout = () => {
    console.log('start logoff');
    this.oidcSecurityService.logoffAndRevokeTokens().subscribe(result => console.log(result));
  };

  public checkAuth = () => {
    this.oidcSecurityService.checkAuth().subscribe({
      next: async loginResponse => {
        if (loginResponse && loginResponse.isAuthenticated) {
          console.info('login successful', loginResponse);
          this.user.set({
            name: loginResponse.userData.name,
            email: loginResponse.userData.email,
            roles: loginResponse.userData.roles,
          });
        } else {
          console.info('login unsuccessful');
        }
      },
      error: () => {
        console.info('error login');
      },
    });
  };
}
