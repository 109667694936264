import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
  output,
  signal,
  untracked
} from '@angular/core';

import { SvgService } from '../../../core/services/svg.service';
import { SvgIconName } from '../../../core/types/svg-icon-name';

@Component({
  selector: 'app-timer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './timer.component.html',
  imports: [NgClass]
})
export class TimerComponent {
  svgService = inject(SvgService);
  timerSeconds = input.required<number>();
  timeRemainingUntilRefresh = signal<number>(60);
  timer = signal<NodeJS.Timeout | undefined>(undefined);
  startTimerClass = signal<SvgIconName>('timer');
  runningTimerHover = signal<boolean>(false);
  timerElapsed = output();
  getSvg = this.svgService.svgMap;

  init = effect(() => {
    const ts = this.timerSeconds();
    this.svgService.loadSvg(['timer']);
    this.svgService.loadSvg(['play']);
    this.svgService.loadSvg(['pause']);
    
    untracked(() => {
      this.timeRemainingUntilRefresh.set(ts);
    });
  });

  startTimer() {
    const initialOffset = -110;

    const timer = setInterval(() => {
      this.timeRemainingUntilRefresh.set(this.timeRemainingUntilRefresh() - 1);

      if (this.timeRemainingUntilRefresh() <= 0) {
        this.timerElapsed.emit();
        this.timeRemainingUntilRefresh.set(this.timerSeconds());
      }

      // animation
      const strokeDashoffset =
        initialOffset - this.timeRemainingUntilRefresh() * (initialOffset / this.timerSeconds());
      this.strokeDashoffset.set(strokeDashoffset.toString());
    }, 1000);

    this.timer.set(timer);
  }

  strokeDashoffset = signal<string>('');

  stopTimer() {
    if (this.timer()) {
      clearInterval(this.timer());
      this.timeRemainingUntilRefresh.set(this.timerSeconds());
      this.timer.set(undefined);
    }
  }
  setPlay() {
    this.startTimerClass.set('play');
  }
  unsetPlay() {
    this.startTimerClass.set('timer');
  }

  toggleRunningTimer(hover: boolean) {
    this.runningTimerHover.set(hover);
  }
}
