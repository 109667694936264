<div class="w-full flex-2-col min-h-40 mt-3">
  @if (
    !(internalAttachments()
      ? attachmentService.allAttachmentsInternalCached()
      : attachmentService.allAttachmentsCached())
  ) {
    <div class="flex-1 flex items-center justify-center p-3">
      <button
        (click)="downloadAllFilesForOfflineUse()"
        class="bg-primary disabled:bg-disabled disabled:text-text-primary font-almarena font-bold hover:bg-interaction-red inline-flex items-center justify-center leading-4 min-w-24 px-6 py-3 rounded-full text-[14px] text-white tracking-[-0.037px]"
      >
        {{ 'incidentDetail.panelAttachments.makeFilesOfflineAvailable' | transloco }}
      </button>
    </div>
  }
  @for (
    item of internalAttachments()
      ? attachmentService.attachmentsInternal()
      : attachmentService.attachments();
    track item.id
  ) {
    <div class="bg-background-1 flex p-3 mb-2">
      <div class="mr-3">
        <i class="fas text-ima-green fa-check"></i>
      </div>
      <div class="grow">{{ item.name }}</div>
      <div>
        <button (click)="download(item)">
          <i [classList]="'fas text-primary ' + (item.isInPwa ? 'fa-database' : 'fa-download')"></i>
        </button>
      </div>
    </div>
  }

  @if (showNoAttachments()) {
    <axpo-typography [variant]="'body-m'">
      {{ 'incidentDetail.panelAttachments.noAttachmentsFound' | transloco }}
    </axpo-typography>
  }
</div>
