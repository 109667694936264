<section class="bg-white dark:bg-gray-900">
  @if (
    incidentService.isLoadingIncidentDetails() ||
    tenantService.isLoadingSettings() ||
    incidentService.updateInProgress() ||
    commentService.isUpdatingComment()
  ) {
    <div class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div class="p-8 bg-white rounded shadow-lg">
        <axpo-spinner size="normal">{{ 'core.loading' | transloco }}</axpo-spinner>
      </div>
    </div>
  }

  <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
    <axpo-typography [variant]="'title-xl'" [weight]="'bold'">
      {{ 'incidentDetail.title' | transloco }}
    </axpo-typography>
    <axpo-typography [variant]="'title-l'">
      {{ incidentTitle() }}
    </axpo-typography>
    <div class="flex justify-center gap-8 mt-16 mb-16">
      <axpo-button [autofocus]="true" (click)="openShareModal()">
        {{ 'incidentDetail.share' | transloco }}
      </axpo-button>

      @if (isLoggedIn()) {
        <axpo-button [autofocus]="true" (click)="openIncidentDialog()">
          {{ 'incidentDetail.duplicate' | transloco }}
        </axpo-button>
      }

      @if (isLoggedIn()) {
        <axpo-button [autofocus]="true" (click)="onDeleteIncident()">
          {{ 'incidentDetail.deleteIncident' | transloco }}
        </axpo-button>
      }

      @if (isCompleted()) {
        <axpo-button [autofocus]="true" (click)="confirmReopen()">
          {{ 'incidentDetail.reopen' | transloco }}
        </axpo-button>
      }
    </div>
    <div class="flex flex-col justify-center gap-8 mt-16 mb-16 md:flex-row">
      <!-- Left Row -->
      <div class="flex-1 space-y-4">
        @if (tiles.all['details'] && tiles.all['details'].visible()) {
          <app-tile-component
            [(mode)]="tiles.all['details'].mode"
            title="{{ 'incidentDetail.details' | transloco }}"
            [controlButtons]="['confirm', 'cancel']"
            (takenAction)="updateAction($event, 'details')"
            key="details"
            [disabled]="tiles.all['details'].disabled()"
          >
            @if (tiles.all['details'].mode() === 'edit') {
              <app-edit-details
                [mode]="tiles.all['details'].mode()"
                (saveCompleted)="saveCompleted('details')"
                (validationError)="validationChanged($event, 'details')"
              ></app-edit-details>
            } @else {
              <app-view-details></app-view-details>
            }
          </app-tile-component>
        }
        @if (tiles.all['comments'] && tiles.all['comments'].visible()) {
          <app-comments
            [mode]="tiles.all['comments'].mode()"
            title="{{ 'incidentDetail.comments' | transloco }}"
          ></app-comments>
        }
        @if (
          isLoggedIn() && !isCompleted() && tiles.all['comments'] && tiles.all['comments'].visible()
        ) {
          <app-comments
            [mode]="tiles.all['comments'].mode()"
            title="{{ 'incidentDetail.internalComments' | transloco }}"
            type="private"
          ></app-comments>
        }
        @if (tiles.all['contacts'] && tiles.all['contacts'].visible()) {
          <app-tile-component
            [(mode)]="tiles.all['contacts'].mode"
            title="{{ 'incidentDetail.contacts' | transloco }}"
          >
            <app-contacts
              [mode]="tiles.all['contacts'].mode()"
              title="{{ 'incidentDetail.contacts' | transloco }}"
            ></app-contacts>
          </app-tile-component>
        }
        @if (
          tiles.all['incidentResolutionResponse'] &&
          tiles.all['incidentResolutionResponse'].visible()
        ) {
          <app-tile-component
            [(mode)]="tiles.all['incidentResolutionResponse'].mode"
            title="{{ 'incidentDetail.incidentResolutionResponse' | transloco }}"
            [controlButtons]="['confirm', 'cancel']"
            (takenAction)="updateAction($event, 'incidentResolutionResponse')"
          >
            <app-incident-edit-incident-resolution
              [mode]="tiles.all['incidentResolutionResponse'].mode()"
              (saveCompleted)="saveCompleted('incidentResolutionResponse')"
            ></app-incident-edit-incident-resolution>
          </app-tile-component>
        }
      </div>

      <!-- Right Row -->
      <div class="flex-1 space-y-4">
        @if (tiles.all['location'] && tiles.all['location'].visible()) {
          <app-tile-component
            [(mode)]="tiles.all['location'].mode"
            title="{{ 'incidentDetail.location' | transloco }}"
          >
            <app-location
              title="{{ 'incidentDetail.location' | transloco }}"
              [mode]="tiles.all['location'].mode()"
              (validationError)="validationChanged($event, 'location')"
            ></app-location>
          </app-tile-component>
        }

        @if (tiles.all['alarm'] && tiles.all['alarm'].visible()) {
          <app-tile-component
            [(mode)]="tiles.all['alarm'].mode"
            title="{{ 'incidentDetail.alert' | transloco }}"
            (takenAction)="updateAction($event, 'alarm')"
            [controlButtons]="['confirm', 'cancel']"
          >
            <app-alert
              [mode]="tiles.all['alarm'].mode()"
              (saveCompleted)="saveCompleted('alarm')"
            ></app-alert>
          </app-tile-component>
        }

        @if (tiles.all['attachments'] && tiles.all['attachments'].visible()) {
          <app-tile-component
            [(mode)]="tiles.all['attachments'].mode"
            title="{{ 'incidentDetail.attachments' | transloco }}"
          >
            @if (tiles.all['attachments'].mode() === 'edit') {
              <app-edit-attachments [internalAttachments]="false"></app-edit-attachments>
            } @else {
              <app-view-attachments [internalAttachments]="false"></app-view-attachments>
            }
          </app-tile-component>
        }

        @if (
          tiles.all['attachmentsInternal'] &&
          tiles.all['attachmentsInternal'].visible() &&
          isLoggedIn()
        ) {
          <app-tile-component
            [(mode)]="tiles.all['attachmentsInternal'].mode"
            title="{{ 'incidentDetail.attachmentsInternal' | transloco }}"
            (takenAction)="updateAction($event, 'attachmentsInternal')"
            key="attachmentsInternal"
          >
            @if (tiles.all['attachmentsInternal'].mode() === 'edit') {
              <app-edit-attachments [internalAttachments]="true"></app-edit-attachments>
            } @else {
              <app-view-attachments [internalAttachments]="true"></app-view-attachments>
            }
          </app-tile-component>
        }
      </div>
    </div>
  </div>

  @if (duplicateIncident()) {
    <axpo-dialog
      [title]="'incidentDetail.duplicateIncidentDialog.text' | transloco"
      [confirmText]="'core.confirm' | transloco"
      [cancelText]="'core.cancel' | transloco"
      [processBeforeClose]="true"
      [dataLoaded]="incidentCloned()"
      (takenProcessAction)="duplicateIncidentDialogAction($event)"
      icon="warning"
    ></axpo-dialog>
  }
  @if (shareLink()) {
    <app-share-link [(shareLink)]="shareLink"></app-share-link>
  }

  @if (changeToPending()) {
    <axpo-dialog
      [title]="'incidentDetail.changeToPending' | transloco"
      [confirmText]="'core.confirm' | transloco"
      [cancelText]="'core.cancel' | transloco"
      [processBeforeClose]="true"
      [dataLoaded]="incidentService.reOpeningDone()"
      (takenProcessAction)="reopenIncident($event)"
      icon="warning"
    ></axpo-dialog>
  }

  @if (deleteIncident()) {
    <axpo-dialog
      [title]="'incidentDetail.deleteIncident' | transloco"
      [confirmText]="'core.confirm' | transloco"
      [cancelText]="'core.cancel' | transloco"
      [processBeforeClose]="true"
      [dataLoaded]="incidentService.deletingDone()"
      (takenProcessAction)="afterIncidentDeleted($event)"
      icon="warning"
    >
      {{ 'incidentDetail.deleteIncidentText' | transloco }}
    </axpo-dialog>
  }
</section>
