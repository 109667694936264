import { animate, style, transition, trigger } from '@angular/animations';
import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  effect,
  inject,
  input,
  output,
  signal,
  untracked,
} from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';

import { AxpoButtonComponent } from '../axpo-button/axpo-button.component';
import { AxpoSpinnerComponent } from '../axpo-spinner/axpo-spinner.component';
import { AxpoTypographyComponent } from '../axpo-typography/axpo-typography.component';

export type ProcessingCallback = () => Promise<boolean>;

@Component({
  selector: 'axpo-dialog',
  imports: [
    AxpoButtonComponent,
    AxpoTypographyComponent,
    NgClass,
    AxpoSpinnerComponent,
    TranslocoPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './axpo-dialog.component.html',
  styleUrl: './axpo-dialog.component.css',
  animations: [
    trigger('foreground', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' })),
      ]),
    ]),
    trigger('background', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class AxpoDialogComponent implements OnInit, OnDestroy {
  title = input<string>();
  icon = input<'warning' | undefined>();
  confirmText = input.required<string>();
  cancelText = input<string>();
  textAlign = input<'center' | 'left'>('center');
  processBeforeClose = input<boolean>(false);
  dataLoaded = input<boolean>(false);
  saveDisabled = input<boolean>(false);
  takenAction = output<'confirm' | 'cancel'>();
  takenProcessAction = output<'confirm' | 'cancel'>();
  actionTaken = signal<'confirm' | 'cancel' | undefined>(undefined);
  displayDialog = signal<boolean>(true);

  private renderer = inject(Renderer2);

  _init = effect(() => {
    if (this.processBeforeClose() === true) {
      const actionTaken = this.actionTaken();
      const isDataLoaded = this.dataLoaded() === true;
      untracked(() => {
        if (actionTaken === 'confirm' && isDataLoaded) {
          this.takenAction.emit(actionTaken);
          this.displayDialog.set(false);
          this.actionTaken.set(undefined);
        }
      });
    }
  });

  takeAction = (action: 'confirm' | 'cancel') => {
    if (this.processBeforeClose() === true) {
      this.actionTaken.set(action);
      this.takenProcessAction.emit(action);
    } else {
      this.takenAction.emit(action);
    }

    if (action === 'cancel' || !this.processBeforeClose()) {
      this.displayDialog.set(false);
    }
  };

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'overflow-hidden');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'overflow-hidden');
  }
}
