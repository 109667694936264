import {
  ChangeDetectionStrategy,
  Component,
  effect,
  input,
  output,
  signal,
  untracked,
} from '@angular/core';

import { AxpoSearchComponent } from '../../../core/axpo-search/axpo-search.component';
import { ClickOutsideDirective } from '../../../shared/directives/click-outside.directive';
import { IContactModel } from '../../../shared/models/api_models';
import { MailingListItemComponent } from '../mailing-list-item/mailing-list-item.component';

// ToDo - If this component is finally not modified to also be used in the Wizard location page
// move the component to the admin page folder
@Component({
  selector: 'app-mail-search-dropdown',
  imports: [MailingListItemComponent, AxpoSearchComponent, ClickOutsideDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './mail-search-dropdown.component.html',
})
export class MailSearchDropdownComponent {
  defaultItem = input<IContactModel | undefined | null>();
  searchItemsFilter = output<string>();
  contacts = input<IContactModel[]>([]);
  searchDropdownVisible = signal<boolean>(false);
  selectedItem = signal<IContactModel | undefined | null>(undefined);
  searchItemValue = signal<string>('');
  selectItem = output<IContactModel | undefined>();

  _setDefaultItemEffect = effect(() => {
    const defaultItem = this.defaultItem();
    untracked(() => {
      this.selectedItem.set(defaultItem);
    });
  });

  onRemoveCurrentItem() {
    this.selectedItem.set(undefined);
    this.selectItem.emit(undefined);
  }

  onClick(contact: IContactModel) {
    this.searchDropdownVisible.set(false);
    this.selectedItem.set(contact);
    this.selectItem.emit(contact);
  }
}
