import { Injectable, inject, signal } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { ICustomLocationModel, ILocationModel, IValidationErrorModel } from '../models/api_models';
import { getErrorMessage } from '../utils/errorHandling';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private apiService = inject(ApiService);
  private toastr = inject(ToastrService);
  private translocoService = inject(TranslocoService);
  environment = environment;

  removeLocationValidation = signal<IValidationErrorModel[] | undefined>(undefined);
  isRemovingLocation = signal<boolean | undefined>(undefined);

  addLocationValidation = signal<IValidationErrorModel[] | undefined>(undefined);
  isAddingLocation = signal<boolean | undefined>(undefined);

  customLocationUpdateCounter = signal<number>(0);
  createdCustomLocationId = signal<number | undefined>(undefined);
  isAddingCustomLocation = signal<boolean | undefined>(undefined);
  removeCustomLocationValidation = signal<IValidationErrorModel[] | undefined>(undefined);
  isRemovingCustomLocation = signal<boolean | undefined>(undefined);

  locations = signal<ILocationModel[] | undefined>(undefined);
  isLoadingLocations = signal<boolean>(false);
  createdAddressId = signal<number | undefined>(undefined);

  removeLocation(accessCode: string, locationId: number) {
    this.isRemovingLocation.set(true);
    this.apiService
      .delete<
        IValidationErrorModel[]
      >(`/api/v1/Location/remove-location/${accessCode}/${locationId}`)
      .subscribe({
        next: details => {
          this.removeLocationValidation.set(details);
        },
        error: (errorResponse: any) => {
          this.toastr.error(
            getErrorMessage(
              errorResponse,
              this.translocoService,
              errorResponse.key,
              'Removing of location failed',
            ),
          );
        },
        complete: () => {
          this.isRemovingLocation.set(false);
        },
      });
  }

  addLocation(accessCode: string, locationId: number) {
    this.isAddingLocation.set(true);
    this.apiService
      .put<IValidationErrorModel[]>(`/api/v1/Location/add-location/${accessCode}/${locationId}`)
      .subscribe({
        next: details => {
          this.addLocationValidation.set(details);
        },
        error: (errorResponse: any) => {
          this.toastr.error(
            getErrorMessage(
              errorResponse,
              this.translocoService,
              errorResponse.key,
              'Adding of location failed',
            ),
          );
        },
        complete: () => {
          this.isAddingLocation.set(false);
        },
      });
  }

  addAddress(accessCode: string, adrdess: ILocationModel) {
    this.isAddingLocation.set(true);
    this.apiService
      .put<IValidationErrorModel[]>(`/api/v1/Location/add-address/${accessCode}`, {
        body: {
          city: adrdess.additionalInformation,
          latitude: adrdess.coordinates?.latitude,
          longitude: adrdess.coordinates?.longitude,
          street: adrdess.name,
          zipCode: adrdess.description,
        },
      })
      .subscribe({
        next: (details: IValidationErrorModel[] | number) => {
          const adrdessId = details as number;
          if (adrdessId) {
            this.createdAddressId.set(adrdessId);
          } else {
            this.addLocationValidation.set(details as IValidationErrorModel[]);
          }
        },
        error: (errorResponse: any) => {
          this.toastr.error(
            getErrorMessage(
              errorResponse,
              this.translocoService,
              errorResponse.key,
              'Adding of location failed',
            ),
          );
        },
        complete: () => {
          this.isAddingLocation.set(false);
        },
      });
  }

  removeAddress(accessCode: string, addressId: number) {
    this.isRemovingLocation.set(true);
    this.apiService
      .delete<IValidationErrorModel[]>(`/api/v1/Location/remove-address/${accessCode}/${addressId}`)
      .subscribe({
        next: details => {
          this.removeLocationValidation.set(details);
        },
        error: (errorResponse: any) => {
          this.toastr.error(
            getErrorMessage(
              errorResponse,
              this.translocoService,
              errorResponse.key,
              'Removing of location failed',
            ),
          );
        },
        complete: () => {
          this.isRemovingLocation.set(false);
        },
      });
  }

  getLocations(
    accessCode: string,
    locationTypeId: number | null | undefined,
    name: string | null | undefined,
  ) {
    let url = `/api/v1/Location/load-list/${accessCode}?`;
    if (locationTypeId !== undefined && locationTypeId !== null)
      url += 'locationTypeId=' + encodeURIComponent('' + locationTypeId) + '&';
    if (name !== undefined && name !== null) url += 'name=' + encodeURIComponent('' + name) + '&';

    url = url.replace(/[?&]$/, '');

    this.isLoadingLocations.set(true);
    this.apiService.get<ILocationModel[]>(url).subscribe({
      next: details => {
        this.locations.set(details);
      },
      error: (errorResponse: any) => {
        this.toastr.error(
          getErrorMessage(
            errorResponse,
            this.translocoService,
            errorResponse.key,
            'Fetching of locations failed',
          ),
        );
      },
      complete: () => {
        this.isLoadingLocations.set(false);
      },
    });
  }

  addOrUpdateCustomLocation(accessCode: string, location: ICustomLocationModel) {
    this.isAddingCustomLocation.set(true);
    this.apiService
      .post<number>(`/api/v1/CustomLocation/add-or-update/${accessCode}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: location,
      })
      .subscribe({
        next: (customLocationId: number) => {
          let counter = this.customLocationUpdateCounter();
          counter++;
          this.createdCustomLocationId.set(customLocationId);
          this.customLocationUpdateCounter.set(counter);
        },
        error: (errorResponse: any) => {
          this.toastr.error(
            getErrorMessage(
              errorResponse,
              this.translocoService,
              errorResponse.key,
              'Add or update of custom location failed',
            ),
          );
        },
        complete: () => {
          this.isAddingCustomLocation.set(false);
        },
      });
  }

  removeCustomLocation(accessCode: string, customLocationId: number) {
    this.isRemovingCustomLocation.set(true);
    this.apiService
      .delete<
        IValidationErrorModel[]
      >(`/api/v1/CustomLocation/remove/${accessCode}/${customLocationId}`)
      .subscribe({
        next: details => {
          this.removeCustomLocationValidation.set(details);
        },
        error: (errorResponse: any) => {
          this.toastr.error(
            getErrorMessage(
              errorResponse,
              this.translocoService,
              errorResponse.key,
              'Removing of custom location failed',
            ),
          );
        },
        complete: () => {
          this.isRemovingCustomLocation.set(false);
        },
      });
  }
}
