<div class="relative">
  <input
    type="text"
    [(ngModel)]="searchValue"
    [placeholder]="placeholder()"
    class="w-full h-10 pr-12 text-left border rounded border-border focus:border-text-secondary focus:ring-0"
  />
  <ng-container
    [ngTemplateOutlet]="clear"
    [ngTemplateOutletContext]="{ $implicit: 'right-9' }"
    class="right-9"
  />

  <div class="absolute text-gray-500 transform -translate-y-1/2 right-3 top-1/2">
    <div class="w-5 h-5 text-text-secondary"><div [innerHTML]="getSvg()['search']"></div></div>
  </div>
</div>

<ng-template #clear let-additionalClasses>
  @if (searchValue()) {
    <button
      class="group-hover:block absolute top-1.5 text-text-secondary text-lg cursor-pointer"
      [class]="additionalClasses"
      (click)="searchValue.set(undefined); $event.stopPropagation()"
    >
      &times;
    </button>
  }
</ng-template>
