import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';

import { ILocationModel } from '../../../models/api_models';
import createGeoIsLinkLink from '../../../utils/geoIsLink';
import createNavigationLink from '../../../utils/navigationLink';

@Component({
  selector: 'app-location-list',
  imports: [TranslocoPipe],
  templateUrl: './location-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationListComponent {
  locations = input.required<ILocationModel[]>();

  createNavigationLink = createNavigationLink;
  createGeoIsLinkLink = createGeoIsLinkLink;

  openNavigationLink = (lat: number, lng: number): void => {
    window.open(createNavigationLink(lat, lng), '_blank');
  };
}
