<div class="flex flex-col items-stretch min-h-screen">
  <axpo-header
    [title]="'core.incidentManagement' | transloco"
    [breadcrumbDeactivated]="true"
    [user]="this.authnService.user()"
    [availableLanguages]="availableLanguages"
    [(selectedLanguageCode)]="activeLanguage"
    [isOffline]="offlineService.offline()"
    (logout)="logout()"
    [showLogout]="showLogout()"
    [customActions]="customActions()"
    [backgroundColorClass]="'bg-background-header'"
    [showUsername]="true"
  />

  <main class="grow shrink-0 text-text-primary">
    <router-outlet />
  </main>
  @if (swService.showNewSwVersionPrompt()) {
    <axpo-dialog
      [title]="'core.newSwVersionTitle' | transloco"
      [confirmText]="'core.ok' | transloco"
      [processBeforeClose]="true"
      (takenAction)="reloadApp($event)"
      icon="warning"
      textAlign="left"
    >
      <p>{{ 'core.newSwVersionRefresh' | transloco }}</p>
    </axpo-dialog>
  }
  <axpo-footer
    [footerLinks]="footerLinks()"
    [version]="version"
    [backgroundColorClass]="'bg-background-footer'"
  />
</div>
