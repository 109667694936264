import { animate, style, transition, trigger } from '@angular/animations';
import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
  output,
  signal,
  untracked,
} from '@angular/core';
import { type VariantProps, cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import { ClickOutsideDirective } from '../../shared/directives/click-outside.directive';
import { DropdownPositionDirective } from '../../shared/directives/dropdown-position.directive';

export const axpoDropdownVariants = cva(['flex', 'justify-center', 'items-center'], {
  variants: {
    variant: {
      default: [
        'gap-1',
        'px-4',
        'py-2',
        'rounded-full',
        'text-center',
        'text-sm',
        'font-semibold',
        'leading-4',
      ],
      icon: ['p-[14px]'],
    },
    type: {
      filled: [
        'text-white',
        'bg-primary',
        'hover:bg-interaction-red',
        'disabled:bg-disabled',
        'disabled:text-text-primary',
      ],
      outlined: [
        'border-[1.25px]',
        'bg-transparent',
        'text-text-link',
        'border-primary',
        'hover:border-interaction-red',
        'hover:text-interaction-red',
        'disabled:border-disabled',
        'disabled:text-text-secondary',
      ],
      ghost: [
        'bg-transparent',
        'px-2',
        'border-[1.25px]',
        'border-transparent',
        'text-text-link',
        'hover:text-interaction-red',
        'disabled:text-text-secondary',
      ],
    },
    style: {
      primary: [],
      secondary: [],
    },
    size: {
      default: [],
      medium: ['px-6', 'py-3'],
    },
  },
  defaultVariants: {
    variant: 'default',
    type: 'filled',
    style: 'primary',
    size: 'default',
  },
  compoundVariants: [
    {
      variant: 'default',
      type: 'filled',
      style: 'secondary',
      class: [
        'text-white',
        'bg-text-primary',
        'hover:bg-text-highlight',
        'disabled:bg-surface-1',
        'disabled:text-text-primary',
      ],
    },
    {
      variant: 'default',
      type: 'outlined',
      style: 'secondary',
      class: [
        'text-text-primary',
        'border-border',
        'hover:border-text-primary',
        'hover:text-text-highlight',
        'disabled:border-border',
        'disabled:text-text-secondary',
      ],
    },
    {
      type: 'ghost',
      style: 'secondary',
      class: ['text-text-primary', 'hover:text-text-highlight', 'disabled:text-text-secondary'],
    },
  ],
});
export type AxpoDropdownVariants = VariantProps<typeof axpoDropdownVariants>;

@Component({
  selector: 'axpo-dropdown',
  imports: [NgClass, ClickOutsideDirective, DropdownPositionDirective],
  templateUrl: './axpo-dropdown.component.html',
  styleUrl: './axpo-dropdown.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('transformOpacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
      ]),
    ]),
  ],
})
export class AxpoDropdownComponent {
  items = input.required<IDropdownItem[]>();
  style = input<AxpoDropdownVariants['style']>('primary');
  type = input<AxpoDropdownVariants['type']>('ghost');
  size = input<AxpoDropdownVariants['size']>('default');
  defaultText = input<string | undefined>(undefined);
  showSelectedValue = input<boolean>(false);
  showScrollbar = input<boolean>(false);
  disabled = input<boolean>(false);
  preSelectedItemValue = input<IDropdownItem['value']>();
  selectedItemValue = signal<IDropdownItem['value'] | undefined>(undefined);
  select = output<IDropdownItem['value']>();
  isMenuOpen = signal<boolean>(false);

  computedClass = computed(() => {
    return twMerge(
      axpoDropdownVariants({
        variant: this.menuText() ? 'default' : 'icon',
        type: this.type(),
        style: this.style(),
        size: this.size(),
      }),
    );
  });

  menuText = computed(() => {
    if (this.showSelectedValue() && this.selectedItemValue()) {
      return this.items().find(item => item.value === this.selectedItemValue())?.label;
    }
    return this.defaultText();
  });

  _ = effect(() => {
    const preSelectedItemValue = this.preSelectedItemValue();
    untracked(() => {
      this.selectedItemValue.set(preSelectedItemValue);
    });
  });

  selectValue = (value: string) => {
    this.selectedItemValue.set(value);
    this.select.emit(value);
    this.isMenuOpen.set(false);
  };
}

export interface IDropdownItem {
  value: string;
  label: string;
}
