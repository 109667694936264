<div class="relative inline-block text-left" (clickOutside)="closeMenus()">
  <div>
    <button
      type="button"
      class="flex items-end h-10 w-13"
      (click)="toggleMainMenu(); $event.stopPropagation()"
      tabindex="0"
    >
      @if (profileImage()) {
        <img class="w-10 h-10 rounded-full" [src]="profileImage()" alt="Profile image" />
      } @else {
        <div class="w-10 h-10"><div [innerHTML]="getSvg()['user']"></div></div>
      }
    </button>
  </div>

  @if (showMainMenu()) {
    <div
      @transformOpacityScale
      class="absolute right-0 z-10 mt-2 bg-white rounded-md shadow-lg min-w-32 ring-1 ring-black ring-opacity-5 focus:outline-none"
      role="menu"
      tabindex="-1"
    >
      @if (user()) {
        <div class="flex flex-col w-full px-4 py-2 border-b-2">
          @if (user()?.name) {
            <axpo-typography class="whitespace-nowrap">{{ user()?.name }}</axpo-typography>
          }
          <axpo-typography class="text-text-secondary" [variant]="'body-s'">
            {{ user()?.email }}
          </axpo-typography>
        </div>
      }
      <div class="py-1">
        @for (action of customActions(); track action; let index = $index) {
          <button
            class="flex items-center w-full px-4 py-2 text-sm text-left gap-x-2 whitespace-nowrap hover:cursor-pointer hover:bg-background-1"
            role="menuitem"
            (click)="clickOption(action, index); $event.stopPropagation()"
          >
            <div class="w-4 h-4"><div [innerHTML]="getSvg()[action.icon]"></div></div>
            <span class="text-text-primary">
              {{ action.label }}
            </span>
            @if (action.subOptions) {
              <div class="w-4 h-4"><div [innerHTML]="getSvg()['chevron-down']"></div></div>
            }
          </button>

          @if (showSubMenu() === index && action.subOptions) {
            <axpo-profile-submenu
              [items]="action.subOptions"
              (itemSelected)="clickSubOption(action.value, $event)"
            />
          }
        }
        @if (availableLanguages() && availableLanguages().length > 0) {
          <button
            class="flex items-center w-full px-4 py-2 text-sm text-left gap-x-2 whitespace-nowrap hover:cursor-pointer hover:bg-background-1"
            role="menuitem"
            (click)="toggleLanguageMenu(); $event.stopPropagation()"
          >
            <div class="w-4 h-4"><div [innerHTML]="getSvg()['translation']"></div></div>
            <span class="text-text-primary">{{ selectedLanguageLabel() }}</span>
            <div class="w-4 h-4"><div [innerHTML]="getSvg()['chevron-down']"></div></div>
          </button>
          @if (showLanguageMenu()) {
            <axpo-profile-submenu
              [items]="availableLanguages()"
              (itemSelected)="clickLanguage($event)"
            />
          }
        }
        @if (showLogout()) {
          <button
            class="flex w-full gap-2 px-4 py-2 text-sm text-left whitespace-nowrap hover:cursor-pointer hover:bg-background-1"
            role="menuitem"
            (click)="selectLogout(); $event.stopPropagation()"
          >
            <div class="w-4 h-4"><div [innerHTML]="getSvg()['logout']"></div></div>
            <span class="text-text-primary">Logout</span>
          </button>
        }
      </div>
    </div>
  }
</div>
