import { Signal, WritableSignal } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

enum ValidationError {
  'validation.required',
  'admin.invalidFormatError',
  'validation.minNumber',
  'validation.maxNumber',
  'validation.minLengthError',
  'admin.maxLengthError',
}

interface FieldConfig {
  key: string;
  errorMessageSignal: WritableSignal<string | undefined>;
  errorIdSignal: WritableSignal<number | undefined>;
}

type FormFieldRecord = Record<
  string,
  {
    errorMessage: Signal<string | undefined>;
    errorId: Signal<number | undefined>;
  }
>;

export class FormValidator {
  private fields: FormFieldRecord = {};

  constructor(
    configs: FieldConfig[],
    private transloco: TranslocoService,
  ) {
    configs.forEach(config => {
      this.fields[config.key] = {
        errorMessage: config.errorMessageSignal,
        errorId: config.errorIdSignal,
      };
    });
  }

  get formFields(): FormFieldRecord {
    return this.fields;
  }

  get isValid(): boolean {
    for (const formElement in this.fields) {
      if (this.fields[formElement].errorId()) {
        return false;
      }
    }

    return true;
  }

  setError(key: string, errorId: number, errorMessage: string, replacement?: string): void {
    const field = this.fields[key];

    // only set custom error, when standard error isn't set already
    const currentErrorId = field.errorId();
    if (errorId === -1 && currentErrorId && currentErrorId > 0) {
      return;
    }

    if (errorId > 0) {
      if (replacement) {
        errorMessage = this.transloco.translate(ValidationError[errorId - 1], { value: replacement });
      } else {
        errorMessage = this.transloco.translate(ValidationError[errorId - 1]);
      }
    }

    if (field) {
      (field.errorId as WritableSignal<number>).set(errorId);
      (field.errorMessage as WritableSignal<string>).set(errorMessage);
    } else {
      console.error(`Field with key "${key}" not found.`);
    }
  }

  clearError(key: string): void {
    const field = this.fields[key];
    if (field) {
      (field.errorId as WritableSignal<number | undefined>).set(undefined);
      (field.errorMessage as WritableSignal<string | undefined>).set(undefined);
    }
  }

  getField(key: string) {
    return this.fields[key];
  }
}
