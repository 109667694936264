import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
  model,
  output,
  untracked,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SvgService } from '../services/svg.service';

@Component({
  imports: [FormsModule, NgTemplateOutlet],
  selector: 'axpo-search',
  templateUrl: './axpo-search.component.html',
  styleUrl: './axpo-search.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AxpoSearchComponent {
  private svgService = inject(SvgService);
  placeholder = input<string>('');
  debounceTime = input<number>(500);
  debounce = input<boolean>(false);
  searchValue = model<string | undefined>(undefined);
  debounceValue = output<string | undefined>();

  onInit = effect(() => {
    this.svgService.loadSvg(['search']);
  });

  getSvg = this.svgService.svgMap;

  _debounce = effect(onCleanup => {
    const value = this.searchValue();
    untracked(() => {
      const timeout = setTimeout(() => this.debounceValue.emit(value), 500);
      onCleanup(() => clearTimeout(timeout));
    });
  });
}
