import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  model,
  output,
  signal,
  untracked,
} from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';

import { AxpoButtonComponent } from '../../core/axpo-button/axpo-button.component';
import { AxpoTypographyComponent } from '../../core/axpo-typography/axpo-typography.component';
import { SvgService } from '../../core/services/svg.service';
import { AuthnService } from '../../shared/services/authn.service';
import { ITileMode } from '../../shared/utils/tileStates';

export type availableButtons = 'confirm' | 'cancel';
export interface ITileButtonAction {
  action: string | undefined;
  key: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tile-component',
  templateUrl: './tile.component.html',
  imports: [AxpoTypographyComponent, AxpoButtonComponent, TranslocoPipe, NgClass],
})
export class TileComponent {
  authnService = inject(AuthnService);
  title = input.required<string>();
  mode = model.required<ITileMode>();
  takenAction = output<string | undefined>();
  controlButtons = input<availableButtons[]>([]);
  disabled = input<boolean>(false);
  editMode = input<'edit' | 'create'>('edit');
  bgClass = input<string | undefined>(undefined);
  isPublicEditableTile = input<boolean>(false);
  svgService = inject(SvgService);
  getSvg = this.svgService.svgMap;
  isLoggedIn = signal<boolean>(false);

  onInit = effect(() => {
    this.svgService.loadSvg(['pen']);
  });

  user = effect(() => {
    const user = this.authnService.user();
    untracked(() => {
      this.isLoggedIn.set(!!user);
    });
  });

  hasConfirmButton = computed(() => {
    return this.controlButtons().indexOf('confirm') > -1;
  });
  hasCancelButton = computed(() => {
    return this.controlButtons().indexOf('cancel') > -1;
  });

  editTile() {
    this.mode.set('edit');
  }

  viewTile() {
    this.mode.set('view');
  }

  onSave(action: availableButtons) {
    if (action === 'confirm') {
      this.takenAction.emit(Date.now().toString());
    } else {
      this.takenAction.emit(undefined);
      this.viewTile();
    }
  }
}
