import { WritableSignal, signal } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { FormDebounceService } from '../services/form-debounce.service';

enum ValidationError {
  'validation.required',
  'admin.invalidFormatError',
  'validation.minNumber',
  'validation.maxNumber',
  'validation.minLengthError',
  'admin.maxLengthError',
}

interface FieldConfig {
  key: string;
  errorMessageSignal: WritableSignal<string | undefined>;
  errorIdSignal: WritableSignal<number | undefined>;
}

type FormFieldRecord = Record<
  string,
  {
    errorMessage: WritableSignal<string | undefined>;
    errorId: WritableSignal<number | undefined>;
  }
>;

type HiddenErrorRecord = Record<
  string,
  string
>;

export class FormValidator {
  private fields: FormFieldRecord = {};
  private hiddenErrors: HiddenErrorRecord = {};
  public showErrorOnLoad = signal<boolean>(false);
  public id = signal<string | undefined>(undefined);

  constructor(
    configs: FieldConfig[],
    private transloco: TranslocoService,
    private debounceService: FormDebounceService,
    id?: string,
    errorOnLoad?: boolean
  ) {
    if (id) {
      this.id.set(id);
    }
    
    configs.forEach(config => {
      this.fields[config.key] = {
        errorMessage: config.errorMessageSignal,
        errorId: config.errorIdSignal
      };
    });
    if (errorOnLoad) {
      this.showErrorOnLoad.set(errorOnLoad);
    }
  }

  get formFields(): FormFieldRecord {
    return this.fields;
  }

  get isValid(): boolean {
    const id = this.id();
    
    if (id && this.debounceService.isActive(id)) {
      return false;
    }

    for (const formElement in this.fields) {
      if (this.fields[formElement].errorId()) {
        return false;
      }
    }

    return true;
  }

  setError(key: string, errorId: number, errorMessage: string, replacement?: string): void {
    const field = this.fields[key];

    // only set custom error, when standard error isn't set already
    const currentErrorId = field.errorId();
    if (errorId === -1 && currentErrorId && currentErrorId > 0) {
      return;
    }

    if (errorId > 0) {
      if (replacement) {
        errorMessage = this.transloco.translate(ValidationError[errorId - 1], { value: replacement });
      } else {
        errorMessage = this.transloco.translate(ValidationError[errorId - 1]);
      }
    }

    if (field) {
      (field.errorId as WritableSignal<number>).set(errorId);
      if (errorId === -1 && this.showErrorOnLoad()) {
        (field.errorMessage as WritableSignal<string>).set(errorMessage);
      } else if (errorId === -1 && !this.showErrorOnLoad()) {
        this.hiddenErrors[key] = errorMessage;
      }

      if (errorId > -1) {
        (field.errorMessage as WritableSignal<string>).set(errorMessage);
      }
    } else {
      console.error(`Field with key "${key}" not found.`);
    }
  }

  clearError(key: string): void {
    const field = this.fields[key];
    if (field) {
      (field.errorId as WritableSignal<number | undefined>).set(undefined);
      (field.errorMessage as WritableSignal<string | undefined>).set(undefined);
    }
    delete this.hiddenErrors[key];
  }

  getField(key: string) {
    return this.fields[key];
  }

  showErrors() {
    for (const key in this.hiddenErrors) {
      const fieldErrorId = this.fields[key].errorId();
      if (fieldErrorId && fieldErrorId > -1) {
        continue;
      }

      const value = this.hiddenErrors[key];
      this.fields[key].errorMessage.set(value);
    }
    this.showErrorOnLoad.set(true);
  }

  validateAfterSubmit() {
    return !this.showErrorOnLoad();
  }

  checkRequired(key: string, value: string | undefined | number | boolean) {
    if (value === '' || value === undefined) {
      this.setError(key, -1, this.transloco.translate('validation.required'));
    } else {
      const currentError = this.getField(key);
      if (currentError?.errorId() === -1) {
        this.clearError(key);
      }
    }
  }
}
