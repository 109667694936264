import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, inject, signal } from "@angular/core";
import { Router } from "@angular/router";
import { TranslocoService } from "@jsverse/transloco";
import { ToastrService } from "ngx-toastr";

import { ApiService, BackendType } from './api.service';
import { Circle, IUpdateCircle, IUpdateUser, IUserState, User } from '../models/api_models';


@Injectable({
  providedIn: 'root',
})

export class CircleService {
  private toastr = inject(ToastrService);
  private router = inject(Router);
  apiService = inject(ApiService);
  translocoService = inject(TranslocoService);
  circles = signal<Circle[] | undefined>(undefined);
  circle = signal<Circle | undefined>(undefined);
  circlesLoading = signal<boolean>(false);
  addingCircles = signal<boolean>(false);
  loadingCircles = signal<boolean>(false);
  loadingCircle = signal<boolean>(false);
  loadingCircleSummary = signal<boolean>(false);
  updatingCircle = signal<boolean>(false);
  updatedCircle = signal<Circle | undefined>(undefined);
  searchingUser = signal<boolean>(false);
  searchedUsers = signal<User[] | undefined>(undefined);
  updatingUserState = signal<number | undefined>(undefined);
  userDeleted = signal<IUpdateUser[] | undefined>(undefined);
  circleSummary = signal<any [] | undefined>(undefined);
  loadingUserInfo = signal<boolean>(false);
  userInfo = signal<any>(undefined);

  private handleError(error: HttpErrorResponse) {
    if (error.status === 401 || error.status === 403) {
      this.router.navigate(['unauthorized']);
    } else if (error.status === 404) {
      this.router.navigate(['404']);
    } else {
      this.toastr.error(error.error?.detail);
    }
  }

  // set onCall and isOnSite property depending on the information on circle level for isOnSite and the information on organization level for onCall
  private addMetaInfoToUsers(circles: Circle[]) {
    for (const circle of circles) {
      circle.onSite = {
        id: circle.id,
        name: this.translocoService.translate('switchboard.officePhone'),
        users: [{
          id: 0,
          first_name: this.translocoService.translate('switchboard.officePhone'),
          last_name: '',
          role: this.translocoService.translate('switchboard.standardPhone'),
          mobile: circle.mainNumber,
          onCall: false,
          isOnSite: !circle.userMainNumber,
          active: true,
        }],
        aad_groups: [],
      };

      const isOnSiteUserId = (circle.userMainNumber) ? circle.userMainNumber.id : undefined;
      const organizations = circle.organizations;

      if (organizations) {
        for (const organization of organizations) {
          const onCallUserId = organization.userOnCall?.id;
          if (onCallUserId) {
            organization.subgroups.forEach(subgroup => {
              let aadGroups = null;
              if (subgroup.aad_groups && subgroup.aad_groups?.length > 0) {
                aadGroups = subgroup.aad_groups;
              }
              if (subgroup.users) {
                subgroup.users.forEach(user => {
                  user.onCall = user.id === onCallUserId;
                  user.isOnSite = user.id === isOnSiteUserId;
                  if (aadGroups && user.automaticallyAdded) {
                    user.last_name += ' ' + aadGroups[0].name;
                  }
                  
                });
                subgroup.users.sort((a, b) =>
                  a.last_name.localeCompare(b.last_name) || a.first_name.localeCompare(b.first_name)
                )
              }
            });
          }
        }
      }
    }
    return circles;
  }

  updateCircle(circleId: number, circle: IUpdateCircle) {
    this.updatingCircle.set(true);
    this.apiService
      .put<Circle>(`api/v1/circle/update/${circleId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: circle,
      }, BackendType.SB)
      .subscribe({
        next: (circleResponse) => {
          this.updatedCircle.set(circleResponse);
          if (circle.remove_users && circle.remove_users?.length > 0) {
            this.userDeleted.set(circle.remove_users);
          }
          this.getCircles();
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
        complete: () => {
          this.updatingCircle.set(false);
        },
      });
  }

  getCircles() {
    this.loadingCircles.set(true);
    this.apiService
      .get<Circle[]>(`api/v1/circle`, {
        headers: {
          'Content-Type': 'application/json',
        }
      }, BackendType.SB)
      .subscribe({
        next: circles => {
          this.circles.set(this.addMetaInfoToUsers(circles));
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
        complete: () => {
          this.loadingCircles.set(false);
        },
      });
  }

  getCircle(id: number) {
    this.loadingCircle.set(true);
    this.apiService
      .get<Circle>(`api/v1/circle/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        }
      }, BackendType.SB)
      .subscribe({
        next: circle => {
          this.circle.set(this.addMetaInfoToUsers([circle])[0]);
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
        complete: () => {
          this.loadingCircle.set(false);
        },
      });
  }

  getCircleSummary() {
    this.loadingCircleSummary.set(true);
    this.apiService
      .get<any[]>(`/api/v1/circle/summary`, {
        headers: {
          'Content-Type': 'application/json',
        }
      }, BackendType.SB)
      .subscribe({
        next: circles => {
          this.circleSummary.set(circles);
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
        complete: () => {
          this.loadingCircleSummary.set(false);
        },
      });
  }

  searchUser(searchTerm: string) {
    this.searchingUser.set(true);
    const searchEncoded = encodeURIComponent(searchTerm);
    this.apiService
      .get<User[]>(`api/v1/user/?searchTerms=${searchEncoded}`, {
        headers: {
          'Content-Type': 'application/json',
        }
      }, BackendType.SB)
      .subscribe({
        next: users => {
          this.searchedUsers.set(users);
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
        complete: () => {
          this.searchingUser.set(false);
        },
      });
  }

  setUserState(userState: IUserState[]) {
    this.apiService
      .post<any>(`/api/v1/subgroup/set_users_state`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: userState,
      }, BackendType.SB)
      .subscribe({
        next: () => {
          this.getCircles();
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
        complete: () => {
          this.updatingUserState.set(Date.now());
        },
      });
  }

  getUserInfo() {
    this.loadingUserInfo.set(true);
    this.apiService
      .get<any[]>(`/api/v1/user/auth-user`, {
        headers: {
          'Content-Type': 'application/json',
        }
      }, BackendType.SB)
      .subscribe({
        next: info => {
          this.userInfo.set(info);
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
        complete: () => {
          this.loadingUserInfo.set(false);
        },
    });
  }
}