<div class="flex-1 space-y-4 mb-8 p-4 rounded-lg shadow-lg" [ngClass]="bgClass()">
  <div class="flex items-center justify-between">
    <axpo-typography [variant]="'title-m'">
      {{ title() }}
    </axpo-typography>
    @if (mode() === 'view' && isLoggedIn()) {
      <div role="button" (click)="editTile()" tabindex="0" aria-hidden="true">
        <div class="w-6 h-6" [innerHTML]="getSvg()['pen']"></div>
      </div>
    }
    @if (mode() === 'view' && !isLoggedIn() && isPublicEditableTile()) {
      <div role="button" (click)="editTile()" tabindex="0" aria-hidden="true">
        <div class="w-6 h-6" [innerHTML]="getSvg()['pen']"></div>
      </div>
    }
    @if (mode() === 'edit') {
      <div role="button" (click)="viewTile()" tabindex="0" aria-hidden="true">
        <svg
          version="1.2"
          id="Ebene_1"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          overflow="visible"
          xml:space="preserve"
          width="24"
          height="24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:svg="http://www.w3.org/2000/svg"
        >
          <defs id="defs15" />
          <g
            id="g10"
            transform="matrix(0.81300813,0,0,0.81300813,-7.625203,-7.026829)"
            style="stroke-width: 1.23"
          >
            <path
              d="M 24.4,32.1 C 17.7,32.1 13,26.2 12.8,25.9 l -0.1,-0.2 0.1,-0.2 c 0,-0.1 3.8,-9.5 11.8,-9.6 1.3,0 2.5,0.2 3.6,0.6 0.2,0.1 0.3,0.3 0.2,0.5 -0.1,0.2 -0.3,0.3 -0.5,0.2 -1,-0.4 -2.2,-0.6 -3.4,-0.6 -6.9,0.1 -10.5,7.6 -11.1,9 0.9,1 5.9,6.5 12.4,5.6 7.7,-1.1 10.3,-5.7 10.7,-6.6 -0.5,-0.8 -2.7,-4.2 -6.2,-6.3 -0.2,-0.1 -0.2,-0.3 -0.1,-0.5 0.1,-0.2 0.3,-0.2 0.5,-0.1 4,2.5 6.4,6.6 6.5,6.7 l 0.1,0.2 -0.1,0.2 c -0.1,0.2 -2.4,5.9 -11.4,7.2 -0.3,0 -0.9,0.1 -1.4,0.1 z"
              id="path2"
              style="stroke-width: 1.5129"
            />
            <path
              fill="none"
              stroke-width="0.9225"
              stroke-linecap="round"
              stroke-miterlimit="10"
              d="M 30.7,18"
              id="path4"
            />
            <path
              d="m 24.7,29 c -2.7,0 -5,-2.2 -5,-5 0,-2.8 2.2,-5 5,-5 2.8,0 5,2.2 5,5 0,2.8 -2.2,5 -5,5 z m 0,-9.2 c -2.3,0 -4.2,1.9 -4.2,4.2 0,2.3 1.9,4.2 4.2,4.2 2.3,0 4.2,-1.9 4.2,-4.2 0.1,-2.3 -1.8,-4.2 -4.2,-4.2 z"
              id="path6"
              style="stroke-width: 1.5129"
            />
            <path
              d="m 24.8,26.7 c -1.5,0 -2.8,-1.2 -2.8,-2.8 0,-1.6 1.2,-2.8 2.8,-2.8 1.6,0 2.8,1.2 2.8,2.8 0,1.6 -1.2,2.8 -2.8,2.8 z m 0,-4.8 c -1.1,0 -2,0.9 -2,2 0,1.1 0.9,2 2,2 1.1,0 2,-0.9 2,-2 0.1,-1.1 -0.9,-2 -2,-2 z"
              id="path8"
              style="stroke-width: 1.5129"
            />
          </g>
        </svg>
      </div>
    }
  </div>
  <ng-content></ng-content>
  @if (mode() === 'edit' && controlButtons().length > 0) {
    <div class="text-right pt-4 border-t-2 border-border-soft-300 flex justify-end">
      @if (hasCancelButton()) {
        <div class="w-22">
          <axpo-button (click)="onSave('cancel')" [type]="'ghost'" [style]="'secondary'">
            {{ 'core.cancel' | transloco }}
          </axpo-button>
        </div>
      }

      @if (hasConfirmButton()) {
        <div class="w-22">
          <axpo-button [disabled]="disabled()" (click)="onSave('confirm')">
            {{ 'core.save' | transloco }}
          </axpo-button>
        </div>
      }
    </div>
  }
</div>
