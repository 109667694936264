// Rules
export const isEmpty = (value: any) => !value || '';
export const isRequired = (value: any) => !!value;
export const hasMin5 = (value: any) => (value || '').length >= 5;
export const hasMax200 = (value: any) => (value || '').length <= 200;
export const hasMax100 = (value: any) => (value || '').length <= 100;
export const hasMax50 = (value: any) => (value || '').length <= 50;
export const isEmail = (value: any) =>
  /^(?=^.{1,100}$)([a-zA-Z0-9_\-.]+@[a-zA-Z0-9_\-.]+\.[a-zA-Z]{2,5})$/.test(value || '');
export const isPhone = (value: any) => /^\+([1-9][0-9]{10,13})$/.test(value || '');

// Combine some rules
export const hasBetween5And100 = (value: any) => hasMin5(value) && hasMax100(value);
export const hasBetween5And200 = (value: any) => hasMin5(value) && hasMax200(value);
