import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'axpo-logo',
  standalone: true,
  imports: [],
  templateUrl: './axpo-logo.component.html',
  styleUrl: './axpo-logo.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AxpoLogoComponent {}
