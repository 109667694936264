import { IKeyValueModel } from '../models/api_models';

export const getTitle = (
  voltages?: IKeyValueModel[],
  alertName?: string,
  impact?: IKeyValueModel,
) => {
  const joinable = [];
  if (voltages) {
    const voltageString = voltages.map(vol => vol.value).join('/');
    joinable.push(voltageString);
  }
  if (alertName) {
    joinable.push(alertName);
  }

  if (impact) {
    joinable.push(impact.value);
  }

  return joinable.join(' - ');
};
