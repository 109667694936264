<div class="flex flex-col space-y-4 pt-4">
  <div>
    <axpo-typography [variant]="'body-m'" class="text-text-secondary">
      {{ 'home.table.title' | transloco }}
    </axpo-typography>
    <axpo-typography [variant]="'body-m'">{{ incidentTitle() }}</axpo-typography>

    <axpo-typography [variant]="'body-m'" class="text-text-secondary">
      {{ 'incidentDetail.creator' | transloco }}
    </axpo-typography>
    <axpo-typography [variant]="'body-m'">{{ creatorName() }}</axpo-typography>
  </div>
  <div>
    <axpo-typography [variant]="'body-m'" class="text-text-secondary">
      {{ 'incidentDetail.description' | transloco }}
    </axpo-typography>
    <axpo-typography [variant]="'body-m'">
      {{ description() }}
    </axpo-typography>
  </div>

  <div class="flex flex-col justify-between md:flex-row">
    <div class="w-1/2">
      <axpo-typography [variant]="'body-m'" class="text-text-secondary">
        {{ 'incidentDetail.alarmCategory' | transloco }}
      </axpo-typography>
      <axpo-typography [variant]="'body-m'">{{ categoryName() }}</axpo-typography>
    </div>

    <div class="w-1/2">
      <axpo-typography [variant]="'body-m'" class="text-text-secondary">
        {{ 'home.state' | transloco }}
      </axpo-typography>
      <axpo-typography [variant]="'body-m'">
        {{ 'home.filterState.' + incidentStateName() | transloco }}
      </axpo-typography>
    </div>
  </div>

  <div class="flex flex-col justify-between md:flex-row">
    <div class="w-1/2">
      <axpo-typography [variant]="'body-m'" class="text-text-secondary">
        {{ 'incidentDetail.impacts' | transloco }}
      </axpo-typography>
      <axpo-typography [variant]="'body-m'">
        {{ 'incidentDetail.' + impactName() | transloco }}
      </axpo-typography>
    </div>

    <div class="w-1/2">
      <axpo-typography [variant]="'body-m'" class="text-text-secondary">
        {{ 'incidentDetail.faults' | transloco }}
      </axpo-typography>
      <axpo-typography [variant]="'body-m'">
        {{ 'core.' + hasInterruptions() | transloco }}
      </axpo-typography>
    </div>
  </div>

  <div>
    <axpo-typography [variant]="'body-m'" class="text-text-secondary">
      {{ 'incidentDetail.impact' | transloco }}
    </axpo-typography>
    <axpo-typography [variant]="'body-m'">
      {{ faultEffectName() }}
    </axpo-typography>
  </div>

  <div class="flex flex-col justify-between md:flex-row">
    <div class="w-1/2">
      <axpo-typography [variant]="'body-m'" class="text-text-secondary">
        {{ 'incidentDetail.faultDate' | transloco }}
      </axpo-typography>
      <axpo-typography [variant]="'body-m'">{{ timeStamp() }}</axpo-typography>
    </div>

    <div class="w-1/2">
      <axpo-typography [variant]="'body-m'" class="text-text-secondary">
        {{ 'incidentDetail.faultFixedDate' | transloco }}
      </axpo-typography>
      <axpo-typography [variant]="'body-m'">{{ fixedTimeStamp() }}</axpo-typography>
    </div>
  </div>
</div>
