<section class="bg-white dark:bg-gray-900">
  @if (
    stepSignals()[activeStep()].saveTrigger() ||
    incidentService.isLoadingIncidentDetails() ||
    incidentService.updateInProgress() ||
    tenantService.isLoadingConfig()
  ) {
    <div class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div class="p-8 bg-white rounded shadow-lg">
        <axpo-spinner size="normal">{{ 'core.loading' | transloco }}</axpo-spinner>
      </div>
    </div>
  }
  <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
    <axpo-stepper
      [steps]="steps()"
      [activeStep]="activeStep()"
      (activeStepChange)="this.performNavigation($event)"
    ></axpo-stepper>
    @if (activeStep() === 0) {
      <div class="w-full">
        <app-alert
          [saveTrigger]="stepSignals()[0].saveTrigger()"
          (validationError)="validateForm($event, 0)"
          mode="edit"
        />
      </div>
    }
    @if (activeStep() === 1) {
      <app-location
        title="{{ 'incidentDetail.location' | transloco }}"
        mode="create"
        (validationError)="validateForm($event, 1)"
      ></app-location>
    }
    @if (activeStep() === 2) {
      <app-edit-details
        [saveTrigger]="stepSignals()[2].saveTrigger()"
        (validationError)="validateForm($event, 2)"
        mode="create"
      ></app-edit-details>
    }
    @if (activeStep() === 3) {
      <app-contacts mode="create" title=""></app-contacts>
    }
    @if (activeStep() === 4) {
      <app-edit-attachments
        [internalAttachments]="false"
        mode="create"
        title=""
      ></app-edit-attachments>
    }
    @if (activeStep() === 5) {
      <app-tile-component
        [(mode)]="tiles.all['alarm'].mode"
        title="{{ 'incidentDetail.alert' | transloco }}"
        (takenAction)="updateAction($event, 'alarm')"
        [controlButtons]="['confirm', 'cancel']"
        editMode="create"
      >
        <app-alert
          [mode]="tiles.all['alarm'].mode()"
          (saveCompleted)="saveCompleted('alarm')"
        ></app-alert>
      </app-tile-component>

      <app-tile-component
        [(mode)]="tiles.all['location'].mode"
        title="{{ 'incidentDetail.location' | transloco }}"
        editMode="create"
      >
        <app-location
          title="{{ 'incidentDetail.location' | transloco }}"
          [mode]="tiles.all['location'].mode()"
          (validationError)="validationChanged($event, 'location')"
        ></app-location>
      </app-tile-component>

      <app-tile-component
        [(mode)]="tiles.all['details'].mode"
        title="{{ 'incidentDetail.details' | transloco }}"
        [controlButtons]="['confirm', 'cancel']"
        (takenAction)="updateAction($event, 'details')"
        key="details"
        [disabled]="tiles.all['details'].disabled()"
        editMode="create"
      >
        @if (tiles.all['details'].mode() === 'edit') {
          <app-edit-details
            [mode]="tiles.all['details'].mode()"
            (saveCompleted)="saveCompleted('details')"
            (validationError)="validationChanged($event, 'details')"
          ></app-edit-details>
        } @else {
          <app-view-details></app-view-details>
        }
      </app-tile-component>

      <app-tile-component
        [(mode)]="tiles.all['contacts'].mode"
        title="{{ 'incidentDetail.contacts' | transloco }}"
        editMode="create"
      >
        <app-contacts
          [mode]="tiles.all['contacts'].mode()"
          title="{{ 'incidentDetail.contacts' | transloco }}"
        ></app-contacts>
      </app-tile-component>

      <app-tile-component
        [(mode)]="tiles.all['attachments'].mode"
        title="{{ 'incidentDetail.attachments' | transloco }}"
        editMode="create"
      >
        @if (tiles.all['attachments'].mode() === 'edit') {
          <app-edit-attachments [internalAttachments]="false"></app-edit-attachments>
        } @else {
          <app-view-attachments [internalAttachments]="false"></app-view-attachments>
        }
      </app-tile-component>
    }

    <div class="mt-16 flex justify-center gap-8">
      @if (activeStep() > 0) {
        <axpo-button (click)="performNavigation(this.activeStep() - 1)">
          {{ 'incidentCreate.back' | transloco }}
        </axpo-button>
      }

      @if (activeStep() < steps().length) {
        <axpo-button
          [disabled]="
            stepSignals()[activeStep()].isInvalid() || !!stepSignals()[activeStep()].saveTrigger()
          "
          (click)="saveStep()"
        >
          {{
            (activeStep() !== steps().length - 1
              ? 'incidentCreate.continue'
              : 'incidentCreate.save'
            ) | transloco
          }}
        </axpo-button>
      }
    </div>
  </div>
  @if (shareLink()) {
    <app-share-link
      (shareLinkClosed)="shareLinkClosed($event)"
      [(shareLink)]="shareLink"
      mode="create"
    ></app-share-link>
  }
</section>
