import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  output,
  signal,
  untracked,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

import { AxpoButtonComponent } from '../../../core/axpo-button/axpo-button.component';
import { AxpoDialogComponent } from '../../../core/axpo-dialog/axpo-dialog.component';
import {
  AxpoFormElementComponent,
  IFormError,
} from '../../../core/axpo-form-element/axpo-form-element.component';
import { AxpoSpinnerComponent } from '../../../core/axpo-spinner/axpo-spinner.component';
import { AxpoTypographyComponent } from '../../../core/axpo-typography/axpo-typography.component';
import { AxpoResizeService } from '../../../core/services/axpo-resize.service';
import { SvgService } from '../../../core/services/svg.service';
import { IContactModel } from '../../models/api_models';
import { IAutocompleteEntry } from '../../models/component_models';
import { ContactService } from '../../services/contact.service';
import { DetailsService } from '../../services/details.service';
import { FormDebounceService } from '../../services/form-debounce.service';
import { IncidentService } from '../../services/incident.service';
import { TenantService } from '../../services/tenant.service';
import { FormValidator } from '../../utils/formValidator';
import { ITileMode } from '../../utils/tileStates';
import { LocationAutocompleteComponent } from '../location/location-autocomplete/location-autocomplete.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  imports: [
    AxpoButtonComponent,
    AxpoFormElementComponent,
    TranslocoPipe,
    AxpoTypographyComponent,
    AxpoDialogComponent,
    AxpoSpinnerComponent,
    LocationAutocompleteComponent,
    NgClass
  ],
})
export class ContactsComponent {
  mode = input.required<ITileMode>();
  title = input.required<string>();
  activatedRoute = inject(ActivatedRoute);
  contactService = inject(ContactService);
  detailsService = inject(DetailsService);
  incidentService = inject(IncidentService);
  tenantService = inject(TenantService);
  translocoService = inject(TranslocoService);
  debounceService = inject(FormDebounceService);
  resizeService = inject(AxpoResizeService);
  svgService = inject(SvgService);
  getSvg = this.svgService.svgMap;
  takenAction = output<{ action: 'confirm' | 'cancel'; title: string }>();
  isBlocked = computed(() => this.mode() !== 'edit' && this.mode() !== 'create');
  contacts = signal<IContactModel[] | null | undefined>(undefined);
  filterContactSearch = signal<string>('');
  selectedSearchResults = signal<IAutocompleteEntry[]>([]);
  searchQueryResult = signal<IAutocompleteEntry[]>([]);
  addNewContactDialog = signal<boolean>(false);
  newContactDesignation = signal<string | undefined>(undefined);
  newContactName = signal<string | undefined>(undefined);
  newContactPhone = signal<string | undefined>(undefined);
  newContactMail = signal<string | undefined>(undefined);
  newContactIsExecuting = signal<boolean>(false);
  newContactProcessed = signal<boolean>(false);
  newContactId = signal<number | undefined>(0);
  newContactCallNotificationStateId = signal<number | null | undefined>(null);
  newCallNotificationStateName = signal<string | null | undefined>(null);
  editedContact = signal<IContactModel | undefined>(undefined);
  abortSaving = signal<boolean>(false);
  isDesktop = this.resizeService.isDesktop;
  showSearchBar = computed(() => {
    return !!(this.filterContactSearch() && this.filterContactSearch().length > 0);
  });
  isLoading = computed(() => {
    return this.contactService.isAddFromTemplate();
  });
  validationError = output<FormValidator>();
  formManager: FormValidator | undefined;
  noContacts = computed(() => {
    return !this.contacts() || this.contacts()?.length === 0;
  });

  onInit = effect(() => {
    this.svgService.loadSvg(['info-circle', 'helmet', 'check', 'phone', 'mail', 'user-add-filled', 'delete', 'play-outline', 'warning', 'horizontal-points']);
  });

  _updateSearchResult = effect(() => {
    const searchResults = this.detailsService.contactSearch().map((contact: IContactModel) => {
      return {
        id: contact.id ?? -1,
        title: contact.name,
        subtitle: contact.designation,
        value: contact.name,
      };
    });
    untracked(() => {
      this.searchQueryResult.set(searchResults);
    });
  });

  _updateIncidentDetails = effect(() => {
    const details = this.incidentService.incidentDetails();
    untracked(() => {
      this.contacts.set(details?.contacts);
      this.contactService.validationMessages.set([]);
    });
  });

  _updateContacts = effect(() => {
    const _d = this.contactService.addFromTemplateResult();
    untracked(() => {
      const accessCode = this.incidentService.incidentDetails()?.accessCode;
      const tenantId = this.tenantService.tenantId();
      if (accessCode && tenantId) {
        this.incidentService.getIncidentDetails(accessCode, tenantId);
      }

      this.searchQueryResult.set([]);
      this.filterContactSearch.set('');
    });
  });

  _contactsDeleted = effect(() => {
    const _dc = this.contactService.deleteResult();
    if (_dc) {
      untracked(() => {
        const accessCode = this.incidentService.incidentDetails()?.accessCode;
        const tenantId = this.tenantService.tenantId();
        if (accessCode && tenantId) {
          this.incidentService.getIncidentDetails(accessCode, tenantId);
        }
      });
    }
  });

  _closeDialogAfterAdding = effect(() => {
    const addLoading = this.contactService.isAdding();
    const updateLoading = this.contactService.isUpdating();
    const addedData = this.contactService.addResult();
    const updatedData = this.contactService.updateResult();

    untracked(() => {
      if ((!updateLoading && updatedData) || (!addLoading && addedData)) {
        if (!updateLoading && updatedData) {
          this.contactService.updateResult.set(undefined);
        }
        if (!addLoading && addedData) {
          this.contactService.addResult.set(undefined);
        }
        this.addNewContactDialog.set(false);
        const accessCode = this.incidentService.incidentDetails()?.accessCode;
        const tenantId = this.tenantService.tenantId();
        if (accessCode && tenantId) {
          this.incidentService.getIncidentDetails(accessCode, tenantId);
        }
      }
    });
  });

  searchValueChanged(searchterm: string | undefined) {
    if (searchterm && searchterm.length > 2) {
      this.detailsService.searchContactTemplates(searchterm as string);
    }
  }

  searchEntrySelected(entry: IAutocompleteEntry) {
    const accessCode = this.incidentService.incidentDetails()?.accessCode;
    if (accessCode && entry.id) {
      this.contactService.addFromTemplate(accessCode, parseInt(entry.id as string));
    }
  }

  addNewContact(editingContact: IContactModel | null) {
    this.searchQueryResult.set([]);
    this.filterContactSearch.set('');

    this.formManager = new FormValidator(
      [
        {
          key: 'designation',
          errorMessageSignal: signal(undefined),
          errorIdSignal: signal(undefined),
        },
        {
          key: 'name',
          errorMessageSignal: signal(undefined),
          errorIdSignal: signal(undefined),
        },
        {
          key: 'phone',
          errorMessageSignal: signal(undefined),
          errorIdSignal: signal(undefined),
        },
        {
          key: 'mail',
          errorMessageSignal: signal(undefined),
          errorIdSignal: signal(undefined),
        },
      ],
      this.translocoService,
      this.debounceService
    );

    if (editingContact) {
      this.editedContact.set(editingContact);
      this.newContactDesignation.set(editingContact.designation);
      this.newContactName.set(editingContact.name);
      this.newContactId.set(editingContact.id);
      this.newContactCallNotificationStateId.set(editingContact.callNotificationStateId);
      this.newCallNotificationStateName.set(editingContact.callNotificationStateName);

      if (editingContact.phone) this.newContactPhone.set(editingContact.phone);
      if (editingContact.mail) this.newContactMail.set(editingContact.mail);
      if (editingContact.isExecutor) this.newContactIsExecuting.set(editingContact.isExecutor);
    } else {
      this.validateForm();
    }

    this.addNewContactDialog.set(true);
  }

  validateForm() {
    this.formManager?.checkRequired('designation', this.newContactDesignation());
    this.formManager?.checkRequired('name', this.newContactName());
    this.formManager?.checkRequired('phone', this.newContactPhone());
    this.formManager?.checkRequired('mail', this.newContactMail());
  }

  contactDialogClosed(action: any, needsValidation?: boolean) {
    if (action === 'cancel') {
      this.addNewContactDialog.set(false);
      this.editedContact.set(undefined);
      this.newContactDesignation.set(undefined);
      this.newContactName.set(undefined);
      this.newContactId.set(undefined);
      this.newContactCallNotificationStateId.set(null);
      this.newCallNotificationStateName.set(null);

      this.newContactPhone.set(undefined);
      this.newContactMail.set(undefined);
      this.newContactIsExecuting.set(false);
      return;
    }

    this.abortSaving.set(false);
    if (!this.formManager?.showErrorOnLoad()) {
      this.formManager?.showErrors();
    }

    if (needsValidation && !this.formManager?.isValid) {
      this.abortSaving.set(true);
      return;
    }

    const accessCode = this.incidentService.incidentDetails()?.accessCode;
    if (action === 'confirm' && accessCode) {
      const contactUpdate: IContactModel = {
        designation: this.newContactDesignation() as string,
        name: this.newContactName() as string,
        mail: this.newContactMail(),
        phone: this.newContactPhone(),
        isExecutor: this.newContactIsExecuting(),
        id: this.newContactId() as number,
        callNotificationStateId: this.newContactCallNotificationStateId(),
        callNotificationStateName: this.newCallNotificationStateName()
      };
      if (this.editedContact()) {
        contactUpdate.id = this.editedContact()?.id;
      }
      this.contactService.addOrUpdate(accessCode, contactUpdate);
    }
  }

  deleteContact(contact: IContactModel) {
    const accessCode = this.incidentService.incidentDetails()?.accessCode;
    this.contactService.remove(accessCode as string, contact.id as number);
  }

  getContactDialogTitle() {
    if (this.editedContact()) {
      return 'incidentDetail.contactsData.editContact';
    } else {
      return 'incidentDetail.contactsData.addContact';
    }
  }

  toggleExecutor(contact: IContactModel) {
    this.editedContact.set(contact);
    this.newContactDesignation.set(contact.designation);
    this.newContactName.set(contact.name);

    if (contact.phone) this.newContactPhone.set(contact.phone);
    if (contact.mail) this.newContactMail.set(contact.mail);
    this.newContactIsExecuting.set(!contact.isExecutor);

    this.contactDialogClosed('confirm');
  }

  formValidation(formError: IFormError | undefined, key: string) {
    const field = this.formManager?.getField(key);
    const fieldErrorId = field?.errorId();
    if (formError) {
      this.formManager?.setError(
        formError.formId,
        formError.value,
        formError.message,
        formError.replacement,
      );
    } else if (fieldErrorId && fieldErrorId > -1){
      this.formManager?.clearError(key);
    }
    if (this.formManager) {
      this.validationError.emit(this.formManager);
    }
  }

  _clearDesignation = effect(() => {
    const designation = this.newContactDesignation();
    if (designation) {
      untracked(() => {
        this.formManager?.checkRequired('designation', designation);
      });
    }
  });

  _clearName = effect(() => {
    const name = this.newContactName();
    if (name) {
      untracked(() => {
        this.formManager?.checkRequired('name', name);
      });
    }
  });

  _clearPhone = effect(() => {
    const phone = this.newContactPhone();
    if (phone) {
      untracked(() => {
        this.formManager?.checkRequired('phone', phone);
      });
    }
  });

  _clearMail = effect(() => {
    const mail = this.newContactMail();
    if (mail) {
      untracked(() => {
        this.formManager?.checkRequired('mail', mail);
      });
    }
  });
}
