@if (shareLink()) {
  <axpo-dialog
    title="{{ getTitle() | transloco }}"
    cancelText="{{ getCancelText() | transloco }}"
    confirmText="{{ getConfirmText() | transloco }}"
    textAlign="left"
    (takenAction)="onShareClosed($event, mode() === 'edit' ? 'share' : 'confirmAndLoad')"
    [cancelBtnStyle]="mode() === 'create' ? 'primary' : 'secondary'"
    [cancelBtnType]="mode() === 'create' ? 'outlined' : 'ghost'"
  >
    @if (mode() === 'edit') {
      {{ 'incidentDetail.shareLinkDialog.text' | transloco }}
    } @else {
      <p class="pb-4">{{ 'incidentCreate.successP1' | transloco }}</p>
      <p class="pb-4">{{ 'incidentCreate.successP2' | transloco }}</p>
      <p class="pb-4">{{ 'incidentCreate.successP3' | transloco }}</p>
      <p class="pb-4">{{ 'incidentCreate.successP4' | transloco: { value: accessCode() } }}</p>
    }
    <div class="pt-4 pb-12 border-b-2 border-border-soft-300">
      <axpo-button
        [type]="getShareLinkBtnStyle()"
        [style]="mode() === 'create' ? 'primary' : 'secondary'"
        [autofocus]="true"
        (click)="copyLinkToClipboard()"
        class="float-right"
      >
        {{ getShareLinkBtnText() | transloco }}
      </axpo-button>
    </div>
    @if (isLoggedIn()) {
      <div class="pt-4">
        {{ 'incidentDetail.shareLinkDialog.textShareBy' | transloco }}
      </div>
      <div class="relative flex justify-between top-5">
        <div class="space-y-1.5">
          <div class="flex space-x-4">
            <div class="w-6 h-6 text-primary" [innerHTML]="getSvg()['comment']"></div>
            <axpo-typography>
              {{ 'incidentDetail.shareLinkDialog.sms' | transloco }}
            </axpo-typography>
          </div>
          <div class="flex space-x-4">
            <div class="w-6 h-6 text-primary" [innerHTML]="getSvg()['mail']"></div>
            <axpo-typography>
              {{ 'incidentDetail.shareLinkDialog.mail' | transloco }}
            </axpo-typography>
          </div>
          <div class="flex space-x-4">
            <div class="w-6 h-6 text-primary" [innerHTML]="getSvg()['phone']"></div>
            <axpo-typography>
              {{ 'incidentDetail.shareLinkDialog.wakeUpCall' | transloco }}
            </axpo-typography>
          </div>
          <div class="flex space-x-4">
            <div class="w-6 h-6 text-primary" [innerHTML]="getSvg()['info-circle']"></div>
            <axpo-typography>
              {{ 'core.personToInform' | transloco }}
            </axpo-typography>
          </div>

          <div class="flex space-x-4">
            <svg class="h-6 fill-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                d="M482 363.58V316c0-49.388-15.925-96.331-46.053-135.753-26.743-34.993-63.719-61.83-105.003-76.443C329.795 80.007 310.077 61 286 61h-60c-24.077 0-43.795 19.007-44.944 42.804-41.284 14.613-78.26 41.45-105.003 76.443C45.925 219.669 30 266.612 30 316v47.58C12.541 369.772 0 386.445 0 406c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 0-19.555-12.541-36.228-30-42.42zM60 316c0-54.091 23.106-104.728 61-140.855V226c0 8.284 6.716 15 15 15s15-6.716 15-15v-74.383a195.128 195.128 0 0130-15.679V256c0 8.284 6.716 15 15 15s15-6.716 15-15V114.932 106c0-8.271 6.729-15 15-15h60c8.271 0 15 6.729 15 15v150c0 8.284 6.716 15 15 15s15-6.716 15-15V135.938a195.304 195.304 0 0130 15.679V226c0 8.284 6.716 15 15 15s15-6.716 15-15v-50.855c37.894 36.127 61 86.764 61 140.855v45H60v-45zm407 105H45c-8.271 0-15-6.729-15-15s6.729-15 15-15h422c8.271 0 15 6.729 15 15s-6.729 15-15 15z"
              ></path>
            </svg>
            <axpo-typography>
              {{ 'core.executingPerson' | transloco }}
            </axpo-typography>
          </div>
        </div>

        <div class="ml-auto w-[80px] flex justify-between pt-[93px] relative">
          <div class="w-[20px]">
            <div class="w-6 h-6 text-primary" [innerHTML]="getSvg()['comment']"></div>
            <axpo-form-element
              formId="linkShareSMS"
              formType="checkbox"
              [value]="linkShareSMS()"
              (valueChange)="updateSMSLinkCheck($event)"
              size="medium"
            ></axpo-form-element>
          </div>
          <div class="w-[20px]">
            <div class="w-6 h-6 text-primary" [innerHTML]="getSvg()['mail']"></div>
            <axpo-form-element
              formId="linkShareMail"
              formType="checkbox"
              [value]="linkShareMail()"
              (valueChange)="updateMailLinkCheck($event)"
              size="medium"
            ></axpo-form-element>
          </div>
          <div class="w-[20px]">
            <div class="w-6 h-6 text-primary" [innerHTML]="getSvg()['phone']"></div>
            <axpo-form-element
              formId="linkShareCall"
              formType="checkbox"
              [value]="linkShareCall()"
              (valueChange)="updateCallLinkCheck($event)"
              size="medium"
            ></axpo-form-element>
          </div>
        </div>
      </div>
      <div class="pt-4">
        @for (contact of contacts(); track contact.id) {
          <div
            class="flex items-center justify-between pt-4 pb-4 border-b-2 border-border-soft-300"
          >
            <div>
              @if (contact.isExecutor === true) {
                <svg
                  class="h-6 fill-primary"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M482 363.58V316c0-49.388-15.925-96.331-46.053-135.753-26.743-34.993-63.719-61.83-105.003-76.443C329.795 80.007 310.077 61 286 61h-60c-24.077 0-43.795 19.007-44.944 42.804-41.284 14.613-78.26 41.45-105.003 76.443C45.925 219.669 30 266.612 30 316v47.58C12.541 369.772 0 386.445 0 406c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 0-19.555-12.541-36.228-30-42.42zM60 316c0-54.091 23.106-104.728 61-140.855V226c0 8.284 6.716 15 15 15s15-6.716 15-15v-74.383a195.128 195.128 0 0130-15.679V256c0 8.284 6.716 15 15 15s15-6.716 15-15V114.932 106c0-8.271 6.729-15 15-15h60c8.271 0 15 6.729 15 15v150c0 8.284 6.716 15 15 15s15-6.716 15-15V135.938a195.304 195.304 0 0130 15.679V226c0 8.284 6.716 15 15 15s15-6.716 15-15v-50.855c37.894 36.127 61 86.764 61 140.855v45H60v-45zm407 105H45c-8.271 0-15-6.729-15-15s6.729-15 15-15h422c8.271 0 15 6.729 15 15s-6.729 15-15 15z"
                  ></path>
                </svg>
              } @else {
                <div class="w-6 h-6 text-primary" [innerHTML]="getSvg()['info-circle']"></div>
              }
            </div>
            <div class="flex-1 ml-8">
              <axpo-typography variant="body-s">
                <span class="light-gray">{{ contact.designation }}</span>
              </axpo-typography>
              <axpo-typography variant="body-m">
                {{ contact.name }}
              </axpo-typography>
              <axpo-typography variant="body-s">
                <span class="light-gray">{{ contact.mail }}</span>
              </axpo-typography>
            </div>

            <div class="w-[80px] flex justify-between">
              @if (contact.phone) {
                <axpo-form-element
                  formId="contactNotifySignalPhone"
                  formType="checkbox"
                  [value]="getContactNotifySignal(contact.id, 'phone')()"
                  (valueChange)="updateContactNotifySignal(contact.id, 'phone')"
                  size="medium"
                  class="w-[20px]"
                ></axpo-form-element>
              } @else {
                <div class="w-[20px] h-[20px]"></div>
              }

              @if (contact.mail) {
                <axpo-form-element
                  formId="contactNotifySignalMail"
                  formType="checkbox"
                  [value]="getContactNotifySignal(contact.id, 'mail')()"
                  (valueChange)="updateContactNotifySignal(contact.id, 'mail')"
                  size="medium"
                  class="w-[20px]"
                ></axpo-form-element>
              } @else {
                <div class="w-[20px] h-[20px]"></div>
              }

              @if (contact.phone) {
                <axpo-form-element
                  formId="contactNotifySignalWakeUp"
                  formType="checkbox"
                  [value]="getContactNotifySignal(contact.id, 'wakeUp')()"
                  (valueChange)="updateContactNotifySignal(contact.id, 'wakeUp')"
                  size="medium"
                  class="w-[20px]"
                ></axpo-form-element>
              } @else {
                <div class="w-[20px] h-[20px]"></div>
              }
            </div>
          </div>
        }
        @if (tenantContactName()) {
          <div class="flex items-center justify-between pt-4">
            <div class="w-6 h-6 text-primary" [innerHTML]="getSvg()['info-circle']"></div>
            <div class="flex-1 ml-8">
              <axpo-typography variant="body-m" class="text-text-secondary">
                {{ 'admin.mailingList' | transloco }}
              </axpo-typography>
              <axpo-typography variant="body-m">
                {{ tenantContactName() }}
              </axpo-typography>
            </div>

            <div class="flex justify-between w-[80px]">
              <div class="w-[20px] h-[20px]"></div>
              <axpo-form-element
                formId="contactTenantInformed"
                formType="checkbox"
                [value]="tenantContactInformed()"
                (valueChange)="updateContactTenantInformedCheck($event)"
                size="medium"
                class="min-w-30"
              ></axpo-form-element>
              <div class="w-[20px] h-[20px]"></div>
            </div>
          </div>
        }
        @if (
          (incidentService.incidentDetails()?.hasInterruptions ?? 0) > 0 &&
          tenantSupplyFailureContactName()
        ) {
          <div class="flex justify-between items-center pt-4">
            <div class="w-6 h-6 text-primary" [innerHTML]="getSvg()['info-circle']"></div>
            <div class="flex-1 ml-8">
              <axpo-typography variant="body-m" class="text-text-secondary">
                {{ 'admin.mailingListSupplyFailure' | transloco }}
              </axpo-typography>
              <axpo-typography variant="body-m">
                {{ tenantSupplyFailureContactName() }}
              </axpo-typography>
            </div>

            <div class="flex justify-between w-[80px]">
              <div class="w-[20px] h-[20px]"></div>
              <axpo-form-element
                formId="tenantSupplyFailureContactInformed"
                formType="checkbox"
                [value]="tenantSupplyFailureContactInformed()"
                (valueChange)="updateSupplyFailureContactTenantInformedCheck($event)"
                size="medium"
                class="min-w-30"
              ></axpo-form-element>
              <div class="w-[20px] h-[20px]"></div>
            </div>
          </div>
        }
      </div>
      @if (mode() === 'create') {
        <axpo-button
          [style]="'primary'"
          [type]="'outlined'"
          class="float-right"
          (click)="onShareClosed('confirm', 'share')"
        >
          {{ 'incidentDetail.share' | transloco }}
        </axpo-button>
      }
    }
  </axpo-dialog>
}
